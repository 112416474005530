.tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 145px;
  height: 65px;
  background: #ffffff;
  text-align: center;
  box-shadow: 3px 5px 14.26px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.tooltip span {
  font-size: 0.857rem;
  font-weight: 600;
  color: black;
}

.tooltip input {
  border: none;
}

.tooltip::before {
  position: absolute;
  content: "";
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
  transform: translateX(310%) translateY(-200%);
}

.closeButton {
  position: absolute;
  right: 3px;
  top: 3px;
}
.closeButton:hover {
  cursor: pointer;
}
