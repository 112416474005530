@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #8268e2;
  --bs-pink: #ee5588;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #fdfdfd;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-purple: #8268e2;
  --bs-purple-rgb: 130, 104, 226;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 138, 144, 157;
  --bs-body-bg-rgb: 253, 253, 253;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: Poppins, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #353535;
  --bs-body-bg: #fdfdfd;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.37525rem + 1.503vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5025rem;
  }
}

h2,
.h2 {
  font-size: calc(1.3249375rem + 0.89925vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 1.999375rem;
  }
}

h3,
.h3 {
  font-size: calc(1.274625rem + 0.2955vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.49625rem;
  }
}

h4,
.h4 {
  font-size: calc(1.250125rem + 0.0015vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.25125rem;
  }
}

h5,
.h5 {
  font-size: 1.124375rem;
}

h6,
.h6 {
  font-size: 0.93625rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #88aaf3;
  text-decoration: underline;
}
a:hover {
  color: #6d88c2;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #ee5588;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
  padding: 5px !important;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fdfdfd;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1500px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
   
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.2);
  padding-left: calc(var(--bs-gutter-x) * 0.2);
  margin-top: var(--bs-gutter-y);
}

.col {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.row-cols-auto > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 4.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 4.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 5rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 5rem;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 4rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 4rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 5rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 5rem;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 5rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #8a909d;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #8a909d;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
  vertical-align: top;
  border-color: #f3f3f3;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #e7eefd;
  --bs-table-striped-bg: #dbe2f0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d0d6e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d6dcea;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d0d6e4;
}

.table-secondary {
  --bs-table-bg: #e8e9eb;
  --bs-table-striped-bg: #dcdddf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d1d2d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d7d8d9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d1d2d4;
}

.table-success {
  --bs-table-bg: #d6f4ea;
  --bs-table-striped-bg: #cbe8de;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1dcd3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6e2d8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1dcd3;
}

.table-info {
  --bs-table-bg: #d3f3f8;
  --bs-table-striped-bg: #c8e7ec;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bedbdf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3e1e5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bedbdf;
}

.table-warning {
  --bs-table-bg: #f8eecd;
  --bs-table-striped-bg: #ece2c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfd6b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5dcbe;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfd6b9;
}

.table-danger {
  --bs-table-bg: #fbdbde;
  --bs-table-striped-bg: #eed0d3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2c5c8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8cbcd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e2c5c8;
}

.table-light {
  --bs-table-bg: #fdfdfd;
  --bs-table-striped-bg: #f0f0f0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4e4e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eaeaea;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4e4e4;
}

.table-dark {
  --bs-table-bg: #56606e;
  --bs-table-striped-bg: #5e6875;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #67707d;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #636c79;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #67707d;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1499.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  color: #000 !important;
}

.col-form-label {
  padding-top: calc(0.59rem + 1px);
  padding-bottom: calc(0.59rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.59rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8a909d;
  background-color: #fdfdfd;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #8a909d;
  background-color: #fdfdfd;
  border-color: #c4d5f9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-webkit-input-placeholder {
  color: rgba(138, 144, 157, 0.7);
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: rgba(138, 144, 157, 0.7);
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: rgba(138, 144, 157, 0.7);
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: rgba(138, 144, 157, 0.7);
  opacity: 1;
}
.form-control::placeholder {
  color: rgba(138, 144, 157, 0.7);
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.59rem 1rem;
  margin: -0.59rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #8a909d;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.59rem 1rem;
  margin: -0.59rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #8a909d;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.59rem 1rem;
  margin: -0.59rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #8a909d;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.59rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #8a909d;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.18rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.5rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.59rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.59rem 3rem 0.59rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8a909d;
  background-color: #fdfdfd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    -webkit-transition: none;
    transition: none;
  }
}
.form-select:focus {
  border-color: #c4d5f9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #8a909d;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fdfdfd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #c4d5f9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}
.form-check-input:checked {
  background-color: #fd9801;
  border-color: #fd9801;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #88aaf3;
  border-color: #88aaf3;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  -webkit-transition: background-position 0.15s ease-in-out;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c4d5f9'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fdfdfd, 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 1px #fdfdfd, 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fdfdfd, 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #88aaf3;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #dbe6fb;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #88aaf3;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #dbe6fb;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: opacity 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    -webkit-transition: none;
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1rem;
}
.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.59rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8a909d;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.75rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #34c997;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #000;
  background-color: rgba(52, 201, 151, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #34c997;
  padding-right: calc(1.5em + 1.18rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334c997' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.295rem) center;
  background-size: calc(0.75em + 0.59rem) calc(0.75em + 0.59rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #34c997;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(52, 201, 151, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(52, 201, 151, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.18rem);
  background-position: top calc(0.375em + 0.295rem) right
    calc(0.375em + 0.295rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #34c997;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334c997' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.59rem) calc(0.75em + 0.59rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #34c997;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(52, 201, 151, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(52, 201, 151, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #34c997;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #34c997;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(52, 201, 151, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(52, 201, 151, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #34c997;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ec4a58;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #000;
  background-color: rgba(236, 74, 88, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #ec4a58;
  padding-right: calc(1.5em + 1.18rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ec4a58'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ec4a58' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.295rem) center;
  background-size: calc(0.75em + 0.59rem) calc(0.75em + 0.59rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #ec4a58;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(236, 74, 88, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(236, 74, 88, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.18rem);
  background-position: top calc(0.375em + 0.295rem) right
    calc(0.375em + 0.295rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #ec4a58;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ec4a58'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ec4a58' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.59rem) calc(0.75em + 0.59rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #ec4a58;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(236, 74, 88, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(236, 74, 88, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #ec4a58;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #ec4a58;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(236, 74, 88, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(236, 74, 88, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ec4a58;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #8a909d;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.59rem 1rem;
  font-size: 0.75rem !important;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #8a909d;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-purple {
  color: #000;
  background-color: #8268e2;
  border-color: #8268e2;
}
.btn-purple:hover {
  color: #000;
  background-color: #957fe6;
  border-color: #8f77e5;
}
.btn-check:focus + .btn-purple,
.btn-purple:focus {
  color: #000;
  background-color: #957fe6;
  border-color: #8f77e5;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(111, 88, 192, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(111, 88, 192, 0.5);
}
.btn-check:checked + .btn-purple,
.btn-check:active + .btn-purple,
.btn-purple:active,
.btn-purple.active,
.show > .btn-purple.dropdown-toggle {
  color: #000;
  background-color: #9b86e8;
  border-color: #8f77e5;
}
.btn-check:checked + .btn-purple:focus,
.btn-check:active + .btn-purple:focus,
.btn-purple:active:focus,
.btn-purple.active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(111, 88, 192, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(111, 88, 192, 0.5);
}
.btn-purple:disabled,
.btn-purple.disabled {
  color: #000;
  background-color: #8268e2;
  border-color: #8268e2;
}

.btn-outline-purple {
  color: #8268e2;
  border-color: #8268e2;
}
.btn-outline-purple:hover {
  color: #000;
  background-color: #8268e2;
  border-color: #8268e2;
}
.btn-check:focus + .btn-outline-purple,
.btn-outline-purple:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(130, 104, 226, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(130, 104, 226, 0.5);
}
.btn-check:checked + .btn-outline-purple,
.btn-check:active + .btn-outline-purple,
.btn-outline-purple:active,
.btn-outline-purple.active,
.btn-outline-purple.dropdown-toggle.show {
  color: #000;
  background-color: #8268e2;
  border-color: #8268e2;
}
.btn-check:checked + .btn-outline-purple:focus,
.btn-check:active + .btn-outline-purple:focus,
.btn-outline-purple:active:focus,
.btn-outline-purple.active:focus,
.btn-outline-purple.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(130, 104, 226, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(130, 104, 226, 0.5);
}
.btn-outline-purple:disabled,
.btn-outline-purple.disabled {
  color: #8268e2;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #88aaf3;
  text-decoration: underline;
}
.btn-link:hover {
  color: #6d88c2;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.75rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  -webkit-transition: width 0.35s ease;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #8a909d;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1500px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #88aaf3;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: #88aaf3;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  /* display: block; */
  padding: 0.5rem 1rem;
  color: #88aaf3;
  text-decoration: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    -webkit-transition: none;
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #6d88c2;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fdfdfd;
  border-color: #dee2e6 #dee2e6 #fdfdfd;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #88aaf3;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    -webkit-transition: none;
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1500px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  /* border: 1px solid #f3f3f3; */
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #f3f3f3;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #f3f3f3;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #ffffff;
  border-bottom-color: #ffffff;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

.ec-assets-card {
  text-align: left;
  font-size: 0.8125rem;
  color: #353535;
}
s .ec-assets-card > hr {
  border: 1px solid rgba(167, 167, 187, 0.82);
  margin: 10px 0;
}

.btn-card-asset {
  /* background: #FD9801 !important;
  border-color: #FD9801 !important; */
  border-radius: 5px !important;
  margin-top: 10px;
}

.ec-assets-card > .ec-asset-card-row {
  margin-bottom: 7px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #8a909d;
  text-align: left;
  background-color: #fdfdfd;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, border-radius 0.15s ease,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #7a99db;
  background-color: inherit;
  /* -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); */
}
.accordion-button:not(.collapsed)::after {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237a99db'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); */
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 10px;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238a909d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #c4d5f9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fdfdfd;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
  background-color: #f5f6f8;
}

.accordion-button {
  background-color: inherit !important;
}

.accordion-item:nth-of-type(even) {
  background-color: #c2cfe0 !important;
}


.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #88aaf3;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    -webkit-transition: none;
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #6d88c2;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #6d88c2;
  background-color: #e9ecef;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #88aaf3;
  border-color: #88aaf3;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.page-link {
  padding: 1rem 1.25rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1.25rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.5625rem 1.25rem;
}

.alert-purple {
  color: #4e3e88;
  background-color: #e6e1f9;
  border-color: #dad2f6;
}
.alert-purple .alert-link {
  color: #3e326d;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #88aaf3;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #fdfdfd;
}
.list-group-item-action:active {
  color: #8a909d;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #88aaf3;
  border-color: #88aaf3;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1500px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-purple {
  color: #4e3e88;
  background-color: #e6e1f9;
}
.list-group-item-purple.list-group-item-action:hover,
.list-group-item-purple.list-group-item-action:focus {
  color: #4e3e88;
  background-color: #cfcbe0;
}
.list-group-item-purple.list-group-item-action.active {
  color: #ffffff;
  background-color: #4e3e88;
  border-color: #4e3e88;
}

.btn-close {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(136, 170, 243, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f3f3f3;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #f3f3f3;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    min-width: 700px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
  .modal-xxl {
    max-width: 1300px !important;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1499.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Roboto, sans-serif";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Roboto, sans-serif";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #8a909d;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    -webkit-transition: none;
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-purple {
  color: #8268e2;
}
.link-purple:hover,
.link-purple:focus {
  color: #9b86e8;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1500px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.vstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: -ms-grid !important;
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #f3f3f3 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #f3f3f3 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #f3f3f3 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #f3f3f3 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #f3f3f3 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-purple {
  border-color: #8268e2 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-gray {
  border: 1px solid #787885 !important;
}

.border-black {
  border-color: #000 !important;
}

.w-25 {
  width: 25% !important;
}
.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}
.w-image-position {
  object-fit: contain !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.gap-1 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 2.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 3.5rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 4.5rem !important;
}

.gap-9 {
  gap: 5rem !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.justify-content-evenly {
  -webkit-box-pack: space-evenly !important;
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  -ms-grid-row-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important;
}

.order-first {
  -webkit-box-ordinal-group: 0 !important;
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.order-0 {
  -webkit-box-ordinal-group: 1 !important;
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

.order-1 {
  -webkit-box-ordinal-group: 2 !important;
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
}

.order-3 {
  -webkit-box-ordinal-group: 4 !important;
  -ms-flex-order: 3 !important;
  order: 3 !important;
}

.order-4 {
  -webkit-box-ordinal-group: 5 !important;
  -ms-flex-order: 4 !important;
  order: 4 !important;
}

.order-5 {
  -webkit-box-ordinal-group: 6 !important;
  -ms-flex-order: 5 !important;
  order: 5 !important;
}

.order-last {
  -webkit-box-ordinal-group: 7 !important;
  -ms-flex-order: 6 !important;
  order: 6 !important;
}

.m-6 {
  margin: 3.5rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 4.5rem !important;
}

.m-9 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-6 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-7 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-8 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-9 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-6 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-9 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-6 {
  margin-top: 3.5rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 4.5rem !important;
}

.mt-9 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-6 {
  margin-right: 3.5rem !important;
}

.me-7 {
  margin-right: 4rem !important;
}

.me-8 {
  margin-right: 4.5rem !important;
}

.me-9 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-6 {
  margin-bottom: 3.5rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 4.5rem !important;
}

.mb-9 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-6 {
  margin-left: 3.5rem !important;
}

.ms-7 {
  margin-left: 4rem !important;
}

.ms-8 {
  margin-left: 4.5rem !important;
}

.ms-9 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-6 {
  padding: 3.5rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 4.5rem !important;
}

.p-9 {
  padding: 5rem !important;
}

.px-6 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.pl-5rem {
  padding-left: 5rem !important;
}

.pr-5rem {
  padding-right: 5rem !important;
}

.px-2rem {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-7 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-8 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-9 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-6 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-9 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-6 {
  padding-top: 3.5rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 4.5rem !important;
}

.pt-9 {
  padding-top: 5rem !important;
}

.pe-6 {
  padding-right: 3.5rem !important;
}

.pe-7 {
  padding-right: 4rem !important;
}

.pe-8 {
  padding-right: 4.5rem !important;
}

.pe-9 {
  padding-right: 5rem !important;
}

.pb-6 {
  padding-bottom: 3.5rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 4.5rem !important;
}

.pb-9 {
  padding-bottom: 5rem !important;
}

.ps-6 {
  padding-left: 3.5rem !important;
}

.ps-7 {
  padding-left: 4rem !important;
}

.ps-8 {
  padding-left: 4.5rem !important;
}

.ps-9 {
  padding-left: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.37525rem + 1.503vw) !important;
}

.fs-2 {
  font-size: calc(1.3249375rem + 0.89925vw) !important;
}

.fs-3 {
  font-size: calc(1.274625rem + 0.2955vw) !important;
}

.fs-4 {
  font-size: calc(1.250125rem + 0.0015vw) !important;
}

.fs-5 {
  font-size: 1.124375rem !important;
}

.fs-6 {
  font-size: 0.93625rem !important;
}

.fs-7 {
  font-size: 0.83625rem !important;
}

.fs-8 {
  font-size: 0.73625rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  color: #fd9801 !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.button-image-list {
  padding: 5px;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-right: 3px;
}

.button-image-preview {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.button-image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  max-width: 100%;
}

.table-tools {
  width: 30%;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-6 {
    gap: 3.5rem !important;
  }

  .gap-sm-7 {
    gap: 4rem !important;
  }

  .gap-sm-8 {
    gap: 4.5rem !important;
  }

  .gap-sm-9 {
    gap: 5rem !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-sm-6 {
    margin: 3.5rem !important;
  }

  .m-sm-7 {
    margin: 4rem !important;
  }

  .m-sm-8 {
    margin: 4.5rem !important;
  }

  .m-sm-9 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-6 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 4rem !important;
  }

  .mt-sm-8 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-6 {
    margin-right: 3.5rem !important;
  }

  .me-sm-7 {
    margin-right: 4rem !important;
  }

  .me-sm-8 {
    margin-right: 4.5rem !important;
  }

  .me-sm-9 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-6 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 4rem !important;
  }

  .ms-sm-8 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-6 {
    padding: 3.5rem !important;
  }

  .p-sm-7 {
    padding: 4rem !important;
  }

  .p-sm-8 {
    padding: 4.5rem !important;
  }

  .p-sm-9 {
    padding: 5rem !important;
  }

  .px-sm-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-6 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 4rem !important;
  }

  .pt-sm-8 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 5rem !important;
  }

  .pe-sm-6 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 4rem !important;
  }

  .pe-sm-8 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 5rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-6 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 4rem !important;
  }

  .ps-sm-8 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-6 {
    gap: 3.5rem !important;
  }

  .gap-md-7 {
    gap: 4rem !important;
  }

  .gap-md-8 {
    gap: 4.5rem !important;
  }

  .gap-md-9 {
    gap: 5rem !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-md-6 {
    margin: 3.5rem !important;
  }

  .m-md-7 {
    margin: 4rem !important;
  }

  .m-md-8 {
    margin: 4.5rem !important;
  }

  .m-md-9 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-6 {
    margin-top: 3.5rem !important;
  }

  .mt-md-7 {
    margin-top: 4rem !important;
  }

  .mt-md-8 {
    margin-top: 4.5rem !important;
  }

  .mt-md-9 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-6 {
    margin-right: 3.5rem !important;
  }

  .me-md-7 {
    margin-right: 4rem !important;
  }

  .me-md-8 {
    margin-right: 4.5rem !important;
  }

  .me-md-9 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 4rem !important;
  }

  .mb-md-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-6 {
    margin-left: 3.5rem !important;
  }

  .ms-md-7 {
    margin-left: 4rem !important;
  }

  .ms-md-8 {
    margin-left: 4.5rem !important;
  }

  .ms-md-9 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-6 {
    padding: 3.5rem !important;
  }

  .p-md-7 {
    padding: 4rem !important;
  }

  .p-md-8 {
    padding: 4.5rem !important;
  }

  .p-md-9 {
    padding: 5rem !important;
  }

  .px-md-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-6 {
    padding-top: 3.5rem !important;
  }

  .pt-md-7 {
    padding-top: 4rem !important;
  }

  .pt-md-8 {
    padding-top: 4.5rem !important;
  }

  .pt-md-9 {
    padding-top: 5rem !important;
  }

  .pe-md-6 {
    padding-right: 3.5rem !important;
  }

  .pe-md-7 {
    padding-right: 4rem !important;
  }

  .pe-md-8 {
    padding-right: 4.5rem !important;
  }

  .pe-md-9 {
    padding-right: 5rem !important;
  }

  .pb-md-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 4rem !important;
  }

  .pb-md-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 5rem !important;
  }

  .ps-md-6 {
    padding-left: 3.5rem !important;
  }

  .ps-md-7 {
    padding-left: 4rem !important;
  }

  .ps-md-8 {
    padding-left: 4.5rem !important;
  }

  .ps-md-9 {
    padding-left: 5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-6 {
    gap: 3.5rem !important;
  }

  .gap-lg-7 {
    gap: 4rem !important;
  }

  .gap-lg-8 {
    gap: 4.5rem !important;
  }

  .gap-lg-9 {
    gap: 5rem !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-lg-6 {
    margin: 3.5rem !important;
  }

  .m-lg-7 {
    margin: 4rem !important;
  }

  .m-lg-8 {
    margin: 4.5rem !important;
  }

  .m-lg-9 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-6 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 4rem !important;
  }

  .mt-lg-8 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-6 {
    margin-right: 3.5rem !important;
  }

  .me-lg-7 {
    margin-right: 4rem !important;
  }

  .me-lg-8 {
    margin-right: 4.5rem !important;
  }

  .me-lg-9 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-6 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 4rem !important;
  }

  .ms-lg-8 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-6 {
    padding: 3.5rem !important;
  }

  .p-lg-7 {
    padding: 4rem !important;
  }

  .p-lg-8 {
    padding: 4.5rem !important;
  }

  .p-lg-9 {
    padding: 5rem !important;
  }

  .px-lg-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-6 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 4rem !important;
  }

  .pt-lg-8 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 5rem !important;
  }

  .pe-lg-6 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 4rem !important;
  }

  .pe-lg-8 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 5rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-6 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 4rem !important;
  }

  .ps-lg-8 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-6 {
    gap: 3.5rem !important;
  }

  .gap-xl-7 {
    gap: 4rem !important;
  }

  .gap-xl-8 {
    gap: 4.5rem !important;
  }

  .gap-xl-9 {
    gap: 5rem !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xl-6 {
    margin: 3.5rem !important;
  }

  .m-xl-7 {
    margin: 4rem !important;
  }

  .m-xl-8 {
    margin: 4.5rem !important;
  }

  .m-xl-9 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-6 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 4rem !important;
  }

  .mt-xl-8 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-6 {
    margin-right: 3.5rem !important;
  }

  .me-xl-7 {
    margin-right: 4rem !important;
  }

  .me-xl-8 {
    margin-right: 4.5rem !important;
  }

  .me-xl-9 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-6 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 4rem !important;
  }

  .ms-xl-8 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-6 {
    padding: 3.5rem !important;
  }

  .p-xl-7 {
    padding: 4rem !important;
  }

  .p-xl-8 {
    padding: 4.5rem !important;
  }

  .p-xl-9 {
    padding: 5rem !important;
  }

  .px-xl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-6 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 4rem !important;
  }

  .pt-xl-8 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 5rem !important;
  }

  .pe-xl-6 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 4rem !important;
  }

  .pe-xl-8 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 5rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-6 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 4rem !important;
  }

  .ps-xl-8 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1500px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-6 {
    gap: 3.5rem !important;
  }

  .gap-xxl-7 {
    gap: 4rem !important;
  }

  .gap-xxl-8 {
    gap: 4.5rem !important;
  }

  .gap-xxl-9 {
    gap: 5rem !important;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xxl-6 {
    margin: 3.5rem !important;
  }

  .m-xxl-7 {
    margin: 4rem !important;
  }

  .m-xxl-8 {
    margin: 4.5rem !important;
  }

  .m-xxl-9 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-6 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 4rem !important;
  }

  .mt-xxl-8 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-6 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 4rem !important;
  }

  .me-xxl-8 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-6 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 4rem !important;
  }

  .ms-xxl-8 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-6 {
    padding: 3.5rem !important;
  }

  .p-xxl-7 {
    padding: 4rem !important;
  }

  .p-xxl-8 {
    padding: 4.5rem !important;
  }

  .p-xxl-9 {
    padding: 5rem !important;
  }

  .px-xxl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-6 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 4rem !important;
  }

  .pt-xxl-8 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 5rem !important;
  }

  .pe-xxl-6 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 4rem !important;
  }

  .pe-xxl-8 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 5rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-6 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 4rem !important;
  }

  .ps-xxl-8 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5025rem !important;
  }

  .fs-2 {
    font-size: 1.999375rem !important;
  }

  .fs-3 {
    font-size: 1.49625rem !important;
  }

  .fs-4 {
    font-size: 1.25125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.ec-cat-form h4,
.ec-cat-form .h4 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
@media (max-width: 1199.98px) {
  .ec-cat-form h4,
  .ec-cat-form .h4 {
    font-size: 18px;
  }
}
.ec-cat-form form label {
  width: 100%;
  font-weight: 500;
  color: #8a909d;
}
.ec-cat-form form label span {
  font-size: 0.928rem;
  color: #999;
  font-weight: 300;
  text-transform: none;
}
.ec-cat-form form input {
  width: 100%;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #eeeeee !important;
  background-color: transparent;
  color: #777;
  line-height: 3;
  border-radius: 15px;
}
.ec-cat-form form textarea {
  width: 100%;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #eeeeee !important;
  background-color: transparent;
  color: #777;
  padding: 15px;
  border-radius: 15px;
}
.ec-cat-form form select {
  width: 100%;
  height: 45px;
  margin: 0;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #eeeeee !important;
  background-color: transparent;
  color: #777;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-radius: 15px;
}
.ec-cat-form form input[type="text"] {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-radius: 15px;
}
.ec-cat-form form small,
.ec-cat-form form .small {
  margin-top: 10px;
  display: block;
}
.ec-cat-form form input[type="color"] {
  width: 25px !important;
  height: 25px !important;
  padding: 0;
  border-radius: 15px;
  border-radius: 0 !important;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 4px;
  min-height: 25px;
}
.ec-cat-form form input[type="color"] i {
  border-radius: 0;
}
.ec-cat-form form .form-checkbox-box {
  height: 30px;
  margin-top: 5px;
  display: inline-block;
}
.ec-cat-form form .form-check {
  height: 20px;
  margin: 0 5px 0 0;
  padding: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ec-cat-form form .form-check input {
  width: 35px !important;
  height: 100% !important;
  margin: 0;
}
.ec-cat-form form .form-check label {
  margin: 5px 5px 2px 7px;
  line-height: 11px;
  color: #999;
}
.ec-cat-form .bootstrap-tagsinput {
  width: 100%;
  margin-bottom: 15px;
  background-color: transparent;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 5px 10px;
  color: #555;
  vertical-align: middle;
  border-radius: 15px;
  line-height: 22px;
  cursor: text;
}
.ec-cat-form .bootstrap-tagsinput input {
  height: 40px;
  border: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 5px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.ec-cat-form .bootstrap-tagsinput input:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-cat-form .bootstrap-tagsinput input::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}
.ec-cat-form .bootstrap-tagsinput input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-cat-form .bootstrap-tagsinput input:-ms-input-placeholder {
  color: #999;
  opacity: 1;
  color: #999;
}
.ec-cat-form .bootstrap-tagsinput input::-ms-input-placeholder {
  color: #999;
  opacity: 1;
  color: #999;
}
.ec-cat-form .bootstrap-tagsinput input::placeholder {
  color: #999;
  opacity: 1;
}
.ec-cat-form .bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #777;
}
.ec-cat-form .bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
  color: #f96e6e;
}
.ec-cat-form .bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "×";
  padding: 0px 2px;
}
.ec-cat-form .bootstrap-tagsinput .tag [data-role="remove"]:hover {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
.ec-cat-form .bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-cat-form .bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.ec-cat-form .bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777;
}
.ec-cat-form
  .bootstrap-tagsinput.form-control
  input::-webkit-input-placeholder {
  color: #777;
}

.ec-cat-list .cat-thumb {
  width: 45px;
  height: 45px;
  border-radius: 15px;
}
.ec-cat-list table tbody tr td {
  max-width: 300px;
}
.ec-cat-list table tbody tr td .ec-sub-cat-count {
  margin: 2px 0 2px 5px;
  background-color: #88aaf3;
  padding: 2px 7px;
  color: #fff;
  border-radius: 10px;
  display: inline-block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.ec-cat-list table tbody tr td .ec-sub-cat-tag {
  margin: 3px 0 3px 5px;
  background-color: #f9f9f9;
  padding: 2px 7px;
  border-radius: 10px;
  text-transform: capitalize;
  display: inline-block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 0.857rem;
  letter-spacing: 0.02rem;
}

/*===== ERROR =====*/
.error-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 15px;
}
.error-wrapper .error-title {
  font-size: 140px;
  line-height: 120px;
  font-weight: 600;
}
@media (max-width: 991.98px) {
  .error-wrapper .error-title {
    font-size: 100px;
    line-height: 80px;
  }
}
@media (max-width: 767.98px) {
  .error-wrapper .error-title {
    font-size: 70px;
    line-height: 50px;
  }
}
@media (max-width: 575.98px) {
  .error-wrapper .error-title {
    font-size: 50px;
    line-height: 35px;
  }
}
.error-wrapper .error-title .error-subtitle {
  font-size: 15px;
}
.error-wrapper .error-title .img-responsive {
  max-width: 100%;
}

/*===== PROFILE =====*/
@media (min-width: 992px) {
  .profile-content-right {
    border-top: 0px;
    border-left: 1px solid #f3f3f3;
  }
}
.profile-content-right .nav-style-border.nav-tabs .nav-link {
  padding: 0 0 0.94rem;
}

.profile-timeline-media .media-body > span {
  font-size: 0.81rem;
}
.profile-timeline-media .media-body > p {
  line-height: 1.6;
  padding-top: 2rem;
}

.text-dark {
  color: #56606e !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-success {
  color: #28a745 !important;
}

.text-secondary {
  color: #8a909d !important;
}

.ec-vendor-list .vendor-thumb {
  width: 45px;
  height: 45px;
  border-radius: 15px;
}

.check-health {
  width: 2em;
  height: 2em;
}

.check-health:checked {
  background-color: #fd9801;
  border-color: #fd9801;
}

 

 

.mh-300 {
  min-height: 300px !important;
}

.alert-icon-img {
  height: auto;
  width: 80px;
  margin: 0 auto;
}

.alert-icon-img-sm {
  height: auto;
  width: 32px;
  margin: 0 auto;
}

.ec-custom-button-add {
  color: #d3d3d3;
}

.ec-custom-button-add > a {
  text-decoration: none !important;
  color: inherit;
}

.ec-custom-button-add > a > i {
  font-size: 100px;
}
 
.bg-blue-handover {
  background-color: #3051cc !important;
}

.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
  background-color: #353535;
  color: #fff;
  height: 100px;
}
.divTableCell-1 {
  display: table-cell;
  padding: 15px 20px;
  /*border-right: 1px solid #fff;*/
  border-top-left-radius: 10px;
  width: 50%;
}
.divTableCell-2 {
  display: table-cell;
  padding: 15px 20px;
  /*border-left: 1px solid #fff;*/
  border-top-right-radius: 10px;
  width: 50%;
}
.divTableBody {
  display: table-row-group;
}

.qrcode-grid {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-top: -20px !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #eeeeee;
}

.qrcode-grid > img {
  width: 50px;
  height: auto;
}

.user-list .vendor-thumb {
  width: 45px;
  height: 45px;
}

.ec-user-card .img-fluid {
  width: 70px;
  border-radius: 15px;
}
.ec-user-card .media-body h5,
.ec-user-card .media-body .h5 {
  font-size: 1.143rem;
}

.ec-ec-content-wrapper .view-detail {
  position: absolute;
  top: 6px;
  right: 15px;
  font-size: 20px;
  color: #bbb;
}
.ec-ec-content-wrapper .modal-contact-detail .dropdown i {
  margin: 0 15px;
}

/*===== ORDERS =====*/
.ec-odr-dtl .ec-odr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ec-odr-dtl h2,
.ec-odr-dtl .h2 {
  margin-top: 0px;
  line-height: 0.8em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}
.ec-odr-dtl .small {
  font-weight: 300;
  text-align: right;
}
.ec-odr-dtl hr {
  margin-top: 10px;
  border-color: #ddd;
}
.ec-odr-dtl .table tr.line {
  border-bottom: 1px solid #ccc;
}
.ec-odr-dtl .table td {
  border: none;
}
.ec-odr-dtl .identity {
  margin-top: 10px;
  font-size: 1.1em;
  font-weight: 300;
}
.ec-odr-dtl .identity strong {
  font-weight: 600;
}
.ec-odr-dtl .info-grid {
  font-style: normal;
  line-height: inherit;
  border: 1px solid #e1e2e6;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}
@media (max-width: 1199.98px) {
  .ec-odr-dtl .info-grid {
    height: auto;
    margin-bottom: 24px;
  }
}
.ec-odr-dtl .info-title {
  width: 100%;
  text-align: center;
  display: inline-block;
  background: #f7f7f7;
  padding: 10px;
  color: #56606e;
}
.ec-odr-dtl .info-title strong {
  font-weight: 500;
}
.ec-odr-dtl .info-content {
  padding: 20px;
}
.ec-odr-dtl .tbl-title {
  margin-top: 30px;
  border: 1px solid #f7f7f7;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  background: #f7f7f7;
  color: #2b2b2b;
  font-size: 15px;
}
@media (max-width: 1199.98px) {
  .ec-odr-dtl .tbl-title {
    margin-top: 0;
  }
}
.ec-odr-dtl .product-img {
  width: 50px;
  border-radius: 15px;
}

.o-tbl thead tr td strong {
  font-weight: 500;
  color: #56606e;
}
.o-tbl tbody tr:nth-of-type(odd) > * {
  background-color: #f9f9f9;
  --bs-table-accent-bg: #f9f9f9;
}
.o-tbl tbody tr td strong {
  font-weight: 500;
  color: #56606e;
}

/*===== TRACK ORDERS =====*/
.steps .step {
  display: block;
  width: 100%;
  text-align: center;
}
@media (max-width: 575.98px) {
  .steps .step {
    margin-bottom: 24px;
  }
}
.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}
.steps .step .step-icon-wrap::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
  left: 0;
}
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
  right: 0;
}
@media (max-width: 991.98px) {
  .steps .step .step-icon-wrap {
    height: 50px;
  }
}
.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 30px;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
}
@media (max-width: 991.98px) {
  .steps .step .step-icon {
    width: 50px;
    height: 50px;
    font-size: 26px;
    line-height: 50px;
    border-radius: 18px;
  }
}
.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}
.steps .step:first-child .step-icon-wrap::before {
  display: none;
}
.steps .step:last-child .step-icon-wrap::after {
  display: none;
}
.steps .step.completed .step-icon-wrap::before {
  background-color: #88aaf3;
}
.steps .step.completed .step-icon-wrap::after {
  background-color: #88aaf3;
}
.steps .step.completed .step-icon {
  border-color: #88aaf3;
  background-color: #88aaf3;
  color: #fff;
}

.trk-order span {
  color: #777777;
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before {
    display: none;
  }
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before {
    display: none;
  }
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before {
    display: none;
  }
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before {
    display: none;
  }
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
/*===== ADD PRODUCTS =====*/
.ec-vendor-uploads .ec-vendor-img-upload {
  margin-bottom: -10px;
  position: sticky;
  top: 30px;
}
.ec-vendor-uploads .ec-vendor-img-upload .ec-vendor-main-img .avatar-upload {
  margin-bottom: 10px;
  position: relative;
}
@media (max-width: 991.98px) {
  .ec-vendor-uploads .ec-vendor-img-upload .ec-vendor-main-img .avatar-upload {
    max-width: 400px;
    margin: 0 auto 15px auto;
  }
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-edit {
  position: absolute;
  right: 25px;
  z-index: 1;
  top: 25px;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-edit
  input {
  opacity: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-edit
  input
  + label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin-bottom: 0;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #eeeeee;
  cursor: pointer;
  font-weight: normal;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-edit
  input
  + label
  .svg_img {
  width: 25px;
  opacity: 0.6;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-edit
  input
  + label:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-preview {
  width: 100%;
  height: 100%;
  padding: 9px;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 15px;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-preview
  > div {
  /* width: 100%;
  height: 100%; */
  /* width: 330px;
  height: 350px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%;
  min-height: 250px; */
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .avatar-upload
  .avatar-preview
  .imagePreview
  img {
  margin: auto;
  vertical-align: middle;
  max-width: 100%;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.ec-vendor-uploads .ec-vendor-img-upload .ec-vendor-main-img .thumb-upload-set {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  /* display: inline-flex; */
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .ec-vendor-uploads
    .ec-vendor-img-upload
    .ec-vendor-main-img
    .thumb-upload-set {
    padding-bottom: 24px;
  }
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload {
  margin: 2px 7px 10px 7px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  .thumb-edit {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  input {
  opacity: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  input
  + label {
  width: 30px;
  height: 30px;
  padding: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #eeeeee;
  cursor: pointer;
  font-weight: normal;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  input
  + label
  .svg_img {
  width: 18px;
  opacity: 0.6;
  cursor: pointer;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  input
  + label:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  .thumb-preview {
  padding: 2px;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 10px;
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  .thumb-preview
  > div {
  width: 90px;
  height: 90px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 1199.98px) {
  .ec-vendor-uploads
    .ec-vendor-img-upload
    .ec-vendor-main-img
    .thumb-upload-set
    .thumb-upload
    .thumb-preview
    > div {
    width: 70px;
    height: 70px;
  }
}
.ec-vendor-uploads
  .ec-vendor-img-upload
  .ec-vendor-main-img
  .thumb-upload-set
  .thumb-upload
  .thumb-preview
  img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 7px;
  display: block;
  margin: auto;
}
.ec-vendor-uploads .ec-vendor-upload-detail .mb-25 {
  margin-bottom: 25px;
}
.ec-vendor-uploads .ec-vendor-upload-detail form label {
  width: 100%;
  /* text-transform: uppercase; */
  font-weight: 500;
}
.ec-vendor-uploads .ec-vendor-upload-detail form label span {
  font-size: 0.928rem;
  color: #999;
  font-weight: 300;
  text-transform: none;
}
.ec-vendor-uploads .ec-vendor-upload-detail form input {
  width: 100%;
  /*margin-bottom: 30px;*/
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #ddd !important;
  background-color: transparent;
  color: #777;
  line-height: 3;
  border-radius: 15px;
}
.ec-vendor-uploads .ec-vendor-upload-detail form textarea {
  width: 100%;
  margin-bottom: 30px;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #ddd !important;
  background-color: transparent;
  color: #777;
  padding: 15px;
  border-radius: 15px;
}
.ec-vendor-uploads .ec-vendor-upload-detail form select {
  width: 100%;
  height: 45px;
  margin-bottom: 30px;
  padding: 0 15px;
  border: 1px solid #ddd !important;
  background-color: transparent;
  color: #777;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-radius: 5px;
}
.ec-vendor-uploads .ec-vendor-upload-detail form input[type="text"] {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-radius: 5px;
}
.ec-vendor-uploads .ec-vendor-upload-detail form input[type="color"] {
  width: 25px !important;
  height: 25px !important;
  padding: 0;
  border-radius: 15px;
  border-radius: 0 !important;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 4px;
  min-height: 25px;
}
.ec-vendor-uploads .ec-vendor-upload-detail form input[type="color"] i {
  border-radius: 0;
}
.ec-vendor-uploads .ec-vendor-upload-detail form .form-checkbox-box {
  height: 30px;
  margin-top: 5px;
  display: inline-block;
}
.ec-vendor-uploads .ec-vendor-upload-detail form .form-check {
  height: 20px;
  margin: 0 5px 0 0;
  padding: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ec-vendor-uploads .ec-vendor-upload-detail form .form-check input {
  width: 35px !important;
  height: 100% !important;
  margin: 0;
}
.ec-vendor-uploads .ec-vendor-upload-detail form .form-check label {
  margin: 5px 5px 2px 7px;
  line-height: 11px;
  color: #999;
}
.ec-vendor-uploads .ec-vendor-upload-detail .bootstrap-tagsinput {
  width: 100%;
  margin-bottom: 30px;
  background-color: transparent;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 5px 10px;
  color: #555;
  vertical-align: middle;
  border-radius: 15px;
  line-height: 22px;
  cursor: text;
}
.ec-vendor-uploads .ec-vendor-upload-detail .bootstrap-tagsinput input {
  height: 40px;
  border: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 5px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.ec-vendor-uploads .ec-vendor-upload-detail .bootstrap-tagsinput input:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  input::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  input:-ms-input-placeholder {
  color: #999;
  opacity: 1;
  color: #999;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  input::-ms-input-placeholder {
  color: #999;
  opacity: 1;
  color: #999;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  input::placeholder {
  color: #999;
  opacity: 1;
}
.ec-vendor-uploads .ec-vendor-upload-detail .bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #777;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  .tag
  [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
  color: #f96e6e;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  .tag
  [data-role="remove"]:after {
  content: "×";
  padding: 0px 2px;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  .tag
  [data-role="remove"]:hover {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput
  .tag
  [data-role="remove"]:hover:active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput.form-control
  input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput.form-control
  input:-ms-input-placeholder {
  color: #777;
}
.ec-vendor-uploads
  .ec-vendor-upload-detail
  .bootstrap-tagsinput.form-control
  input::-webkit-input-placeholder {
  color: #777;
}

/*===== LIST PRODUCTS =====*/
.card-default .card-header .card-bar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.card-default .card-header .card-bar .p-space {
  padding: 0 6px;
}
@media (max-width: 991.98px) {
  .card-default .card-header .card-bar .p-space {
    width: 50%;
  }
}
.card-default .card-header .card-bar .sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .card-default .card-header .card-bar .sort {
    padding-top: 12px;
  }
}
@media (max-width: 991.98px) {
  .card-default .card-header .card-bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.card-wrapper {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: white;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}
.card-wrapper:hover {
  -webkit-box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0 20px -1px rgba(0, 0, 0, 0.1);
}
.card-wrapper:hover .card-top .card-image {
  border-radius: 10px;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.card-wrapper .card-container {
  padding: 12px;
}
.card-wrapper .card-container .card-top {
  overflow: hidden;
  border-radius: 10px;
}
.card-wrapper .card-container .card-top .card-image {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  border-radius: 10px;
}
.card-wrapper .card-container .card-bottom {
  padding: 12px 0 0 0;
  width: 100%;
  background: #ffffff;
}
.card-wrapper .card-container .card-bottom h3,
.card-wrapper .card-container .card-bottom .h3 {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}
.card-wrapper .card-container .card-bottom h3:hover,
.card-wrapper .card-container .card-bottom .h3:hover {
  cursor: pointer;
}
.card-wrapper .card-container .card-bottom p {
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.card-wrapper .card-container .card-action {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  list-style: none;
  line-height: 2em;
  overflow: hidden;
  top: 22px;
  right: -50px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  right: 22px;
  opacity: 0;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
}
.card-wrapper .card-container .card-action .card-edit {
  -ms-flex-line-pack: center;
  align-content: center;
  width: 35px;
  height: 35px;
  display: -ms-grid;
  display: grid;
  text-align: center;
}
.card-wrapper .card-container .card-action .card-edit i {
  font-size: 20px;
  padding: 0;
  color: #bbb;
  padding: 15px ​10p;
}
.card-wrapper .card-container .card-action .card-edit:hover {
  cursor: pointer;
}
.card-wrapper .card-container .card-action .card-edit:hover i {
  color: #88aaf3;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.card-wrapper .card-container .card-action .card-preview {
  -ms-flex-line-pack: center;
  align-content: center;
  display: -ms-grid;
  display: grid;
  width: 35px;
  height: 35px;
  text-align: center;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}
.card-wrapper .card-container .card-action .card-preview i {
  font-size: 20px;
  padding: 0;
  color: #bbb;
}
.card-wrapper .card-container .card-action .card-preview:hover {
  cursor: pointer;
}
.card-wrapper .card-container .card-action .card-preview:hover i {
  color: #88aaf3;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.card-wrapper .card-container .card-action .card-remove {
  -ms-flex-line-pack: center;
  align-content: center;
  display: -ms-grid;
  display: grid;
  width: 35px;
  height: 35px;
  text-align: center;
}
.card-wrapper .card-container .card-action .card-remove i {
  font-size: 20px;
  padding: 0;
  color: #bbb;
}
.card-wrapper .card-container .card-action .card-remove:hover {
  cursor: pointer;
}
.card-wrapper .card-container .card-action .card-remove:hover i {
  color: #88aaf3;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.card-wrapper:hover .card-action {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  right: 22px;
  opacity: 1;
}

/*===== PRODUCT DETAILS =====*/
/*-----  Single Product  -----*/
.single-pro-img {
  position: relative;
  width: 100%;
}
@media (max-width: 991.98px) {
  .single-pro-img {
    max-width: 500px;
    margin: auto;
  }
}
.single-pro-img .single-product-scroll {
  position: sticky;
  top: 30px;
}
.single-pro-img .ec-360-lbl {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  text-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
  display: -ms-grid;
  display: grid;
  opacity: 0.7;
}
.single-pro-img .ec-360-lbl:hover {
  opacity: 1;
}
.single-pro-img .ec-video-icon {
  position: absolute;
  display: -ms-grid;
  display: grid;
  top: 15px;
  right: 15px;
  height: 40px;
  width: 40px;
  text-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
  cursor: pointer;
  z-index: 1;
  opacity: 0.7;
}
.single-pro-img .ec-video-icon:hover {
  opacity: 1;
}

.single-product-cover {
  overflow: hidden;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  border-radius: 15px;
}
.single-product-cover .slick-slide img {
  width: 100%;
  border-radius: 15px;
}

.single-nav-thumb {
  width: 100%;
}
.single-nav-thumb .slick-arrow {
  position: absolute;
  top: 37%;
  left: auto;
  right: auto;
  margin: 0 auto;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  z-index: 1;
}
.single-nav-thumb .slick-arrow.slick-prev {
  right: auto;
  left: -11px;
}
.single-nav-thumb .slick-arrow.slick-prev:before {
  content: "";
  font-family: "Material Design Icons";
  color: #777;
}
.single-nav-thumb .slick-arrow.slick-next {
  right: -14px;
  left: auto;
}
.single-nav-thumb .slick-arrow.slick-next:before {
  content: "";
  font-family: "Material Design Icons";
  color: #777;
}
.single-nav-thumb .slick-arrow {
  opacity: 0.5;
}
.single-nav-thumb .slick-arrow:hover {
  opacity: 1;
}
.single-nav-thumb .slick-arrow:before {
  content: "";
  color: #777777;
  position: relative;
  font-size: 30px;
  font-family: "EcIcons";
  width: 100%;
  line-height: 30px;
  height: 30px;
}
.single-nav-thumb .slick-list {
  margin: 0 -8px;
}
.single-nav-thumb .single-slide {
  padding: 11px 11px 0 11px;
  display: block !important;
}
.single-nav-thumb .slick-slide img {
  width: 100%;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 10px;
  border-color: #f3f3f3;
}
.single-nav-thumb .slick-slide:hover img {
  border-color: #88aaf3;
}
.single-nav-thumb .slick-slide.slick-current.slick-active img {
  border-color: #ddd;
}
.single-nav-thumb .slick-arrow {
  top: 40%;
}

.product-detail .product-desc {
  padding-bottom: 15px;
}
.product-detail .product-rate {
  padding: 5px 0;
}
.product-detail .product-rate i {
  font-size: 22px;
  padding: 0 2px 0 0;
}
.product-detail .product-rate .is-rated {
  color: #ffd481;
}
.product-detail h5,
.product-detail .h5 {
  color: #56606e;
}
.product-detail .product-price {
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 1.143rem;
}
.product-detail .product-sku {
  padding-bottom: 15px;
}
.product-detail .ec-ofr {
  margin: 10px 0 20px 0;
}
.product-detail .ec-ofr ul li {
  margin: 10px 0;
}
.product-detail .ec-ofr ul li b {
  font-weight: 500;
}
.product-detail .product-size,
.product-detail .product-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
}
.product-detail .product-size li,
.product-detail .product-color li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  border-radius: 30px;
  opacity: 0.7;
  background-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 25px;
  height: 25px;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.857rem;
  line-height: 22px;
  padding: 0;
  border: 1px solid #eeeeee;
  float: left;
  margin-right: 10px;
  border-radius: 0;
}
.product-detail .product-size li {
  width: auto;
  height: auto;
  line-height: 18px;
  padding: 0px 7px;
  border-radius: 10px;
}
.product-detail .product-color .color {
  border-radius: 30px;
}
.product-detail .product-color .color span {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 30px;
}
.product-detail .product-stock {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.product-detail .product-stock .stock {
  border: 1px solid #f3f3f3;
  margin: 0 20px 5px 0;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
}
.product-detail .product-stock .stock .title {
  font-weight: 500;
  font-size: 14px;
}
.product-detail .seller-card {
  overflow: inherit;
}
@media (max-width: 991.98px) {
  .product-detail .product-overview {
    padding-top: 24px;
  }
}
.product-detail .product-overview .list-unstyled li {
  display: block !important;
  text-align: center;
}
.product-detail .nav-tabs {
  border: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.product-detail .nav-tabs .nav-link {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.product-detail .nav-tabs .nav-link i {
  font-size: 1.143rem;
}
.product-detail .tab-content {
  overflow: hidden;
  background-color: #fff;
  text-align: left;
  padding: 35px 30px 21px 30px;
  border: 1px solid #eeeeee;
  border-radius: 0;
  color: #202020;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.6;
  border-radius: 15px;
}
.product-detail .tab-content p {
  color: #777777;
}
.product-detail .tab-content .tab-pane {
  padding: 0 !important;
}
.product-detail .tab-content .tab-pane .features {
  padding-top: 10px;
}
.product-detail .tab-content .tab-pane ul {
  padding-left: 16px;
  font-size: 15px;
}
.product-detail .tab-content .tab-pane ul li {
  list-style-type: disclosure-closed;
  color: #777777;
}
.product-detail .tab-content .tab-pane .ec-t-review-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 25px;
  padding-bottom: 25px;
  /* border-bottom: 1px solid #eeeeee; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-detail .tab-content .tab-pane .ec-t-review-item .ec-t-review-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
}
.product-detail
  .tab-content
  .tab-pane
  .ec-t-review-item
  .ec-t-review-top
  .ec-t-review-name {
  font-weight: 500;
}
.product-detail .tab-content .tab-pane .ec-t-review-item .ec-t-review-avtar {
  width: 100px;
  margin: 0 15px 0 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
}
.product-detail
  .tab-content
  .tab-pane
  .ec-t-review-item
  .ec-t-review-avtar
  img {
  width: 100%;
  border-radius: 15px;
}
@media (max-width: 1199.98px) {
  .product-detail .u-card {
    margin-top: 24px;
  }
  .product-detail .u-card img {
    width: 100px;
  }
}

.ec-t-rate {
  padding: 0;
  line-height: 25px;
}
.ec-t-rate i {
  font-size: 18px;
  padding: 0;
}
.ec-t-rate .is-rated {
  color: #ffd481;
}

.product-brand .card-default {
  margin-bottom: 24px;
  overflow: inherit;
}
@media (max-width: 767.98px) {
  .product-brand .card-default {
    max-width: 300px;
    margin: 0 auto 24px auto;
  }
}
.product-brand .rounded-circle {
  border-radius: 15px !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  width: 100px;
  border: 1px solid #f3f3f3;
}
.product-brand .brand-delete {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 17px;
  cursor: pointer;
  color: #bbb;
}
.product-brand .brand-delete:hover {
  color: #f7b7b7;
}

/** Invoice CSS **/
.invoice-wrapper .text-dark {
  font-weight: 500;
}
.invoice-wrapper address span {
  font-weight: 500;
}
.invoice-wrapper .inv-tbl tbody tr:nth-of-type(odd) > * {
  background-color: #f9f9f9;
  --bs-table-accent-bg: #f9f9f9;
}
.invoice-wrapper .inv-tbl tbody tr td {
  vertical-align: middle;
}
.invoice-wrapper .inv-tbl tbody tr td img {
  width: 40px;
  border-radius: 15px;
}
.invoice-wrapper .inc-total ul li span {
  float: right;
}
.invoice-wrapper .inc-total .btn {
  max-width: 180px;
  margin-left: auto;
}
@media (max-width: 1199.98px) {
  .invoice-wrapper .disp-none {
    display: none;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.mt-1,
.my-1 {
  margin-top: 5px !important;
}

.mr-1,
.mx-1 {
  margin-right: 5px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 5px !important;
}

.ml-1,
.mx-1 {
  margin-left: 5px !important;
}

.m-2 {
  margin: 5px !important;
}

.mt-2,
.my-2 {
  margin-top: 5px !important;
}

.mr-2,
.mx-2 {
  margin-right: 5px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 5px !important;
}

.ml-2,
.mx-2 {
  margin-left: 5px !important;
}

.m-3 {
  margin: 15px !important;
}

.mt-3,
.my-3 {
  margin-top: 15px !important;
}

.mr-3,
.mx-3 {
  margin-right: 15px !important;
}

.mb-3,
.my-3 {
  margin-bottom: 15px !important;
}

.ml-3,
.mx-3 {
  margin-left: 15px !important;
}

.m-4 {
  margin: 30px !important;
}

.mt-4,
.my-4 {
  margin-top: 30px !important;
}

.mt-24px {
  margin-top: 24px;
}

.mb-24px {
  margin-bottom: 24px;
}

.mt-m-24px {
  margin-top: -24px;
}

.mt-m-10px {
  margin-top: -10px;
}

.mt-m-55px {
  margin-top: -55px;
}

.mb-m-24px {
  margin-bottom: -24px;
}

.mr-4,
.mx-4 {
  margin-right: 30px !important;
}

.mb-4,
.my-4 {
  margin-bottom: 30px !important;
}

.ml-4,
.mx-4 {
  margin-left: 30px !important;
}

.m-5 {
  margin: 30px !important;
}

.mt-5,
.my-5 {
  margin-top: 30px !important;
}

.mr-5,
.mx-5 {
  margin-right: 30px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 30px !important;
}

.ml-5,
.mx-5 {
  margin-left: 30px !important;
}

.m-6 {
  margin: 50px !important;
}

.mt-6,
.my-6 {
  margin-top: 50px !important;
}

.mr-6,
.mx-6 {
  margin-right: 50px !important;
}

.mb-6,
.my-6 {
  margin-bottom: 50px !important;
}

.ml-6,
.mx-6 {
  margin-left: 43px !important;
}

.m-7 {
  margin: 60px !important;
}

.mt-7,
.my-7 {
  margin-top: 60px !important;
}

.mr-7,
.mx-7 {
  margin-right: 60px !important;
}

.mb-7,
.my-7 {
  margin-bottom: 60px !important;
}

.ml-7,
.mx-7 {
  margin-left: 60px !important;
}

.m-8 {
  margin: 70px !important;
}

.mt-8,
.my-8 {
  margin-top: 70px !important;
}

.mr-8,
.mx-8 {
  margin-right: 70px !important;
}

.mb-8,
.my-8 {
  margin-bottom: 70px !important;
}

.ml-8,
.mx-8 {
  margin-left: 70px !important;
}

.m-9 {
  margin: 80px !important;
}

.mt-9,
.my-9 {
  margin-top: 80px !important;
}

.mr-9,
.mx-9 {
  margin-right: 80px !important;
}

.mb-9,
.my-9 {
  margin-bottom: 80px !important;
}

.ml-9,
.mx-9 {
  margin-left: 80px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.pt-1,
.py-1 {
  padding-top: 5px !important;
}

.pr-1,
.px-1 {
  padding-right: 5px !important;
}

.pb-1,
.py-1 {
  padding-bottom: 5px !important;
}

.pl-1,
.px-1 {
  padding-left: 5px !important;
}

.p-2 {
  padding: 5px !important;
}

.pt-2,
.py-2 {
  padding-top: 5px !important;
}

.pr-2,
.px-2 {
  padding-right: 5px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 5px !important;
}

.pl-2,
.px-2 {
  padding-left: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pt-3,
.py-3 {
  padding-top: 15px !important;
}

.pr-3,
.px-3 {
  padding-right: 15px !important;
}

.pb-3,
.py-3 {
  padding-bottom: 15px !important;
}

.pl-3,
.px-3 {
  padding-left: 15px !important;
}

.p-4 {
  padding: 30px !important;
}

.p-24px {
  padding: 24px !important;
}

.pt-24px {
  padding-top: 24px !important;
}

.pb-24px {
  padding-bottom: 24px !important;
}

.pt-4,
.py-4 {
  padding-top: 30px !important;
}

.pr-4,
.px-4 {
  padding-right: 30px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 30px !important;
}

.pl-4,
.px-4 {
  padding-left: 30px !important;
}

.p-5 {
  padding: 30px !important;
}

.pt-5,
.py-5 {
  padding-top: 30px !important;
}

.pr-5,
.px-5 {
  padding-right: 30px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 30px !important;
}

.pl-5,
.px-5 {
  padding-left: 30px !important;
}

.p-6 {
  padding: 50px !important;
}

.pt-6,
.py-6 {
  padding-top: 50px !important;
}

.pr-6,
.px-6 {
  padding-right: 50px !important;
}

.pb-6,
.py-6 {
  padding-bottom: 50px !important;
}

.pl-6,
.px-6 {
  padding-left: 50px !important;
}

.p-7 {
  padding: 60px !important;
}

.pt-7,
.py-7 {
  padding-top: 60px !important;
}

.pr-7,
.px-7 {
  padding-right: 60px !important;
}

.pb-7,
.py-7 {
  padding-bottom: 60px !important;
}

.pl-7,
.px-7 {
  padding-left: 60px !important;
}

.p-8 {
  padding: 70px !important;
}

.pt-8,
.py-8 {
  padding-top: 70px !important;
}

.pr-8,
.px-8 {
  padding-right: 70px !important;
}

.pb-8,
.py-8 {
  padding-bottom: 70px !important;
}

.pl-8,
.px-8 {
  padding-left: 70px !important;
}

.p-9 {
  padding: 80px !important;
}

.pt-9,
.py-9 {
  padding-top: 80px !important;
}

.pr-9,
.px-9 {
  padding-right: 80px !important;
}

.pb-9,
.py-9 {
  padding-bottom: 80px !important;
}

.pl-9,
.px-9 {
  padding-left: 80px !important;
}

.m-n1 {
  margin: -5px !important;
}

.mt-n1,
.my-n1 {
  margin-top: -5px !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -5px !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -5px !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -5px !important;
}

.m-n2 {
  margin: -5px !important;
}

.mt-n2,
.my-n2 {
  margin-top: -5px !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -5px !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -5px !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -5px !important;
}

.m-n3 {
  margin: -15px !important;
}

.mt-n3,
.my-n3 {
  margin-top: -15px !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -15px !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -15px !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -15px !important;
}

.m-n4 {
  margin: -30px !important;
}

.mt-n4,
.my-n4 {
  margin-top: -30px !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -30px !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -30px !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -30px !important;
}

.m-n5 {
  margin: -50px !important;
}

.mt-n5,
.my-n5 {
  margin-top: -50px !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -50px !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -50px !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -50px !important;
}

.m-n6 {
  margin: -50px !important;
}

.mt-n6,
.my-n6 {
  margin-top: -50px !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -50px !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -50px !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -50px !important;
}

.m-n7 {
  margin: -60px !important;
}

.mt-n7,
.my-n7 {
  margin-top: -60px !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -60px !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -60px !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -60px !important;
}

.m-n8 {
  margin: -70px !important;
}

.mt-n8,
.my-n8 {
  margin-top: -70px !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -70px !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -70px !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -70px !important;
}

.m-n9 {
  margin: -80px !important;
}

.mt-n9,
.my-n9 {
  margin-top: -80px !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -80px !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -80px !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -80px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 5px !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 5px !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 5px !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 5px !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 5px !important;
  }

  .m-sm-2 {
    margin: 5px !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 5px !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 5px !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 5px !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 5px !important;
  }

  .m-sm-3 {
    margin: 15px !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 15px !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 15px !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 15px !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 15px !important;
  }

  .m-sm-4 {
    margin: 30px !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 30px !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 30px !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 30px !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 30px !important;
  }

  .m-sm-5 {
    margin: 30px !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 30px !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 30px !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 30px !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 30px !important;
  }

  .m-sm-6 {
    margin: 50px !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 50px !important;
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 50px !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 50px !important;
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 50px !important;
  }

  .m-sm-7 {
    margin: 60px !important;
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 60px !important;
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 60px !important;
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 60px !important;
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 60px !important;
  }

  .m-sm-8 {
    margin: 70px !important;
  }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 70px !important;
  }

  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 70px !important;
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 70px !important;
  }

  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 70px !important;
  }

  .m-sm-9 {
    margin: 80px !important;
  }

  .mt-sm-9,
  .my-sm-9 {
    margin-top: 80px !important;
  }

  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 80px !important;
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 80px !important;
  }

  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 80px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 5px !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 5px !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 5px !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 5px !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 5px !important;
  }

  .p-sm-2 {
    padding: 5px !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 5px !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 5px !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 5px !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 5px !important;
  }

  .p-sm-3 {
    padding: 15px !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 15px !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 15px !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 15px !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 15px !important;
  }

  .p-sm-4 {
    padding: 30px !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 30px !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 30px !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 30px !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 30px !important;
  }

  .p-sm-5 {
    padding: 30px !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 30px !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 30px !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 30px !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 30px !important;
  }

  .p-sm-6 {
    padding: 50px !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 50px !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 50px !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 50px !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 50px !important;
  }

  .p-sm-7 {
    padding: 60px !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 60px !important;
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 60px !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 60px !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 60px !important;
  }

  .p-sm-8 {
    padding: 70px !important;
  }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 70px !important;
  }

  .pr-sm-8,
  .px-sm-8 {
    padding-right: 70px !important;
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 70px !important;
  }

  .pl-sm-8,
  .px-sm-8 {
    padding-left: 70px !important;
  }

  .p-sm-9 {
    padding: 80px !important;
  }

  .pt-sm-9,
  .py-sm-9 {
    padding-top: 80px !important;
  }

  .pr-sm-9,
  .px-sm-9 {
    padding-right: 80px !important;
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 80px !important;
  }

  .pl-sm-9,
  .px-sm-9 {
    padding-left: 80px !important;
  }

  .m-sm-n1 {
    margin: -5px !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -5px !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -5px !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -5px !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -5px !important;
  }

  .m-sm-n2 {
    margin: -5px !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -5px !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -5px !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -5px !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -5px !important;
  }

  .m-sm-n3 {
    margin: -15px !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -15px !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -15px !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -15px !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -15px !important;
  }

  .m-sm-n4 {
    margin: -30px !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -30px !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -30px !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -30px !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -30px !important;
  }

  .m-sm-n5 {
    margin: -50px !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -50px !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -50px !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -50px !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -50px !important;
  }

  .m-sm-n6 {
    margin: -50px !important;
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -50px !important;
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -50px !important;
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -50px !important;
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -50px !important;
  }

  .m-sm-n7 {
    margin: -60px !important;
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -60px !important;
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -60px !important;
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -60px !important;
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -60px !important;
  }

  .m-sm-n8 {
    margin: -70px !important;
  }

  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -70px !important;
  }

  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -70px !important;
  }

  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -70px !important;
  }

  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -70px !important;
  }

  .m-sm-n9 {
    margin: -80px !important;
  }

  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -80px !important;
  }

  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -80px !important;
  }

  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -80px !important;
  }

  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -80px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 5px !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 5px !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 5px !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 5px !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 5px !important;
  }

  .m-md-2 {
    margin: 5px !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 5px !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 5px !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 5px !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 5px !important;
  }

  .m-md-3 {
    margin: 15px !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 15px !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 15px !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 15px !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 15px !important;
  }

  .m-md-4 {
    margin: 30px !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 30px !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 30px !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 30px !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 30px !important;
  }

  .m-md-5 {
    margin: 30px !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 30px !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 30px !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 30px !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 30px !important;
  }

  .m-md-6 {
    margin: 50px !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 50px !important;
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 50px !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 50px !important;
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 50px !important;
  }

  .m-md-7 {
    margin: 60px !important;
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 60px !important;
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 60px !important;
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 60px !important;
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 60px !important;
  }

  .m-md-8 {
    margin: 70px !important;
  }

  .mt-md-8,
  .my-md-8 {
    margin-top: 70px !important;
  }

  .mr-md-8,
  .mx-md-8 {
    margin-right: 70px !important;
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 70px !important;
  }

  .ml-md-8,
  .mx-md-8 {
    margin-left: 70px !important;
  }

  .m-md-9 {
    margin: 80px !important;
  }

  .mt-md-9,
  .my-md-9 {
    margin-top: 80px !important;
  }

  .mr-md-9,
  .mx-md-9 {
    margin-right: 80px !important;
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 80px !important;
  }

  .ml-md-9,
  .mx-md-9 {
    margin-left: 80px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 5px !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 5px !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 5px !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 5px !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 5px !important;
  }

  .p-md-2 {
    padding: 5px !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 5px !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 5px !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 5px !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 5px !important;
  }

  .p-md-3 {
    padding: 15px !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 15px !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 15px !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 15px !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 15px !important;
  }

  .p-md-4 {
    padding: 30px !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 30px !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 30px !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 30px !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 30px !important;
  }

  .p-md-5 {
    padding: 30px !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 30px !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 30px !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 30px !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 30px !important;
  }

  .p-md-6 {
    padding: 50px !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 50px !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 50px !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 50px !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 50px !important;
  }

  .p-md-7 {
    padding: 60px !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 60px !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 60px !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 60px !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 60px !important;
  }

  .p-md-8 {
    padding: 70px !important;
  }

  .pt-md-8,
  .py-md-8 {
    padding-top: 70px !important;
  }

  .pr-md-8,
  .px-md-8 {
    padding-right: 70px !important;
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 70px !important;
  }

  .pl-md-8,
  .px-md-8 {
    padding-left: 70px !important;
  }

  .p-md-9 {
    padding: 80px !important;
  }

  .pt-md-9,
  .py-md-9 {
    padding-top: 80px !important;
  }

  .pr-md-9,
  .px-md-9 {
    padding-right: 80px !important;
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 80px !important;
  }

  .pl-md-9,
  .px-md-9 {
    padding-left: 80px !important;
  }

  .m-md-n1 {
    margin: -5px !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -5px !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -5px !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -5px !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -5px !important;
  }

  .m-md-n2 {
    margin: -5px !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -5px !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -5px !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -5px !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -5px !important;
  }

  .m-md-n3 {
    margin: -15px !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -15px !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -15px !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -15px !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -15px !important;
  }

  .m-md-n4 {
    margin: -30px !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -30px !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -30px !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -30px !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -30px !important;
  }

  .m-md-n5 {
    margin: -50px !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -50px !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -50px !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -50px !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -50px !important;
  }

  .m-md-n6 {
    margin: -50px !important;
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -50px !important;
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -50px !important;
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -50px !important;
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -50px !important;
  }

  .m-md-n7 {
    margin: -60px !important;
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -60px !important;
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -60px !important;
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -60px !important;
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -60px !important;
  }

  .m-md-n8 {
    margin: -70px !important;
  }

  .mt-md-n8,
  .my-md-n8 {
    margin-top: -70px !important;
  }

  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -70px !important;
  }

  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -70px !important;
  }

  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -70px !important;
  }

  .m-md-n9 {
    margin: -80px !important;
  }

  .mt-md-n9,
  .my-md-n9 {
    margin-top: -80px !important;
  }

  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -80px !important;
  }

  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -80px !important;
  }

  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -80px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 5px !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 5px !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 5px !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 5px !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 5px !important;
  }

  .m-lg-2 {
    margin: 5px !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 5px !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 5px !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 5px !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 5px !important;
  }

  .m-lg-3 {
    margin: 15px !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 15px !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 15px !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 15px !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 15px !important;
  }

  .m-lg-4 {
    margin: 30px !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 30px !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 30px !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 30px !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 30px !important;
  }

  .m-lg-5 {
    margin: 30px !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 30px !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 30px !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 30px !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 30px !important;
  }

  .m-lg-6 {
    margin: 50px !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 50px !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 50px !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 50px !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 50px !important;
  }

  .m-lg-7 {
    margin: 60px !important;
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 60px !important;
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 60px !important;
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 60px !important;
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 60px !important;
  }

  .m-lg-8 {
    margin: 70px !important;
  }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 70px !important;
  }

  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 70px !important;
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 70px !important;
  }

  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 70px !important;
  }

  .m-lg-9 {
    margin: 80px !important;
  }

  .mt-lg-9,
  .my-lg-9 {
    margin-top: 80px !important;
  }

  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 80px !important;
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 80px !important;
  }

  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 80px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 5px !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 5px !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 5px !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 5px !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 5px !important;
  }

  .p-lg-2 {
    padding: 5px !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 5px !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 5px !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 5px !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 5px !important;
  }

  .p-lg-3 {
    padding: 15px !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 15px !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 15px !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 15px !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 15px !important;
  }

  .p-lg-4 {
    padding: 30px !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 30px !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 30px !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 30px !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 30px !important;
  }

  .p-lg-5 {
    padding: 30px !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 30px !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 30px !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 30px !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 30px !important;
  }

  .p-lg-6 {
    padding: 50px !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 50px !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 50px !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 50px !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 50px !important;
  }

  .p-lg-7 {
    padding: 60px !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 60px !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 60px !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 60px !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 60px !important;
  }

  .p-lg-8 {
    padding: 70px !important;
  }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 70px !important;
  }

  .pr-lg-8,
  .px-lg-8 {
    padding-right: 70px !important;
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 70px !important;
  }

  .pl-lg-8,
  .px-lg-8 {
    padding-left: 70px !important;
  }

  .p-lg-9 {
    padding: 80px !important;
  }

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 80px !important;
  }

  .pr-lg-9,
  .px-lg-9 {
    padding-right: 80px !important;
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 80px !important;
  }

  .pl-lg-9,
  .px-lg-9 {
    padding-left: 80px !important;
  }

  .m-lg-n1 {
    margin: -5px !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -5px !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -5px !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -5px !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -5px !important;
  }

  .m-lg-n2 {
    margin: -5px !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -5px !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -5px !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -5px !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -5px !important;
  }

  .m-lg-n3 {
    margin: -15px !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -15px !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -15px !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -15px !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -15px !important;
  }

  .m-lg-n4 {
    margin: -30px !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -30px !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -30px !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -30px !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -30px !important;
  }

  .m-lg-n5 {
    margin: -50px !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -50px !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -50px !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -50px !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -50px !important;
  }

  .m-lg-n6 {
    margin: -50px !important;
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -50px !important;
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -50px !important;
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -50px !important;
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -50px !important;
  }

  .m-lg-n7 {
    margin: -60px !important;
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -60px !important;
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -60px !important;
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -60px !important;
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -60px !important;
  }

  .m-lg-n8 {
    margin: -70px !important;
  }

  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -70px !important;
  }

  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -70px !important;
  }

  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -70px !important;
  }

  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -70px !important;
  }

  .m-lg-n9 {
    margin: -80px !important;
  }

  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -80px !important;
  }

  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -80px !important;
  }

  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -80px !important;
  }

  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -80px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 5px !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 5px !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 5px !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 5px !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 5px !important;
  }

  .m-xl-2 {
    margin: 5px !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 5px !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 5px !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 5px !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 5px !important;
  }

  .m-xl-3 {
    margin: 15px !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 15px !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 15px !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 15px !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 15px !important;
  }

  .m-xl-4 {
    margin: 30px !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 30px !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 30px !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 30px !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 30px !important;
  }

  .m-xl-5 {
    margin: 30px !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 30px !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 30px !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 30px !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 30px !important;
  }

  .m-xl-6 {
    margin: 50px !important;
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 50px !important;
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 50px !important;
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 50px !important;
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 50px !important;
  }

  .m-xl-7 {
    margin: 60px !important;
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 60px !important;
  }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 60px !important;
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 60px !important;
  }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 60px !important;
  }

  .m-xl-8 {
    margin: 70px !important;
  }

  .mt-xl-8,
  .my-xl-8 {
    margin-top: 70px !important;
  }

  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 70px !important;
  }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 70px !important;
  }

  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 70px !important;
  }

  .m-xl-9 {
    margin: 80px !important;
  }

  .mt-xl-9,
  .my-xl-9 {
    margin-top: 80px !important;
  }

  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 80px !important;
  }

  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 80px !important;
  }

  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 80px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 5px !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 5px !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 5px !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 5px !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 5px !important;
  }

  .p-xl-2 {
    padding: 5px !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 5px !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 5px !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 5px !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 5px !important;
  }

  .p-xl-3 {
    padding: 15px !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 15px !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 15px !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 15px !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 15px !important;
  }

  .p-xl-4 {
    padding: 30px !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 30px !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 30px !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 30px !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 30px !important;
  }

  .p-xl-5 {
    padding: 30px !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 30px !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 30px !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 30px !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 30px !important;
  }

  .p-xl-6 {
    padding: 50px !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 50px !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 50px !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 50px !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 50px !important;
  }

  .p-xl-7 {
    padding: 60px !important;
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 60px !important;
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 60px !important;
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 60px !important;
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 60px !important;
  }

  .p-xl-8 {
    padding: 70px !important;
  }

  .pt-xl-8,
  .py-xl-8 {
    padding-top: 70px !important;
  }

  .pr-xl-8,
  .px-xl-8 {
    padding-right: 70px !important;
  }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 70px !important;
  }

  .pl-xl-8,
  .px-xl-8 {
    padding-left: 70px !important;
  }

  .p-xl-9 {
    padding: 80px !important;
  }

  .pt-xl-9,
  .py-xl-9 {
    padding-top: 80px !important;
  }

  .pr-xl-9,
  .px-xl-9 {
    padding-right: 80px !important;
  }

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 80px !important;
  }

  .pl-xl-9,
  .px-xl-9 {
    padding-left: 80px !important;
  }

  .m-xl-n1 {
    margin: -5px !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -5px !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -5px !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -5px !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -5px !important;
  }

  .m-xl-n2 {
    margin: -5px !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -5px !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -5px !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -5px !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -5px !important;
  }

  .m-xl-n3 {
    margin: -15px !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -15px !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -15px !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -15px !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -15px !important;
  }

  .m-xl-n4 {
    margin: -30px !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -30px !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -30px !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -30px !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -30px !important;
  }

  .m-xl-n5 {
    margin: -50px !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -50px !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -50px !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -50px !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -50px !important;
  }

  .m-xl-n6 {
    margin: -50px !important;
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -50px !important;
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -50px !important;
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -50px !important;
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -50px !important;
  }

  .m-xl-n7 {
    margin: -60px !important;
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -60px !important;
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -60px !important;
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -60px !important;
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -60px !important;
  }

  .m-xl-n8 {
    margin: -70px !important;
  }

  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -70px !important;
  }

  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -70px !important;
  }

  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -70px !important;
  }

  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -70px !important;
  }

  .m-xl-n9 {
    margin: -80px !important;
  }

  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -80px !important;
  }

  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -80px !important;
  }

  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -80px !important;
  }

  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -80px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1500px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 5px !important;
  }

  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 5px !important;
  }

  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 5px !important;
  }

  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 5px !important;
  }

  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 5px !important;
  }

  .m-xxl-2 {
    margin: 5px !important;
  }

  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 5px !important;
  }

  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 5px !important;
  }

  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 5px !important;
  }

  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 5px !important;
  }

  .m-xxl-3 {
    margin: 15px !important;
  }

  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 15px !important;
  }

  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 15px !important;
  }

  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 15px !important;
  }

  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 15px !important;
  }

  .m-xxl-4 {
    margin: 30px !important;
  }

  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 30px !important;
  }

  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 30px !important;
  }

  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 30px !important;
  }

  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 30px !important;
  }

  .m-xxl-5 {
    margin: 30px !important;
  }

  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 30px !important;
  }

  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 30px !important;
  }

  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 30px !important;
  }

  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 30px !important;
  }

  .m-xxl-6 {
    margin: 50px !important;
  }

  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 50px !important;
  }

  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 50px !important;
  }

  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 50px !important;
  }

  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 50px !important;
  }

  .m-xxl-7 {
    margin: 60px !important;
  }

  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 60px !important;
  }

  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 60px !important;
  }

  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 60px !important;
  }

  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 60px !important;
  }

  .m-xxl-8 {
    margin: 70px !important;
  }

  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 70px !important;
  }

  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 70px !important;
  }

  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 70px !important;
  }

  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 70px !important;
  }

  .m-xxl-9 {
    margin: 80px !important;
  }

  .mt-xxl-9,
  .my-xxl-9 {
    margin-top: 80px !important;
  }

  .mr-xxl-9,
  .mx-xxl-9 {
    margin-right: 80px !important;
  }

  .mb-xxl-9,
  .my-xxl-9 {
    margin-bottom: 80px !important;
  }

  .ml-xxl-9,
  .mx-xxl-9 {
    margin-left: 80px !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 5px !important;
  }

  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 5px !important;
  }

  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 5px !important;
  }

  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 5px !important;
  }

  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 5px !important;
  }

  .p-xxl-2 {
    padding: 5px !important;
  }

  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 5px !important;
  }

  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 5px !important;
  }

  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 5px !important;
  }

  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 5px !important;
  }

  .p-xxl-3 {
    padding: 15px !important;
  }

  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 15px !important;
  }

  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 15px !important;
  }

  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 15px !important;
  }

  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 15px !important;
  }

  .p-xxl-4 {
    padding: 30px !important;
  }

  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 30px !important;
  }

  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 30px !important;
  }

  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 30px !important;
  }

  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 30px !important;
  }

  .p-xxl-5 {
    padding: 30px !important;
  }

  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 30px !important;
  }

  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 30px !important;
  }

  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 30px !important;
  }

  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 30px !important;
  }

  .p-xxl-6 {
    padding: 50px !important;
  }

  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 50px !important;
  }

  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 50px !important;
  }

  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 50px !important;
  }

  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 50px !important;
  }

  .p-xxl-7 {
    padding: 60px !important;
  }

  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 60px !important;
  }

  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 60px !important;
  }

  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 60px !important;
  }

  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 60px !important;
  }

  .p-xxl-8 {
    padding: 70px !important;
  }

  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 70px !important;
  }

  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 70px !important;
  }

  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 70px !important;
  }

  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 70px !important;
  }

  .p-xxl-9 {
    padding: 80px !important;
  }

  .pt-xxl-9,
  .py-xxl-9 {
    padding-top: 80px !important;
  }

  .pr-xxl-9,
  .px-xxl-9 {
    padding-right: 80px !important;
  }

  .pb-xxl-9,
  .py-xxl-9 {
    padding-bottom: 80px !important;
  }

  .pl-xxl-9,
  .px-xxl-9 {
    padding-left: 80px !important;
  }

  .m-xxl-n1 {
    margin: -5px !important;
  }

  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -5px !important;
  }

  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -5px !important;
  }

  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -5px !important;
  }

  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -5px !important;
  }

  .m-xxl-n2 {
    margin: -5px !important;
  }

  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -5px !important;
  }

  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -5px !important;
  }

  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -5px !important;
  }

  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -5px !important;
  }

  .m-xxl-n3 {
    margin: -15px !important;
  }

  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -15px !important;
  }

  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -15px !important;
  }

  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -15px !important;
  }

  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -15px !important;
  }

  .m-xxl-n4 {
    margin: -30px !important;
  }

  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -30px !important;
  }

  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -30px !important;
  }

  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -30px !important;
  }

  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -30px !important;
  }

  .m-xxl-n5 {
    margin: -50px !important;
  }

  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -50px !important;
  }

  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -50px !important;
  }

  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -50px !important;
  }

  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -50px !important;
  }

  .m-xxl-n6 {
    margin: -50px !important;
  }

  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -50px !important;
  }

  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -50px !important;
  }

  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -50px !important;
  }

  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -50px !important;
  }

  .m-xxl-n7 {
    margin: -60px !important;
  }

  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -60px !important;
  }

  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -60px !important;
  }

  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -60px !important;
  }

  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -60px !important;
  }

  .m-xxl-n8 {
    margin: -70px !important;
  }

  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -70px !important;
  }

  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -70px !important;
  }

  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -70px !important;
  }

  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -70px !important;
  }

  .m-xxl-n9 {
    margin: -80px !important;
  }

  .mt-xxl-n9,
  .my-xxl-n9 {
    margin-top: -80px !important;
  }

  .mr-xxl-n9,
  .mx-xxl-n9 {
    margin-right: -80px !important;
  }

  .mb-xxl-n9,
  .my-xxl-n9 {
    margin-bottom: -80px !important;
  }

  .ml-xxl-n9,
  .mx-xxl-n9 {
    margin-left: -80px !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
/* Padding Top */
.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 48px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

@media (min-width: 992px) {
  .pt-100 {
    padding-top: 100px;
  }
}
/* Padding Bottom */
.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pv-45 {
  padding-top: 45px;
  padding-bottom: 40px;
}

.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

/*  margin top */
.mt-5px {
  margin-top: 5px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

/* Margin Bottom */
.mb-5px {
  margin-bottom: 5px;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

/* Width */
.w-45 {
  width: 45px;
  height: auto;
}

/* Icon box width */
.iconbox-18 {
  width: 18px;
  height: 18px;
}

.iconbox-45 {
  width: 45px;
  height: 45px;
}

.noborders-left {
  border-left-style: hidden;
  border-top-style: hidden;
}

.noborders-right {
  border-right-style: hidden;
  border-top-style: hidden;
}

/* Weight */
.font-weight-medium {
  font-weight: 500;
}

.font-size-20 {
  font-size: 20px;
}

/* Vertical Position */
.absolute-middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.absolute-bottom {
  top: auto;
  bottom: 0;
}

/* Over Flow Hidden */
.overflow-hidden {
  overflow: hidden;
}

/*===== REBOOT =====*/
* {
  outline: 0;
  margin: 0;
  padding: 0;
}

html,
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}

hr {
  margin: 10px 0;
  background: #f3f3f3;
  opacity: 1;
}

p {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.2;
}

/* ::-moz-selection {
  background-color: #88aaf3;
  color: #ffffff;
} */

/* ::selection {
  background-color: #88aaf3;
  color: #ffffff;
} */

button,
a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  button,
  a {
    -webkit-transition: none;
    transition: none;
  }
}
button:hover,
button:focus,
button:active,
a:hover,
a:focus,
a:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

/*===== BADGE =====*/
.badge {
  text-transform: uppercase;
  color: #ffffff;
  padding-top: 0.75em;
}

.badge-sm {
  padding-top: 0.5em;
  padding-left: 0.6em;
  padding-right: 0.6em;
  font-size: 65%;
}

.badge {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 75%;
  font-weight: 500;
  line-height: 9px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #4c84ff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #ffffff;
  background-color: #1961ff;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #8a909d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #ffffff;
  background-color: #6f7685;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(138, 144, 157, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(138, 144, 157, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #29cc97;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #ffffff;
  background-color: #20a278;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(41, 204, 151, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(41, 204, 151, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #13cae1;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #ffffff;
  background-color: #0fa0b2;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(19, 202, 225, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(19, 202, 225, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fec400;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #cb9d00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 196, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(254, 196, 0, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #fe5461;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #ffffff;
  background-color: #fe2132;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 84, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(254, 84, 97, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f5f6fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #d3d7e9;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 246, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 246, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #1b223c;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #ffffff;
  background-color: #0b0e19;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 34, 60, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(27, 34, 60, 0.5);
}

.badge-purple {
  color: #ffffff;
  background-color: #896def;
}

a.badge-purple:hover,
a.badge-purple:focus {
  color: #ffffff;
  background-color: #643fea;
}

a.badge-purple:focus,
a.badge-purple.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(137, 109, 239, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(137, 109, 239, 0.5);
}

/*===== BREDCRUMB =====*/
.breadcrumb-wrapper-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.breadcrumb-wrapper-2 .breadcrumbs {
  margin-top: 0 !important;
}

.breadcrumb-wrapper {
  margin-bottom: 5px;
}
.breadcrumb-wrapper h1,
.breadcrumb-wrapper .h1 {
  color: #56606e;
  font-size: 25px;
  font-weight: 500;
}
@media (max-width: 1499.98px) {
  .breadcrumb-wrapper h1,
  .breadcrumb-wrapper .h1 {
    font-size: 23px;
  }
}
@media (max-width: 1199.98px) {
  .breadcrumb-wrapper h1,
  .breadcrumb-wrapper .h1 {
    font-size: 20px;
  }
}
.breadcrumb-wrapper .btn {
  border-radius: 15px;
}
.breadcrumb-wrapper .btn:hover {
  background-color: #6d88c2;
}
.breadcrumb-wrapper .breadcrumbs {
  margin-top: 5px;
  color: #8694a5;
}
.breadcrumb-wrapper .breadcrumbs span {
  color: #8694a5;
}
.breadcrumb-wrapper .breadcrumbs a {
  color: #8694a5;
}
.breadcrumb-wrapper .breadcrumbs span i {
  margin: 0 5px;
  font-size: 18px;
}
.breadcrumb-wrapper.breadcrumb-contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.breadcrumb-wrapper.breadcrumb-contacts .btn {
  margin: 10px 0;
}

.breadcrumb .breadcrumb-item {
  font-size: 15px;
  text-transform: capitalize;
}
.breadcrumb .breadcrumb-item a {
  color: #8a909d;
  text-transform: capitalize;
}
.breadcrumb.breadcrumb-inverse {
  border-color: #4c84ff;
  background-color: rgba(228, 237, 255, 0.6);
}
.breadcrumb.breadcrumb-inverse .breadcrumb-item {
  color: #88aaf3;
}
.breadcrumb.breadcrumb-inverse .breadcrumb-item:before {
  color: inherit;
}
.breadcrumb.breadcrumb-primary {
  border-color: #88aaf3;
  background-color: #88aaf3;
}
.breadcrumb.breadcrumb-primary .breadcrumb-item,
.breadcrumb.breadcrumb-primary .breadcrumb-item a {
  color: #ffffff;
}
.breadcrumb.breadcrumb-primary .breadcrumb-item:before {
  color: #ffffff;
}
.breadcrumb.breadcrumb-pink {
  border-color: #ee5588;
  background-color: #ee5588;
}
.breadcrumb.breadcrumb-pink .breadcrumb-item,
.breadcrumb.breadcrumb-pink .breadcrumb-item a {
  color: #ffffff;
}
.breadcrumb.breadcrumb-pink .breadcrumb-item:before {
  color: #ffffff;
}
.breadcrumb.breadcrumb-success {
  border-color: #34c997;
  background-color: #34c997;
}
.breadcrumb.breadcrumb-success .breadcrumb-item,
.breadcrumb.breadcrumb-success .breadcrumb-item a {
  color: #ffffff;
}
.breadcrumb.breadcrumb-success .breadcrumb-item:before {
  color: #ffffff;
}
.breadcrumb.breadcrumb-danger {
  border-color: #ec4a58;
  background-color: #ec4a58;
}
.breadcrumb.breadcrumb-danger .breadcrumb-item,
.breadcrumb.breadcrumb-danger .breadcrumb-item a {
  color: #ffffff;
}
.breadcrumb.breadcrumb-danger .breadcrumb-item:before {
  color: #ffffff;
}
.breadcrumb.breadcrumb-warning {
  border-color: #ddaa04;
  background-color: #ddaa04;
}
.breadcrumb.breadcrumb-warning .breadcrumb-item,
.breadcrumb.breadcrumb-warning .breadcrumb-item a {
  color: #ffffff;
}
.breadcrumb.breadcrumb-warning .breadcrumb-item:before {
  color: #ffffff;
}
.breadcrumb.breadcrumb-info {
  border-color: #24c4da;
  background-color: #24c4da;
}
.breadcrumb.breadcrumb-info .breadcrumb-item,
.breadcrumb.breadcrumb-info .breadcrumb-item a {
  color: #ffffff;
}
.breadcrumb.breadcrumb-info .breadcrumb-item:before {
  color: #ffffff;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  top: 10px;
  font-size: 7px;
  color: #8a909d;
  position: relative;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  font-family: "Material Design Icons";
}

/*===== BUTTONS =====*/
.btn {
  border-radius: 5px;
}
.btn.btn-square {
  border-radius: 0px;
}
.btn.btn-pill {
  border-radius: 100px;
}
.btn.btn-outline-primary {
  color: #88aaf3;
  border-color: #88aaf3;
}
.btn.btn-outline-secondary {
  color: #8a909d;
  border-color: #8a909d;
}
.btn.btn-outline-success {
  color: #34c997;
  border-color: #34c997;
}
.btn.btn-outline-info {
  color: #24c4da;
  border-color: #24c4da;
}
.btn.btn-outline-warning {
  color: #ddaa04;
  border-color: #ddaa04;
}
.btn.btn-outline-danger {
  color: #ec4a58;
  border-color: #ec4a58;
}
.btn.btn-primary {
  color: #ffffff;
  background-color: #fd9801;
  border-color: #fd9801;
}

.btn.btn-primary:disabled {
  color: #ffffff;
  background-color: #c2cfe0 !important;
}
.btn.btn-primary:hover,
.btn.btn-primary-white:hover,
.btn.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #3051cc;
  border-color: #3051cc;
}

.btn.btn-primary-blue {
  color: #ffffff;
  background-color: #3051cc;
  border-color: #3051cc;
}
.btn.btn-primary-blue:hover,
.btn.btn-outline-primary:hover {
  color: #ffffff;
  background-color: rgba(48, 81, 204, 0.6);
  border-color: rgba(48, 81, 204, 0.6);
}

.btn.btn-primary-white {
  background: #ffffff !important;
  border: 0.5px solid rgba(179, 181, 178, 0.23) !important;
  border-radius: 5px;
  color: #fd9801 !important;
}
.btn.btn-primary-white:hover,
.btn.white-button-text-gray:hover {
  color: #ffffff !important;
  background-color: #3051cc !important;
  border-color: #3051cc !important;
}
.btn.btn-secondary {
  color: #ffffff;
  background-color: #8a909d;
  border-color: #8a909d;
}
.btn.btn-secondary:hover,
.btn.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #8a909d;
  border-color: #8a909d;
}
.btn.btn-success {
  color: #ffffff;
  background-color: #34c997;
  border-color: #34c997;
}
.btn.btn-success:hover,
.btn.btn-outline-success:hover {
  color: #ffffff;
  background-color: #34c997;
  border-color: #34c997;
}
.btn.btn-info {
  color: #ffffff;
  background-color: #3051cc;
  border-color: #3051cc;
}
.btn.btn-info:hover,
.btn.btn-outline-info:hover {
  color: #ffffff;
  background-color: #fd9801;
  border-color: #fd9801;
}
.btn.btn-warning {
  color: #ffffff;
  background-color: #ddaa04;
  border-color: #ddaa04;
}
.btn.btn-warning:hover,
.btn.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #ddaa04;
  border-color: #ddaa04;
}
.btn.btn-danger {
  color: #ffffff;
  background-color: #ec4a58;
  border-color: #ec4a58;
}
.btn.btn-danger:hover,
.btn.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #ec4a58;
  border-color: #ec4a58;
}

.btn-block {
  display: block;
  width: 100%;
}

/* Button Social */
.btn-facebook,
.btn-twitter,
.btn-google-plus,
.btn-linkedin,
.btn-pinterest,
.btn-tumblr,
.btn-vimeo,
.btn-skype,
.btn-dropbox {
  position: relative;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  padding: 0.312rem 0.625rem;
  font-size: 15px;
  border: 1px solid transparent;
  line-height: 1.5;
  width: 40px;
  height: 40px;
}
.btn-facebook.text,
.btn-twitter.text,
.btn-google-plus.text,
.btn-linkedin.text,
.btn-pinterest.text,
.btn-tumblr.text,
.btn-vimeo.text,
.btn-skype.text,
.btn-dropbox.text {
  width: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.btn-facebook.text i,
.btn-twitter.text i,
.btn-google-plus.text i,
.btn-linkedin.text i,
.btn-pinterest.text i,
.btn-tumblr.text i,
.btn-vimeo.text i,
.btn-skype.text i,
.btn-dropbox.text i {
  margin-right: 0.5rem;
}
.btn-facebook.btn-lg,
.btn-group-lg > .btn-facebook.btn,
.btn-twitter.btn-lg,
.btn-group-lg > .btn-twitter.btn,
.btn-google-plus.btn-lg,
.btn-group-lg > .btn-google-plus.btn,
.btn-linkedin.btn-lg,
.btn-group-lg > .btn-linkedin.btn,
.btn-pinterest.btn-lg,
.btn-group-lg > .btn-pinterest.btn,
.btn-tumblr.btn-lg,
.btn-group-lg > .btn-tumblr.btn,
.btn-vimeo.btn-lg,
.btn-group-lg > .btn-vimeo.btn,
.btn-skype.btn-lg,
.btn-group-lg > .btn-skype.btn,
.btn-dropbox.btn-lg,
.btn-group-lg > .btn-dropbox.btn {
  height: 50px;
  padding-left: 1.88rem;
  padding-right: 1.88rem;
}

.btn-facebook {
  background: #3b5998;
}
.btn-facebook.btn-outline {
  color: #3b5998;
  border-color: rgba(59, 89, 152, 0.6);
  background: transparent;
}

.btn-facebook:hover {
  color: #ffffff;
  background: #344e86;
}
.btn-facebook:hover.btn-outline {
  color: #ffffff;
  border-color: #3b5998;
}

.btn-facebook:hover::before {
  background: #2d4373;
}

.btn-twitter {
  background: #00aced;
}
.btn-twitter.btn-outline {
  color: #00aced;
  border-color: rgba(0, 172, 237, 0.6);
  background: transparent;
}

.btn-twitter:hover {
  color: #ffffff;
  background: #0099d4;
}
.btn-twitter:hover.btn-outline {
  color: #ffffff;
  border-color: #00aced;
}

.btn-skype {
  background: #00aff0;
}
.btn-skype.btn-outline {
  color: #00aff0;
  border-color: rgba(0, 175, 240, 0.6);
  background: transparent;
}

.btn-skype:hover {
  color: #ffffff;
  background: #00aff0;
}
.btn-skype:hover.btn-outline {
  color: #ffffff;
  border-color: #00aff0;
}

.btn-skype:hover::before {
  background: #0087ba;
}

.btn-linkedin {
  background: #4875b4;
}
.btn-linkedin.btn-outline {
  color: #4875b4;
  border-color: rgba(72, 117, 180, 0.6);
  background: transparent;
}

.btn-linkedin:hover {
  color: #ffffff;
  background: #4169a2;
}
.btn-linkedin:hover.btn-outline {
  color: #ffffff;
  border-color: #4875b4;
}

.btn-linkedin:hover::before {
  background: #395d90;
}

.btn-pinterest {
  background: #cb2027;
}
.btn-pinterest.btn-outline {
  color: #cb2027;
  border-color: rgba(203, 32, 39, 0.6);
  background: transparent;
}

.btn-pinterest:hover {
  color: #ffffff;
  background: #b51d23;
}
.btn-pinterest:hover.btn-outline {
  color: #ffffff;
  border-color: #cb2027;
}

.btn-pinterest:hover::before {
  background: #9f191f;
}

.btn-tumblr {
  background: #32506d;
}
.btn-tumblr.btn-outline {
  color: #32506d;
  border-color: rgba(50, 80, 109, 0.6);
  background: transparent;
}

.btn-tumblr:hover {
  color: #ffffff;
  background: #2a435c;
}
.btn-tumblr:hover.btn-outline {
  color: #ffffff;
  border-color: #32506d;
}

.btn-tumblr:hover::before {
  background: #22364a;
}

.btn-google-plus {
  background: #d34836;
}
.btn-google-plus.btn-outline {
  color: #d34836;
  border-color: rgba(211, 72, 54, 0.6);
  background: transparent;
}

.btn-google-plus:hover {
  color: #ffffff;
  background: #c43d2b;
}
.btn-google-plus:hover.btn-outline {
  color: #ffffff;
  border-color: #d34836;
}

.btn-google-plus:hover::before {
  background: #b03626;
}

.btn-dropbox {
  background: #007ee5;
}
.btn-dropbox.btn-outline {
  color: #007ee5;
  border-color: rgba(0, 126, 229, 0.6);
  background: transparent;
}

.btn-dropbox:hover {
  color: #ffffff;
  background: #0070cc;
}
.btn-dropbox:hover.btn-outline {
  color: #ffffff;
  border-color: #007ee5;
}

.btn-dropbox:hover::before {
  background: #0062b2;
}

.btn-vimeo {
  background: #aad450;
}
.btn-vimeo.btn-outline {
  color: #aad450;
  border-color: rgba(170, 212, 80, 0.6);
  background: transparent;
}

.btn-vimeo:hover {
  color: #ffffff;
  background: #a0cf3c;
}
.btn-vimeo:hover.btn-outline {
  color: #ffffff;
  border-color: #aad450;
}

.btn-vimeo:hover::before {
  background: #93c130;
}

.social-button .btn {
  margin: 2px;
  border-color: #f3f3f3;
  line-height: 28px;
  color: #8a909d;
}
.social-button .btn:hover {
  border-color: #88aaf3;
  color: #ffffff;
  background: #88aaf3;
}

/*===== 4.12 DROPDOWN =====*/
.btn:focus,
.btn:active,
.btn.active,
.dropdown-toggle:focus,
.dropdown-toggle:active,
.dropdown-toggle.active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.dropdown-toggle::after {
  border-right: 5px solid transparent;
  border-bottom: 0px;
  border-top: 6px solid;
  border-left: 5px solid transparent;
  vertical-align: middle;
}

/* Button Split */
.dropdown-toggle-split {
  padding: 0 0.75rem;
}

/*===== BUTTON LOADING =====*/
.btn.btn-ladda {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: auto 20px;
  font-size: auto;
  border-radius: 0px;
}

/*===== CARD =====*/
.card {
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.card .card-header h2,
.card .card-header .h2 {
  color: #56606e;
  margin-bottom: 0.312rem;
  font-size: 20px;
  line-height: 1;
}
.card .card-img-overlay {
  z-index: 1;
}

.card-default .card-header {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px 15px 0 0;
}
@media (min-width: 1500px) {
  .card-default .card-header {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.card-default .card-header .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
.card-default .card-header h2,
.card-default .card-header .h2 {
  color: #56606e;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1;
}
.card-default .card-header .tools {
  line-height: 15px;
}
.card-default .card-header .date-range-report {
  line-height: 17px;
}
@media (max-width: 575.98px) {
  .card-default .card-header .date-range-report {
    width: 100%;
    margin-top: 5px;
  }
}
.card-default .card-header-border-bottom {
  border-bottom: 1px solid #f3f3f3;
}
.card-default .card-header-border-bottom h2,
.card-default .card-header-border-bottom .h2 {
  margin-bottom: 0;
}
.card-default .card-body {
  padding: 24px;
}
.card-default .card-body p {
  line-height: 1.5;
  color: #777777;
}
.card-default .card-footer {
  border-top: 1px solid #f3f3f3;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0 0 15px 15px;
}
.card-default .card-footer a {
  text-decoration: none;
}
.card-default .card-footer ul li {
  font-size: 0.928rem;
}
.card-default.contact a {
  color: #8a909d;
}
.card-default.contact .contact-details .small {
  font-size: 0.875rem;
}

/*Card mini*/
.card-mini {
  border: 1px solid #f3f3f3;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
}
.card-mini .card-body {
  padding: 1.63rem;
}
.card-mini .card-body h2,
.card-mini .card-body .h2 {
  color: #192a3e;
  font-size: 20px;
  font-weight: 600;
}
.card-mini .chartjs-wrapper {
  height: 100px;
}
.card-mini .card-footer {
  border-top: 1px solid #f3f3f3;
}

/* Card Icon Info*/
.card-icon-info > i {
  color: #88aaf3;
  font-size: 1.56rem;
  display: inline-block;
}
.card-icon-info > span {
  font-size: 0.88rem;
  display: inline-block;
}

/* Card table border none */
.card-table-border-none .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
}
.card-table-border-none .card-header h2,
.card-table-border-none .card-header .h2 {
  color: #56606e;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1;
}
.card-table-border-none .card-header-border-bottom {
  background-color: #ffffff;
  padding-top: 28px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f3f3f3;
}
.card-table-border-none .card-header-border-bottom h2,
.card-table-border-none .card-header-border-bottom .h2 {
  margin-bottom: 0;
}
.card-table-border-none .card-body {
  padding: 20px;
}
@media (min-width: 1500px) {
  .card-table-border-none .card-body {
    padding: 3.125rem 3.125rem;
  }
}
.card-table-border-none .card-footer {
  border-top: 1px solid #f3f3f3;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 1500px) {
  .card-table-border-none .card-footer {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}
.card-table-border-none tbody td,
.card-table-border-none thead th {
  border: none;
}
.card-table-border-none tbody td {
  font-size: 15px;
  padding-top: 30px;
}

.Sold-card-table tbody td,
.Sold-card-table thead th {
  border-bottom: 1px solid #f3f3f3;
}
.Sold-card-table tbody td {
  padding: 15px 0;
  font-size: 15px;
}

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.card-deck .card {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
}

/*===== ACCORDION CARD =====*/
.accordion .card {
  border: 0px;
  margin-bottom: 0.94rem;
}
.accordion .card-header {
  padding: 0;
  background-color: transparent;
  border: none;
}
.accordion .card-header .btn,
.accordion .card-header a {
  display: block;
  height: auto;
  position: relative;
  line-height: 1.5;
  padding: 1.56rem;
  width: 100%;
  text-align: left;
  text-decoration: none !important;
  color: #56606e;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  white-space: unset;
  -webkit-transition: all 0.3s 0.2s ease-in;
  transition: all 0.3s 0.2s ease-in;
}
@media (prefers-reduced-motion: reduce) {
  .accordion .card-header .btn,
  .accordion .card-header a {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion .card-header .btn:after,
.accordion .card-header a:after {
  position: absolute;
  color: #8a909d;
  font-size: 15px;
  right: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.accordion .card-header .btn:hover,
.accordion .card-header .btn:focus,
.accordion .card-header a:hover,
.accordion .card-header a:focus {
  text-decoration: none !important;
}
.accordion .card-header .btn[aria-expanded="false"]:after,
.accordion .card-header a[aria-expanded="false"]:after {
  content: "";
  font-family: "Material Design Icons";
}
.accordion .card-header .btn[aria-expanded="true"],
.accordion .card-header a[aria-expanded="true"] {
  -webkit-box-shadow: 0px 0px 19px rgba(229, 230, 235, 0.6) !important;
  box-shadow: 0px 0px 19px rgba(229, 230, 235, 0.6) !important;
  color: #88aaf3;
  border-bottom: 1px solid #f3f3f3;
}
.accordion .card-header .btn[aria-expanded="true"]:after,
.accordion .card-header a[aria-expanded="true"]:after {
  content: "";
  font-family: "Material Design Icons";
  color: #88aaf3;
}
.accordion .card-body {
  -webkit-box-shadow: 0px 7px 19px rgba(229, 230, 235, 0.6);
  box-shadow: 0px 7px 19px rgba(229, 230, 235, 0.6);
}

.accordion-shadow .card {
  -webkit-box-shadow: 0px 7px 19px rgba(229, 230, 235, 0.6);
  box-shadow: 0px 7px 19px rgba(229, 230, 235, 0.6);
}
.accordion-shadow .card-header .btn[aria-expanded="false"]:after,
.accordion-shadow .card-header a[aria-expanded="false"]:after {
  opacity: 1;
}
.accordion-shadow .card-header .btn[aria-expanded="true"],
.accordion-shadow .card-header a[aria-expanded="true"] {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.accordion-shadow .card-header .btn[aria-expanded="true"]:after,
.accordion-shadow .card-header a[aria-expanded="true"]:after {
  content: "";
  font-family: "Material Design Icons";
  color: #88aaf3;
}
.accordion-shadow .card-body {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.accordion-bordered .card {
  border: 1px solid #f3f3f3 !important;
  border-radius: 0;
}
.accordion-bordered .card-header .btn[aria-expanded="false"]:after,
.accordion-bordered .card-header a[aria-expanded="false"]:after {
  opacity: 1;
}
.accordion-bordered .card-header .btn[aria-expanded="true"],
.accordion-bordered .card-header a[aria-expanded="true"] {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.accordion-bordered .card-body {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.accordion-header-border-bottom .card-header {
  border-bottom: 1px solid #f3f3f3;
}
.accordion-header-border-bottom .card-header .btn,
.accordion-header-border-bottom .card-header a {
  padding: 1.56rem 0;
  border-bottom: none;
}
.accordion-header-border-bottom .card-header .btn:after,
.accordion-header-border-bottom .card-header a:after {
  right: 0;
}
.accordion-header-border-bottom .card-header .btn[aria-expanded="false"]:after,
.accordion-header-border-bottom .card-header a[aria-expanded="false"]:after {
  opacity: 1;
}
.accordion-header-border-bottom .card-header .btn[aria-expanded="true"],
.accordion-header-border-bottom .card-header a[aria-expanded="true"] {
  border-bottom: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.accordion-header-border-bottom .card-header .btn[aria-expanded="true"]:after,
.accordion-header-border-bottom .card-header a[aria-expanded="true"]:after {
  content: "";
  font-family: "Material Design Icons";
  color: #88aaf3;
}
.accordion-header-border-bottom .card-body {
  padding-left: 0;
  padding-right: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.accordion .card-header {
  padding: 0 !important;
  background-color: transparent;
  border: none;
}

.full-calendar {
  position: relative;
}
.full-calendar .fc-header-toolbar {
  margin: 30px;
  line-height: 0;
}
@media (min-width: 1500px) {
  .full-calendar .fc-header-toolbar {
    margin: 30px 50px;
  }
}
.full-calendar .fc-toolbar {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .full-calendar .fc-toolbar {
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.full-calendar .fc-left {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .full-calendar .fc-left {
    margin-bottom: 0;
  }
}
.full-calendar .fc-left h2,
.full-calendar .fc-left .h2 {
  color: #56606e;
  font-size: 1.25125rem;
  text-transform: capitalize;
  margin-left: 0;
}
.full-calendar .fc-view-container {
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #ddd;
  margin: 30px;
}
@media (min-width: 1500px) {
  .full-calendar .fc-view-container {
    margin: 30px 50px 50px;
  }
}
.full-calendar thead tr {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.full-calendar .fc-day-header {
  padding: 10px 0;
}
.full-calendar .fc-day-header span {
  color: #000;
  font-size: 1rem;
}
.full-calendar .fc-time,
.full-calendar .fc-title {
  color: #ffffff;
}
.full-calendar .fc-day-number {
  color: #000;
}
.full-calendar .fc-time {
  font-weight: normal;
}
.full-calendar thead tr th:first-child {
  border-top-left-radius: 10px;
}
.full-calendar .fc-event {
  border: 1px solid transparent;
}
.full-calendar .fc-event,
.full-calendar .fc-event-dot {
  background-color: #88aaf3;
  cursor: pointer;
}
.full-calendar .fc-button-primary {
  background-color: #ffffff;
  border-color: #ddd;
}
.full-calendar .fc-button-primary:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.full-calendar .fc-button-primary:hover {
  background-color: transparent;
  border-color: #ddd;
}
.full-calendar .fc-icon {
  margin-top: -3px;
}
.full-calendar .fc-icon-chevron-left,
.full-calendar .fc-icon-chevron-right {
  color: #8a909d;
}
.full-calendar .fc-today-button {
  color: #ffffff;
  background-color: #88aaf3;
  border-color: #88aaf3;
  text-transform: uppercase;
}
.full-calendar .fc-today-button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.full-calendar .fc-today-button:hover {
  color: #88aaf3;
  background-color: #ffffff;
  border-color: #88aaf3;
}
.full-calendar .fc-button:disabled {
  opacity: 1;
}
.full-calendar .fc-button-primary:not(:disabled).fc-button-active,
.full-calendar .fc-button-primary:not(:disabled):active {
  background-color: transparent;
  border-color: #ddd;
}
.full-calendar .fc td,
.full-calendar .fc th {
  border: 1px solid transparent;
}
.full-calendar .fc td {
  border-right: 1px solid #ddd;
}
.full-calendar .fc td:last-child {
  border-right-color: transparent;
}
.full-calendar .fc-row .fc-bg {
  border-top: 1px solid #ddd !important;
}
.full-calendar tbody tr {
  border-bottom: 1px solid #ddd !important;
}
.full-calendar .fc th {
  border: 1px solid #ddd;
}
.full-calendar .fc-past-event {
  background-color: #bfbfc3;
}
.full-calendar .fc-current-event .fc-time,
.full-calendar .fc-current-event .fc-title {
  font-size: 0.928rem;
}
.full-calendar .fc-today .fc-day-number {
  background-color: #88aaf3;
  color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.fc-datepicker-header {
  cursor: pointer;
}

.fc-unthemed td.fc-today {
  background-color: rgba(136, 170, 243, 0.3);
}

.ui-icon {
  top: -4px;
  left: -7px;
}

/*===== ALERT =====*/
label {
  /* text-transform: capitalize; */
}

p,
li,
span {
  letter-spacing: 0.02rem;
}

.alert {
  font-size: 15px;
  font-weight: 500;
  color: #56606e;
}
.alert.alert-highlighted {
  color: #ffffff;
}
.alert.alert-primary {
  background-color: #c5d6fc;
  border-color: #c5d6fc;
}
.alert.alert-secondary {
  background-color: #cacaca;
  border-color: #cacaca;
}
.alert.alert-success {
  background-color: #bffde9;
  border-color: #bffde9;
}
.alert.alert-danger {
  background-color: #ffc3c8;
  border-color: #ffc3c8;
}
.alert.alert-warning {
  background-color: #faeec6;
  border-color: #faeec6;
}
.alert.alert-info {
  background-color: #b8f5fc;
  border-color: #b8f5fc;
}
.alert.alert-light {
  background-color: #f5f6fa;
  border-color: #f5f6fa;
  color: #56606e;
}
.alert.alert-dark {
  background-color: #3d3d3d;
  border-color: #3d3d3d;
}
.alert.alert-outlined {
  background-color: transparent;
  color: #3d3d3d;
}

/* Notification List */
.notifications-list li a {
  display: block;
  color: #8a909d;
  font-size: 0.88rem;
  padding: 0.625rem 1.25rem;
}
.notifications-list li:hover {
  background-color: #fdfdfd;
}

/*===== ALERT =====*/
.bg-primary {
  background-color: #88aaf3 !important;
}

.bg-success {
  background-color: #34c997 !important;
}

.bg-warning {
  background-color: #ddaa04 !important;
}

.bg-info {
  background-color: #24c4da !important;
}

.bg-danger {
  background-color: #ec4a58 !important;
}

label {
  /* text-transform: capitalize; */
  display: inline-block;
  margin-bottom: 0.5rem;
}

.text-right {
  text-align: right !important;
}

@media (max-width: 1199.98px) {
  .ec-cust-card {
    margin-bottom: 24px;
  }
}
.ec-cust-card tbody td {
  padding-top: 22px;
}
.ec-cust-card tbody td small,
.ec-cust-card tbody td .small {
  color: #999;
}

.ec-card-top-prod .ec-pos {
  position: relative;
}
.ec-card-top-prod .ec-price {
  margin-top: 10px;
}
.ec-card-top-prod .ec-price span {
  margin-right: 10px;
  font-weight: 500;
}
.ec-card-top-prod .ec-price del {
  color: #999;
  font-size: 0.928rem;
}
.ec-card-top-prod .sale {
  position: absolute;
  top: 0;
  right: 15px;
  color: #999 !important;
  font-size: 0.928rem;
}

.media-image img {
  width: 100%;
  border-radius: 15px;
}

.table .tbl-thumb {
  width: 50px;
  border-radius: 15px;
}

.rounded-circle {
  border-radius: 15px !important;
}

.border-right {
  border-right: 1px solid #e5e9f2;
}

.p-b-15 {
  padding-bottom: 24px;
}
.p-r-15 {
  padding-right: 24px;
}
.p-20 {
  padding: 20px;
}

/* .dash-card .card-body span {
  position: absolute;
  right: 20px;
  bottom: 25px;
  font-size: 30px;
  border-radius: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  background: #88aaf3;
  color: #ffffff;
} */

.profile-img {
  width: 50px !important;
}

/* ===== CARET =====*/
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.125rem;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/* ===== Form Control =====*/
.form-control {
  border-radius: 5px;
}

/*===== CAROUSEL =====*/
.carousel-indicators {
  bottom: 0;
}
@media (min-width: 768px) {
  .carousel-indicators {
    bottom: -5px;
  }
}
.carousel-indicators.indicator-success li {
  background-color: rgba(52, 201, 151, 0.2);
}
.carousel-indicators.indicator-success li.active {
  background-color: #34c997;
}
.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

.carousel-caption {
  bottom: 40px;
}

/* Carousel Testimonial */
.carousel-testimonial .carousel-control-next,
.carousel-testimonial .carousel-control-prev {
  color: red;
}
.carousel-testimonial .carousel-control-prev,
.carousel-testimonial .carousel-control-next {
  width: auto;
  z-index: 55;
  color: #8a909d;
}
.carousel-testimonial .carousel-control-prev .carousel-control-prev-icon {
  color: #8a909d;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/*===== CHART =====*/
@media (max-width: 575.98px) {
  #user-acquisition .mb-6 {
    margin-bottom: 80px !important;
  }
}

.world-data-chart {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 1200px) {
  .world-data-chart.border-bottom {
    border-bottom: 0 !important;
    border-right: 1px solid #f3f3f3;
  }
}

#regions_purchase > div > div {
  margin: 0 auto 30px auto;
}

@media (min-width: 1200px) {
  #linechart {
    min-height: 280px;
  }
}

@media (min-width: 1200px) {
  #doChart {
    min-height: 210px;
  }
}

#polar,
#radar {
  min-height: 200px;
}
@media (min-width: 768px) {
  #polar,
  #radar {
    min-height: 230px;
  }
}
@media (min-width: 1200px) {
  #polar,
  #radar {
    min-height: 270px;
    min-width: 100%;
  }
}

[polar-data-height] {
  height: 455px;
}

[radar-data-height] {
  height: 408px;
}

@media (min-width: 1200px) {
  #currentUser {
    min-height: 283px;
    height: 283px !important;
  }
}

@media (min-width: 1200px) {
  #activity {
    min-height: 280px;
  }
}

#deviceChart {
  min-height: 200px;
}
@media (min-width: 768px) {
  #deviceChart {
    min-height: 230px;
  }
}

/* Coutom Lenged*/
.customLegend li {
  display: inline-block;
  margin-right: 20px;
  margin-top: 20px;
}
.customLegend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 0.312rem;
  border-radius: 1.56rem;
}

.chat {
  border-radius: 0.25rem;
}
.chat .chat-left-side .chat-search {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.chat .chat-left-side #chat-left-content {
  height: 373px !important;
}
@media (min-width: 992px) {
  .chat .chat-left-side #chat-left-content {
    height: calc(100vh - 320px) !important;
  }
}
@media (min-width: 1200px) {
  .chat .chat-left-side #chat-left-content {
    height: calc(100vh - 350px) !important;
  }
}
@media (min-width: 768px) {
  .chat .chat-left-side .last-msg {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .chat .chat-left-side .last-msg {
    width: 125px;
  }
}
@media (min-width: 1500px) {
  .chat .chat-left-side .last-msg {
    width: 220px;
  }
}
.chat .chat-right-side {
  border-left: 1px solid #f3f3f3;
}
.chat .chat-right-side .chat-right-content {
  padding: 1.5rem;
}
.chat .chat-right-side #chat-right-content {
  height: 373px !important;
}
@media (min-width: 992px) {
  .chat .chat-right-side #chat-right-content {
    height: calc(100vh - 395px) !important;
  }
}
@media (min-width: 1200px) {
  .chat .chat-right-side #chat-right-content {
    height: calc(100vh - 405px) !important;
  }
}

pre {
  border-left: 4px solid #88aaf3;
  padding: 1.25rem 0.94rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.19rem;
}
pre code {
  background: transparent;
  font-size: 1rem;
  color: #56606e;
}

.pre-code,
code {
  font-family: Menlo, Monaco, "Courier New", Courier, monospace;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.05);
  padding: 0.19rem 0.625rem;
  margin: 0 0.125rem;
  color: #56606e;
}

.pre-code {
  padding: 1.25rem 0.94rem !important;
}

/* Dropdown Menu */
.dropdown-menu {
  border: 1px solid #f3f3f3;
  right: 0;
}

.sign-inup .container {
  position: relative;
  z-index: 2;
}
.sign-inup .form-height {
  height: 100vh;
}
@media (max-width: 575.98px) {
  .sign-inup .form-height {
    height: 100%;
  }
}
@media (max-height: 600px) {
  .sign-inup .form-height {
    height: 100%;
  }
}
.sign-inup .form-height-login {
  height: 100vh;
}
@media (max-width: 575.98px) {
  .sign-inup .form-height-login {
    height: 100%;
  }
}
@media (max-height: 480px) {
  .sign-inup .form-height-login {
    height: 100%;
  }
}

/*===== REGISTRATION =====*/
.sign-inup .card {
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .sign-inup .card {
    max-width: 430px;
  }
}
@media (max-width: 767.98px) {
  .sign-inup .card .p-5 {
    padding: 20px 15px 15px 15px !important;
  }
}
.sign-inup .card .ec-brand {
  border: none;
}
.sign-inup .card form .control {
  color: #777777;
}
.sign-inup .card form button {
  width: 130px;
  margin: auto;
}
.sign-inup .text-dark {
  text-align: center;
  margin-bottom: 25px !important;
}
.sign-inup .ec-brand a {
  width: 100%;
  padding: 0;
  display: block;
  text-align: center;
  border: none;
}
.sign-inup .ec-brand a img {
  margin-bottom: 0;
}
.sign-inup .sign-upp {
  text-align: center;
}
.sign-inup .ec-brand a img {
  width: 100px;
  max-width: 100px;
}

.sign-upp {
  text-align: center;
}

/* Separator */
.separator {
  border-top: 1px solid #f3f3f3;
}

/*===== DATERANGEPICKER =====*/
.daterangepicker {
  -webkit-box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
  box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
}
.daterangepicker .ranges ul {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  width: auto;
}
.daterangepicker .ranges li {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
}
.daterangepicker .ranges li.active {
  background-color: transparent;
  color: #88aaf3;
}
.daterangepicker:before,
.daterangepicker:after {
  display: none;
}
.daterangepicker .drp-buttons .btn {
  padding: 0 1.25rem;
}
.daterangepicker td.in-range {
  background-color: rgba(136, 170, 243, 0.1);
}
.daterangepicker td.available:hover {
  background-color: #88aaf3;
  color: #ffffff;
}
.daterangepicker .ranges li:hover {
  background-color: transparent;
  color: #88aaf3;
}

/* Ragnge Date Picker */
.date-range-report span {
  cursor: pointer;
}
.date-range-report span:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 1em;
  content: "";
  border-right: 5px solid transparent;
  border-bottom: 0px;
  border-top: 6px solid;
  border-left: 5px solid transparent;
  vertical-align: middle;
}

.date-range::-webkit-input-placeholder {
  color: #495057;
}

.date-range::-moz-placeholder {
  color: #495057;
}

.date-range:-ms-input-placeholder {
  color: #495057;
}

.date-range::-ms-input-placeholder {
  color: #495057;
}

.date-range::placeholder {
  color: #495057;
}

/*===== FORMS =====*/
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group,
.input-group {
  margin-bottom: 1.25rem;
}
.form-group label,
.input-group label {
  color: #56606e;
  font-size: 15px;
}
.form-group .form-control,
.input-group .form-control {
  font-size: 15px;
  padding: 0.5rem 1.06rem;
  border-color: #f3f3f3;
}
.form-group .form-control.input-lg,
.input-group .form-control.input-lg {
  font-size: 1.06rem;
  padding: 0.9375rem 1.06rem;
}
.form-group .form-control.input-sm,
.input-group .form-control.input-sm {
  font-size: 0.8125rem;
  padding: 0.38rem 1.06rem;
}
.form-group .input-group-prepend,
.input-group .input-group-prepend {
  margin-right: -1px;
}
.form-group .input-group-prepend,
.form-group .input-group-append,
.input-group .input-group-prepend,
.input-group .input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-pill .form-group .form-control {
  border-radius: 100px;
}

.horizontal-form .form-check {
  padding-left: 0;
}
.horizontal-form .form-check label {
  font-weight: 400;
  font-size: 0.875rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

/* Form components - checkbox and radios */
.control {
  display: block;
  position: relative;
  padding-left: 1.56rem;
  color: #56606e;
  margin-bottom: 0.9375rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control-indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background: #e1e2e5;
  border: 1px solid #ccc;
  border-radius: 0.125rem;
}

.control-radio .control-indicator {
  border-radius: 50%;
}

.control input:disabled ~ .control-indicator {
  background: #e1e2e5;
  opacity: 0.6;
  pointer-events: none;
  border: 1px solid #ccc;
}

.control-indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.control input:checked ~ .control-indicator:after {
  display: block;
}

.control-checkbox .control-indicator:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px;
  background: transparent !important;
  border: solid #56606e;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control-indicator:after {
  border-color: #7b7b7b;
}

.control-radio .control-indicator:after {
  left: 4px;
  top: 4px;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #56606e;
}

.control-radio input:disabled ~ .control-indicator:after {
  background: #7b7b7b;
}

/* Contextual Style */
.checkbox-primary .control-indicator,
.radio-primary .control-indicator {
  background: #88aaf3;
  border-color: #88aaf3;
}
.checkbox-primary .control-indicator::after,
.radio-primary .control-indicator::after {
  color: #ffffff;
  border-color: #ffffff;
  background: #ffffff;
}

.checkbox-warning .control-indicator,
.radio-warning .control-indicator {
  background: #ddaa04;
  border-color: #ddaa04;
}
.checkbox-warning .control-indicator::after,
.radio-warning .control-indicator::after {
  color: #ffffff;
  border-color: #ffffff;
  background: #ffffff;
}

.checkbox-danger .control-indicator,
.radio-danger .control-indicator {
  background: #ec4a58;
  border-color: #ec4a58;
}
.checkbox-danger .control-indicator::after,
.radio-danger .control-indicator::after {
  color: #ffffff;
  border-color: #ffffff;
  background: #ffffff;
}

.checkbox-success .control-indicator,
.radio-success .control-indicator {
  background: #34c997;
  border-color: #34c997;
}
.checkbox-success .control-indicator::after,
.radio-success .control-indicator::after {
  color: #ffffff;
  border-color: #ffffff;
  background: #ffffff;
}

.checkbox-info .control-indicator,
.radio-info .control-indicator {
  background: #24c4da;
  border-color: #24c4da;
}
.checkbox-info .control-indicator::after,
.radio-info .control-indicator::after {
  color: #ffffff;
  border-color: #ffffff;
  background: #ffffff;
}

/* All Outline Contextual Style */
.control.outlined .control-indicator {
  background: transparent !important;
}
.control.outlined input:disabled ~ .control-indicator {
  background: transparent !important;
}

.control.outlined.checkbox-primary .control-indicator,
.control.outlined.radio-primary .control-indicator {
  background: transparent;
  border-color: #88aaf3;
}
.control.outlined.checkbox-primary .control-indicator::after,
.control.outlined.radio-primary .control-indicator::after {
  background: #88aaf3;
  border-color: #88aaf3;
  color: #ffffff;
}

.control.outlined.checkbox-warning .control-indicator,
.control.outlined.radio-warning .control-indicator {
  background: transparent;
  border-color: #ddaa04;
}
.control.outlined.checkbox-warning .control-indicator::after,
.control.outlined.radio-warning .control-indicator::after {
  background: #ddaa04;
  border-color: #ddaa04;
  color: #ffffff;
}

.control.outlined.checkbox-danger .control-indicator,
.control.outlined.radio-danger .control-indicator {
  background: transparent;
  border-color: #ec4a58;
}
.control.outlined.checkbox-danger .control-indicator::after,
.control.outlined.radio-danger .control-indicator::after {
  background: #ec4a58;
  border-color: #ec4a58;
  color: #ffffff;
}

.control.outlined.checkbox-success .control-indicator,
.control.outlined.radio-success .control-indicator {
  background: transparent;
  border-color: #34c997;
}
.control.outlined.checkbox-success .control-indicator::after,
.control.outlined.radio-success .control-indicator::after {
  background: #34c997;
  border-color: #34c997;
  color: #ffffff;
}

.control.outlined.checkbox-info .control-indicator,
.control.outlined.radio-info .control-indicator {
  background: transparent;
  border-color: #24c4da;
}
.control.outlined.checkbox-info .control-indicator::after,
.control.outlined.radio-info .control-indicator::after {
  background: #24c4da;
  border-color: #24c4da;
  color: #ffffff;
}

.custom-select {
  width: auto;
  margin-left: 0.625rem;
}

/* Form components- input groups */
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append > .input-group-text {
  background-color: #fdfdfd;
  border-color: #f3f3f3;
}
.input-group > .input-group-prepend > .input-group-text.bg-primary,
.input-group > .input-group-append > .input-group-text.bg-primary {
  border-color: #88aaf3;
  color: #ffffff;
}
.input-group > .input-group-prepend > .input-group-text.bg-warning,
.input-group > .input-group-append > .input-group-text.bg-warning {
  border-color: #ddaa04;
  color: #ffffff;
}
.input-group > .input-group-prepend > .input-group-text.bg-danger,
.input-group > .input-group-append > .input-group-text.bg-danger {
  border-color: #ec4a58;
  color: #ffffff;
}
.input-group > .input-group-prepend > .input-group-text.bg-success,
.input-group > .input-group-append > .input-group-text.bg-success {
  border-color: #34c997;
  color: #ffffff;
}
.input-group > .input-group-prepend > .input-group-text .control,
.input-group > .input-group-append > .input-group-text .control {
  margin-bottom: 1.25rem;
}
.input-group > .input-group-prepend .form-check,
.input-group > .input-group-append .form-check {
  padding-left: 0;
}
.input-group > .input-group-prepend .form-check .form-check-label,
.input-group > .input-group-append .form-check .form-check-label {
  margin-bottom: 0;
}
.input-group > .input-group-prepend .form-check .radio-custom-label:before,
.input-group > .input-group-prepend .form-check .checkbox-custom-label:before,
.input-group > .input-group-append .form-check .radio-custom-label:before,
.input-group > .input-group-append .form-check .checkbox-custom-label:before {
  margin-right: 0;
  background: #ffffff;
}
.input-group
  > .input-group-prepend
  .form-check
  .radio-custom:checked
  + .radio-custom-label:before,
.input-group
  > .input-group-prepend
  .form-check
  .checkbox-custom:checked
  + .checkbox-custom-label:before,
.input-group
  > .input-group-append
  .form-check
  .radio-custom:checked
  + .radio-custom-label:before,
.input-group
  > .input-group-append
  .form-check
  .checkbox-custom:checked
  + .checkbox-custom-label:before {
  border: 1px solid #d4d5db;
  line-height: 1rem;
}
.input-group
  > .input-group-prepend
  .form-check
  .radio-custom:checked
  + .radio-custom-label:before,
.input-group
  > .input-group-append
  .form-check
  .radio-custom:checked
  + .radio-custom-label:before {
  font-size: 0.5rem;
}

/* Form components- Validation */
.invalid-feedback,
.valid-feedback {
  font-size: 90%;
}

/* Form components- Advanced */
.select2-container--default .select2-selection--multiple {
  border-color: #f3f3f3;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: rgba(136, 170, 243, 0.5);
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  border-color: #4c84ff;
  background-color: rgba(76, 132, 255, 0.051);
  color: #4c84ff;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #4c84ff;
  padding-right: 0.31rem;
  margin-right: 0.31rem;
  border-right: 1px solid #4c84ff;
}

.form-group .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.18rem + 2px);
  margin-bottom: 0;
}
.form-group .custom-file .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.18rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.form-group .custom-file .form-group label,
.form-group .custom-file .input-group label {
  color: #1b223c;
  font-size: 15px;
}
.form-group .custom-file .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.18rem + 2px);
  padding: 0.59rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.form-group .custom-file .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.18rem);
  padding: 0.59rem 1rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

/* Media Small */
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-message {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.media-message img {
  width: 40px;
}
.media-message .status {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 12px;
  height: 12px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.media-message .status.active,
.media-message .status.away {
  border-color: #ffffff;
}
.media-message .status.active {
  background-color: #34c997;
}
.media-message .status.away {
  background-color: #ddaa04;
}
.media-message .title {
  color: #56606e;
  font-size: 0.9375rem;
  margin-bottom: 0;
}
.media-message .last-msg {
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  color: #8a909d;
}
@media (min-width: 768px) {
  .media-message .last-msg {
    width: 550px;
  }
}
@media (min-width: 992px) {
  .media-message .last-msg {
    width: 125px;
  }
}
@media (min-width: 1200px) {
  .media-message .last-msg {
    width: 220px;
  }
}
.media-message .date {
  text-align: right;
  font-size: 0.857rem;
  color: #8a909d;
}
.media-message:hover {
  background-color: rgba(233, 236, 239, 0.5);
  text-decoration: none;
}
.media-message.media-active {
  background-color: #88aaf3;
}
.media-message.media-active .title,
.media-message.media-active .last-msg,
.media-message.media-active .date {
  color: #ffffff;
}
.media-message.event-active {
  background-color: rgba(233, 236, 239, 0.5);
}

.media-notification .last-msg {
  width: 200px;
}

.media-chat {
  margin-bottom: 1.5rem;
}
.media-chat .content {
  background-color: rgba(233, 236, 239, 0.5);
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
}
.media-chat .heading-title a {
  color: #56606e;
}
.media-chat .heading-title a:hover {
  color: #88aaf3;
  text-decoration: none;
}
.media-chat .title {
  color: #88aaf3;
  font-size: 1rem;
}
.media-chat .message {
  background-color: rgba(233, 236, 239, 0.5);
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  color: #56606e;
}
.media-chat .date-time {
  font-size: 10px;
  padding-left: 1.25rem;
}
.media-chat.media-left img {
  width: 50px;
}
.media-chat.media-right {
  text-align: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.media-chat.media-right img {
  width: 40px;
}
.media-chat:hover {
  text-decoration: none;
}
.media-chat.media-chat-header {
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
  padding: 1.125rem 1.5rem;
}
@media (min-width: 992px) {
  .media-chat.media-chat-header {
    border-top: 0;
  }
}
.media-chat.media-chat-header img {
  width: 60px;
}
.media-chat.media-chat-header .dropdown-toggle {
  color: #8a909d;
  text-transform: uppercase;
}
.media-chat.media-chat-header .dropdown-toggle.icon-burger-mini {
  font-size: 1.5rem;
  position: relative;
}
.media-chat.media-chat-header .dropdown-toggle.icon-burger-mini:after {
  display: none;
}
.media-chat.media-chat-header .dropdown-toggle.icon-burger-mini:before {
  font-family: "Material Design Icons";
  content: "";
}
.media-chat.media-chat-header .dropdown-menu {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  -webkit-box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
  box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
  min-width: auto;
}
.media-chat.media-chat-header .dropdown-menu .dropdown-item {
  padding: 0.3125rem 1.25rem;
}
.media-chat.media-chat-header .dropdown-menu .dropdown-item a {
  font-size: 0.875rem;
  color: #8a909d;
  text-transform: capitalize;
}
.media-chat.media-chat-header .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}
.media-chat.media-chat-header .dropdown-menu .dropdown-item:hover a {
  color: #88aaf3;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/*===== FOOTER =====*/
.main-footer {
  background: #ffffff;
  padding: 15px;
  color: #444444;
  border-top: 1px solid #f3f3f3;
}

.copyright {
  padding: 15px;
  border-top: 1px solid #f3f3f3;
}
.copyright p {
  font-weight: 300;
  text-align: center;
  font-size: 14px;
}

/* Body */
#body {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

/* Wrapper */
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  min-height: 100vh;
}

.web-site-wrapper {
}

/* Page Wrapper */
.ec-page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.ec-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow-x: auto;
}

/* Content */
.app-content {
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #f5f6f8;
}
@media (max-width: 767.98px) {
  .app-content {
    padding: 30px 15px;
  }
}

.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* Meterial Desing Codes */
.mdi-show-code code {
  display: inline-block;
  vertical-align: middle;
  background: rgba(138, 144, 157, 0.1);
  padding: 3px 5px;
  border-radius: 3px;
  border: 1px solid #f3f3f3;
}
.mdi-show-code i.mdi {
  vertical-align: middle;
  border-radius: 4px;
  display: inline-block;
}
.mdi-show-code i.mdi.dark-demo {
  background: #333;
}
.mdi-show-code .note {
  color: #999;
  font-size: 14px;
  padding: 0 20px 5px 20px;
}

.icons {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}
@media (min-width: 992px) {
  .icons {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (min-width: 1200px) {
  .icons {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}
.icons div {
  line-height: 3em;
}
.icons div span {
  cursor: pointer;
  font-size: 14px;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: calc(100% - 90px);
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
}

.icons div code:hover,
.icons div span:hover,
.icons div i:hover {
  color: #88aaf3;
}

.icons div code:hover {
  border-color: #88aaf3;
}

.icons div code {
  border: 1px solid #f3f3f3;
  width: 65px;
  margin-left: 2px;
  margin-right: 4px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}

.icons div i {
  display: inline-block;
  width: 32px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 24px;
}

.icons .mdi:before {
  font-size: 24px;
}

div.copied {
  position: fixed;
  top: 100px;
  left: 50%;
  width: 200px;
  text-align: center;
  color: #ffffff;
  background-color: #34c997;
  padding: 10px 15px;
  border-radius: 4px;
  margin-left: -100px;
}

.icons-list .fa-icon {
  font-weight: 900;
  font-size: 20px;
  margin: 5px 10px;
  line-height: 21px;
  padding: 10px 0;
  width: 30px;
  text-align: center;
}
.icons-list .fa-span {
  background: #f1f1f1;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

/*===== LIST GROUP =====*/
.list-group .list-group-item {
  padding: 1.38rem;
}

.list-styled > li {
  list-style-type: disc;
  margin-left: 0.94rem;
  margin-right: 0.94rem;
}

.list-group-item-secondary {
  color: #8a909d;
  background-color: #cacaca;
}

.list-group-item-success {
  color: #34c997;
  background-color: #bffde9;
}

.list-group-item-danger {
  color: #ec4a58;
  background-color: #ffc3c8;
}

.list-group-item-warning {
  color: #ddaa04;
  background-color: #faeec6;
}

.list-group-item-info {
  color: #24c4da;
  background-color: #b8f5fc;
}

/* ======== MAP ======== */
.map-container {
  height: 400px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.info-title {
  color: #56606e;
  padding: 0.31rem;
}

/* ===== Vector Map Zoom In And Zoom Out Button ===== */
.jvectormap-zoomin,
.jvectormap-zoomout {
  background-color: #88aaf3;
}

/* ======== VECTOR MAP ======== */
/* Vector Map World 2 */
.vector-map-world-2 {
  padding-bottom: 0 !important;
  height: 350px;
}

@media (min-width: 992px) {
  #modal-add-contact .modal-lg,
  #modal-contact .modal-lg {
    max-width: 755px;
  }
}
@media (min-width: 1200px) {
  #modal-add-contact .modal-lg,
  #modal-contact .modal-lg {
    margin-left: auto;
    margin-right: auto;
  }
}
#modal-add-contact .modal-header .btn-close-icon,
#modal-add-contact .modal-header .btn-edit-icon,
#modal-add-contact .modal-header .btn-dots-icon,
#modal-contact .modal-header .btn-close-icon,
#modal-contact .modal-header .btn-edit-icon,
#modal-contact .modal-header .btn-dots-icon {
  font-size: 1.125rem;
  padding: 0 5px;
  color: #8a909d;
}

.modal .modal-content {
  border-radius: 30px;
}

#modal-add-member .modal-header .btn-close-icon,
#modal-add-member .modal-header .btn-edit-icon,
#modal-add-member .modal-header .btn-dots-icon {
  font-size: 1.125rem;
  padding: 0 5px;
  color: #8a909d;
}
@media (min-width: 768px) {
  #modal-add-member .last-msg {
    width: 200px;
  }
}
@media (min-width: 992px) {
  #modal-add-member .last-msg {
    width: 125px;
  }
}
@media (min-width: 1200px) {
  #modal-add-member .last-msg {
    width: 220px;
  }
}

/* ======================================
  --> 2. NAVBAR
========================================= */
.ec-main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  margin-bottom: 0;
  padding: 0;
  -webkit-transition: -webkit-box-shadow 0.2s linear;
  transition: -webkit-box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
}

/* Navbar */
.navbar {
  margin-bottom: 0;
  border: none;
  min-height: 60px;
  border-radius: 0;
  padding: 0;
  border-bottom: 1px solid #f3f3f3;
  -webkit-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
  background-color: #ffffff;
  /* Search Form */
  /* Navbar Nav */
  /* Navbar Collapse */
  /* Navbar Right */
}
@media (prefers-reduced-motion: reduce) {
  .navbar {
    -webkit-transition: none;
    transition: none;
  }
}
.navbar .sidebar-offcanvas-toggle,
.navbar .sidebar-toggle {
  background-color: transparent;
  background-image: none;
  color: #fff;
  font-size: 30px;
  padding: 0 24px;
  border: 0px;
  cursor: pointer;
}
.navbar .sidebar-offcanvas-toggle:before,
.navbar .navbar-toggler i {
  color: #8a909d;
}
.navbar .search-form {
  width: 400px;
  position: relative;
  /* margin-right: auto; */
  padding-left: 0;
  background-color: #f7f7f7;
  border-radius: 15px;
}
@media (min-width: 1500px) {
  .navbar .search-form {
    width: auto;
  }
}
@media (max-width: 1199.98px) {
  .navbar .search-form {
    width: 300px;
  }
}
@media (max-width: 767.98px) {
  .navbar .search-form {
    width: 70%;
    margin: 0 auto 15px auto;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
@media (max-width: 575.98px) {
  .navbar .search-form {
    width: 100%;
    margin: 0 24px 15px 24px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
.navbar .search-form .input-group {
  margin-bottom: 0;
}
.navbar .search-form .btn {
  background: transparent;
  border: none;
  color: #8a909d;
  font-size: 20px;
  padding: 0px 10px;
}
.navbar .search-form .btn:active,
.navbar .search-form .btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar .search-form input.form-control {
  border: none;
  background: transparent;
  padding: 0.5rem 15px;
}
@media (min-width: 1500px) {
  .navbar .search-form input.form-control {
    min-width: 340px;
  }
}
.navbar .search-form #search-results-container {
  width: 100%;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  -webkit-box-shadow: 1px 2px 5px #e5e9f2;
  box-shadow: 1px 2px 5px #e5e9f2;
}
.navbar .search-form #search-results-container li {
  padding: 20px 30px;
}
.navbar .search-form #search-results-container li .link {
  font-size: 15px;
}
.navbar .navbar-nav {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .navbar .navbar-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.navbar .navbar-nav .nav-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .nav-item {
    padding-top: 1.28px;
    padding-bottom: 1.28px;
  }
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #8a909d;
}
.navbar .navbar-collapse {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  border-top: 1px solid #f3f3f3;
}
@media (min-width: 992px) {
  .navbar .navbar-collapse {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    border-top: none;
  }
}
.navbar .navbar-right .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 992px) {
  .navbar .navbar-right .navbar-nav {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-right .navbar-nav {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767.98px) {
  .navbar .navbar-right .navbar-nav {
    margin: 15px 0;
  }
}
.navbar .navbar-right .navbar-nav li > .dropdown-menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 54px;
  left: auto;
  right: 0;
  border-color: #f3f3f3;
  border-radius: 15px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 575.98px) {
  .navbar .navbar-right .navbar-nav li > .dropdown-menu {
    right: -130px;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-right .navbar-nav .user-menu {
    text-align: right;
  }
}
.navbar .navbar-right .navbar-nav .user-menu .user-image {
  width: 40px;
  border-radius: 15px;
}
.navbar .navbar-right .navbar-nav .user-menu .dropdown-toggle {
  font-size: 15px;
  padding: 0 15px;
  display: inline-block;
  line-height: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 575.98px) {
  .navbar .navbar-right .navbar-nav .user-menu .dropdown-toggle {
    padding: 0 5px;
  }
}
.navbar .navbar-right .navbar-nav .user-menu .dropdown-toggle > span {
  color: #56606e;
  padding: 0 0.5rem;
  font-size: 15px;
  font-weight: 500;
}
@media (min-width: 992px) {
  .navbar .navbar-right .navbar-nav .user-menu .dropdown-toggle {
    padding-right: 5px;
    padding-left: 1rem;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-right .navbar-nav .user-menu .dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.navbar .navbar-right .navbar-nav .user-menu .dropdown-toggle:after {
  border-top: 6px solid;
  border-left-width: 6px;
  border-right-width: 6px;
  vertical-align: middle;
  color: #bbb;
}
@media (min-width: 992px) {
  .navbar .navbar-right .navbar-nav .notifications-menu {
    min-width: 60px;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-right .navbar-nav .notifications-menu {
    min-width: 70px;
  }
}
.navbar .navbar-right .navbar-nav .notifications-menu .dropdown-toggle {
  position: relative;
  padding-left: 20px;
  padding-right: 0;
  font-size: 26px;
  color: #fff;
  display: inline-block;
  line-height: 30px;
}
.navbar .navbar-right .navbar-nav .notifications-menu .dropdown-toggle i {
  font-size: 28px;
}
.navbar .navbar-right .navbar-nav .notifications-menu .dropdown-toggle:after {
  border: 3px solid #ff8e8e;
  content: "";
  margin: 0;
  position: relative;
  width: 11px;
  height: 11px;
  border-radius: 100px;
  top: -18px;
  left: -17px;
  -webkit-animation: blink 2s infinite;
  animation: blink 2s infinite;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.navbar .navbar-right .navbar-nav .notifications-menu > .dropdown-menu {
  width: 275px;
  right: -75px;
}
@media (min-width: 768px) {
  .navbar .navbar-right .navbar-nav .notifications-menu > .dropdown-menu {
    width: 300px;
    right: 0px;
  }
}
.navbar .navbar-right .navbar-nav .right-sidebar-2-menu {
  text-align: center;
  cursor: pointer;
  min-width: 60px;
}
@media (min-width: 1200px) {
  .navbar .navbar-right .navbar-nav .right-sidebar-2-menu {
    min-width: 70px;
  }
}
.navbar .navbar-right .navbar-nav .right-sidebar-2-menu i {
  font-size: 24px;
  color: #bbb;
  line-height: 30px;
}
.navbar .dropdown-menu {
  width: 255px;
  padding: 0 0 0 0;
  margin: 0;
  top: 100%;
  border-color: transparent;
}
@media (min-width: 992px) {
  .navbar .dropdown-menu {
    border-color: #f3f3f3;
  }
}
.navbar .dropdown-menu li.dropdown-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px;
  padding: 15px;
  color: #56606e;
  font-size: 15px;
  margin-bottom: 0.63rem;
}
.navbar .dropdown-menu li.dropdown-header img {
  width: 50px;
  vertical-align: top;
  border-radius: 15px;
  margin-right: 0.63rem;
}
.navbar .dropdown-menu li.dropdown-header small,
.navbar .dropdown-menu li.dropdown-header .small {
  font-size: 0.928rem;
  display: block;
  color: #8a909d;
}
.navbar .dropdown-menu > li a {
  display: block;
  color: #8a909d;
  font-size: 14px;
  padding: 10px 15px;
}
.navbar .dropdown-menu > li a:hover {
  background-color: #f5f6fa;
}
.navbar .dropdown-menu > li a > i {
  width: 18px;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  font-size: 17px;
  line-height: 15px;
}
.navbar .dropdown-menu li.dropdown-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: #ffffff;
  border-top: 1px solid #f3f3f3;
  margin-top: 10px;
}
.navbar .dropdown-menu li.dropdown-footer > a {
  padding: 15px 20px;
}
.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  color: #8a909d;
  background-color: transparent;
}

@media (max-width: 767px) {
  .ec-main-header .logo,
  .ec-main-header .navbar {
    width: 100%;
  }

  .ec-main-header .navbar {
    margin: 0;
  }
}
/* Header Fix and Header Static For Mobile */
.ec-header-fixed .ec-page-wrapper {
  padding-top: 4.4rem;
}
@media (max-width: 767.98px) {
  .ec-header-fixed .ec-page-wrapper {
    padding-top: 7.4rem;
  }
}
.ec-header-fixed.ec-header-static-mobile .ec-main-header {
  position: relative;
}
@media (min-width: 768px) {
  .ec-header-fixed.ec-header-static-mobile .ec-main-header {
    position: fixed;
  }
}
.ec-header-fixed.ec-header-static-mobile .ec-page-wrapper {
  padding-top: 0;
}
@media (min-width: 768px) {
  .ec-header-fixed.ec-header-static-mobile .ec-page-wrapper {
    padding-top: 4.875rem;
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #3051cc !important;
}

.daterangepicker td.available:hover {
  background-color: #fd9801 !important;
}

/* Header Static and Header Fixed for Mobile */
.ec-header-static.ec-header-fixed-mobile .ec-main-header {
  position: fixed !important;
}
@media (min-width: 768px) {
  .ec-header-static.ec-header-fixed-mobile .ec-main-header {
    position: relative !important;
  }
}
.ec-header-static.ec-header-fixed-mobile .ec-page-wrapper {
  padding-top: 4.875rem;
}
@media (min-width: 768px) {
  .ec-header-static.ec-header-fixed-mobile .ec-page-wrapper {
    padding-top: 0;
  }
}

/*  Header Dark Version */
.ec-header-dark .navbar {
  background: #1a283e;
}
.ec-header-dark .navbar .search-form .input-group input,
.ec-header-dark .navbar .search-form .input-group .btn {
  background-color: #56606e;
  color: #fff !important;
}
.ec-header-dark
  .navbar
  .search-form
  .input-group
  input::-webkit-input-placeholder,
.ec-header-dark
  .navbar
  .search-form
  .input-group
  .btn::-webkit-input-placeholder {
  color: #eee;
}
.ec-header-dark .navbar .search-form .input-group input::-moz-placeholder,
.ec-header-dark .navbar .search-form .input-group .btn::-moz-placeholder {
  color: #eee;
}
.ec-header-dark .navbar .search-form .input-group input:-ms-input-placeholder,
.ec-header-dark .navbar .search-form .input-group .btn:-ms-input-placeholder {
  color: #eee;
}
.ec-header-dark .navbar .search-form .input-group input::-ms-input-placeholder,
.ec-header-dark .navbar .search-form .input-group .btn::-ms-input-placeholder {
  color: #eee;
}
.ec-header-dark .navbar .search-form .input-group input::placeholder,
.ec-header-dark .navbar .search-form .input-group .btn::placeholder {
  color: #eee;
}
.ec-header-dark .navbar .sidebar-toggle {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.ec-header-dark
  .navbar
  .navbar-right
  .navbar-nav
  .user-menu
  .dropdown-toggle:after {
  color: #fff;
}
.ec-header-dark
  .navbar
  .navbar-right
  .navbar-nav
  .notifications-menu
  .dropdown-toggle {
  color: #fff;
}
.ec-header-dark .navbar .navbar-right .navbar-nav .right-sidebar-2-menu i {
  color: #fff;
}
.ec-header-dark .navbar .sidebar-offcanvas-toggle {
  border-color: rgba(255, 255, 255, 0.2);
}
.ec-header-dark .navbar .search-form .btn,
.ec-header-dark .navbar .search-form .input-group input.form-control {
  color: #b7c0cd;
}

.ec-header-dark
  .navbar
  .navbar-right
  .navbar-nav
  .user-menu
  .dropdown-toggle
  > span {
  color: #8a909d;
}
.ec-header-dark .navbar .navbar-right .navbar-nav .right-sidebar-2-menu {
  border-color: rgba(255, 255, 255, 0.2);
}

/* light Header */
.ec-header-light .navbar {
  background-color: #353535;
}
.ec-header-light .navbar .sidebar-toggle {
  border-color: #f3f3f3;
}
.ec-header-light .navbar .sidebar-offcanvas-toggle {
  border-color: #f3f3f3;
}
.ec-header-light .navbar .search-form .btn,
.ec-header-light .navbar .search-form .input-group input.form-control {
  color: #8a909d;
}

.ec-header-light
  .navbar
  .navbar-right
  .navbar-nav
  .user-menu
  .dropdown-toggle
  > span {
  color: #56606e;
}

/* Cusmtom Dropdown */
.custom-dropdown .custom-dropdown-toggler .icon {
  font-size: 28px;
  color: #212529;
}
.custom-dropdown .custom-dropdown-toggler .badge-xs {
  position: absolute;
  top: 4px;
  right: 8px;
  background-color: #8a909d;
  min-width: 18px;
  min-height: 18px;
  color: #ffffff;
  font-size: 0.857rem;
  font-weight: 700;
  padding: 3px 5px;
}

#notification-tab .nav-item .nav-link {
  font-size: 15px !important;
}
#notification-tab .nav-item:not(:last-child):after {
  height: 15px;
}

/* Notification */
.dropdown-notify {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: none;
  width: 320px;
  position: absolute;
  top: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: 0;
  left: auto;
  border-radius: 15px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateX(0);
  transform: translateX(0);
  z-index: 15;
}
@media (max-width: 575.98px) {
  .dropdown-notify {
    width: 300px;
    right: -67px;
    left: auto;
  }
}

/*===== PAGINATION =====*/
.pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination .page-item.active .page-link {
  z-index: 3;
}
.pagination .page-item:first-child .page-link {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.pagination .page-item:last-child .page-link {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.pagination .page-link {
  padding: 5px 10px;
  line-height: 17px;
  color: #8a909d;
}
.pagination .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-flat .page-item .page-link {
  border: 0px;
}

.pagination-flat-rounded .page-item .page-link {
  border-radius: 100%;
}

.pagination-seperated .page-item .page-link {
  padding: 5px 10px;
  margin: 0 3px;
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .pagination-seperated .page-item .page-link {
    padding: 5px 7px;
  }
}
.pagination-seperated .page-item:last-child .page-link {
  margin-right: 0;
}
.pagination-seperated .page-item:first-child .page-link {
  margin-left: 0;
}

.pagination-seperated-rounded .page-item .page-link {
  border-radius: 10px;
}

.border-rounded .page-item:last-child .page-link {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.border-rounded .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*===== CIRCLE PROGRESS =====*/
.circle {
  position: relative;
  text-align: center;
}
.circle .circle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.circle .circle-content h6,
.circle .circle-content .h6,
.circle .circle-content strong {
  font-size: 0.857rem;
}
.circle.circle-lg h6,
.circle.circle-lg .h6 {
  font-size: 45px;
}
.circle.circle-lg h6 span,
.circle.circle-lg .h6 span {
  font-size: 35px;
}

/*===== TOOLTIP =====*/
/*Popver Tooltip*/
.popover {
  background-color: #ffffff;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

.popover-header {
  background-color: transparent;
  border-bottom: 0px;
  padding-bottom: 0px;
  color: #56606e;
  font-size: 1.125rem;
}

.popover-body {
  color: #8a909d;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-top > .arrow,
.bs-popover-auto[data-popper-placement^="top"] > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

/* Tooltip Inner */
.tooltip-inner {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  padding: 0.56rem 1.125rem;
  color: #56606e;
  color: #56606e;
  font-size: 15px;
  font-weight: 500;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  top: 0px;
  border-top-color: #f3f3f3;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0px;
  border-right-color: #f3f3f3;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before {
  bottom: 0px;
  border-bottom-color: #f3f3f3;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0px;
  border-left-color: #f3f3f3;
}

.tooltip .arrow::after {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::after,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[data-popper-placement^="top"] .arrow::after {
  z-index: 0;
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::after,
.bs-tooltip-right .arrow::after {
  z-index: 0;
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::after {
  border-width: 0 0.4rem 0.4rem;
  z-index: 0;
  bottom: -1px;
  border-bottom-color: #fff;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::after,
.bs-tooltip-left .arrow::after {
  border-width: 0.4rem 0.4rem;
  z-index: 0;
  left: -1px;
  border-left-color: #fff;
}

/* Vector Map Tooltip */
.jvectormap-tip {
  background-color: #ffffff;
  color: #8a909d;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-radius: 0.31rem;
}

/* ======================================
--> 3. SIDEBAR NAVIGATION
========================================= */
.ec-navigation {
  height: calc(100% - 150px);
}

.ec-left-sidebar {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translateX(-251px);
  transform: translateX(-251px);
  top: 0;
  bottom: 0;
  box-shadow: 4px 0 3px -2px rgba(0, 0, 0, 0.05);
}
@media (min-width: 768px) {
  .ec-left-sidebar {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    width: 250px;
    position: relative;
    z-index: 1;
  }
}

.sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  z-index: 1050;
  min-height: 100vh;
  position: relative;
  top: 10px;
}
.sidebar .sidebar-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .sidebar .sidebar-inner {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .sidebar .sidebar-inner {
    margin-top: 10px;
  }
}
.sidebar .sidebar-inner > li {
  position: relative;
}
.sidebar .sidebar-inner > li > a {
  padding: 10px 25px;
  line-height: 20px;
  color: #353535;
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar .sidebar-inner > li > a > i {
  color: #c2cfe0;
}
.sidebar .sidebar-inner .collapse.show {
  width: 100%;
  display: block;
}

.sidebar .sidebar-inner > li.active > a {
  position: relative;
  z-index: 10;
  color: #ffffff;
}
.sidebar .sidebar-inner > li.active > a:before {
  opacity: 1;
}
.sidebar .sidebar-inner > li > a:before {
  position: absolute;
  content: "";
  left: 7px;
  top: 0;
  width: 90%;
  height: 100%;
  border-radius: 15px;
  background: #fd9801;
  opacity: 0;
  z-index: -1;
}
.sidebar .sidebar-inner .section-title {
  padding: 1.25rem 1.56rem 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: rgba(138, 144, 157, 0.7);
  letter-spacing: 1.2px;
}
.sidebar .sidebar-inner .sub-menu .section-title {
  padding: 1.25rem 0.94rem 0.625rem;
}

.sidebar .nav > li.active > a:before {
  opacity: 1;
}

/* App brand */
.ec-brand {
  position: relative;
  display: block;
  background-color: transparent;
}
.ec-brand a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  height: 70px;
  line-height: 75px;
  width: 250px;
  border-bottom: 1px solid #f3f3f3;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  padding: 0 3.56rem;
}
@media (prefers-reduced-motion: reduce) {
  .ec-brand a {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 768px) {
  .ec-brand a {
    overflow: hidden;
  }
}
.ec-brand a img {
  max-width: 157px;
  height: 57px;
  vertical-align: middle;
}

.aside-user {
  padding: 0 25px;
  margin: 10px 0;
  gap: 0.5rem;
}
.user-name {
  line-height: 1;
}

.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}

.symbol.symbol-50px > img {
  width: 50px;
  height: 50px;
}

.flex-row-fluid {
  flex: 1 auto;
  min-width: 0;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.ec-brand .ec-brand-name {
  color: #56606e;
  font-size: 30px;
  margin-left: 5px;
  max-width: 170px;
  font-size: 30px;
  font-weight: 700;
}

/*  Nav menus Icon Styles */
.sidebar .nav > li > a i {
  float: left;
  margin-right: 0.94rem;
  width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 1.5rem;
}

.sidebar .nav > li.active > a i {
  color: #fff;
}

/* Caret styles */
.sidebar li > a .caret {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 1.25rem;
  border: none;
}
.sidebar li > a .caret:before {
  content: "";
  display: block;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  opacity: 0.5;
}

.sidebar li > a .caret:before {
  font-family: "Material Design Icons" !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

.sidebar li.active > a .caret:before,
.sidebar li.expand > a .caret:before,
.sidebar li.expanding > a .caret:before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.sidebar li.active.expand > a .caret:before,
.sidebar li.expand > a .caret:before,
.sidebar li.expanding > a .caret:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar li.active > a .caret:before {
  opacity: 1;
}

.sidebar li.closed > a .caret:before,
.sidebar li.closing > a .caret:before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

/*  sub-menu dropdowns */
.sidebar .sub-menu {
  list-style-type: none;
  padding: 0.625rem 0 0.625rem 2.81rem;
  margin: 0;
  position: relative;
}
.sidebar .sub-menu > li > a {
  padding: 0.625rem 1.25rem 0.625rem 0.94rem;
  display: block;
  text-decoration: none;
  position: relative;
  font-size: 0.88rem;
}
.sidebar .sub-menu > li .sub-menu {
  padding-left: 2.18rem;
}
.sidebar .sub-menu > li .sub-menu > li > a {
  position: relative;
  font-size: 0.81rem;
  padding-left: 0;
  text-overflow: ellipsis;
}
.sidebar .sub-menu > li .sub-menu > li > a:after {
  position: absolute;
  content: "";
  top: 20px;
  left: -20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 10px;
}

.sidebar .has-sub.active.expand > .sub-menu {
  display: none;
}

.sidebar .has-sub .has-sub .caret {
  top: 12px;
}

.sidebar .sidenav-item-link {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
}

.sidebar .sidenav-item-link .nav-text {
  margin-right: auto;
  overflow: hidden;
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar .sidenav-item-link .badge {
  padding: 0.4em 0.6em;
}
.sidebar .sub-menu > li > a .nav-text {
  white-space: pre-line;
}
.sidebar .sub-menu .has-sub .nav-text {
  white-space: nowrap;
}

/* Sidebar for Mobile */
.sidebar-mobile-in .ec-left-sidebar {
  -webkit-transform: translateX(0px) !important;
  transform: translateX(0px) !important;
  z-index: 1050;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .sidebar-mobile-in .ec-left-sidebar {
    -webkit-transform: translateX(-251px) !important;
    transform: translateX(-251px) !important;
  }
}

.sidebar-mobile-out .ec-left-sidebar {
  -webkit-transform: translateX(-251px) !important;
  transform: translateX(-251px) !important;
  z-index: 1050;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .sidebar-mobile-out .ec-left-sidebar {
    -webkit-transform: translateX(0px) !important;
    transform: translateX(0px) !important;
  }
}

/* Sidebar Minified */
.sidebar-minified .ec-left-sidebar {
  width: 250px;
  z-index: 15;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar {
    width: 75px;
    -webkit-transition: width 0.3s ease-in;
    transition: width 0.3s ease-in;
  }
}
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar .ec-brand .ec-brand-name {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-minified .ec-left-sidebar .ec-brand .ec-brand-name {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified .ec-left-sidebar .sidebar .sub-menu {
  padding-top: 0;
  padding-bottom: 0;
}
.sidebar-minified .ec-left-sidebar .nav > li > a > .caret,
.sidebar-minified .ec-left-sidebar .nav > li > a > span {
  opacity: 1;
}
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar .nav > li > a > .caret,
  .sidebar-minified .ec-left-sidebar .nav > li > a > span {
    opacity: 0;
  }
}
.sidebar-minified .ec-left-sidebar .nav > li > a > .caret {
  right: 15px;
}
.sidebar-minified .ec-left-sidebar .nav li.has-sub .collapse > .sub-menu {
  left: 0;
  top: 0;
  width: 100%;
  margin: 0;
}
.sidebar-minified
  .ec-left-sidebar
  .nav
  li.has-sub.expand
  .collapse
  > .sub-menu {
  height: 0;
}
.sidebar-minified
  .ec-left-sidebar
  .nav
  li.has-sub.expand
  .collapse
  > .sub-menu
  li {
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 575.98px) {
  .sidebar-minified
    .ec-left-sidebar
    .nav
    li.has-sub.expand
    .collapse
    > .sub-menu {
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    display: block !important;
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-minified
    .ec-left-sidebar
    .nav
    li.has-sub.expand
    .collapse
    > .sub-menu {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified .ec-left-sidebar .separator {
  border-top-width: 0;
}
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar .sidebar-footer {
    height: 0;
  }
}
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar .sidebar-footer-content {
    visibility: hidden;
    opacity: 0;
  }
}
.sidebar-minified .ec-left-sidebar .sidebar-scrollbar {
  overflow: unset !important;
}

/* Sidebar Minified Hover */
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar:hover {
    width: 250px;
    margin-right: -10.94rem;
  }
}
.sidebar-minified
  .ec-left-sidebar:hover
  .nav
  li.has-sub.expand
  .collapse
  > .sub-menu {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  height: auto;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified
    .ec-left-sidebar:hover
    .nav
    li.has-sub.expand
    .collapse
    > .sub-menu {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified
  .ec-left-sidebar:hover
  .nav
  li.has-sub.expand
  .collapse
  > .sub-menu
  li {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s ease-out 0.25s;
  transition: opacity 0.3s ease-out 0.25s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified
    .ec-left-sidebar:hover
    .nav
    li.has-sub.expand
    .collapse
    > .sub-menu
    li {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified .ec-left-sidebar:hover .nav > li > a > .caret,
.sidebar-minified .ec-left-sidebar:hover .nav > li > a > span {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified .ec-left-sidebar:hover .nav > li > a > .caret,
  .sidebar-minified .ec-left-sidebar:hover .nav > li > a > span {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified .ec-left-sidebar:hover .ec-brand-name {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified .ec-left-sidebar:hover .ec-brand-name {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar:hover .ec-brand .ec-brand-name {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
}
.sidebar-minified .ec-left-sidebar:hover .sidebar .sub-menu {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.sidebar-minified .ec-left-sidebar:hover .separator {
  border-top-width: 1px;
}
@media (min-width: 768px) {
  .sidebar-minified .ec-left-sidebar:hover .sidebar-footer {
    height: auto;
  }
}
.sidebar-minified .ec-left-sidebar:hover .sidebar-footer-content {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.15s;
  transition: opacity 0.3s ease-in-out 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified .ec-left-sidebar:hover .sidebar-footer-content {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified .ec-left-sidebar:hover .sidebar-scrollbar {
  overflow: hidden !important;
}

/* Sidebar Minified Not transition */
@media (min-width: 768px) {
  .sidebar-minified:not(.ec-left-sidebar) .ec-left-sidebar {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-property: margin-left, margin-right, width;
    transition-property: margin-left, margin-right, width;
  }
}

/* Sidebar Static With Minified */
.ec-sidebar-static.sidebar-minified .ec-page-wrapper .ec-main-header {
  -webkit-transition: padding-left 0.3s ease-in-out;
  transition: padding-left 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .ec-sidebar-static.sidebar-minified .ec-page-wrapper .ec-main-header {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 768px) {
  .ec-sidebar-static.sidebar-minified.ec-header-fixed
    .ec-page-wrapper
    .ec-main-header {
    padding-left: 4.69rem;
  }
}
.ec-sidebar-static.sidebar-minified-out .ec-left-sidebar {
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .ec-sidebar-static.sidebar-minified-out .ec-left-sidebar {
    -webkit-transition: none;
    transition: none;
  }
}
.ec-sidebar-static.sidebar-minified-out .ec-page-wrapper .ec-main-header {
  -webkit-transition: padding-left 0.3s ease-in-out;
  transition: padding-left 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .ec-sidebar-static.sidebar-minified-out .ec-page-wrapper .ec-main-header {
    -webkit-transition: none;
    transition: none;
  }
}

/* Sidebar Fixed With Minified Layout Container */
@media (min-width: 768px) {
  .ec-sidebar-fixed.sidebar-minified .ec-page-wrapper {
    padding-left: 4.69rem;
    -webkit-transition: padding-left 0.3s ease-in-out;
    transition: padding-left 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .ec-sidebar-fixed.sidebar-minified .ec-page-wrapper {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 768px) {
  .ec-sidebar-fixed.sidebar-minified.ec-header-fixed
    .ec-page-wrapper
    .ec-main-header {
    padding-left: 4.69rem;
    -webkit-transition: padding-left 0.3s ease-in-out;
    transition: padding-left 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .ec-sidebar-fixed.sidebar-minified.ec-header-fixed
    .ec-page-wrapper
    .ec-main-header {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified-out .ec-left-sidebar {
  z-index: 10000;
  -webkit-transform: translateX(-251px);
  transform: translateX(-251px);
  -webkit-transition: -webkit-transform 0.3s ease-in-out !important;
  transition: -webkit-transform 0.3s ease-in-out !important;
  transition: transform 0.3s ease-in-out !important;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out !important;
}
@media (min-width: 768px) {
  .sidebar-minified-out .ec-left-sidebar {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-transition: width 0.3s ease-in-out !important;
    transition: width 0.3s ease-in-out !important;
  }
}
.sidebar-minified-out .sidebar .sub-menu {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}
.sidebar-minified-out .sidebar .nav > li > a span {
  -webkit-transition: opacity 0.3s ease-in-out 0.3s;
  transition: opacity 0.3s ease-in-out 0.3s;
  visibility: visible;
  opacity: 1;
}
.sidebar-minified-out .sidebar li > a .caret {
  -webkit-transition: opacity 0.3s ease-in-out 0.3s;
  transition: opacity 0.3s ease-in-out 0.3s;
  visibility: visible;
  opacity: 1;
}
.sidebar-minified-out .nav li.has-sub.expand .collapse > .sub-menu {
  height: auto;
}
.sidebar-minified-out .nav li.has-sub.expand .collapse > .sub-menu li {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s ease-out 0.25s;
  transition: opacity 0.3s ease-out 0.25s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified-out .nav li.has-sub.expand .collapse > .sub-menu li {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified-out .sidebar-footer-content {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.15s;
  transition: opacity 0.3s ease-in-out 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified-out .sidebar-footer-content {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified-out .ec-left-sidebar {
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified-out .ec-left-sidebar {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified-out .ec-page-wrapper {
  -webkit-transition: padding-left 0.3s ease-in-out;
  transition: padding-left 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified-out .ec-page-wrapper {
    -webkit-transition: none;
    transition: none;
  }
}
.sidebar-minified-out .ec-page-wrapper .ec-main-header {
  -webkit-transition: padding-left 0.3s ease-in-out;
  transition: padding-left 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-minified-out .ec-page-wrapper .ec-main-header {
    -webkit-transition: none;
    transition: none;
  }
}

/* Sidebar Fixed And Offcanvas */
@media (min-width: 768px) {
  .ec-sidebar-fixed-offcanvas .ec-left-sidebar,
  .ec-sidebar-fixed .ec-left-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 16;
  }
}
@media (min-width: 768px) {
  .ec-sidebar-fixed-offcanvas .ec-page-wrapper,
  .ec-sidebar-fixed .ec-page-wrapper {
    padding-left: 15.6rem;
  }
}
@media (min-width: 768px) {
  .ec-sidebar-fixed-offcanvas .ec-main-header,
  .ec-sidebar-fixed .ec-main-header {
    padding-left: 15.6rem;
  }
}
.ec-sidebar-fixed-offcanvas.ec-header-static .ec-main-header,
.ec-sidebar-fixed.ec-header-static .ec-main-header {
  position: relative;
}
@media (min-width: 768px) {
  .ec-sidebar-fixed-offcanvas.ec-header-static .ec-main-header,
  .ec-sidebar-fixed.ec-header-static .ec-main-header {
    padding-left: 0;
  }
}
.ec-sidebar-fixed-offcanvas .ec-sidebar-footer,
.ec-sidebar-fixed .ec-sidebar-footer {
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .ec-sidebar-fixed-offcanvas .ec-sidebar-footer,
  .ec-sidebar-fixed .ec-sidebar-footer {
    padding-bottom: 30px;
  }
}
.ec-sidebar-fixed-offcanvas .ec-sidebar-footer .sidebar-footer,
.ec-sidebar-fixed .ec-sidebar-footer .sidebar-footer {
  position: absolute;
  bottom: 0;
}

.ec-sidebar-fixed-offcanvas.sidebar-collapse .ec-page-wrapper {
  padding-left: 0;
}
@media (min-width: 768px) {
  .ec-sidebar-fixed-offcanvas.sidebar-collapse .sidebar-footer {
    left: -251px;
  }
}
@media (min-width: 768px) {
  .ec-sidebar-fixed-offcanvas.sidebar-collapse-out .sidebar-footer {
    left: 0px;
    -webkit-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .ec-sidebar-fixed-offcanvas.sidebar-collapse-out .sidebar-footer {
    -webkit-transition: none;
    transition: none;
  }
}

/* Sidebar Collapse */
@media (min-width: 768px) {
  .sidebar-collapse .ec-left-sidebar {
    margin-left: -15.6rem;
    -webkit-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-collapse .ec-left-sidebar {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .ec-page-wrapper {
    -webkit-transition: padding-left 0.3s ease-in-out;
    transition: padding-left 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-collapse .ec-page-wrapper {
    -webkit-transition: none;
    transition: none;
  }
}

/* Sidebar Out Collapse */
@media (min-width: 768px) {
  .sidebar-collapse-out .ec-left-sidebar {
    margin-left: 0px;
    -webkit-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-collapse-out .ec-left-sidebar {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse-out .ec-page-wrapper {
    -webkit-transition: padding-left 0.3s ease-in-out;
    transition: padding-left 0.3s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-collapse-out .ec-page-wrapper {
    -webkit-transition: none;
    transition: none;
  }
}

.sidebar-collapse.ec-header-fixed .ec-page-wrapper .ec-main-header {
  padding-left: 0;
  -webkit-transition: padding-left 0.3s ease-in-out;
  transition: padding-left 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-collapse.ec-header-fixed .ec-page-wrapper .ec-main-header {
    -webkit-transition: none;
    transition: none;
  }
}

.sidebar-collapse-out .ec-page-wrapper .ec-main-header {
  -webkit-transition: padding-left 0.3s ease-in-out;
  transition: padding-left 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-collapse-out .ec-page-wrapper .ec-main-header {
    -webkit-transition: none;
    transition: none;
  }
}

/* Sidebar Static With Footer */
.ec-sidebar-static .sidebar.ec-sidebar-footer .slimScrollDiv,
.ec-sidebar-static-offcanvas .sidebar.ec-sidebar-footer .slimScrollDiv {
  height: 100%;
}
@media (min-width: 768px) {
  .ec-sidebar-static .sidebar.ec-sidebar-footer .slimScrollDiv,
  .ec-sidebar-static-offcanvas .sidebar.ec-sidebar-footer .slimScrollDiv {
    height: auto !important;
  }
}
@media (min-width: 768px) {
  .ec-sidebar-static.ec-header-fixed .ec-main-header,
  .ec-sidebar-static-offcanvas.ec-header-fixed .ec-main-header {
    padding-left: 15.6rem;
  }
}

.sidebar-ec-content-wrapper {
  height: calc(100% - 45px) !important;
}

/* Sidebar Bg Dark */
.ec-sidebar-dark .ec-brand {
  border-color: #37455a;
  border-right: 1px solid #37455a;
}
.ec-sidebar-dark .ec-brand a {
  border-color: #37455a;
}
.ec-sidebar-dark hr {
  background: #37455a;
}
.ec-sidebar-dark .ec-left-sidebar {
  background-color: #1a283e;
}
.ec-sidebar-dark .sidebar .sub-menu {
  background: #293546;
}
.ec-sidebar-dark .sidebar .sub-menu > li > a {
  color: #b7c0cd;
}
.ec-sidebar-dark .sidebar .sub-menu > li > a.active {
  color: #ffffff;
}
.ec-sidebar-dark .sidebar .sub-menu > li > a .nav-text {
  white-space: pre-line;
}
.ec-sidebar-dark .sidebar .sub-menu .has-sub .nav-text {
  white-space: nowrap;
}
.ec-sidebar-dark .sidebar .sub-menu > li.active > a,
.ec-sidebar-dark .sidebar .sub-menu > li.active > a:focus,
.ec-sidebar-dark .sidebar .sub-menu > li.active > a:hover,
.ec-sidebar-dark .sidebar .sub-menu > li > a:focus,
.ec-sidebar-dark .sidebar .sub-menu > li > a:hover {
  color: #ffffff;
}
.ec-sidebar-dark .sidebar .nav > li.active > a {
  color: #ffffff;
}
.ec-sidebar-dark .separator {
  border-color: rgba(243, 243, 243, 0.2);
}
.ec-sidebar-dark .sidebar-footer {
  background-color: #212a39;
}

/* Sidebar Light */
.ec-sidebar-light .ec-left-sidebar {
  background-color: #ffffff;
}
.ec-sidebar-light .sidebar {
  border-right: 1px solid #f3f3f3;
}
.ec-sidebar-light .sidebar .sub-menu {
  background-color: #f7f7f7;
}
.ec-sidebar-light .sidebar .sub-menu > li > a {
  color: #8a909d;
}
.ec-sidebar-light .sidebar .sub-menu > li > a.active {
  color: #4c515b;
}
.ec-sidebar-light .sidebar .sub-menu > li.active > a,
.ec-sidebar-light .sidebar .sub-menu > li.active > a:focus,
.ec-sidebar-light .sidebar .sub-menu > li.active > a:hover,
.ec-sidebar-light .sidebar .sub-menu > li > a:focus,
.ec-sidebar-light .sidebar .sub-menu > li > a:hover {
  color: #4c515b;
}
.ec-sidebar-light .sidebar .nav > li.active > a {
  color: #fff;
}
.ec-sidebar-light .sidebar .separator {
  border-color: #f3f3f3;
}
.ec-sidebar-light .sidebar-footer {
  background-color: #ffffff;
}
.ec-sidebar-light .sidebar .sub-menu > li .sub-menu > li > a:after {
  border: 1px solid #8a909d;
}

/* Sidebar Footer Content*/
.sidebar-footer-content {
  padding: 0 1.9rem;
  padding-top: 1.56rem;
  padding-bottom: 0.75rem;
  width: 250px;
}
@media (min-width: 1200px) {
  .sidebar-footer-content {
    padding-top: 2.81rem;
    padding-bottom: 2rem;
  }
}
.sidebar-footer-content > h6,
.sidebar-footer-content > .h6 {
  color: #b7c0cd;
  font-size: 0.81rem;
  margin-top: 0.69rem;
  margin-bottom: 0.69rem;
  white-space: nowrap;
}
.sidebar-footer-content .progress {
  height: 15px;
  margin-bottom: 1.88rem;
  overflow: hidden;
  background-color: #e4eaec;
  border-radius: 0.215rem;
}
.sidebar-footer-content .progress-xs {
  height: 4px;
  border-radius: 0.06rem;
}
.sidebar-footer-content .progress-xs .progress-bar {
  line-height: 4px;
}
.sidebar-footer-content .progress-xs .progress-bar.progress-bar-warning {
  background: #ddaa04;
}

/* App Logo Fill Color */
.logo-fill-blue {
  fill: #84befc;
}

.logo-fill-white {
  fill: #ffffff;
}

/* Right Sidebar Toogler Add */
.right-sidebar-toggoler-in .right-sidebar {
  right: 0;
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}
.right-sidebar-toggoler-in .right-sidebar .btn-right-sidebar-toggler {
  right: 60px;
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}
.right-sidebar-toggoler-in .right-sidebar .btn-right-sidebar-toggler i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.right-sidebar-toggoler-out .right-sidebar {
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}
.right-sidebar-toggoler-out .right-sidebar .btn-right-sidebar-toggler {
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}
.right-sidebar-toggoler-out .right-sidebar i {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

/* Right Sidebar */
.right-sidebar {
  width: 60px;
  min-height: 100vh;
  position: fixed;
  right: -60px;
  top: 0;
  z-index: 1;
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
  /* From Group Label */
}
@media (min-width: 1200px) {
  .right-sidebar {
    right: 0;
    -webkit-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
  }
}
.right-sidebar .btn-right-sidebar-toggler {
  position: fixed;
  top: 135px;
  right: 0;
  background-color: #ffffff;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 35px;
  height: 30px;
  border: 1px solid #f3f3f3;
}
.right-sidebar .btn-right-sidebar-toggler i {
  line-height: 28px;
  margin-left: 5px;
  font-size: 1.25rem;
  display: inline-block;
}
@media (min-width: 1200px) {
  .right-sidebar .btn-right-sidebar-toggler {
    display: none;
  }
}
.right-sidebar .form-group label {
  line-height: 1.3;
}

/* Nav Right Sidebar */
.nav-right-sidebar {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.nav-right-sidebar .nav-item {
  margin: auto;
  margin-bottom: 0.75rem;
}
.nav-right-sidebar .nav-link {
  font-size: 1.5rem;
}
.nav-right-sidebar .nav-link:focus {
  background-color: rgba(138, 144, 157, 0.1);
}

/* Nav Container */
.right-nav-container {
  position: relative;
  z-index: 100;
  background-color: #ffffff;
  min-height: 100vh;
  border-left: 1px solid #f3f3f3;
}
.right-nav-container .nav-right-sidebar {
  padding-top: 120px;
}

/* Right Sidebar Tab Content */
.right-sidebar-tab {
  position: fixed;
  top: 76px;
  right: -375px;
  width: 315px;
  min-height: 100%;
  background-color: #ffffff;
  border-left: 1px solid #f3f3f3;
}
.right-sidebar-tab .tab-content {
  position: relative;
  z-index: 10;
}

/* Right Sidebar Content Out */
@media (min-width: 1200px) {
  .right-sidebar-out .ec-content-wrapper {
    margin-right: 60px;
    -webkit-transition: margin-right 0.3s ease-in-out;
    transition: margin-right 0.3s ease-in-out;
  }
}
.right-sidebar-out .right-sidebar-tab {
  right: -315px;
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}

/* Right Sidebar Content IN */
@media (min-width: 1200px) {
  .right-sidebar-in .ec-content-wrapper {
    margin-right: 375px;
    -webkit-transition: margin-right 0.3s ease-in-out;
    transition: margin-right 0.3s ease-in-out;
  }
}
.right-sidebar-in .right-sidebar-tab {
  right: 60px;
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}

/* Nav Icon Small */
.icon-sm {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
}

/* Card Right Sidebar */
.card-right-sidebar {
  border: none;
}
.card-right-sidebar .card-header {
  background-color: transparent;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  color: #56606e;
}
.card-right-sidebar .card-header .close {
  width: 30px;
  height: 30px;
  line-height: 1;
  border-radius: 50%;
  margin-top: -5px;
}
.card-right-sidebar .card-header .close:hover {
  background-color: rgba(138, 144, 157, 0.1);
}
.card-right-sidebar .card-header .card-title {
  margin-bottom: 0;
  font-size: 0.94rem;
}

/* Dark Version  */
.right-ec-sidebar-dark .right-nav-container {
  background-color: #1a283e;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

/* Right Sidebar 2 */
.right-sidebar-container-2 {
  right: 0;
  top: 0;
  width: 315px !important;
  display: block;
  z-index: 555555;
  position: fixed;
  background: #fff;
  border-radius: 0;
  height: 100%;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  border-left: 1px solid #f3f3f3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* Theme Button */
  /* Reset Button */
  /* Theme Button Active */
  /* Slelect box */
  /* Theme Subtitle */
}
.right-sidebar-container-2 .btn-close-right-sidebar-2 {
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  margin-left: -40px;
  min-width: 40px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  font-size: 1.25rem;
  color: #56606e;
}
.right-sidebar-container-2 h2,
.right-sidebar-container-2 .h2 {
  font-size: 1.25rem;
  font-weight: 200;
  color: #ffffff;
}
.right-sidebar-container-2 .right-sidebar-2-header {
  padding: 17px 30px;
  border-radius: 0 0 !important;
  display: block;
  color: #56606e;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3;
}
.right-sidebar-container-2 .right-sidebar-2-header p {
  font-size: 0.928rem;
  color: #56606e;
  display: none;
}
.right-sidebar-container-2 .right-sidebar-2-header h2,
.right-sidebar-container-2 .right-sidebar-2-header .h2 {
  color: #56606e;
}
.right-sidebar-container-2 .btn-right-sidebar-2 {
  color: #8a909d;
  font-size: 0.928rem;
  text-transform: uppercase;
  font-weight: 200;
  padding: 4px 10px;
  width: 48%;
  text-align: center;
  margin-right: 10px;
  text-decoration: none;
  line-height: 21px;
}
.right-sidebar-container-2 .btn-right-sidebar-2:last-child {
  margin-right: 0;
}
.right-sidebar-container-2 .btn-reset {
  background-color: #88aaf3;
  border: 0;
  color: #ffffff;
  width: auto;
  cursor: pointer;
  line-height: 2;
  border-radius: 15px !important;
}
.right-sidebar-container-2 .btn-right-sidebar-2.btn-right-sidebar-2-active {
  background-color: #8a909d;
  color: #ffffff;
  text-decoration: none;
}
.right-sidebar-container-2 .right-sidebar-2-select {
  width: 100%;
  height: 31px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}
.right-sidebar-container-2 .right-sidebar-2-subtitle {
  color: #a6aab4;
  font-size: 1.143rem;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* Theme Option Visibile */
.right-sidebar-2-visible {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* Style Swticher Body */
.right-sidebar-2-body {
  padding: 15px 30px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: calc(100vh - 60px);
}
.right-sidebar-2-body .no-col-space {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.right-sidebar-2-body hr {
  margin: 11px 0;
  border-color: #454545;
}

/* Simplebar */
.simplebar-scrollbar::before {
  background-color: rgba(153, 153, 153, 0.8);
  width: 5px;
  left: 6px;
  right: 6px;
}

/*===== SPINNERS =====*/
/* Rotating Plane */
.sk-rotating-plane {
  width: 40px;
  height: 40px;
  background-color: #333;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/* double-bounce */
.sk-double-bounce {
  width: 40px;
  height: 40px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/* Wave */
.sk-wave {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 0.625rem;
}

.sk-wave > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.sk-wave .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-wave .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-wave .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-wave .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
/* Wonderting Cube */
.sk-wonder-cube {
  width: 40px;
  height: 40px;
  position: relative;
}

.cube1,
.cube2 {
  background-color: #333;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
/* Pulse */
.sk-pulse {
  width: 60px;
  height: 60px;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
/* Chasing dots */
.sk-chasing-dots {
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/* Three bounce */
.sk-three-bounce {
  width: 70px;
  height: 40px;
  text-align: center;
}

.sk-three-bounce > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.sk-three-bounce .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Circle */
.sk-circle {
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Cube Grid */
.sk-cube-grid {
  width: 40px;
  height: 40px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
/* Fading Circle */
.sk-fading-circle {
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
/* Folding Cube */
.sk-folding-cube {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
/* Chase */
.sk-chase {
  width: 60px;
  height: 60px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

@-webkit-keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  100%,
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  100%,
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*===== SWITCHERS =====*/
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 64px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
}
.switch .switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  display: none;
  visibility: hidden;
}
.switch .switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #fff;
  border: 1px solid #c2cfd6;
  border-radius: 4px;
  -webkit-transition: opacity background-color 0.3s 0.3s ease-out;
  transition: opacity background-color 0.3s 0.3s ease-out;
}
.switch .switch-handle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #c2cfd6;
  border-radius: 1px;
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
}

.switch .switch-input:checked ~ .switch-handle {
  left: 37px;
}

/* Switch context properties */
.switch-primary > .switch-input:checked ~ .switch-label {
  background-color: #88aaf3 !important;
  border-color: #88aaf3;
}
.switch-primary > .switch-input:checked ~ .switch-handle {
  border-color: transparent;
}

.switch-secondary > .switch-input:checked ~ .switch-label {
  background-color: #8a909d !important;
  border-color: #8a909d;
}
.switch-secondary > .switch-input:checked ~ .switch-handle {
  border-color: transparent;
}

.switch-success > .switch-input:checked ~ .switch-label {
  background-color: #34c997 !important;
  border-color: #34c997;
}
.switch-success > .switch-input:checked ~ .switch-handle {
  border-color: transparent;
}

.switch-info > .switch-input:checked ~ .switch-label {
  background-color: #24c4da !important;
  border-color: #24c4da;
}
.switch-info > .switch-input:checked ~ .switch-handle {
  border-color: transparent;
}

.switch-warning > .switch-input:checked ~ .switch-label {
  background-color: #ddaa04 !important;
  border-color: #ddaa04;
}
.switch-warning > .switch-input:checked ~ .switch-handle {
  border-color: transparent;
}

.switch-danger > .switch-input:checked ~ .switch-label {
  background-color: #ec4a58 !important;
  border-color: #ec4a58;
}
.switch-danger > .switch-input:checked ~ .switch-handle {
  border-color: transparent;
}

/* Switch Pill */
.switch-pill .switch-handle,
.switch-pill .switch-label {
  border-radius: 50px !important;
}

/* Switch Outline */
.switch-outline-primary > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #88aaf3;
}
.switch-outline-primary .switch-input:checked ~ .switch-label:after {
  color: #88aaf3;
}
.switch-outline-primary > .switch-input:checked ~ .switch-handle {
  border-color: #88aaf3;
}

.switch-outline-secondary > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #8a909d;
}
.switch-outline-secondary .switch-input:checked ~ .switch-label:after {
  color: #8a909d;
}
.switch-outline-secondary > .switch-input:checked ~ .switch-handle {
  border-color: #8a909d;
}

.switch-outline-success > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #34c997;
}
.switch-outline-success .switch-input:checked ~ .switch-label:after {
  color: #34c997;
}
.switch-outline-success > .switch-input:checked ~ .switch-handle {
  border-color: #34c997;
}

.switch-outline-info > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #24c4da;
}
.switch-outline-info .switch-input:checked ~ .switch-label:after {
  color: #24c4da;
}
.switch-outline-info > .switch-input:checked ~ .switch-handle {
  border-color: #24c4da;
}

.switch-outline-warning > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #ddaa04;
}
.switch-outline-warning > .switch-input:checked ~ .switch-label:after {
  color: #ddaa04;
}
.switch-outline-warning > .switch-input:checked ~ .switch-handle {
  border-color: #ddaa04;
}

.switch-outline-danger > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #ec4a58;
}
.switch-outline-danger > .switch-input:checked ~ .switch-label:after {
  color: #ec4a58;
}
.switch-outline-danger > .switch-input:checked ~ .switch-handle {
  border-color: #ec4a58;
}

/* Switch Outline Alternative */
.switch-outline-alt-primary > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #88aaf3;
}
.switch-outline-alt-primary .switch-input:checked ~ .switch-label:after {
  color: #88aaf3;
}
.switch-outline-alt-primary > .switch-input:checked ~ .switch-handle {
  background-color: #88aaf3;
  border-color: #88aaf3;
}

.switch-outline-alt-secondary > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #8a909d;
}
.switch-outline-alt-secondary > .switch-input:checked ~ .switch-label:after {
  color: #8a909d;
}
.switch-outline-alt-secondary > .switch-input:checked ~ .switch-handle {
  background-color: #8a909d;
  border-color: #8a909d;
}

.switch-outline-alt-success > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #34c997;
}
.switch-outline-alt-success > .switch-input:checked ~ .switch-label:after {
  color: #34c997;
}
.switch-outline-alt-success > .switch-input:checked ~ .switch-handle {
  background-color: #34c997;
  border-color: #34c997;
}

.switch-outline-alt-info > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #24c4da;
}
.switch-outline-alt-info > .switch-input:checked ~ .switch-label:after {
  color: #24c4da;
}
.switch-outline-alt-info > .switch-input:checked ~ .switch-handle {
  background-color: #24c4da;
  border-color: #24c4da;
}

.switch-outline-alt-warning > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #ddaa04;
}
.switch-outline-alt-warning > .switch-input:checked ~ .switch-label:after {
  color: #ddaa04;
}
.switch-outline-alt-warning > .switch-input:checked ~ .switch-handle {
  background-color: #ddaa04;
  border-color: #ddaa04;
}

.switch-outline-alt-danger > .switch-input:checked ~ .switch-label {
  background-color: #ffffff !important;
  border-color: #ec4a58;
}
.switch-outline-alt-danger > .switch-input:checked ~ .switch-label:after {
  color: #ec4a58;
}
.switch-outline-alt-danger > .switch-input:checked ~ .switch-handle {
  background-color: #ec4a58;
  border-color: #ec4a58;
}

/* Switch With Text */
.switch.switch-text,
.switch.switch-icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 64px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
}
.switch.switch-text .switch-input,
.switch.switch-icon .switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch.switch-text .switch-label,
.switch.switch-icon .switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #ffffff;
  border: 1px solid #c2cfd6;
  border-radius: 2px;
  -webkit-transition: opacity background 0.3s ease-out;
  transition: opacity background 0.3s ease-out;
}
.switch.switch-text .switch-handle,
.switch.switch-icon .switch-handle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #c2cfd6;
  border-radius: 0.06rem;
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
}

.switch.switch-text .switch-label:after,
.switch.switch-text .switch-label:before {
  position: absolute;
  top: 50%;
  width: 50%;
  margin-top: -0.31rem;
  line-height: 1;
  font-size: 0.857rem;
  font-weight: 400;
  font-family: "Roboto, sans-serif";
  text-align: center;
  -webkit-transition: inherit;
  transition: inherit;
}
.switch.switch-text .switch-label:before {
  right: 1px;
  color: #c2cfd6;
  content: attr(data-off);
}
.switch.switch-text .switch-label:after {
  left: 1px;
  color: #fff;
  content: attr(data-on);
  opacity: 0;
}

.switch.switch-text .switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch.switch-text .switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch.switch-text .switch-input:checked ~ .switch-handle {
  left: 37px;
}

/* Switch With Icon */
.switch.switch-icon .switch-label:after,
.switch.switch-icon .switch-label:before {
  position: absolute;
  top: 50%;
  width: 50%;
  margin-top: -8px;
  line-height: 1;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Material Design Icons";
  text-align: center;
  -webkit-transition: inherit;
  transition: inherit;
}
.switch.switch-icon .switch-label:before {
  right: 1px;
  color: #c2cfd6;
  content: "";
}
.switch.switch-icon .switch-label:after {
  left: 1px;
  color: #fff;
  content: "";
  opacity: 0;
}

.switch.switch-icon .switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch.switch-icon .switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch.switch-icon .switch-input:checked ~ .switch-handle {
  left: 37px;
}

/*===== TABS =====*/
.notifications-menu .card-default .card-header-border-bottom {
  margin-bottom: 12px !important;
}
.nav-vertical {
  border: none;
}

.nav-vertical > li {
  width: 100% !important;
}

.nav-vertical > .nav-item > .nav-link {
  border: none;
  border-radius: 0;
  width: 100%;
  text-align: left;
}

.nav-vertical > .nav-item > .nav-link.active {
  background: #fd9801 !important;
  border: none;
  color: #ffffff !important;
}

.nav-vertical > .nav-item > .nav-link:hover {
  border: none;
  background: #3051cc !important;
  color: #ffffff !important;
}

.nav-tabs .nav-item .nav-link {
  color: #56606e;
  font-weight: 500;
  /* padding: 8px 20px; */
}
.nav-tabs .nav-item .nav-link.active {
  color: #88aaf3;
}
.nav-tabs .nav-item .nav-link.disabled {
  color: #8a909d;
}
.nav-tabs .nav-item .nav-link.disabled:hover {
  cursor: not-allowed;
}

.nav-pills .nav-item .nav-link {
  color: #56606e;
  font-weight: 500;
  /* padding: 12px 20px; */
}
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background: #88aaf3;
}
.nav-pills .nav-item .nav-link.disabled {
  color: #8a909d;
}
.nav-pills .nav-item .nav-link.disabled:hover {
  cursor: not-allowed;
}

.tab-pane {
  line-height: 1.8;
}

/* Custom Tab Style - Fill */
.nav-style-fill.nav-pills {
  border: 2px solid #f3f3f3;
  border-radius: 0.38rem;
  overflow: hidden;
}
.nav-style-fill.nav-pills .nav-link {
  border-radius: 0;
  padding-top: 0.94rem;
  padding-bottom: 0.94rem;
}
.nav-style-fill.nav-pills .nav-link.active,
.nav-style-fill.nav-pills .show > .nav-link {
  color: #88aaf3;
  background-color: #f5f6fa;
}

.nav-tabs.nav-style-border {
  padding-left: 1.9rem;
  padding-right: 1.9rem;
  padding-bottom: 0.625rem;
}
@media (min-width: 768px) {
  .nav-tabs.nav-style-border {
    padding-bottom: 0;
  }
}
@media (min-width: 1500px) {
  .nav-tabs.nav-style-border {
    padding-left: 3.12rem;
    padding-right: 3.12rem;
  }
}
.nav-tabs.nav-style-border .nav-item {
  margin-right: 0.94rem;
}
@media (min-width: 768px) {
  .nav-tabs.nav-style-border .nav-item {
    /* margin-right: 2.19rem; */
  }
}
@media (min-width: 1200px) {
  .nav-tabs.nav-style-border .nav-item {
    /* margin-right: 3.12rem; */
    margin-bottom: 0;
  }
}
@media (min-width: 1500px) {
  .nav-tabs.nav-style-border .nav-item {
    margin-right: 3rem;
  }
}
.nav-tabs.nav-style-border .nav-item .nav-link {
  padding: 0 0 10px 0;
  color: #353535;
  font-size: 1.4rem;
  font-weight: 300;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 1180px) {
  .nav-tabs.nav-style-border .nav-item .nav-link {
    font-size: 1.19rem;
  }
}
.nav-tabs.nav-style-border .nav-item .nav-link .type-name {
  display: block;
}
/* .nav-tabs.nav-style-border .nav-item .nav-link:after {
  content: "";
  position: absolute;
  bottom: 14px;
  left: 0;
  height: 2px;
  background: #fd9801;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
} */
@media (prefers-reduced-motion: reduce) {
  .nav-tabs.nav-style-border .nav-item .nav-link:after {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 576px) {
  .nav-tabs.nav-style-border .nav-item .nav-link:after {
    bottom: -1px;
  }
}
.nav-tabs.nav-style-border .nav-item .nav-link.active:after {
  opacity: 1;
  visibility: visible;
}
.nav-tabs.nav-style-border .nav-item .nav-link.active {
  border-color: transparent;
  background-color: transparent;
  color: #353535;
  font-weight: 600;
}

.nav-tabs.nav-style-border .nav-item .nav-link:hover {
  border-color: transparent;
  background-color: transparent;
  color: #353535;
  font-weight: 600;
}
@media (min-width: 768px) {
  .nav-tabs.nav-style-border .nav-item:first-child {
    margin-bottom: 0.94rem;
  }
}
@media (min-width: 992px) {
  .nav-tabs.nav-style-border .nav-item:first-child {
    margin-bottom: 0;
  }
}
.nav-tabs.nav-style-border .nav-item:last-child {
  margin-bottom: 0;
}

/* Vertical Tabs */
.nav-tabs.nav-stacked {
  margin-right: 0;
  border-bottom: 0;
  border-right: none;
}
@media (min-width: 768px) {
  .nav-tabs.nav-stacked {
    border-right: 1px solid #f3f3f3;
    padding-right: 0;
  }
}

.nav-tabs.nav-stacked .nav-item {
  margin: 0 !important;
  float: none !important;
  display: block !important;
}
.nav-tabs.nav-stacked .nav-item .nav-link {
  padding: 12px 0.94rem;
  margin-right: 0px;
  border-right: 0px;
  margin-right: -1px;
  border-color: transparent;
}
.nav-tabs.nav-stacked .nav-item .nav-link i {
  padding-right: 0.625rem;
}
.nav-tabs.nav-stacked .nav-item .nav-link.active {
  border: 1px solid #f3f3f3;
}
@media (min-width: 768px) {
  .nav-tabs.nav-stacked .nav-item .nav-link.active {
    border-right: 0px;
    border-radius: 0;
    border-top-left-radius: 0.38rem;
    border-bottom-left-radius: 0.38rem;
  }
  .nav-tabs.nav-stacked .nav-item .nav-link.active:before {
    top: 0;
    width: 2px;
    height: 100%;
    bottom: 0px;
  }
}

/*===== TODO LIST ======*/
.todo-single-item {
  margin-bottom: 2.19em;
  position: relative;
}
.todo-single-item .task-content {
  white-space: nowrap;
  line-height: normal;
}
.todo-single-item .task-content span {
  margin-left: 1rem;
}
.todo-single-item > span {
  margin-left: 2rem;
  position: relative;
  display: inline-block;
  color: #000;
}
.todo-single-item > span.badge {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  display: inline-table;
}
@media (prefers-reduced-motion: reduce) {
  .todo-single-item > span.badge {
    -webkit-transition: none;
    transition: none;
  }
}
.todo-single-item.alert {
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
}

.todo-single-item i.mdi {
  width: 20px;
  height: 20px;
  border: 1px solid #d4d5db;
  border-radius: 50%;
  margin-right: 0.38rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  line-height: 1.3rem;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
@media (prefers-reduced-motion: reduce) {
  .todo-single-item i.mdi {
    -webkit-transition: none;
    transition: none;
  }
}
.todo-single-item i.mdi:hover {
  border-color: rgba(52, 201, 151, 0.5);
  background-color: rgba(52, 201, 151, 0.2);
  cursor: pointer;
}

.todo-input {
  display: none;
}
.todo-input input {
  margin-top: -0.31rem;
  border: none;
  font-size: 15px;
  padding: 0.312rem 0.625rem;
}
.todo-input input:active,
.todo-input input:focus {
  -webkit-box-shadow: 0px 2px 8px rgba(86, 96, 110, 0.2);
  box-shadow: 0px 2px 8px rgba(86, 96, 110, 0.2);
}

.todo-single-item.finished > span {
  color: #8a909d;
  text-decoration: line-through;
}
.todo-single-item.finished > span.badge {
  text-decoration: none;
  background-color: #cfd0d8;
  color: #8a909d;
}
.todo-single-item.finished i.mdi {
  background-color: transparent;
  border-color: #d4d5db;
}
.todo-single-item.finished i.mdi:after {
  content: "";
  font-size: 0.75rem;
  color: #34c997;
  font-family: "Material Design Icons";
  font-weight: bold;
}
.todo-single-item.finished .task-content span.badge {
  text-decoration: none;
  background-color: #cfd0d8;
  color: #8a909d;
}

.remove-task {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  fill: #cfd0d8;
  cursor: pointer;
}
.remove-task:hover {
  fill: rgba(236, 74, 88, 0.7);
}

.todo-form i.mdi {
  border-color: transparent;
  font-size: 20px;
  width: auto;
  height: auto;
  margin-right: 0;
  position: absolute;
  right: 0;
}
@media (min-width: 992px) {
  .todo-form i.mdi {
    font-size: 25px;
  }
}
.todo-form i.mdi:hover {
  border-color: transparent;
  background-color: transparent;
}

/*===== FONTS SIZE =====*/
.font-size-6 {
  font-size: 6px;
}

.font-size-8 {
  font-size: 8px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 0.857rem;
}

.font-size-13 {
  font-size: 0.928rem;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 1.143rem;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

/*===== TABLES =====*/
@media (max-width: 767.98px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

div.dataTables_wrapper div.dataTables_length select {
  margin: 0 5px;
  border-color: #f3f3f3;
  border-radius: 10px;
}
div.dataTables_wrapper div.dataTables_filter input {
  border-color: #f3f3f3;
  border-radius: 10px;
}

.bottom-information {
  margin-top: 15px !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 575.98px) {
  .dataTables_length {
    width: 100% !important;
    margin-bottom: 15px;
  }
}

@media (max-width: 575.98px) {
  #responsive-data-table_filter {
    width: 100% !important;
  }
}

@media (max-width: 575.98px) {
  .dataTables_info {
    display: none;
  }
}

.table > :not(:first-child) {
  border-top: 1px solid #787885;
}

table.dataTable td {
  vertical-align: middle;
}
table.dataTable td .dropdown-menu {
  top: 30px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
table.dataTable td .btn-group button {
  padding: 3px 10px;
}
table.dataTable td .btn-group button:first-child {
  border-radius: 15px 0 0 15px !important;
}
table.dataTable td .btn-group button:nth-last-child(-n + 2) {
  border-radius: 0 15px 15px 0 !important;
}

.ec-tbl .ec-tbl-content {
  overflow-y: auto;
}
.ec-tbl .ec-tbl-content table {
  min-width: 800px;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
  width: auto !important;
  padding: 0 !important;
}

.table-secondary td {
  background-color: #ffffff;
  color: #56606e;
}

.table-primary {
  background-color: rgba(136, 170, 243, 0.6);
  color: #56606e;
}

.table-info {
  background-color: rgba(36, 196, 218, 0.6);
  color: #56606e;
}

.table-success {
  background-color: rgba(52, 201, 151, 0.6);
  color: #56606e;
}

.table-danger {
  background-color: rgba(236, 74, 88, 0.6);
  color: #56606e;
}

.table-warning {
  background-color: rgba(221, 170, 4, 0.6);
  color: #56606e;
}

.table-dark thead th,
.table-dark tbody tr td:first-child {
  color: #ffffff;
}

/* Table borderless */
.table-borderless th,
.table-borderless td {
  border: 1px solid transparent;
}

.table-borderless thead th {
  border-bottom: 1px solid transparent;
  font-size: 0.81rem;
  color: #8a909d;
  font-weight: 400;
}

.table-borderless tbody tr a.link {
  color: #56606e;
}
.table-borderless tbody tr a.link:hover {
  color: #88aaf3;
}

/* Card Table */
.card-table {
  margin-top: 20px;
  margin-bottom: 0;
}
.card-table thead th {
  font-size: 0.88rem;
  font-weight: 400;
  padding: 0 1.9rem 1.25rem 0;
  color: #8a909d;
}
@media (min-width: 768px) {
  .card-table thead th {
    font-size: 1.06rem;
  }
}
.card-table tbody td {
  padding: 15px 15px 15px 0;
  vertical-align: middle;
  margin: 0.625rem 0 0.625rem;
  font-size: 0.81rem;
}
@media (min-width: 768px) {
  .card-table tbody td {
    font-size: 15px;
  }
}
.card-table tbody td a:hover {
  color: #88aaf3 !important;
}
.card-table tbody tr {
  position: relative;
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .card-table tbody tr {
    -webkit-transition: none;
    transition: none;
  }
}
.card-table tbody tr td:first-child {
  color: #8a909d;
}
.card-table .avater {
  width: 45px;
  height: 45px;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .table-responsive-large {
    display: inline-table;
  }
}

/* Customer Table */
.customer-table .media-image {
  width: 45px;
  height: 45px;
  overflow: hidden;
}
.customer-table .media-image img {
  max-width: 100%;
}
.customer-table .media-body h6,
.customer-table .media-body .h6 {
  font-size: 15px;
  font-weight: 500;
}

/* Notification Block */
.notification-table .table-header {
  margin-bottom: 1.9rem;
}
.notification-table .media {
  max-width: 70%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.notification-table .media-icon {
  color: #ffffff;
  font-size: 1rem;
}
.notification-table .media-icon.cat-1 {
  background-color: #88aaf3;
}
.notification-table .media-icon.cat-2 {
  background-color: #34c997;
}
.notification-table .media-icon.cat-3 {
  background-color: #ddaa04;
}
.notification-table .media-icon.cat-4 {
  background-color: #24c4da;
}
.notification-table .media-body a {
  font-size: 15px;
  color: #56606e;
  display: inline-block;
}
.notification-table .media-body a:hover {
  color: #88aaf3;
}
.notification-table .media-body p {
  font-size: 0.81rem;
}

/* Products Table */
.products-table .table-header {
  padding-bottom: 0.94rem;
}
.products-table table tr td {
  vertical-align: middle;
  padding-top: 1.9rem;
}
.products-table a.dropdown-toggle:after {
  display: none;
}
.products-table .media {
  margin-top: 2.19rem;
}
@media (min-width: 1200px) {
  .products-table .media {
    margin-top: 2.81rem;
  }
}
@media (min-width: 1500px) {
  .products-table .media {
    margin-top: 2.19rem;
  }
}
.products-table .media .media-body {
  padding-right: 1.25rem;
}
.products-table .media .media-body a {
  color: #56606e;
}
.products-table .media .media-body a h6,
.products-table .media .media-body a .h6 {
  font-size: 15px;
  margin-bottom: 0.625rem;
  font-weight: 500;
  color: inherit;
}
.products-table .media .media-body a:hover {
  color: #88aaf3;
}
.products-table .media .media-body > p {
  margin-bottom: 0.31rem;
  line-height: 1.5;
}

/* Data Tables */
.expendable-data-table,
.basic-data-table {
  overflow-x: scroll;
}
@media (min-width: 1500px) {
  .expendable-data-table,
  .basic-data-table {
    overflow-x: hidden;
  }
}

.expendable-data-table .table,
.basic-data-table .table,
.responsive-data-table .table {
  border-top: 0;
  margin: 25px 0 !important;
}
.expendable-data-table .table.table-borderless thead th,
.basic-data-table .table.table-borderless thead th,
.responsive-data-table .table.table-borderless thead th {
  border-bottom: 1px solid transparent;
}
.expendable-data-table .table-bordered thead th,
.expendable-data-table .table-bordered thead td,
.basic-data-table .table-bordered thead th,
.basic-data-table .table-bordered thead td,
.responsive-data-table .table-bordered thead th,
.responsive-data-table .table-bordered thead td {
  border-bottom-width: 1px;
}
.expendable-data-table .table thead th,
.basic-data-table .table thead th,
.responsive-data-table .table thead th {
  border-bottom: 1px solid #f3f3f3;
}
.expendable-data-table thead th,
.expendable-data-table tbody tr td:first-child,
.basic-data-table thead th,
.basic-data-table tbody tr td:first-child,
.responsive-data-table thead th,
.responsive-data-table tbody tr td:first-child {
  color: #1b223c;
  font-weight: 500;
  font-size: 14px;
}
.expendable-data-table table.dataTable > tbody > tr.child span.dtr-title,
.basic-data-table table.dataTable > tbody > tr.child span.dtr-title,
.responsive-data-table table.dataTable > tbody > tr.child span.dtr-title {
  font-weight: 500;
}
.expendable-data-table .top-information,
.expendable-data-table .bottom-information,
.basic-data-table .top-information,
.basic-data-table .bottom-information,
.responsive-data-table .top-information,
.responsive-data-table .bottom-information {
  padding: 0 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.expendable-data-table .dataTables_filter,
.basic-data-table .dataTables_filter,
.responsive-data-table .dataTables_filter {
  display: inline-block !important;
}
.expendable-data-table .dataTables_filter label,
.basic-data-table .dataTables_filter label,
.responsive-data-table .dataTables_filter label {
  margin-bottom: 0;
}
.expendable-data-table .dataTables_filter label .form-control-sm,
.basic-data-table .dataTables_filter label .form-control-sm,
.responsive-data-table .dataTables_filter label .form-control-sm {
  height: 30px;
  border-radius: 10px;
}
.expendable-data-table .dataTables_length,
.basic-data-table .dataTables_length,
.responsive-data-table .dataTables_length {
  display: inline-block !important;
}
.expendable-data-table .dataTables_length label,
.basic-data-table .dataTables_length label,
.responsive-data-table .dataTables_length label {
  margin-bottom: 0;
}
.expendable-data-table .dataTables_length label .custom-select-sm,
.basic-data-table .dataTables_length label .custom-select-sm,
.responsive-data-table .dataTables_length label .custom-select-sm {
  height: 30px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 10px;
}
.expendable-data-table .dataTables_length .custom-select,
.basic-data-table .dataTables_length .custom-select,
.responsive-data-table .dataTables_length .custom-select {
  background: transparent;
}
.expendable-data-table .dataTables_info,
.basic-data-table .dataTables_info,
.responsive-data-table .dataTables_info {
  padding-top: 7px !important;
}
@media (min-width: 768px) {
  .expendable-data-table .dataTables_info,
  .basic-data-table .dataTables_info,
  .responsive-data-table .dataTables_info {
    margin-bottom: 0;
    opacity: 0;
  }
}
@media (min-width: 992px) {
  .expendable-data-table .dataTables_info,
  .basic-data-table .dataTables_info,
  .responsive-data-table .dataTables_info {
    padding-top: 1.2em !important;
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .expendable-data-table .dataTables_info,
  .basic-data-table .dataTables_info,
  .responsive-data-table .dataTables_info {
    padding-top: 1em !important;
  }
}
.expendable-data-table .pagination .disabled .page-link,
.basic-data-table .pagination .disabled .page-link,
.responsive-data-table .pagination .disabled .page-link {
  background-color: transparent !important;
}
.expendable-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
.expendable-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before,
.basic-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
.basic-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before,
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  background-color: #34c997;
  top: 14px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border: 0;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.expendable-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
.expendable-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before,
.basic-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
.basic-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before,
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before {
  background-color: #ddaa04;
}
.expendable-data-table table.dataTable.hover tbody tr:hover,
.expendable-data-table table.dataTable.display tbody tr:hover,
.basic-data-table table.dataTable.hover tbody tr:hover,
.basic-data-table table.dataTable.display tbody tr:hover,
.responsive-data-table table.dataTable.hover tbody tr:hover,
.responsive-data-table table.dataTable.display tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#basic-data-table_next .page-link,
#basic-data-table_previous .page-link,
#responsive-data-table_previous .page-link,
#responsive-data-table_next .page-link,
#expendable-data-table_previous .page-link,
#expendable-data-table_next .page-link {
  background-color: #e9ecef;
}

/* Responsive Data Tables */
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child,
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child {
  padding-left: 40px;
}
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
.responsive-data-table
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  left: 10px;
}

/* Expendable Data Tables */
.expendable-data-table .dataTable {
  margin: 25px 0 !important;
}
.expendable-data-table table.dataTable.display tbody tr.odd > .sorting_1,
.expendable-data-table table.dataTable.display tbody tr.even > .sorting_1,
.expendable-data-table
  table.dataTable.order-column.stripe
  tbody
  tr.odd
  > .sorting_1,
.expendable-data-table
  table.dataTable.order-column.stripe
  tbody
  tr.even
  > .sorting_1,
.expendable-data-table table.dataTable thead .sorting_asc,
.expendable-data-table table.dataTable thead .sorting {
  background-color: transparent;
  font-weight: 500;
  color: #56606e;
}
.expendable-data-table table.dataTable.display tbody tr.odd,
.expendable-data-table table.dataTable.display tbody tr:hover {
  background-color: transparent;
}
.expendable-data-table table.dataTable.display tbody td {
  border-top: 1px solid #f3f3f3;
}
.expendable-data-table
  .table-bordered.table.dataTable.display
  tbody
  tr[role]
  td {
  padding: 12px 12px;
}
.expendable-data-table table.dataTable.display tbody tr[role] td {
  border-top: 1px solid #f3f3f3;
  padding: 12px 0px;
}
.expendable-data-table .table-borderless.table.dataTable thead th,
.expendable-data-table .table-borderless.table.dataTable tbody td,
.expendable-data-table
  .table-borderless.table.dataTable.display
  tbody
  tr[role]
  td {
  border-top: 1px solid transparent;
}
.expendable-data-table .table-striped tbody tr[role]:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.expendable-data-table .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}
.expendable-data-table table.dataTable tbody td {
  border-top: 1px solid #f3f3f3;
}
.expendable-data-table table.dataTable thead th {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 12px 18px;
}
.expendable-data-table table.dataTable.no-footer {
  border-bottom: 1px solid transparent;
}
.expendable-data-table
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button {
  padding: 0;
  margin-left: -2px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(transparent)
  );
  background: linear-gradient(to bottom, transparent 0%, transparent 100%);
}
.expendable-data-table
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  border: 1px solid #ffffff;
}
.expendable-data-table
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.expendable-data-table .dataTables_wrapper .dataTables_paginate {
  padding-top: 0;
}
.expendable-data-table .table-bordered.table.dataTable thead .sorting_asc,
.expendable-data-table .table-bordered.table.dataTable thead .sorting {
  padding-left: 12px;
}
.expendable-data-table .table-bordered.table.dataTable.no-footer {
  border-bottom: 1px solid #f3f3f3;
}
.expendable-data-table table.dataTable thead .sorting_asc,
.expendable-data-table table.dataTable thead .sorting {
  background: transparent;
  padding-left: 0px;
}
.expendable-data-table table.dataTable thead th:first-child:before,
.expendable-data-table table.dataTable thead th:first-child:after {
  display: none;
}
.expendable-data-table table.dataTable thead .sorting:before,
.expendable-data-table table.dataTable thead .sorting_desc:before,
.expendable-data-table table.dataTable thead .sorting_asc:before {
  right: 2em;
}
.expendable-data-table table.dataTable thead .sorting:after,
.expendable-data-table table.dataTable thead .sorting_desc:after,
.expendable-data-table table.dataTable thead .sorting_asc:after {
  right: 1.5em;
}
.expendable-data-table .dataTables_filter,
.expendable-data-table .dataTables_length,
.expendable-data-table .dataTables_wrapper .dataTables_info {
  color: #8a909d;
}
.expendable-data-table .dataTables_filter,
.expendable-data-table .dataTables_info,
.expendable-data-table .dataTables_length {
  margin-top: 0;
  padding-top: 0 !important;
}
.expendable-data-table .top-information,
.expendable-data-table .bottom-information {
  padding: 0 16px;
}
.expendable-data-table th.details-control.sorting_disabled {
  width: 1px !important;
}
.expendable-data-table table.dataTable thead > tr > th.sorting_desc {
  padding-left: 0;
  font-weight: 500;
}
.expendable-data-table tr.shown td.details-control,
.expendable-data-table td.details-control {
  position: relative;
  background: transparent;
  cursor: pointer;
}
.expendable-data-table tr.shown td.details-control::before,
.expendable-data-table td.details-control::before {
  position: absolute;
  content: "+";
  font-family: "Courier New", Courier, monospace;
  top: 14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #34c997;
}
.expendable-data-table tr.shown td.details-control::before {
  content: "-";
  background-color: #ddaa04;
}
.expendable-data-table .table-hover tbody tr:hover {
  color: #56606e;
}
.expendable-data-table .table-hover.table.dataTable.hover tbody tr[role]:hover,
.expendable-data-table
  .table-hover.table.dataTable.display
  tbody
  tr[role]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

div.dataTables_wrapper div.dataTables_length,
div.dataTables_wrapper div.dataTables_filter {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

/*===== WIDGETS =====*/
.profile-content .profile-left-spacing {
  padding: 24px 0 24px 24px;
}
@media (max-width: 991.98px) {
  .profile-content .profile-left-spacing {
    padding: 24px 24px 0 24px;
  }
}
.profile-content .profile-left-spacing .btn {
  border-radius: 15px;
}
.profile-content .nav-tabs.nav-style-border {
  padding-bottom: 0;
}
.profile-content .nav-tabs.nav-style-border .nav-item:first-child {
  margin-bottom: 0;
}

.widget-block {
  position: relative;
  padding: 1.9rem;
  margin-bottom: 1.9rem;
}
.widget-block.bg-primary {
  border-color: #88aaf3;
}
.widget-block.bg-primary p {
  color: rgba(255, 255, 255, 0.7);
}
.widget-block.bg-primary .progress {
  background: rgba(255, 255, 255, 0.5);
}
.widget-block.bg-warning {
  border-color: #ddaa04;
}
.widget-block.bg-warning p {
  color: rgba(255, 255, 255, 0.7);
}
.widget-block.bg-warning .progress {
  background: rgba(255, 255, 255, 0.5);
}
.widget-block.bg-danger {
  border-color: #ec4a58;
}
.widget-block.bg-danger p {
  color: rgba(255, 255, 255, 0.7);
}
.widget-block.bg-danger .progress {
  background: rgba(255, 255, 255, 0.5);
}
.widget-block.bg-success {
  border-color: #34c997;
}
.widget-block.bg-success p {
  color: rgba(255, 255, 255, 0.7);
}
.widget-block.bg-success .progress {
  background: rgba(255, 255, 255, 0.5);
}
.widget-block i {
  font-size: 1.38rem;
}

.widget-chart {
  height: 70px;
}
.widget-chart #barChart {
  width: 100%;
  height: 100%;
}

.widget-media {
  position: relative;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
}
.widget-media .icon {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
}
.widget-media i {
  font-size: 26px;
}

.widget-profile .card-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.widget-profile .card-img img {
  width: 100%;
}

/* Widgets Dropdown */
.widget-dropdown .dropdown-toggle {
  color: #8a909d;
  text-transform: uppercase;
}
.widget-dropdown .dropdown-toggle.icon-burger-mini {
  font-size: 1.5rem;
  position: relative;
}
.widget-dropdown .dropdown-toggle.icon-burger-mini:after {
  display: none;
}
.widget-dropdown .dropdown-toggle.icon-burger-mini:before {
  font-family: "Material Design Icons";
  content: "";
}
.widget-dropdown .dropdown-menu {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  -webkit-box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
  box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
  min-width: auto;
  right: 0 !important;
  left: auto !important;
}
.widget-dropdown .dropdown-menu .dropdown-item {
  padding: 0.3125rem 1.25rem;
}
.widget-dropdown .dropdown-menu .dropdown-item a {
  font-size: 0.875rem;
  color: #8a909d;
  text-transform: capitalize;
}
.widget-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}
.widget-dropdown .dropdown-menu .dropdown-item:hover a {
  color: #88aaf3;
}
.compact-spacing .navbar {
  min-height: 60px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /* Navbar Right */
}
.compact-spacing .navbar .sidebar-offcanvas-toggle,
.compact-spacing .navbar .sidebar-toggle {
  height: 60px;
}
.compact-spacing .navbar .navbar-right .navbar-nav .user-menu .dropdown-toggle {
  line-height: 60px;
}
.compact-spacing
  .navbar
  .navbar-right
  .navbar-nav
  .notifications-menu
  .dropdown-toggle {
  line-height: 60px;
}
.compact-spacing .navbar .navbar-right .navbar-nav .right-sidebar-2-menu i {
  line-height: 60px;
}
.compact-spacing .github-link {
  top: -8px;
}
.compact-spacing .github-link i {
  top: 0px;
  right: 5px;
}
.compact-spacing .github-link svg {
  width: 69px;
  height: 69px;
}
.compact-spacing .ec-brand a {
  height: 62px;
  line-height: 62px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.compact-spacing #toast-container {
  margin-top: 50px;
}
.compact-spacing .right-sidebar-container-2 .right-sidebar-2-header {
  padding: 11.5px 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.compact-spacing.ec-header-fixed .ec-page-wrapper {
  padding-top: 3.875rem;
}
@media (min-width: 1200px) {
  .compact-spacing .content {
    padding: 24px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}
.compact-spacing .sidebar .sidebar-inner > li > a {
  padding: 12px 25px;
}
.compact-spacing .compact-units {
  height: 365px !important;
}
.compact-spacing .compact-notifications {
  height: 415px !important;
}
.compact-spacing .media-border-bottom {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.compact-spacing .card-table-border-none .card-header,
.compact-spacing .card-default .card-header {
  padding: 15px;
}
.compact-spacing .card-table-border-none .card-body,
.compact-spacing .card-default .card-body {
  padding: 15px;
}
.compact-spacing .card-table-border-none .card-footer,
.compact-spacing .card-default .card-footer {
  padding-left: 15px;
  padding-right: 15px;
}
.compact-spacing .card-table-border-none .card-footer a,
.compact-spacing .card-default .card-footer a {
  padding: 7px 0 !important;
}
.compact-spacing [analytics-data-height] {
  height: 573px;
}
.compact-spacing [polar-data-content] {
  margin-bottom: 20px;
}
.compact-spacing [Profile-data-height] {
  min-height: 520px;
}
.compact-spacing [activity-data-spacing] {
  margin-bottom: 39px;
}
.compact-spacing .profile-right-spacing {
  padding: 1.88rem 0 !important;
}
.compact-spacing .profile-right-spacing .nav-style-border {
  padding-left: 1.88rem !important;
  padding-right: 1.88rem !important;
}
.compact-spacing .profile-right-spacing .tab-content {
  padding-left: 1.88rem !important;
  padding-right: 1.88rem !important;
}
.compact-spacing .profile-right-spacing .tab-content .tab-pane-content {
  margin-top: 1.88rem !important;
}
.compact-spacing
  .profile-right-spacing
  .tab-content
  .tab-pane-content
  .form-group {
  margin-bottom: 1.5rem !important;
}
.compact-spacing .tab-widget,
.compact-spacing .timeline-media-spacing {
  margin-top: 15px !important;
}
.compact-spacing .invoice-wrapper {
  padding: 15px !important;
}
.compact-spacing .recent-orders .card-body {
  padding-bottom: 0 !important;
}
.compact-spacing .card-table {
  margin-top: 1rem;
}
.compact-spacing .copyright {
  padding-top: 1.35rem;
  padding-bottom: 1.35rem;
}
.compact-spacing .right-sidebar-2-body {
  padding: 0 15px;
}
.compact-spacing .right-sidebar-2-body .no-col-space {
  margin-bottom: 20px;
}

/** Options sidebar css **/
.ec-tools-sidebar-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 17;
}

.right-sidebar-2-body .no-col-space {
  border: solid 1px #eee !important;
  border-radius: 15px;
}

.right-sidebar-container-2 .btn-right-sidebar-2 {
  width: 50% !important;
  margin-right: 0 !important;
  border: 0;
  border-radius: 15px;
}
.right-sidebar-container-2 .btn-right-sidebar-2.btn-right-sidebar-2-active {
  background-color: #eee !important;
  color: #777777 !important;
  text-decoration: none !important;
  margin-right: 0 !important;
  border-radius: 15px !important;
}
.right-sidebar-container-2 .right-sidebar-2-select {
  background: #eee;
  color: #777777;
  border: none;
  border-radius: 15px;
}

.nav-tabs.nav-style-border .nav-item:not(:last-child):after {
  content: "";
  width: 1px;
  height: 25px;
  /* background: #000; */
  /* display: inline-block; */
  /* vertical-align: middle; */
  /* position: relative; */
  /* left: 20px; */
}

.card-wrap-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.card-wrap-inner {
  display: flex;
  flex-direction: row;
}
.card-default-machine {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: inherit;
  min-height: 486px;
  border-radius: 5px;
  box-shadow: 4px 6px 17px rgb(0 0 0 / 10%);
}

.card-footer-machine {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.flexible {
  flex-grow: 1;
}
@media (min-width: 480px) {
  .card-wrap-inner {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .card-wrap-inner {
    width: 33.33%;
  }
}

.push-right-blue {
  float: right;
  color: blue;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.2);
}
.preloader .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font: 14px arial;
}

#ec-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 999999999999;
}
#ec-overlay .loader_img {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999999999;
  background: rgba(255, 255, 255, 0.2) url("assets/img/loader.svg") no-repeat
    scroll 50% 49%;
  background-size: 150px;
  pointer-events: none;
  overflow: hidden;
}

.fixed-height-63 {
  height: 63vh;
  overflow-x: auto;
}

.card .qr-code {
  position: absolute !important;
  top: 10px;
  right: 10px;
}
.card .qr-code img {
  width: 37px;
  height: 37px;
}

.tooltip-box-sign {
  width: 22vh;
  height: auto;
  padding: 7px;
  margin-top: -13px;
  background: #ffffff;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 18%);
  border-radius: 0px 8.49057px 8.49057px 8.49057px;
  z-index: 1000;
  /* left: 95%; */
  top: 25%;
}

.tooltip-box-sign p {
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
  color: black;
  text-align: justify;
  text-justify: inter-word;
}

table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
  text-align: center;
}
table thead th {
  font-weight: 600 !important;
  color: #353535;
}

table .table-striped {
  /* color: #fff;
  background-color: #212529;
  border-color: #32383e; */
  text-align: center;
}

.rounded-15 {
  border-radius: 15px;
}

.see-more {
  display: none;
}
.text-container {
  display: grid;
}

.full-text {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}

.pointer-grab {
  cursor: grab;
}

.option-list {
  background-color: #fff;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}

.option-list:hover {
  /* background-color: #787885;  */
  background-color: rgb(120, 120, 133, 0.3);
  color: #000000;
  color: #000;
  cursor: grab;
}

.label-status {
  font-size: 8.92px;
  color: #353535;
}

.invisible-input{
  border: unset !important;
  background-color: inherit !important;
}


