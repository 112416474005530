@charset "UTF-8";

/* assets/fonts/poppins/Poppins-Thin.ttf */
.background-customer {
  background-image: url(assets/images/bg/customer-login.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  left: 0px;
  width: 100vw;
  min-height: 100vh;
}

.background-supplier {
  background-image: url(assets/images/bg/supplier-login.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Fjalla One";
  src: url("assets/fonts/fjalla/FjallaOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/oswald/Oswald-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/oswald/Oswald-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/oswald/Oswald-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/oswald/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/oswald/Oswald-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("assets/fonts/oswald/Oswald-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/*-------------------------------------------------
  Helper Class
---------------------------------------------------*/
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

body {
  margin: 0 auto;
  background-color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.625;
  position: relative;
  visibility: visible;
  color: #444444;
  letter-spacing: 0.4px;
  min-height: 100%;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0;
}

hr {
  margin: 10px 0;
  color: inherit;
  background-color: #ffffff;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
  color: #212121;
}

.h6 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
  color: #212121;
}

h5,
.h5 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  color: #212121;
}

.h5 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  color: #212121;
}

h4,
.h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.275rem + 0.3vw);
  color: #212121;
}

.h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.275rem + 0.3vw);
  color: #212121;
}

h3,
.h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.3rem + 0.6vw);
  color: #212121;
}

.h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.3rem + 0.6vw);
  color: #212121;
}

h2,
.h2 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.325rem + 0.9vw);
  color: #212121;
}

.h2 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.325rem + 0.9vw);
  color: #212121;
}

h1,
.h1 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.375rem + 1.5vw);
  color: #212121;
}

.h1 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.375rem + 1.5vw);
  color: #212121;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

p:last-child {
  margin-bottom: 0;
}

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
}

abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol {
  padding-left: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}

ol ol {
  margin-bottom: 0;
}

ol ul {
  margin-bottom: 0;
}

ul {
  padding-left: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}

ul ul {
  margin-bottom: 0;
}

ul ol {
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b {
  font-weight: bold;
}

small,
.small {
  font-size: 0.875rem;
}

.small {
  font-size: 0.875rem;
}

mark,
.mark {
  padding: 3.2px;
  background-color: #fcf8e3;
}

.mark {
  padding: 3.2px;
  background-color: #fcf8e3;
}

sub {
  position: relative;
  font-size: 0.75rem;
  line-height: 0;
  vertical-align: baseline;
  bottom: -0.25em;
}

sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 0;
  vertical-align: baseline;
  top: -0.5em;
}

a {
  color: #555;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

a > code {
  color: inherit;
}

pre {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875rem;
  -ms-overflow-style: scrollbar;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  font-size: 0.875rem;
  color: #d63384;
  word-wrap: break-word;
}

kbd {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  padding: 3.2px 6.4px;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: #444444;
  border-radius: 3.2px;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

thead {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

tbody {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

tfoot {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

td {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
  margin-bottom: 15px;
}

button {
  border-radius: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  background-color: transparent;
  border: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button:not(:disabled) {
  cursor: pointer;
}

input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  display: inline-block;
  line-height: 50px;
  height: 50px;
  font-size: 15px;
  width: 100%;
  background: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-left: 20px;
  padding-right: 10px;
  outline: 0;
}

input:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
  -webkit-box-shadow: none;
  box-shadow: none;
}

select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  word-wrap: normal;
}

optgroup {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  resize: vertical;
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  outline: 0;
}

[role="button"] {
  cursor: pointer;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type="button"] {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled) {
  cursor: pointer;
}

[type="reset"] {
  -webkit-appearance: button;
}

[type="reset"]:not(:disabled) {
  cursor: pointer;
}

[type="submit"] {
  -webkit-appearance: button;
}

[type="submit"]:not(:disabled) {
  cursor: pointer;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 8px;
}

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 4px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 5px;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: #6c757d;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-md {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: -15px;
  margin-left: -15px;
} */

/* .row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 0;
} */

.col {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.row-cols-auto > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0 {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.gy-0 {
  --bs-gutter-y: 0;
}

.g-1 {
  --bs-gutter-x: 5px;
  --bs-gutter-y: 5px;
}

.gx-1 {
  --bs-gutter-x: 5px;
}

.gy-1 {
  --bs-gutter-y: 5px;
}

.g-2 {
  --bs-gutter-x: 10px;
  --bs-gutter-y: 10px;
}

.gx-2 {
  --bs-gutter-x: 10px;
}

.gy-2 {
  --bs-gutter-y: 10px;
}

.g-3 {
  --bs-gutter-x: 15px;
  --bs-gutter-y: 15px;
}

.gx-3 {
  --bs-gutter-x: 15px;
}

.gy-3 {
  --bs-gutter-y: 15px;
}

.g-4 {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
}

.gx-4 {
  --bs-gutter-x: 20px;
}

.gy-4 {
  --bs-gutter-y: 20px;
}

.g-5 {
  --bs-gutter-x: 25px;
  --bs-gutter-y: 25px;
}

.gx-5 {
  --bs-gutter-x: 25px;
}

.gy-5 {
  --bs-gutter-y: 25px;
}

.g-6 {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 30px;
}

.gx-6 {
  --bs-gutter-x: 30px;
}

.gy-6 {
  --bs-gutter-y: 30px;
}

.g-7 {
  --bs-gutter-x: 35px;
  --bs-gutter-y: 35px;
}

.gx-7 {
  --bs-gutter-x: 35px;
}

.gy-7 {
  --bs-gutter-y: 35px;
}

.g-8 {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 40px;
}

.gx-8 {
  --bs-gutter-x: 40px;
}

.gy-8 {
  --bs-gutter-y: 40px;
}

.g-9 {
  --bs-gutter-x: 45px;
  --bs-gutter-y: 45px;
}

.gx-9 {
  --bs-gutter-x: 45px;
}

.gy-9 {
  --bs-gutter-y: 45px;
}

.g-10 {
  --bs-gutter-x: 50px;
  --bs-gutter-y: 50px;
}

.gx-10 {
  --bs-gutter-x: 50px;
}

.gy-10 {
  --bs-gutter-y: 50px;
}

.table-primary {
  color: #000000;
  border-color: #e6c2a7;
}

.table-secondary {
  color: #000000;
  border-color: #c1c2c5;
}

.table-success {
  color: #000000;
  border-color: #acc7ba;
}

.table-info {
  color: #000000;
  border-color: #a8d8e2;
}

.table-warning {
  color: #000000;
  border-color: #e6d6a7;
}

.table-danger {
  color: #000000;
  border-color: #a7a7a7;
}

.table-light {
  color: #000000;
  border-color: #dfe0e1;
}

.table-dark {
  color: #ffffff;
  border-color: #484e53;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.form-label {
  margin-bottom: 8px;
}

.col-form-label {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  min-height: 38px;
  padding: 6px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  display: block;
  width: 100%;
  min-height: 45px;
  padding: 6px 80px 6px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.625;
  color: #444444;
  border-radius: 5px;
  background-color: #ffffff;
}

.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #ffb984;
  outline: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  color: #495057;
  background-color: #ffffff;
  border-color: #ced4da;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 6px 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #444444;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: 34px;
  padding: 4px 8px;
  font-size: 0.875rem;
  border-radius: 3.2px;
}

.form-control-lg {
  min-height: 42px;
  padding: 8px 16px;
  font-size: 1.25rem;
  border-radius: 4.8px;
}

.form-control-color {
  max-width: 3rem;
  padding: 6px;
}

.form-control-color::-moz-color-swatch {
  border-radius: 4px;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 4px;
}

.form-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 28px 6px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343A40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select:focus {
  border-color: #ffb984;
  outline: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
}

.form-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-select[multiple] {
  height: auto;
  padding-right: 12px;
  background-image: none;
}

.form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 12px;
  background-image: none;
}

.form-select-sm {
  height: 34px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  font-size: 0.875rem;
}

.form-select-lg {
  height: 42px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 24px;
  padding-left: 24px;
  margin-bottom: 2px;
}

.form-check .form-check-input {
  float: left;
  margin-left: -24px;
}

.form-check-input {
  width: 16px;
  height: 16px;
  margin-top: -7.25px;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  -webkit-transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #ffb984;
  outline: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
}

.form-check-input:checked {
  background-color: #555;
  border-color: #555;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-check-input[type="checkbox"] {
  border-radius: 4px;
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #555;
  border-color: #555;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 40px;
}

.form-switch .form-check-input {
  width: 32px;
  margin-left: -40px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 32px;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffb984'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 16px;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:focus + .btn {
  outline: 0;
}

.btn-check:focus + .btn-primary {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

.btn-check:focus + .btn-secondary {
  color: #ffffff;
  background-color: #555;
  border-color: #555;
}

.btn-check:focus + .btn-success {
  color: #ffffff;
  background-color: #136740;
  border-color: #115c39;
  -webkit-box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
  box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
}

.btn-check:focus + .btn-info {
  color: #000000;
  background-color: #2fd3f4;
  border-color: #23d0f3;
  -webkit-box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
  box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
}

.btn-check:focus + .btn-warning {
  color: #000000;
  background-color: #ffcb2d;
  border-color: #ffc721;
  -webkit-box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
  box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
}

.btn-check:focus + .btn-danger {
  color: #ffffff;
  background-color: black;
  border-color: black;
  -webkit-box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
  box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
}

.btn-check:focus + .btn-light {
  color: #000000;
  background-color: white;
  border-color: white;
  -webkit-box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
  box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
}

.btn-check:focus + .btn-dark {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  -webkit-box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
}

.btn-check:focus + .btn-outline-primary {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
}

.btn-check:focus + .btn-outline-secondary {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
}

.btn-check:focus + .btn-outline-success {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
}

.btn-check:focus + .btn-outline-info {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
  box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
}

.btn-check:focus + .btn-outline-warning {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
}

.btn-check:focus + .btn-outline-danger {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
}

.btn-check:focus + .btn-outline-light {
  -webkit-box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
}

.btn-check:focus + .btn-outline-dark {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
}

.btn-check:checked + .btn-primary {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

.btn-check:checked + .btn-secondary {
  color: #ffffff;
  background-color: #555;
  border-color: #555;
}

.btn-check:checked + .btn-success {
  color: #ffffff;
  background-color: #115c39;
  border-color: #0f5133;
}

.btn-check:checked + .btn-success:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
  box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-info {
  color: #000000;
  background-color: #3cd5f4;
  border-color: #23d0f3;
}

.btn-check:checked + .btn-info:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
  box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-warning {
  color: #000000;
  background-color: #ffce3a;
  border-color: #ffc721;
}

.btn-check:checked + .btn-warning:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
  box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-danger {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.btn-check:checked + .btn-danger:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
  box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
}

.btn-check:checked + .btn-light {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-check:checked + .btn-light:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
  box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-dark {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-check:checked + .btn-dark:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
}

.btn-check:checked + .btn-outline-primary {
  color: #000000;
  background-color: #555;
  border-color: #555;
}

.btn-check:checked + .btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
}

.btn-check:checked + .btn-outline-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-success {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-info {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
  box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-warning {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-danger {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-check:checked + .btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
}

.btn-check:checked + .btn-outline-light {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:checked + .btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
}

.btn-check:active + .btn-primary {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

.btn-check:active + .btn-secondary {
  color: #ffffff;
  background-color: #555;
  border-color: #555;
}

.btn-check:active + .btn-success {
  color: #ffffff;
  background-color: #115c39;
  border-color: #0f5133;
}

.btn-check:active + .btn-success:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
  box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
}

.btn-check:active + .btn-info {
  color: #000000;
  background-color: #3cd5f4;
  border-color: #23d0f3;
}

.btn-check:active + .btn-info:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
  box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
}

.btn-check:active + .btn-warning {
  color: #000000;
  background-color: #ffce3a;
  border-color: #ffc721;
}

.btn-check:active + .btn-warning:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
  box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
}

.btn-check:active + .btn-danger {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.btn-check:active + .btn-danger:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
  box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
}

.btn-check:active + .btn-light {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-check:active + .btn-light:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
  box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
}

.btn-check:active + .btn-dark {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-check:active + .btn-dark:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
}

.btn-check:active + .btn-outline-primary {
  color: #000000;
  background-color: #555;
  border-color: #555;
}

.btn-check:active + .btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
}

.btn-check:active + .btn-outline-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:active + .btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
}

.btn-check:active + .btn-outline-success {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:active + .btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
}

.btn-check:active + .btn-outline-info {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:active + .btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
  box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
}

.btn-check:active + .btn-outline-warning {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:active + .btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
}

.btn-check:active + .btn-outline-danger {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-check:active + .btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
}

.btn-check:active + .btn-outline-light {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:active + .btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
}

.btn-check:active + .btn-outline-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:active + .btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
}

.form-file {
  --bs-form-file-height: 38px;
  position: relative;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus-within ~ .form-file-label {
  border-color: #ffb984;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
}

.form-file-input:disabled ~ .form-file-label .form-file-text {
  background-color: #e9ecef;
}

.form-file-input[disabled] ~ .form-file-label .form-file-text {
  background-color: #e9ecef;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #ced4da;
  border-radius: 4px;
}

.form-file-text {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 6px 12px;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #ffffff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-file-sm {
  --bs-form-file-height: 34px;
  font-size: 0.875rem;
}

.form-file-sm .form-file-text {
  padding: 4px 8px;
}

.form-file-sm .form-file-button {
  padding: 4px 8px;
}

.form-file-lg {
  --bs-form-file-height: 42px;
  font-size: 1.25rem;
}

.form-file-lg .form-file-text {
  padding: 8px 16px;
}

.form-file-lg .form-file-button {
  padding: 8px 16px;
}

.form-range {
  width: 100%;
  height: 24px;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #ffffff, 0 0 0 4px rgba(255, 112, 4, 0.25);
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 4px rgba(255, 112, 4, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 4px rgba(255, 112, 4, 0.25);
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 4px rgba(255, 112, 4, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  background-color: #555;
  border: 0;
  border-radius: 16px;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.form-range::-webkit-slider-thumb:active {
  background-color: #ffd6b7;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  color: transparent;
  cursor: pointer;
  background-color: #eeeeee;
  border-color: transparent;
  border-radius: 16px;
}

.form-range::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #555;
  border: 0;
  border-radius: 16px;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

.form-range::-moz-range-thumb:active {
  background-color: #ffd6b7;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 8px;
  color: transparent;
  cursor: pointer;
  background-color: #eeeeee;
  border-color: transparent;
  border-radius: 16px;
}

.form-range::-ms-thumb {
  width: 16px;
  height: 16px;
  margin-top: 0;
  margin-right: 4px;
  margin-left: 4px;
  background-color: #555;
  border: 0;
  border-radius: 16px;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

.form-range::-ms-thumb:active {
  background-color: #ffd6b7;
}

.form-range::-ms-track {
  width: 100%;
  height: 8px;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 8px;
}

.form-range::-ms-fill-lower {
  background-color: #eeeeee;
  border-radius: 16px;
}

.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #eeeeee;
  border-radius: 16px;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.input-group-lg > .form-control {
  min-height: 42px;
  padding: 8px 16px;
  font-size: 1.25rem;
  border-radius: 4.8px;
}

.input-group-lg > .form-select {
  height: 42px;
  padding: 8px 16px;
  font-size: 1.25rem;
  border-radius: 4.8px;
  padding-right: 28px;
}

.input-group-lg > .input-group-text {
  padding: 8px 16px;
  font-size: 1.25rem;
  border-radius: 4.8px;
}

.input-group-lg > .btn {
  padding: 8px 16px;
  font-size: 1.25rem;
  border-radius: 4.8px;
}

.input-group-sm > .form-control {
  min-height: 34px;
  padding: 4px 8px;
  font-size: 0.875rem;
  border-radius: 3.2px;
}

.input-group-sm > .form-select {
  height: 34px;
  padding: 4px 8px;
  font-size: 0.875rem;
  border-radius: 3.2px;
  padding-right: 28px;
}

.input-group-sm > .input-group-text {
  padding: 4px 8px;
  font-size: 0.875rem;
  border-radius: 3.2px;
}

.input-group-sm > .btn {
  padding: 4px 8px;
  font-size: 0.875rem;
  border-radius: 3.2px;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 4px;
  font-size: 0.875rem;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 2.5px 5px;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 4px;
}

.was-validated:valid ~ .valid-feedback {
  display: block;
}

.was-validated:valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid {
  border-color: #198754;
  padding-right: 36px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 9px center;
  background-size: 18px 18px;
}

.was-validated .form-control:valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

.was-validated .form-control:invalid {
  border-color: #050505;
  padding-right: 36px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23050505' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23050505' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 9px center;
  background-size: 18px 18px;
}

.was-validated .form-control:invalid:focus {
  border-color: #050505;
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

.was-validated textarea.form-control:valid {
  padding-right: 36px;
  background-position: top 9px right 9px;
}

.was-validated textarea.form-control:invalid {
  padding-right: 36px;
  background-position: top 9px right 9px;
}

.was-validated .form-select:valid {
  border-color: #198754;
  padding-right: 49px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343A40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 12px center, center right 28px;
  background-size: 16px 12px, 18px 18px;
}

.was-validated .form-select:valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

.was-validated .form-select:invalid {
  border-color: #050505;
  padding-right: 49px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343A40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23050505' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23050505' stroke='none'/%3e%3c/svg%3e");
  background-position: right 12px center, center right 28px;
  background-size: 16px 12px, 18px 18px;
}

.was-validated .form-select:invalid:focus {
  border-color: #050505;
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

.was-validated .form-check-input:valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754;
}

.was-validated .form-check-input:invalid {
  border-color: #050505;
}

.was-validated .form-check-input:invalid:checked {
  background-color: #050505;
}

.was-validated .form-check-input:invalid:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #050505;
}

.was-validated .form-file-input:valid ~ .form-file-label {
  border-color: #198754;
}

.was-validated .form-file-input:valid:focus ~ .form-file-label {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

.was-validated .form-file-input:invalid ~ .form-file-label {
  border-color: #050505;
}

.was-validated .form-file-input:invalid:focus ~ .form-file-label {
  border-color: #050505;
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

.was-validated:invalid ~ .invalid-feedback {
  display: block;
}

.was-validated:invalid ~ .invalid-tooltip {
  display: block;
}

.is-valid ~ .valid-feedback {
  display: block;
}

.is-valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid {
  border-color: #198754;
  padding-right: 36px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 9px center;
  background-size: 18px 18px;
}

.form-control.is-valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

textarea.form-control.is-valid {
  padding-right: 36px;
  background-position: top 9px right 9px;
}

.form-select.is-valid {
  border-color: #198754;
  padding-right: 49px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343A40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 12px center, center right 28px;
  background-size: 16px 12px, 18px 18px;
}

.form-select.is-valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid {
  border-color: #198754;
}

.form-check-input.is-valid:checked {
  background-color: #198754;
}

.form-check-input.is-valid:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-file-input.is-valid ~ .form-file-label {
  border-color: #198754;
}

.form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 4px;
  font-size: 0.875rem;
  color: #050505;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 2.5px 5px;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(5, 5, 5, 0.9);
  border-radius: 4px;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

.is-invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid {
  border-color: #050505;
  padding-right: 36px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23050505' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23050505' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 9px center;
  background-size: 18px 18px;
}

.form-control.is-invalid:focus {
  border-color: #050505;
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

textarea.form-control.is-invalid {
  padding-right: 36px;
  background-position: top 9px right 9px;
}

.form-select.is-invalid {
  border-color: #050505;
  padding-right: 49px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343A40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23050505' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23050505' stroke='none'/%3e%3c/svg%3e");
  background-position: right 12px center, center right 28px;
  background-size: 16px 12px, 18px 18px;
}

.form-select.is-invalid:focus {
  border-color: #050505;
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

.form-check-input.is-invalid {
  border-color: #050505;
}

.form-check-input.is-invalid:checked {
  background-color: #050505;
}

.form-check-input.is-invalid:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label {
  color: #050505;
}

.form-file-input.is-invalid ~ .form-file-label {
  border-color: #050505;
}

.form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #050505;
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.25);
}

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #444444;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 4px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #444444;
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn.disabled {
  pointer-events: none;
  opacity: 0.65;
}

/* .btn-primary {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
} */

/* .btn-primary:hover {
    color: #ffffff;
    background-color: #555;
    border-color: #555;
} */

/* .btn-primary:focus {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
}

.btn-primary:active {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
} */

/* .btn-primary:disabled {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
} */

/* .btn-primary.active {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
} */

.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #555;
  border-color: #555;
}

.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #115c39;
  border-color: #0f5133;
}

.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
  box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
}

.show > .btn-info.dropdown-toggle {
  color: #000000;
  background-color: #3cd5f4;
  border-color: #23d0f3;
}

.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
  box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
}

.show > .btn-warning.dropdown-toggle {
  color: #000000;
  background-color: #ffce3a;
  border-color: #ffc721;
}

.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
  box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
}

.show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
  box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
}

.show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: white;
  border-color: white;
}

.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
  box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
}

.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
}

/* .btn-primary.disabled {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
} */

/* .btn-secondary {
    color: #ffffff;
    background-color: #555;
    border-color: #555;
} */

/* .btn-secondary:hover {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
}

.btn-secondary:focus {
    color: #ffffff;
    background-color: #555;
    border-color: #555;
}

.btn-secondary:active {
    color: #ffffff;
    background-color: #555;
    border-color: #555;
}

.btn-secondary:disabled {
    color: #ffffff;
    background-color: #6C757D;
    border-color: #6C757D;
}

.btn-secondary.active {
    color: #ffffff;
    background-color: #555;
    border-color: #555;
}

.btn-secondary.disabled {
    color: #ffffff;
    background-color: #6C757D;
    border-color: #6C757D;
} */

/* .btn-success {
    color: #ffffff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success:hover {
    color: #ffffff;
    background-color: #136740;
    border-color: #115c39;
}

.btn-success:focus {
    color: #ffffff;
    background-color: #136740;
    border-color: #115c39;
    -webkit-box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
    box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
}

.btn-success:active {
    color: #ffffff;
    background-color: #115c39;
    border-color: #0f5133;
}

.btn-success:active:focus {
    -webkit-box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
    box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
}

.btn-success:disabled {
    color: #ffffff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success.active {
    color: #ffffff;
    background-color: #115c39;
    border-color: #0f5133;
}

.btn-success.active:focus {
    -webkit-box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
    box-shadow: 0 0 0 4px rgba(60, 153, 110, 0.5);
}

.btn-success.disabled {
    color: #ffffff;
    background-color: #198754;
    border-color: #198754;
} */

.btn-info {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000000;
  background-color: #2fd3f4;
  border-color: #23d0f3;
}

.btn-info:focus {
  color: #000000;
  background-color: #2fd3f4;
  border-color: #23d0f3;
  -webkit-box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
  box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
}

.btn-info:active {
  color: #000000;
  background-color: #3cd5f4;
  border-color: #23d0f3;
}

.btn-info:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
  box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
}

.btn-info:disabled {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info.active {
  color: #000000;
  background-color: #3cd5f4;
  border-color: #23d0f3;
}

.btn-info.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
  box-shadow: 0 0 0 4px rgba(11, 172, 204, 0.5);
}

.btn-info.disabled {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000000;
  background-color: #ffcb2d;
  border-color: #ffc721;
}

.btn-warning:focus {
  color: #000000;
  background-color: #ffcb2d;
  border-color: #ffc721;
  -webkit-box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
  box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
}

.btn-warning:active {
  color: #000000;
  background-color: #ffce3a;
  border-color: #ffc721;
}

.btn-warning:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
  box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning.active {
  color: #000000;
  background-color: #ffce3a;
  border-color: #ffc721;
}

.btn-warning.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
  box-shadow: 0 0 0 4px rgba(217, 164, 6, 0.5);
}

.btn-warning.disabled {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.btn-danger:focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  -webkit-box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
  box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
}

.btn-danger:active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.btn-danger:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
  box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
}

.btn-danger:disabled {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-danger.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.btn-danger.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
  box-shadow: 0 0 0 4px rgba(43, 43, 43, 0.5);
}

.btn-danger.disabled {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-light {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-light:focus {
  color: #000000;
  background-color: white;
  border-color: white;
  -webkit-box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
  box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
}

.btn-light:active {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-light:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
  box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
}

.btn-light:disabled {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light.active {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-light.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
  box-shadow: 0 0 0 4px rgba(211, 212, 213, 0.5);
}

.btn-light.disabled {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  -webkit-box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
}

.btn-dark:active {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
}

.btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark.active {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 4px rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-primary {
  color: #555;
  border-color: #555;
}

.btn-outline-primary:hover {
  color: #000000;
  background-color: #555;
  border-color: #555;
}

.btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
}

.btn-outline-primary:active {
  color: #000000;
  background-color: #555;
  border-color: #555;
}

.btn-outline-primary:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
}

.btn-outline-primary:disabled {
  color: #555;
  background-color: transparent;
}

.btn-outline-primary.active {
  color: #000000;
  background-color: #555;
  border-color: #555;
}

.btn-outline-primary.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
}

.btn-outline-primary.dropdown-toggle.show {
  color: #000000;
  background-color: #555;
  border-color: #555;
}

.btn-outline-primary.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.5);
}

.btn-outline-primary.disabled {
  color: #555;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:active {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary.active {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
}

.btn-outline-success:active {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-outline-success:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-success.active {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-outline-success.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
}

.btn-outline-success.dropdown-toggle.show {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

.btn-outline-success.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
  box-shadow: 0 0 0 4px rgba(25, 135, 84, 0.5);
}

.btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
  box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
}

.btn-outline-info:active {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
  box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-info.active {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
  box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
}

.btn-outline-info.dropdown-toggle.show {
  color: #000000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
  box-shadow: 0 0 0 4px rgba(13, 202, 240, 0.5);
}

.btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:active {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning.active {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.dropdown-toggle.show {
  color: #000000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 4px rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #050505;
  border-color: #050505;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
}

.btn-outline-danger:active {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-outline-danger:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
}

.btn-outline-danger:disabled {
  color: #050505;
  background-color: transparent;
}

.btn-outline-danger.active {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-outline-danger.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
}

.btn-outline-danger.dropdown-toggle.show {
  color: #ffffff;
  background-color: #050505;
  border-color: #050505;
}

.btn-outline-danger.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
  box-shadow: 0 0 0 4px rgba(5, 5, 5, 0.5);
}

.btn-outline-danger.disabled {
  color: #050505;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
}

.btn-outline-light:active {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light.active {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
}

.btn-outline-light.dropdown-toggle.show {
  color: #000000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 4px rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:active {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark.active {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 4px rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #555;
  text-decoration: underline;
}

.btn-link:hover {
  color: #b74f00;
}

.btn-link:disabled {
  color: #6c757d;
}

.btn-link.disabled {
  color: #6c757d;
}

.btn-lg {
  padding: 8px 16px;
  font-size: 1.25rem;
  border-radius: 4.8px;
}

.btn-lg + .dropdown-toggle-split {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-group-lg > .btn {
  padding: 8px 16px;
  font-size: 1.25rem;
  border-radius: 4.8px;
}

.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-sm {
  padding: 4px 8px;
  font-size: 0.875rem;
  border-radius: 3.2px;
}

.btn-sm + .dropdown-toggle-split {
  padding-right: 6px;
  padding-left: 6px;
}

.btn-group-sm > .btn {
  padding: 4px 8px;
  font-size: 0.875rem;
  border-radius: 3.2px;
}

.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 6px;
  padding-left: 6px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 8px;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.dropup {
  position: relative;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 2px;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 0;
  border-right: 4.8px solid transparent;
  border-bottom: 4.8px solid;
  border-left: 4.8px solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropright {
  position: relative;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 2px;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 4.8px solid transparent;
  border-right: 0;
  border-bottom: 4.8px solid transparent;
  border-left: 4.8px solid;
  vertical-align: 0;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropdown {
  position: relative;
}

.dropleft {
  position: relative;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 2px;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 4.8px solid transparent;
  border-right: 4.8px solid;
  border-bottom: 4.8px solid transparent;
  vertical-align: 0;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 4.8px solid;
  border-right: 4.8px solid transparent;
  border-bottom: 0;
  border-left: 4.8px solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 8px 0;
  margin: 2px 0 0;
  font-size: 1rem;
  color: #444444;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}

.dropdown-menu[x-placement^="right"] {
  right: auto;
  bottom: auto;
}

.dropdown-menu[x-placement^="bottom"] {
  right: auto;
  bottom: auto;
}

.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 5px 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 2.5px 10px;
  clear: both;
  font-weight: 400;
  color: #444444;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover {
  color: #3a3a3a;
  background-color: #f8f9fa;
}

.dropdown-item:focus {
  color: #3a3a3a;
  background-color: #f8f9fa;
}

.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #555;
}

.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-item.active {
  color: #ffffff;
  text-decoration: none;
  background-color: #555;
}

.dropdown-item.disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 8px 10px;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 2.5px 10px;
  color: #444444;
}

.dropdown-menu-dark {
  color: #eeeeee;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #eeeeee;
}

.dropdown-menu-dark .dropdown-item:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: #555;
}

.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-item.active {
  color: #ffffff;
  background-color: #555;
}

.dropdown-menu-dark .dropdown-item.disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #eeeeee;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
}

.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group-vertical > .btn:focus {
  z-index: 1;
}

.btn-group-vertical > .btn:active {
  z-index: 1;
}

.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-check:checked + .btn {
  z-index: 1;
}

.btn-group-vertical > .btn-check:focus + .btn {
  z-index: 1;
}

.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.dropdown-toggle-split {
  padding-right: 9px;
  padding-left: 9px;
}

.dropdown-toggle-split::after {
  margin-left: 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #eeeeee;
}

.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #eeeeee;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #ffffff;
  border-color: #eeeeee #eeeeee #ffffff;
}

.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #eeeeee #eeeeee #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 4px;
}

.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #555;
}

.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #555;
}

.nav-fill > .nav-link {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.navbar > .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar > .container-sm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar > .container-md {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar > .container-lg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar > .container-xl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 16px;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 8px;
  padding-bottom: 8px;
}

.navbar-collapse {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 4px 12px;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: 0 0 0 4px;
  box-shadow: 0 0 0 4px;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-expand {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 8px;
  padding-left: 8px;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover {
  color: #ffffff;
}

.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a {
  color: #ffffff;
}

.navbar-dark .navbar-text a:hover {
  color: #ffffff;
}

.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #555;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  padding: 6px 12px;
}

.page-link:hover {
  z-index: 2;
  color: #b74f00;
  background-color: #e9ecef;
  border-color: #eeeeee;
}

.page-link:focus {
  z-index: 3;
  color: #b74f00;
  background-color: #e9ecef;
  outline: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #555;
  border-color: #555;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #eeeeee;
}

.pagination-lg .page-link {
  padding: 12px 24px;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 4.8px;
  border-bottom-left-radius: 4.8px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 4.8px;
  border-bottom-right-radius: 4.8px;
}

.pagination-sm .page-link {
  padding: 4px 8px;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 3.2px;
  border-bottom-left-radius: 3.2px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 3.2px;
  border-bottom-right-radius: 3.2px;
}

.badge {
  display: inline-block;
  padding: 5.6px 10.4px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
}

.badge:empty {
  display: none;
}

.alert {
  position: relative;
  padding: 10px 10px;
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 0.857rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 30px;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12.5px 10px;
}

.alert-primary {
  color: #853a02;
  background-color: #ffe2cd;
  border-color: #ffd7b9;
}

.alert-primary .alert-link {
  color: #532401;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #0d462c;
  background-color: #d1e7dd;
  border-color: #bfddcf;
}

.alert-success .alert-link {
  color: #051b11;
}

.alert-info {
  color: #07697d;
  background-color: #cff4fc;
  border-color: #bbf0fb;
}

.alert-info .alert-link {
  color: #04404d;
}

.alert-warning {
  color: #856404;
  background-color: #ffffff;
  border-color: #ffeeba;
}

.alert-warning .alert-link {
  color: #533f03;
}

/* .alert-danger {
  color: #030303;
  background-color: #cdcdcd;
  border-color: #b9b9b9;
} */
/* .alert-danger .alert-link {
  color: black;
} */

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark .alert-link {
  color: #040505;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 16px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 4px;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #555;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 16px 16px;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #444444;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-item.disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #555;
  border-color: #555;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #853a02;
  background-color: #ffd7b9;
}

.list-group-item-primary.list-group-item-action:hover {
  color: #853a02;
  background-color: #ffc8a0;
}

.list-group-item-primary.list-group-item-action:focus {
  color: #853a02;
  background-color: #ffc8a0;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #853a02;
  border-color: #853a02;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #0d462c;
  background-color: #bfddcf;
}

.list-group-item-success.list-group-item-action:hover {
  color: #0d462c;
  background-color: #aed4c3;
}

.list-group-item-success.list-group-item-action:focus {
  color: #0d462c;
  background-color: #aed4c3;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #0d462c;
  border-color: #0d462c;
}

.list-group-item-info {
  color: #07697d;
  background-color: #bbf0fb;
}

.list-group-item-info.list-group-item-action:hover {
  color: #07697d;
  background-color: #a3ebfa;
}

.list-group-item-info.list-group-item-action:focus {
  color: #07697d;
  background-color: #a3ebfa;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #07697d;
  border-color: #07697d;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #030303;
  background-color: #b9b9b9;
}

.list-group-item-danger.list-group-item-action:hover {
  color: #030303;
  background-color: #acacac;
}

.list-group-item-danger.list-group-item-action:focus {
  color: #030303;
  background-color: #acacac;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #030303;
  border-color: #030303;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.btn-close {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  padding: 4px 4px;
  color: #000000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    no-repeat center center/16px auto;
  background-clip: content-box;
  border: 0;
  border-radius: 4px;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  box-shadow: 0 0 0 4px rgba(255, 112, 4, 0.25);
  opacity: 1;
}

.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  opacity: 0;
  border-radius: 4px;
}

.toast:not(:last-child) {
  margin-bottom: 12px;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.toast-header .btn-close {
  margin-right: -6px;
  margin-left: 12px;
}

.toast-body {
  padding: 12px;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 8px;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 16px);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 16px);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 10px;
  border-bottom: 1px solid #eeeeee;
}

.modal-header .btn-close {
  padding: 5px 5px;
  margin: -5px -5px -5px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 20px;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 6px;
  border-top: 1px solid #eeeeee;
  border-bottom-right-radius: 3.8px;
  border-bottom-left-radius: 3.8px;
}

.modal-footer > * {
  margin: 4px;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 12.8px;
  height: 6.4px;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.tooltip.show {
  opacity: 0.9;
}

.bs-tooltip-top {
  padding: 6.4px 0;
}

.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before {
  top: 0;
  border-width: 6.4px 6.4px 0;
  border-top-color: #000000;
}

.bs-tooltip-auto[x-placement^="top"] {
  padding: 6.4px 0;
}

.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 6.4px 6.4px 0;
  border-top-color: #000000;
}

.bs-tooltip-right {
  padding: 0 6.4px;
}

.bs-tooltip-right .tooltip-arrow {
  left: 0;
  width: 6.4px;
  height: 12.8px;
}

.bs-tooltip-right .tooltip-arrow::before {
  right: 0;
  border-width: 6.4px 6.4px 6.4px 0;
  border-right-color: #000000;
}

.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 6.4px;
}

.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 6.4px;
  height: 12.8px;
}

.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
  right: 0;
  border-width: 6.4px 6.4px 6.4px 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom {
  padding: 6.4px 0;
}

.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 6.4px 6.4px;
  border-bottom-color: #fff;
}

.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 6.4px 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 6.4px 6.4px;
  border-bottom-color: #000000;
}

.bs-tooltip-left {
  padding: 0 6.4px;
}

.bs-tooltip-left .tooltip-arrow {
  right: 0;
  width: 6.4px;
  height: 12.8px;
}

.bs-tooltip-left .tooltip-arrow::before {
  left: 0;
  border-width: 6.4px 0 6.4px 6.4px;
  border-left-color: #000000;
}

.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 6.4px;
}

.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 6.4px;
  height: 12.8px;
}

.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
  left: 0;
  border-width: 6.4px 0 6.4px 6.4px;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 2.5px 5px;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 16px;
  height: 8px;
  margin: 0 4.8px;
}

.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top {
  margin-bottom: 8px;
}

.bs-popover-top > .popover-arrow {
  bottom: -9px;
}

.bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: 8px 8px 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: 8px 8px 0;
  border-top-color: #ffffff;
}

.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 8px;
}

.bs-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: -9px;
}

.bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 8px 8px 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 8px 8px 0;
  border-top-color: #ffffff;
}

.bs-popover-right {
  margin-left: 8px;
}

.bs-popover-right > .popover-arrow {
  left: -9px;
  width: 8px;
  height: 16px;
  margin: 4.8px 0;
}

.bs-popover-right > .popover-arrow::before {
  left: 0;
  border-width: 8px 8px 8px 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .popover-arrow::after {
  left: 1px;
  border-width: 8px 8px 8px 0;
  border-right-color: #ffffff;
}

.bs-popover-auto[x-placement^="right"] {
  margin-left: 8px;
}

.bs-popover-auto[x-placement^="right"] > .popover-arrow {
  left: -9px;
  width: 8px;
  height: 16px;
  margin: 4.8px 0;
}

.bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 8px 8px 8px 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 8px 8px 8px 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom {
  margin-top: 8px;
}

.bs-popover-bottom > .popover-arrow {
  top: -9px;
}

.bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 8px 8px 8px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 8px 8px 8px;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 16px;
  margin-left: -8px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 8px;
}

.bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: -9px;
}

.bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 8px 8px 8px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 8px 8px 8px;
  border-bottom-color: #ffffff;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 16px;
  margin-left: -8px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left {
  margin-right: 8px;
}

.bs-popover-left > .popover-arrow {
  right: -9px;
  width: 8px;
  height: 16px;
  margin: 4.8px 0;
}

.bs-popover-left > .popover-arrow::before {
  right: 0;
  border-width: 8px 0 8px 8px;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .popover-arrow::after {
  right: 1px;
  border-width: 8px 0 8px 8px;
  border-left-color: #ffffff;
}

.bs-popover-auto[x-placement^="left"] {
  margin-right: 8px;
}

.bs-popover-auto[x-placement^="left"] > .popover-arrow {
  right: -9px;
  width: 8px;
  height: 16px;
  margin: 4.8px 0;
}

.bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 8px 0 8px 8px;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 8px 0 8px 8px;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 8px 10px;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 3.8px;
  border-top-right-radius: 3.8px;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 10px 10px;
  color: #444444;
}
/*
.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.carousel-item.active {
  display: block;
}

.carousel-item-next {
  display: block;
}

.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev {
  display: block;
}

.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .carousel-item-next.carousel-item-left {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
  left: 0;
}

.carousel-control-prev:hover {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
  right: 0;
}

.carousel-control-next:hover {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

/* .carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-control-next-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators li {
  background-color: #000000;
}

.carousel-dark .carousel-caption {
  color: #000000;
} */

.spinner-border {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: text-bottom;
  border: 4px solid #ffffff;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 16px;
  height: 16px;
  border-width: 3.2px;
}

.spinner-grow {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: text-bottom;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 16px;
  height: 16px;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #555;
}

.link-primary:hover {
  color: #ff9c51;
}

.link-primary:focus {
  color: #ff9c51;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover {
  color: #494f54;
}

.link-secondary:focus {
  color: #494f54;
}

.link-success {
  color: #198754;
}

.link-success:hover {
  color: #0d462c;
}

.link-success:focus {
  color: #0d462c;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover {
  color: #54dbf6;
}

.link-info:focus {
  color: #54dbf6;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover {
  color: #ffd454;
}

.link-warning:focus {
  color: #ffd454;
}

.link-danger {
  color: #050505;
}

.link-danger:hover {
  color: black;
}

.link-danger:focus {
  color: black;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover {
  color: white;
}

.link-light:focus {
  color: white;
}

.link-dark {
  color: #343a40;
}

.link-dark:hover {
  color: #121416;
}

.link-dark:focus {
  color: #121416;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.visually-hidden-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
}

.shadow-none {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.left-0 {
  left: 0;
}

.left-50 {
  left: 50%;
}

.left-100 {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-50 {
  right: 50%;
}

.right-100 {
  right: 100%;
}

.translate-middle {
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.border {
  border: 1px solid #eeeeee;
}

.border-0 {
  border: 0;
  border-width: 0;
}

.border-top {
  border-top: 1px solid #eeeeee;
}

.border-top-0 {
  border-top: 0;
}

.border-right {
  border-right: 1px solid #eeeeee;
}

.border-right-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-left {
  border-left: 1px solid #eeeeee;
}

.border-left-0 {
  border-left: 0;
}

.border-primary {
  border-color: #555;
}

.border-secondary {
  border-color: #6c757d;
}

.border-success {
  border-color: #198754;
}

.border-info {
  border-color: #0dcaf0;
}

.border-warning {
  border-color: #ffc107;
}

.border-danger {
  border-color: #050505;
}

.border-light {
  border-color: #f8f9fa;
}

.border-dark {
  border-color: #343a40;
}

.border-white {
  border-color: #ffffff;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.flex-grow-0 {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.justify-content-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.align-items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-items-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.align-items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.align-self-auto {
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-self-center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-last {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 5px;
}

.m-2 {
  margin: 10px;
}

.m-3 {
  margin: 15px;
}

.m-4 {
  margin: 20px;
}

.m-5 {
  margin: 25px;
}

.m-6 {
  margin: 30px;
}

.m-7 {
  margin: 35px;
}

.m-8 {
  margin: 40px;
}

.m-9 {
  margin: 45px;
}

.m-10 {
  margin: 50px;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-2 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-3 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-4 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-5 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-6 {
  margin-right: 30px;
  margin-left: 30px;
}

.mx-7 {
  margin-right: 35px;
  margin-left: 35px;
}

.mx-8 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-9 {
  margin-right: 45px;
  margin-left: 45px;
}

.mx-10 {
  margin-right: 50px;
  margin-left: 50px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-5 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-6 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-7 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.my-8 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-9 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.my-10 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mt-6 {
  margin-top: 30px;
}

.mt-7 {
  margin-top: 35px;
}

.mt-8 {
  margin-top: 40px;
}

.mt-9 {
  margin-top: 45px;
}

.mt-10 {
  margin-top: 50px;
}

.mt-auto {
  margin-top: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.mr-6 {
  margin-right: 30px;
}

.mr-7 {
  margin-right: 35px;
}

.mr-8 {
  margin-right: 40px;
}

.mr-9 {
  margin-right: 45px;
}

.mr-10 {
  margin-right: 50px;
}

.mr-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.mb-6 {
  margin-bottom: 30px;
}

.mb-7 {
  margin-bottom: 35px;
}

.mb-8 {
  margin-bottom: 40px;
}

.mb-9 {
  margin-bottom: 45px;
}

.mb-10 {
  margin-bottom: 50px;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.ml-6 {
  margin-left: 30px;
}

.ml-7 {
  margin-left: 35px;
}

.ml-8 {
  margin-left: 40px;
}

.ml-9 {
  margin-left: 45px;
}

.ml-10 {
  margin-left: 50px;
}

.ml-auto {
  margin-left: auto;
}

.m-n1 {
  margin: -5px;
}

.m-n2 {
  margin: -10px;
}

.m-n3 {
  margin: -15px;
}

.m-n4 {
  margin: -20px;
}

.m-n5 {
  margin: -25px;
}

.m-n6 {
  margin: -30px;
}

.m-n7 {
  margin: -35px;
}

.m-n8 {
  margin: -40px;
}

.m-n9 {
  margin: -45px;
}

.m-n10 {
  margin: -50px;
}

.mx-n1 {
  margin-right: -5px;
  margin-left: -5px;
}

.mx-n2 {
  margin-right: -10px;
  margin-left: -10px;
}

.mx-n3 {
  margin-right: -15px;
  margin-left: -15px;
}

.mx-n4 {
  margin-right: -20px;
  margin-left: -20px;
}

.mx-n5 {
  margin-right: -25px;
  margin-left: -25px;
}

.mx-n6 {
  margin-right: -30px;
  margin-left: -30px;
}

.mx-n7 {
  margin-right: -35px;
  margin-left: -35px;
}

.mx-n8 {
  margin-right: -40px;
  margin-left: -40px;
}

.mx-n9 {
  margin-right: -45px;
  margin-left: -45px;
}

.mx-n10 {
  margin-right: -50px;
  margin-left: -50px;
}

.my-n1 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.my-n2 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.my-n3 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.my-n4 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.my-n5 {
  margin-top: -25px;
  margin-bottom: -25px;
}

.my-n6 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.my-n7 {
  margin-top: -35px;
  margin-bottom: -35px;
}

.my-n8 {
  margin-top: -40px;
  margin-bottom: -40px;
}

.my-n9 {
  margin-top: -45px;
  margin-bottom: -45px;
}

.my-n10 {
  margin-top: -50px;
  margin-bottom: -50px;
}

.mt-n1 {
  margin-top: -5px;
}

.mt-n2 {
  margin-top: -10px;
}

.mt-n3 {
  margin-top: -15px;
}

.mt-n4 {
  margin-top: -20px;
}

.mt-n5 {
  margin-top: -25px;
}

.mt-n6 {
  margin-top: -30px;
}

.mt-n7 {
  margin-top: -35px;
}

.mt-n8 {
  margin-top: -40px;
}

.mt-n9 {
  margin-top: -45px;
}

.mt-n10 {
  margin-top: -50px;
}

.mr-n1 {
  margin-right: -5px;
}

.mr-n2 {
  margin-right: -10px;
}

.mr-n3 {
  margin-right: -15px;
}

.mr-n4 {
  margin-right: -20px;
}

.mr-n5 {
  margin-right: -25px;
}

.mr-n6 {
  margin-right: -30px;
}

.mr-n7 {
  margin-right: -35px;
}

.mr-n8 {
  margin-right: -40px;
}

.mr-n9 {
  margin-right: -45px;
}

.mr-n10 {
  margin-right: -50px;
}

.mb-n1 {
  margin-bottom: -5px;
}

.mb-n2 {
  margin-bottom: -10px;
}

.mb-n3 {
  margin-bottom: -15px;
}

.mb-n4 {
  margin-bottom: -20px;
}

.mb-n5 {
  margin-bottom: -25px;
}

.mb-n6 {
  margin-bottom: -30px;
}

.mb-n7 {
  margin-bottom: -35px;
}

.mb-n8 {
  margin-bottom: -40px;
}

.mb-n9 {
  margin-bottom: -45px;
}

.mb-n10 {
  margin-bottom: -50px;
}

.ml-n1 {
  margin-left: -5px;
}

.ml-n2 {
  margin-left: -10px;
}

.ml-n3 {
  margin-left: -15px;
}

.ml-n4 {
  margin-left: -20px;
}

.ml-n5 {
  margin-left: -25px;
}

.ml-n6 {
  margin-left: -30px;
}

.ml-n7 {
  margin-left: -35px;
}

.ml-n8 {
  margin-left: -40px;
}

.ml-n9 {
  margin-left: -45px;
}

.ml-n10 {
  margin-left: -50px;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 15px;
}

.p-4 {
  padding: 20px;
}

.p-5 {
  padding: 25px;
}

.p-6 {
  padding: 30px;
}

.p-7 {
  padding: 35px;
}

.p-8 {
  padding: 40px;
}

.p-9 {
  padding: 45px;
}

.p-10 {
  padding: 50px;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-3 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-4 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-5 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-6 {
  padding-right: 30px;
  padding-left: 30px;
}

.px-7 {
  padding-right: 35px;
  padding-left: 35px;
}

.px-8 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-9 {
  padding-right: 45px;
  padding-left: 45px;
}

.px-10 {
  padding-right: 50px;
  padding-left: 50px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-4 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-5 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-6 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-7 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py-8 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-9 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.py-10 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.pt-5 {
  padding-top: 25px;
}

.pt-6 {
  padding-top: 30px;
}

.pt-7 {
  padding-top: 35px;
}

.pt-8 {
  padding-top: 40px;
}

.pt-9 {
  padding-top: 45px;
}

.pt-10 {
  padding-top: 50px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.pr-5 {
  padding-right: 25px;
}

.pr-6 {
  padding-right: 30px;
}

.pr-7 {
  padding-right: 35px;
}

.pr-8 {
  padding-right: 40px;
}

.pr-9 {
  padding-right: 45px;
}

.pr-10 {
  padding-right: 50px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-5 {
  padding-bottom: 25px;
}

.pb-6 {
  padding-bottom: 30px;
}

.pb-7 {
  padding-bottom: 35px;
}

.pb-8 {
  padding-bottom: 40px;
}

.pb-9 {
  padding-bottom: 45px;
}

.pb-10 {
  padding-bottom: 50px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pl-5 {
  padding-left: 25px;
}

.pl-6 {
  padding-left: 30px;
}

.pl-7 {
  padding-left: 35px;
}

.pl-8 {
  padding-left: 40px;
}

.pl-9 {
  padding-left: 45px;
}

.pl-10 {
  padding-left: 50px;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-bolder {
  font-weight: bolder;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: #555;
}

.text-orange {
  color: #fd9801;
}

.text-grey {
  color: #353535 !important;
  font-family: "Roboto";
}

.text-secondary {
  color: #6c757d;
}

.text-success {
  color: #198754;
}

.text-blue {
  color: #3051cc !important;
}

.text-info {
  color: #0dcaf0;
}

.text-warning {
  color: #ffc107;
}

.text-danger {
  color: #050505;
}

.text-light {
  color: #f8f9fa;
}

.text-dark {
  color: #343a40;
}

.text-white {
  color: #ffffff;
}

.text-body {
  color: #444444;
}

.text-muted {
  color: #6c757d;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.text-reset {
  color: inherit;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.bg-primary {
  background-color: #555;
}

.bg-secondary {
  background-color: #6c757d;
}

.bg-success {
  background-color: #198754;
}

.bg-info {
  background-color: #0dcaf0;
}

.bg-warning {
  background-color: #ffc107;
}

.bg-danger {
  background-color: #050505;
}

.bg-light {
  background-color: #f8f9fa;
}

.bg-dark {
  background-color: #343a40;
}

.bg-body {
  background-color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

.bg-transparent {
  background-color: transparent;
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.font-italic {
  font-style: italic;
}

.font-normal {
  font-style: normal;
}

.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.user-select-all {
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
}

.user-select-auto {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: 4px;
}

.rounded-sm {
  border-radius: 3.2px;
}

.rounded-lg {
  border-radius: 4.8px;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 800px;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rounded-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rounded-left {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

html {
  min-height: 100%;
}

body[dir="rtl"] {
  text-align: right;
}

li {
  list-style: none;
}

.img-responsive {
  max-width: 100%;
  display: block;
}

.pt-48px {
  padding-top: 48px;
}

.pt-100px {
  padding-top: 100px;
}

.pb-48px {
  padding-bottom: 48px;
}

.pt-70px {
  padding-top: 70px;
}

@media (min-width: 320px) {
  .container-sm {
    max-width: 100%;
  }

  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1 {
    --bs-gutter-x: 5px;
    --bs-gutter-y: 5px;
  }

  .gx-sm-1 {
    --bs-gutter-x: 5px;
  }

  .gy-sm-1 {
    --bs-gutter-y: 5px;
  }

  .g-sm-2 {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 10px;
  }

  .gx-sm-2 {
    --bs-gutter-x: 10px;
  }

  .gy-sm-2 {
    --bs-gutter-y: 10px;
  }

  .g-sm-3 {
    --bs-gutter-x: 15px;
    --bs-gutter-y: 15px;
  }

  .gx-sm-3 {
    --bs-gutter-x: 15px;
  }

  .gy-sm-3 {
    --bs-gutter-y: 15px;
  }

  .g-sm-4 {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
  }

  .gx-sm-4 {
    --bs-gutter-x: 20px;
  }

  .gy-sm-4 {
    --bs-gutter-y: 20px;
  }

  .g-sm-5 {
    --bs-gutter-x: 25px;
    --bs-gutter-y: 25px;
  }

  .gx-sm-5 {
    --bs-gutter-x: 25px;
  }

  .gy-sm-5 {
    --bs-gutter-y: 25px;
  }

  .g-sm-6 {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 30px;
  }

  .gx-sm-6 {
    --bs-gutter-x: 30px;
  }

  .gy-sm-6 {
    --bs-gutter-y: 30px;
  }

  .g-sm-7 {
    --bs-gutter-x: 35px;
    --bs-gutter-y: 35px;
  }

  .gx-sm-7 {
    --bs-gutter-x: 35px;
  }

  .gy-sm-7 {
    --bs-gutter-y: 35px;
  }

  .g-sm-8 {
    --bs-gutter-x: 40px;
    --bs-gutter-y: 40px;
  }

  .gx-sm-8 {
    --bs-gutter-x: 40px;
  }

  .gy-sm-8 {
    --bs-gutter-y: 40px;
  }

  .g-sm-9 {
    --bs-gutter-x: 45px;
    --bs-gutter-y: 45px;
  }

  .gx-sm-9 {
    --bs-gutter-x: 45px;
  }

  .gy-sm-9 {
    --bs-gutter-y: 45px;
  }

  .g-sm-10 {
    --bs-gutter-x: 50px;
    --bs-gutter-y: 50px;
  }

  .gx-sm-10 {
    --bs-gutter-x: 50px;
  }

  .gy-sm-10 {
    --bs-gutter-y: 50px;
  }

  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .modal-dialog {
    max-width: 500px;
    margin: 28px auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 56px);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 56px);
  }

  .modal-sm {
    max-width: 300px;
  }

  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .float-sm-left {
    float: left;
  }

  .float-sm-right {
    float: right;
  }

  .float-sm-none {
    float: none;
  }

  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-table {
    display: table;
  }

  .d-sm-table-row {
    display: table-row;
  }

  .d-sm-table-cell {
    display: table-cell;
  }

  .d-sm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-sm-none {
    display: none;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .flex-sm-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  .align-items-sm-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .align-items-sm-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .align-items-sm-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .m-sm-0 {
    margin: 0;
  }

  .m-sm-1 {
    margin: 5px;
  }

  .m-sm-2 {
    margin: 10px;
  }

  .m-sm-3 {
    margin: 15px;
  }

  .m-sm-4 {
    margin: 20px;
  }

  .m-sm-5 {
    margin: 25px;
  }

  .m-sm-6 {
    margin: 30px;
  }

  .m-sm-7 {
    margin: 35px;
  }

  .m-sm-8 {
    margin: 40px;
  }

  .m-sm-9 {
    margin: 45px;
  }

  .m-sm-10 {
    margin: 50px;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-sm-1 {
    margin-right: 5px;
    margin-left: 5px;
  }

  .mx-sm-2 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .mx-sm-3 {
    margin-right: 15px;
    margin-left: 15px;
  }

  .mx-sm-4 {
    margin-right: 20px;
    margin-left: 20px;
  }

  .mx-sm-5 {
    margin-right: 25px;
    margin-left: 25px;
  }

  .mx-sm-6 {
    margin-right: 30px;
    margin-left: 30px;
  }

  .mx-sm-7 {
    margin-right: 35px;
    margin-left: 35px;
  }

  .mx-sm-8 {
    margin-right: 40px;
    margin-left: 40px;
  }

  .mx-sm-9 {
    margin-right: 45px;
    margin-left: 45px;
  }

  .mx-sm-10 {
    margin-right: 50px;
    margin-left: 50px;
  }

  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-sm-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-sm-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my-sm-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .my-sm-4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-sm-5 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .my-sm-6 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .my-sm-7 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .my-sm-8 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-sm-9 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .my-sm-10 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mt-sm-1 {
    margin-top: 5px;
  }

  .mt-sm-2 {
    margin-top: 10px;
  }

  .mt-sm-3 {
    margin-top: 15px;
  }

  .mt-sm-4 {
    margin-top: 20px;
  }

  .mt-sm-5 {
    margin-top: 25px;
  }

  .mt-sm-6 {
    margin-top: 30px;
  }

  .mt-sm-7 {
    margin-top: 35px;
  }

  .mt-sm-8 {
    margin-top: 40px;
  }

  .mt-sm-9 {
    margin-top: 45px;
  }

  .mt-sm-10 {
    margin-top: 50px;
  }

  .mt-sm-auto {
    margin-top: auto;
  }

  .mr-sm-0 {
    margin-right: 0;
  }

  .mr-sm-1 {
    margin-right: 5px;
  }

  .mr-sm-2 {
    margin-right: 10px;
  }

  .mr-sm-3 {
    margin-right: 15px;
  }

  .mr-sm-4 {
    margin-right: 20px;
  }

  .mr-sm-5 {
    margin-right: 25px;
  }

  .mr-sm-6 {
    margin-right: 30px;
  }

  .mr-sm-7 {
    margin-right: 35px;
  }

  .mr-sm-8 {
    margin-right: 40px;
  }

  .mr-sm-9 {
    margin-right: 45px;
  }

  .mr-sm-10 {
    margin-right: 50px;
  }

  .mr-sm-auto {
    margin-right: auto;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  .mb-sm-1 {
    margin-bottom: 5px;
  }

  .mb-sm-2 {
    margin-bottom: 10px;
  }

  .mb-sm-3 {
    margin-bottom: 15px;
  }

  .mb-sm-4 {
    margin-bottom: 20px;
  }

  .mb-sm-5 {
    margin-bottom: 25px;
  }

  .mb-sm-6 {
    margin-bottom: 30px;
  }

  .mb-sm-7 {
    margin-bottom: 35px;
  }

  .mb-sm-8 {
    margin-bottom: 40px;
  }

  .mb-sm-9 {
    margin-bottom: 45px;
  }

  .mb-sm-10 {
    margin-bottom: 50px;
  }

  .mb-sm-auto {
    margin-bottom: auto;
  }

  .ml-sm-0 {
    margin-left: 0;
  }

  .ml-sm-1 {
    margin-left: 5px;
  }

  .ml-sm-2 {
    margin-left: 10px;
  }

  .ml-sm-3 {
    margin-left: 15px;
  }

  .ml-sm-4 {
    margin-left: 20px;
  }

  .ml-sm-5 {
    margin-left: 25px;
  }

  .ml-sm-6 {
    margin-left: 30px;
  }

  .ml-sm-7 {
    margin-left: 35px;
  }

  .ml-sm-8 {
    margin-left: 40px;
  }

  .ml-sm-9 {
    margin-left: 45px;
  }

  .ml-sm-10 {
    margin-left: 50px;
  }

  .ml-sm-auto {
    margin-left: auto;
  }

  .m-sm-n1 {
    margin: -5px;
  }

  .m-sm-n2 {
    margin: -10px;
  }

  .m-sm-n3 {
    margin: -15px;
  }

  .m-sm-n4 {
    margin: -20px;
  }

  .m-sm-n5 {
    margin: -25px;
  }

  .m-sm-n6 {
    margin: -30px;
  }

  .m-sm-n7 {
    margin: -35px;
  }

  .m-sm-n8 {
    margin: -40px;
  }

  .m-sm-n9 {
    margin: -45px;
  }

  .m-sm-n10 {
    margin: -50px;
  }

  .mx-sm-n1 {
    margin-right: -5px;
    margin-left: -5px;
  }

  .mx-sm-n2 {
    margin-right: -10px;
    margin-left: -10px;
  }

  .mx-sm-n3 {
    margin-right: -15px;
    margin-left: -15px;
  }

  .mx-sm-n4 {
    margin-right: -20px;
    margin-left: -20px;
  }

  .mx-sm-n5 {
    margin-right: -25px;
    margin-left: -25px;
  }

  .mx-sm-n6 {
    margin-right: -30px;
    margin-left: -30px;
  }

  .mx-sm-n7 {
    margin-right: -35px;
    margin-left: -35px;
  }

  .mx-sm-n8 {
    margin-right: -40px;
    margin-left: -40px;
  }

  .mx-sm-n9 {
    margin-right: -45px;
    margin-left: -45px;
  }

  .mx-sm-n10 {
    margin-right: -50px;
    margin-left: -50px;
  }

  .my-sm-n1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .my-sm-n2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .my-sm-n3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .my-sm-n4 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .my-sm-n5 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .my-sm-n6 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .my-sm-n7 {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .my-sm-n8 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .my-sm-n9 {
    margin-top: -45px;
    margin-bottom: -45px;
  }

  .my-sm-n10 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mt-sm-n1 {
    margin-top: -5px;
  }

  .mt-sm-n2 {
    margin-top: -10px;
  }

  .mt-sm-n3 {
    margin-top: -15px;
  }

  .mt-sm-n4 {
    margin-top: -20px;
  }

  .mt-sm-n5 {
    margin-top: -25px;
  }

  .mt-sm-n6 {
    margin-top: -30px;
  }

  .mt-sm-n7 {
    margin-top: -35px;
  }

  .mt-sm-n8 {
    margin-top: -40px;
  }

  .mt-sm-n9 {
    margin-top: -45px;
  }

  .mt-sm-n10 {
    margin-top: -50px;
  }

  .mr-sm-n1 {
    margin-right: -5px;
  }

  .mr-sm-n2 {
    margin-right: -10px;
  }

  .mr-sm-n3 {
    margin-right: -15px;
  }

  .mr-sm-n4 {
    margin-right: -20px;
  }

  .mr-sm-n5 {
    margin-right: -25px;
  }

  .mr-sm-n6 {
    margin-right: -30px;
  }

  .mr-sm-n7 {
    margin-right: -35px;
  }

  .mr-sm-n8 {
    margin-right: -40px;
  }

  .mr-sm-n9 {
    margin-right: -45px;
  }

  .mr-sm-n10 {
    margin-right: -50px;
  }

  .mb-sm-n1 {
    margin-bottom: -5px;
  }

  .mb-sm-n2 {
    margin-bottom: -10px;
  }

  .mb-sm-n3 {
    margin-bottom: -15px;
  }

  .mb-sm-n4 {
    margin-bottom: -20px;
  }

  .mb-sm-n5 {
    margin-bottom: -25px;
  }

  .mb-sm-n6 {
    margin-bottom: -30px;
  }

  .mb-sm-n7 {
    margin-bottom: -35px;
  }

  .mb-sm-n8 {
    margin-bottom: -40px;
  }

  .mb-sm-n9 {
    margin-bottom: -45px;
  }

  .mb-sm-n10 {
    margin-bottom: -50px;
  }

  .ml-sm-n1 {
    margin-left: -5px;
  }

  .ml-sm-n2 {
    margin-left: -10px;
  }

  .ml-sm-n3 {
    margin-left: -15px;
  }

  .ml-sm-n4 {
    margin-left: -20px;
  }

  .ml-sm-n5 {
    margin-left: -25px;
  }

  .ml-sm-n6 {
    margin-left: -30px;
  }

  .ml-sm-n7 {
    margin-left: -35px;
  }

  .ml-sm-n8 {
    margin-left: -40px;
  }

  .ml-sm-n9 {
    margin-left: -45px;
  }

  .ml-sm-n10 {
    margin-left: -50px;
  }

  .p-sm-0 {
    padding: 0;
  }

  .p-sm-1 {
    padding: 5px;
  }

  .p-sm-2 {
    padding: 10px;
  }

  .p-sm-3 {
    padding: 15px;
  }

  .p-sm-4 {
    padding: 20px;
  }

  .p-sm-5 {
    padding: 25px;
  }

  .p-sm-6 {
    padding: 30px;
  }

  .p-sm-7 {
    padding: 35px;
  }

  .p-sm-8 {
    padding: 40px;
  }

  .p-sm-9 {
    padding: 45px;
  }

  .p-sm-10 {
    padding: 50px;
  }

  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-sm-1 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .px-sm-2 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .px-sm-3 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .px-sm-4 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .px-sm-5 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .px-sm-6 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .px-sm-7 {
    padding-right: 35px;
    padding-left: 35px;
  }

  .px-sm-8 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .px-sm-9 {
    padding-right: 45px;
    padding-left: 45px;
  }

  .px-sm-10 {
    padding-right: 50px;
    padding-left: 50px;
  }

  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-sm-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .py-sm-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .py-sm-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .py-sm-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .py-sm-5 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .py-sm-6 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .py-sm-7 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .py-sm-8 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .py-sm-9 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .py-sm-10 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pt-sm-0 {
    padding-top: 0;
  }

  .pt-sm-1 {
    padding-top: 5px;
  }

  .pt-sm-2 {
    padding-top: 10px;
  }

  .pt-sm-3 {
    padding-top: 15px;
  }

  .pt-sm-4 {
    padding-top: 20px;
  }

  .pt-sm-5 {
    padding-top: 25px;
  }

  .pt-sm-6 {
    padding-top: 30px;
  }

  .pt-sm-7 {
    padding-top: 35px;
  }

  .pt-sm-8 {
    padding-top: 40px;
  }

  .pt-sm-9 {
    padding-top: 45px;
  }

  .pt-sm-10 {
    padding-top: 50px;
  }

  .pr-sm-0 {
    padding-right: 0;
  }

  .pr-sm-1 {
    padding-right: 5px;
  }

  .pr-sm-2 {
    padding-right: 10px;
  }

  .pr-sm-3 {
    padding-right: 15px;
  }

  .pr-sm-4 {
    padding-right: 20px;
  }

  .pr-sm-5 {
    padding-right: 25px;
  }

  .pr-sm-6 {
    padding-right: 30px;
  }

  .pr-sm-7 {
    padding-right: 35px;
  }

  .pr-sm-8 {
    padding-right: 40px;
  }

  .pr-sm-9 {
    padding-right: 45px;
  }

  .pr-sm-10 {
    padding-right: 50px;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .pb-sm-1 {
    padding-bottom: 5px;
  }

  .pb-sm-2 {
    padding-bottom: 10px;
  }

  .pb-sm-3 {
    padding-bottom: 15px;
  }

  .pb-sm-4 {
    padding-bottom: 20px;
  }

  .pb-sm-5 {
    padding-bottom: 25px;
  }

  .pb-sm-6 {
    padding-bottom: 30px;
  }

  .pb-sm-7 {
    padding-bottom: 35px;
  }

  .pb-sm-8 {
    padding-bottom: 40px;
  }

  .pb-sm-9 {
    padding-bottom: 45px;
  }

  .pb-sm-10 {
    padding-bottom: 50px;
  }

  .pl-sm-0 {
    padding-left: 0;
  }

  .pl-sm-1 {
    padding-left: 5px;
  }

  .pl-sm-2 {
    padding-left: 10px;
  }

  .pl-sm-3 {
    padding-left: 15px;
  }

  .pl-sm-4 {
    padding-left: 20px;
  }

  .pl-sm-5 {
    padding-left: 25px;
  }

  .pl-sm-6 {
    padding-left: 30px;
  }

  .pl-sm-7 {
    padding-left: 35px;
  }

  .pl-sm-8 {
    padding-left: 40px;
  }

  .pl-sm-9 {
    padding-left: 45px;
  }

  .pl-sm-10 {
    padding-left: 50px;
  }

  .text-sm-left {
    text-align: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .container-sm {
    padding-right: 15px;
    padding-left: 15px;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .container-md {
    max-width: 738px;
  }

  .container-sm {
    max-width: 738px;
  }

  .container {
    max-width: 738px;
  }

  .col-md {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1 {
    --bs-gutter-x: 5px;
    --bs-gutter-y: 5px;
  }

  .gx-md-1 {
    --bs-gutter-x: 5px;
  }

  .gy-md-1 {
    --bs-gutter-y: 5px;
  }

  .g-md-2 {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 10px;
  }

  .gx-md-2 {
    --bs-gutter-x: 10px;
  }

  .gy-md-2 {
    --bs-gutter-y: 10px;
  }

  .g-md-3 {
    --bs-gutter-x: 15px;
    --bs-gutter-y: 15px;
  }

  .gx-md-3 {
    --bs-gutter-x: 15px;
  }

  .gy-md-3 {
    --bs-gutter-y: 15px;
  }

  .g-md-4 {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
  }

  .gx-md-4 {
    --bs-gutter-x: 20px;
  }

  .gy-md-4 {
    --bs-gutter-y: 20px;
  }

  .g-md-5 {
    --bs-gutter-x: 25px;
    --bs-gutter-y: 25px;
  }

  .gx-md-5 {
    --bs-gutter-x: 25px;
  }

  .gy-md-5 {
    --bs-gutter-y: 25px;
  }

  .g-md-6 {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 30px;
  }

  .gx-md-6 {
    --bs-gutter-x: 30px;
  }

  .gy-md-6 {
    --bs-gutter-y: 30px;
  }

  .g-md-7 {
    --bs-gutter-x: 35px;
    --bs-gutter-y: 35px;
  }

  .gx-md-7 {
    --bs-gutter-x: 35px;
  }

  .gy-md-7 {
    --bs-gutter-y: 35px;
  }

  .g-md-8 {
    --bs-gutter-x: 40px;
    --bs-gutter-y: 40px;
  }

  .gx-md-8 {
    --bs-gutter-x: 40px;
  }

  .gy-md-8 {
    --bs-gutter-y: 40px;
  }

  .g-md-9 {
    --bs-gutter-x: 45px;
    --bs-gutter-y: 45px;
  }

  .gx-md-9 {
    --bs-gutter-x: 45px;
  }

  .gy-md-9 {
    --bs-gutter-y: 45px;
  }

  .g-md-10 {
    --bs-gutter-x: 50px;
    --bs-gutter-y: 50px;
  }

  .gx-md-10 {
    --bs-gutter-x: 50px;
  }

  .gy-md-10 {
    --bs-gutter-y: 50px;
  }

  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-md .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .float-md-left {
    float: left;
  }

  .float-md-right {
    float: right;
  }

  .float-md-none {
    float: none;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-table {
    display: table;
  }

  .d-md-table-row {
    display: table-row;
  }

  .d-md-table-cell {
    display: table-cell;
  }

  .d-md-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-md-none {
    display: none;
  }

  .flex-md-fill {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .flex-md-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .flex-md-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .justify-content-md-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .justify-content-md-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .justify-content-md-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  .align-items-md-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .align-items-md-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .align-items-md-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  .align-self-md-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .align-self-md-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }

  .align-self-md-center {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .m-md-0 {
    margin: 0;
  }

  .m-md-1 {
    margin: 5px;
  }

  .m-md-2 {
    margin: 10px;
  }

  .m-md-3 {
    margin: 15px;
  }

  .m-md-4 {
    margin: 20px;
  }

  .m-md-5 {
    margin: 25px;
  }

  .m-md-6 {
    margin: 30px;
  }

  .m-md-7 {
    margin: 35px;
  }

  .m-md-8 {
    margin: 40px;
  }

  .m-md-9 {
    margin: 45px;
  }

  .m-md-10 {
    margin: 50px;
  }

  .m-md-auto {
    margin: auto;
  }

  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-md-1 {
    margin-right: 5px;
    margin-left: 5px;
  }

  .mx-md-2 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .mx-md-3 {
    margin-right: 15px;
    margin-left: 15px;
  }

  .mx-md-4 {
    margin-right: 20px;
    margin-left: 20px;
  }

  .mx-md-5 {
    margin-right: 25px;
    margin-left: 25px;
  }

  .mx-md-6 {
    margin-right: 30px;
    margin-left: 30px;
  }

  .mx-md-7 {
    margin-right: 35px;
    margin-left: 35px;
  }

  .mx-md-8 {
    margin-right: 40px;
    margin-left: 40px;
  }

  .mx-md-9 {
    margin-right: 45px;
    margin-left: 45px;
  }

  .mx-md-10 {
    margin-right: 50px;
    margin-left: 50px;
  }

  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-md-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-md-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my-md-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .my-md-4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-md-5 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .my-md-6 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .my-md-7 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .my-md-8 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-md-9 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .my-md-10 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mt-md-1 {
    margin-top: 5px;
  }

  .mt-md-2 {
    margin-top: 10px;
  }

  .mt-md-3 {
    margin-top: 15px;
  }

  .mt-md-4 {
    margin-top: 20px;
  }

  .mt-md-5 {
    margin-top: 25px;
  }

  .mt-md-6 {
    margin-top: 30px;
  }

  .mt-md-7 {
    margin-top: 35px;
  }

  .mt-md-8 {
    margin-top: 40px;
  }

  .mt-md-9 {
    margin-top: 45px;
  }

  .mt-md-10 {
    margin-top: 50px;
  }

  .mt-md-auto {
    margin-top: auto;
  }

  .mr-md-0 {
    margin-right: 0;
  }

  .mr-md-1 {
    margin-right: 5px;
  }

  .mr-md-2 {
    margin-right: 10px;
  }

  .mr-md-3 {
    margin-right: 15px;
  }

  .mr-md-4 {
    margin-right: 20px;
  }

  .mr-md-5 {
    margin-right: 25px;
  }

  .mr-md-6 {
    margin-right: 30px;
  }

  .mr-md-7 {
    margin-right: 35px;
  }

  .mr-md-8 {
    margin-right: 40px;
  }

  .mr-md-9 {
    margin-right: 45px;
  }

  .mr-md-10 {
    margin-right: 50px;
  }

  .mr-md-auto {
    margin-right: auto;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .mb-md-1 {
    margin-bottom: 5px;
  }

  .mb-md-2 {
    margin-bottom: 10px;
  }

  .mb-md-3 {
    margin-bottom: 15px;
  }

  .mb-md-4 {
    margin-bottom: 20px;
  }

  .mb-md-5 {
    margin-bottom: 25px;
  }

  .mb-md-6 {
    margin-bottom: 30px;
  }

  .mb-md-7 {
    margin-bottom: 35px;
  }

  .mb-md-8 {
    margin-bottom: 40px;
  }

  .mb-md-9 {
    margin-bottom: 45px;
  }

  .mb-md-10 {
    margin-bottom: 50px;
  }

  .mb-md-auto {
    margin-bottom: auto;
  }

  .ml-md-0 {
    margin-left: 0;
  }

  .ml-md-1 {
    margin-left: 5px;
  }

  .ml-md-2 {
    margin-left: 10px;
  }

  .ml-md-3 {
    margin-left: 15px;
  }

  .ml-md-4 {
    margin-left: 20px;
  }

  .ml-md-5 {
    margin-left: 25px;
  }

  .ml-md-6 {
    margin-left: 30px;
  }

  .ml-md-7 {
    margin-left: 35px;
  }

  .ml-md-8 {
    margin-left: 40px;
  }

  .ml-md-9 {
    margin-left: 45px;
  }

  .ml-md-10 {
    margin-left: 50px;
  }

  .ml-md-auto {
    margin-left: auto;
  }

  .m-md-n1 {
    margin: -5px;
  }

  .m-md-n2 {
    margin: -10px;
  }

  .m-md-n3 {
    margin: -15px;
  }

  .m-md-n4 {
    margin: -20px;
  }

  .m-md-n5 {
    margin: -25px;
  }

  .m-md-n6 {
    margin: -30px;
  }

  .m-md-n7 {
    margin: -35px;
  }

  .m-md-n8 {
    margin: -40px;
  }

  .m-md-n9 {
    margin: -45px;
  }

  .m-md-n10 {
    margin: -50px;
  }

  .mx-md-n1 {
    margin-right: -5px;
    margin-left: -5px;
  }

  .mx-md-n2 {
    margin-right: -10px;
    margin-left: -10px;
  }

  .mx-md-n3 {
    margin-right: -15px;
    margin-left: -15px;
  }

  .mx-md-n4 {
    margin-right: -20px;
    margin-left: -20px;
  }

  .mx-md-n5 {
    margin-right: -25px;
    margin-left: -25px;
  }

  .mx-md-n6 {
    margin-right: -30px;
    margin-left: -30px;
  }

  .mx-md-n7 {
    margin-right: -35px;
    margin-left: -35px;
  }

  .mx-md-n8 {
    margin-right: -40px;
    margin-left: -40px;
  }

  .mx-md-n9 {
    margin-right: -45px;
    margin-left: -45px;
  }

  .mx-md-n10 {
    margin-right: -50px;
    margin-left: -50px;
  }

  .my-md-n1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .my-md-n2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .my-md-n3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .my-md-n4 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .my-md-n5 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .my-md-n6 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .my-md-n7 {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .my-md-n8 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .my-md-n9 {
    margin-top: -45px;
    margin-bottom: -45px;
  }

  .my-md-n10 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mt-md-n1 {
    margin-top: -5px;
  }

  .mt-md-n2 {
    margin-top: -10px;
  }

  .mt-md-n3 {
    margin-top: -15px;
  }

  .mt-md-n4 {
    margin-top: -20px;
  }

  .mt-md-n5 {
    margin-top: -25px;
  }

  .mt-md-n6 {
    margin-top: -30px;
  }

  .mt-md-n7 {
    margin-top: -35px;
  }

  .mt-md-n8 {
    margin-top: -40px;
  }

  .mt-md-n9 {
    margin-top: -45px;
  }

  .mt-md-n10 {
    margin-top: -50px;
  }

  .mr-md-n1 {
    margin-right: -5px;
  }

  .mr-md-n2 {
    margin-right: -10px;
  }

  .mr-md-n3 {
    margin-right: -15px;
  }

  .mr-md-n4 {
    margin-right: -20px;
  }

  .mr-md-n5 {
    margin-right: -25px;
  }

  .mr-md-n6 {
    margin-right: -30px;
  }

  .mr-md-n7 {
    margin-right: -35px;
  }

  .mr-md-n8 {
    margin-right: -40px;
  }

  .mr-md-n9 {
    margin-right: -45px;
  }

  .mr-md-n10 {
    margin-right: -50px;
  }

  .mb-md-n1 {
    margin-bottom: -5px;
  }

  .mb-md-n2 {
    margin-bottom: -10px;
  }

  .mb-md-n3 {
    margin-bottom: -15px;
  }

  .mb-md-n4 {
    margin-bottom: -20px;
  }

  .mb-md-n5 {
    margin-bottom: -25px;
  }

  .mb-md-n6 {
    margin-bottom: -30px;
  }

  .mb-md-n7 {
    margin-bottom: -35px;
  }

  .mb-md-n8 {
    margin-bottom: -40px;
  }

  .mb-md-n9 {
    margin-bottom: -45px;
  }

  .mb-md-n10 {
    margin-bottom: -50px;
  }

  .ml-md-n1 {
    margin-left: -5px;
  }

  .ml-md-n2 {
    margin-left: -10px;
  }

  .ml-md-n3 {
    margin-left: -15px;
  }

  .ml-md-n4 {
    margin-left: -20px;
  }

  .ml-md-n5 {
    margin-left: -25px;
  }

  .ml-md-n6 {
    margin-left: -30px;
  }

  .ml-md-n7 {
    margin-left: -35px;
  }

  .ml-md-n8 {
    margin-left: -40px;
  }

  .ml-md-n9 {
    margin-left: -45px;
  }

  .ml-md-n10 {
    margin-left: -50px;
  }

  .p-md-0 {
    padding: 0;
  }

  .p-md-1 {
    padding: 5px;
  }

  .p-md-2 {
    padding: 10px;
  }

  .p-md-3 {
    padding: 15px;
  }

  .p-md-4 {
    padding: 20px;
  }

  .p-md-5 {
    padding: 25px;
  }

  .p-md-6 {
    padding: 30px;
  }

  .p-md-7 {
    padding: 35px;
  }

  .p-md-8 {
    padding: 40px;
  }

  .p-md-9 {
    padding: 45px;
  }

  .p-md-10 {
    padding: 50px;
  }

  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-md-1 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .px-md-2 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .px-md-3 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .px-md-4 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .px-md-5 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .px-md-6 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .px-md-7 {
    padding-right: 35px;
    padding-left: 35px;
  }

  .px-md-8 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .px-md-9 {
    padding-right: 45px;
    padding-left: 45px;
  }

  .px-md-10 {
    padding-right: 50px;
    padding-left: 50px;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-md-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .py-md-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .py-md-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .py-md-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .py-md-5 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .py-md-6 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .py-md-7 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .py-md-8 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .py-md-9 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .py-md-10 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pt-md-0 {
    padding-top: 0;
  }

  .pt-md-1 {
    padding-top: 5px;
  }

  .pt-md-2 {
    padding-top: 10px;
  }

  .pt-md-3 {
    padding-top: 15px;
  }

  .pt-md-4 {
    padding-top: 20px;
  }

  .pt-md-5 {
    padding-top: 25px;
  }

  .pt-md-6 {
    padding-top: 30px;
  }

  .pt-md-7 {
    padding-top: 35px;
  }

  .pt-md-8 {
    padding-top: 40px;
  }

  .pt-md-9 {
    padding-top: 45px;
  }

  .pt-md-10 {
    padding-top: 50px;
  }

  .pr-md-0 {
    padding-right: 0;
  }

  .pr-md-1 {
    padding-right: 5px;
  }

  .pr-md-2 {
    padding-right: 10px;
  }

  .pr-md-3 {
    padding-right: 15px;
  }

  .pr-md-4 {
    padding-right: 20px;
  }

  .pr-md-5 {
    padding-right: 25px;
  }

  .pr-md-6 {
    padding-right: 30px;
  }

  .pr-md-7 {
    padding-right: 35px;
  }

  .pr-md-8 {
    padding-right: 40px;
  }

  .pr-md-9 {
    padding-right: 45px;
  }

  .pr-md-10 {
    padding-right: 50px;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }

  .pb-md-1 {
    padding-bottom: 5px;
  }

  .pb-md-2 {
    padding-bottom: 10px;
  }

  .pb-md-3 {
    padding-bottom: 15px;
  }

  .pb-md-4 {
    padding-bottom: 20px;
  }

  .pb-md-5 {
    padding-bottom: 25px;
  }

  .pb-md-6 {
    padding-bottom: 30px;
  }

  .pb-md-7 {
    padding-bottom: 35px;
  }

  .pb-md-8 {
    padding-bottom: 40px;
  }

  .pb-md-9 {
    padding-bottom: 45px;
  }

  .pb-md-10 {
    padding-bottom: 50px;
  }

  .pl-md-0 {
    padding-left: 0;
  }

  .pl-md-1 {
    padding-left: 5px;
  }

  .pl-md-2 {
    padding-left: 10px;
  }

  .pl-md-3 {
    padding-left: 15px;
  }

  .pl-md-4 {
    padding-left: 20px;
  }

  .pl-md-5 {
    padding-left: 25px;
  }

  .pl-md-6 {
    padding-left: 30px;
  }

  .pl-md-7 {
    padding-left: 35px;
  }

  .pl-md-8 {
    padding-left: 40px;
  }

  .pl-md-9 {
    padding-left: 45px;
  }

  .pl-md-10 {
    padding-left: 50px;
  }

  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px;
  }

  .container-md {
    max-width: 960px;
  }

  .container-sm {
    max-width: 960px;
  }

  .container {
    max-width: 960px;
  }

  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1 {
    --bs-gutter-x: 5px;
    --bs-gutter-y: 5px;
  }

  .gx-lg-1 {
    --bs-gutter-x: 5px;
  }

  .gy-lg-1 {
    --bs-gutter-y: 5px;
  }

  .g-lg-2 {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 10px;
  }

  .gx-lg-2 {
    --bs-gutter-x: 10px;
  }

  .gy-lg-2 {
    --bs-gutter-y: 10px;
  }

  .g-lg-3 {
    --bs-gutter-x: 15px;
    --bs-gutter-y: 15px;
  }

  .gx-lg-3 {
    --bs-gutter-x: 15px;
  }

  .gy-lg-3 {
    --bs-gutter-y: 15px;
  }

  .g-lg-4 {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
  }

  .gx-lg-4 {
    --bs-gutter-x: 20px;
  }

  .gy-lg-4 {
    --bs-gutter-y: 20px;
  }

  .g-lg-5 {
    --bs-gutter-x: 25px;
    --bs-gutter-y: 25px;
  }

  .gx-lg-5 {
    --bs-gutter-x: 25px;
  }

  .gy-lg-5 {
    --bs-gutter-y: 25px;
  }

  .g-lg-6 {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 30px;
  }

  .gx-lg-6 {
    --bs-gutter-x: 30px;
  }

  .gy-lg-6 {
    --bs-gutter-y: 30px;
  }

  .g-lg-7 {
    --bs-gutter-x: 35px;
    --bs-gutter-y: 35px;
  }

  .gx-lg-7 {
    --bs-gutter-x: 35px;
  }

  .gy-lg-7 {
    --bs-gutter-y: 35px;
  }

  .g-lg-8 {
    --bs-gutter-x: 40px;
    --bs-gutter-y: 40px;
  }

  .gx-lg-8 {
    --bs-gutter-x: 40px;
  }

  .gy-lg-8 {
    --bs-gutter-y: 40px;
  }

  .g-lg-9 {
    --bs-gutter-x: 45px;
    --bs-gutter-y: 45px;
  }

  .gx-lg-9 {
    --bs-gutter-x: 45px;
  }

  .gy-lg-9 {
    --bs-gutter-y: 45px;
  }

  .g-lg-10 {
    --bs-gutter-x: 50px;
    --bs-gutter-y: 50px;
  }

  .gx-lg-10 {
    --bs-gutter-x: 50px;
  }

  .gy-lg-10 {
    --bs-gutter-y: 50px;
  }

  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .modal-lg {
    max-width: 800px;
  }

  .modal-xl {
    max-width: 800px;
  }

  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .float-lg-left {
    float: left;
  }

  .float-lg-right {
    float: right;
  }

  .float-lg-none {
    float: none;
  }

  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-table {
    display: table;
  }

  .d-lg-table-row {
    display: table-row;
  }

  .d-lg-table-cell {
    display: table-cell;
  }

  .d-lg-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .flex-lg-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  .align-items-lg-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .align-items-lg-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .align-items-lg-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .m-lg-0 {
    margin: 0;
  }

  .m-lg-1 {
    margin: 5px;
  }

  .m-lg-2 {
    margin: 10px;
  }

  .m-lg-3 {
    margin: 15px;
  }

  .m-lg-4 {
    margin: 20px;
  }

  .m-lg-5 {
    margin: 25px;
  }

  .m-lg-6 {
    margin: 30px;
  }

  .m-lg-7 {
    margin: 35px;
  }

  .m-lg-8 {
    margin: 40px;
  }

  .m-lg-9 {
    margin: 45px;
  }

  .m-lg-10 {
    margin: 50px;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-lg-1 {
    margin-right: 5px;
    margin-left: 5px;
  }

  .mx-lg-2 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .mx-lg-3 {
    margin-right: 15px;
    margin-left: 15px;
  }

  .mx-lg-4 {
    margin-right: 20px;
    margin-left: 20px;
  }

  .mx-lg-5 {
    margin-right: 25px;
    margin-left: 25px;
  }

  .mx-lg-6 {
    margin-right: 30px;
    margin-left: 30px;
  }

  .mx-lg-7 {
    margin-right: 35px;
    margin-left: 35px;
  }

  .mx-lg-8 {
    margin-right: 40px;
    margin-left: 40px;
  }

  .mx-lg-9 {
    margin-right: 45px;
    margin-left: 45px;
  }

  .mx-lg-10 {
    margin-right: 50px;
    margin-left: 50px;
  }

  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-lg-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-lg-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my-lg-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .my-lg-4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-lg-5 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .my-lg-6 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .my-lg-7 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .my-lg-8 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-lg-9 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .my-lg-10 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .mt-lg-1 {
    margin-top: 5px;
  }

  .mt-lg-2 {
    margin-top: 10px;
  }

  .mt-lg-3 {
    margin-top: 15px;
  }

  .mt-lg-4 {
    margin-top: 20px;
  }

  .mt-lg-5 {
    margin-top: 25px;
  }

  .mt-lg-6 {
    margin-top: 30px;
  }

  .mt-lg-7 {
    margin-top: 35px;
  }

  .mt-lg-8 {
    margin-top: 40px;
  }

  .mt-lg-9 {
    margin-top: 45px;
  }

  .mt-lg-10 {
    margin-top: 50px;
  }

  .mt-lg-auto {
    margin-top: auto;
  }

  .mr-lg-0 {
    margin-right: 0;
  }

  .mr-lg-1 {
    margin-right: 5px;
  }

  .mr-lg-2 {
    margin-right: 10px;
  }

  .mr-lg-3 {
    margin-right: 15px;
  }

  .mr-lg-4 {
    margin-right: 20px;
  }

  .mr-lg-5 {
    margin-right: 25px;
  }

  .mr-lg-6 {
    margin-right: 30px;
  }

  .mr-lg-7 {
    margin-right: 35px;
  }

  .mr-lg-8 {
    margin-right: 40px;
  }

  .mr-lg-9 {
    margin-right: 45px;
  }

  .mr-lg-10 {
    margin-right: 50px;
  }

  .mr-lg-auto {
    margin-right: auto;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .mb-lg-1 {
    margin-bottom: 5px;
  }

  .mb-lg-2 {
    margin-bottom: 10px;
  }

  .mb-lg-3 {
    margin-bottom: 15px;
  }

  .mb-lg-4 {
    margin-bottom: 20px;
  }

  .mb-lg-5 {
    margin-bottom: 25px;
  }

  .mb-lg-6 {
    margin-bottom: 30px;
  }

  .mb-lg-7 {
    margin-bottom: 35px;
  }

  .mb-lg-8 {
    margin-bottom: 40px;
  }

  .mb-lg-9 {
    margin-bottom: 45px;
  }

  .mb-lg-10 {
    margin-bottom: 50px;
  }

  .mb-lg-auto {
    margin-bottom: auto;
  }

  .ml-lg-0 {
    margin-left: 0;
  }

  .ml-lg-1 {
    margin-left: 5px;
  }

  .ml-lg-2 {
    margin-left: 10px;
  }

  .ml-lg-3 {
    margin-left: 15px;
  }

  .ml-lg-4 {
    margin-left: 20px;
  }

  .ml-lg-5 {
    margin-left: 25px;
  }

  .ml-lg-6 {
    margin-left: 30px;
  }

  .ml-lg-7 {
    margin-left: 35px;
  }

  .ml-lg-8 {
    margin-left: 40px;
  }

  .ml-lg-9 {
    margin-left: 45px;
  }

  .ml-lg-10 {
    margin-left: 50px;
  }

  .ml-lg-auto {
    margin-left: auto;
  }

  .m-lg-n1 {
    margin: -5px;
  }

  .m-lg-n2 {
    margin: -10px;
  }

  .m-lg-n3 {
    margin: -15px;
  }

  .m-lg-n4 {
    margin: -20px;
  }

  .m-lg-n5 {
    margin: -25px;
  }

  .m-lg-n6 {
    margin: -30px;
  }

  .m-lg-n7 {
    margin: -35px;
  }

  .m-lg-n8 {
    margin: -40px;
  }

  .m-lg-n9 {
    margin: -45px;
  }

  .m-lg-n10 {
    margin: -50px;
  }

  .mx-lg-n1 {
    margin-right: -5px;
    margin-left: -5px;
  }

  .mx-lg-n2 {
    margin-right: -10px;
    margin-left: -10px;
  }

  .mx-lg-n3 {
    margin-right: -15px;
    margin-left: -15px;
  }

  .mx-lg-n4 {
    margin-right: -20px;
    margin-left: -20px;
  }

  .mx-lg-n5 {
    margin-right: -25px;
    margin-left: -25px;
  }

  .mx-lg-n6 {
    margin-right: -30px;
    margin-left: -30px;
  }

  .mx-lg-n7 {
    margin-right: -35px;
    margin-left: -35px;
  }

  .mx-lg-n8 {
    margin-right: -40px;
    margin-left: -40px;
  }

  .mx-lg-n9 {
    margin-right: -45px;
    margin-left: -45px;
  }

  .mx-lg-n10 {
    margin-right: -50px;
    margin-left: -50px;
  }

  .my-lg-n1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .my-lg-n2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .my-lg-n3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .my-lg-n4 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .my-lg-n5 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .my-lg-n6 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .my-lg-n7 {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .my-lg-n8 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .my-lg-n9 {
    margin-top: -45px;
    margin-bottom: -45px;
  }

  .my-lg-n10 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mt-lg-n1 {
    margin-top: -5px;
  }

  .mt-lg-n2 {
    margin-top: -10px;
  }

  .mt-lg-n3 {
    margin-top: -15px;
  }

  .mt-lg-n4 {
    margin-top: -20px;
  }

  .mt-lg-n5 {
    margin-top: -25px;
  }

  .mt-lg-n6 {
    margin-top: -30px;
  }

  .mt-lg-n7 {
    margin-top: -35px;
  }

  .mt-lg-n8 {
    margin-top: -40px;
  }

  .mt-lg-n9 {
    margin-top: -45px;
  }

  .mt-lg-n10 {
    margin-top: -50px;
  }

  .mr-lg-n1 {
    margin-right: -5px;
  }

  .mr-lg-n2 {
    margin-right: -10px;
  }

  .mr-lg-n3 {
    margin-right: -15px;
  }

  .mr-lg-n4 {
    margin-right: -20px;
  }

  .mr-lg-n5 {
    margin-right: -25px;
  }

  .mr-lg-n6 {
    margin-right: -30px;
  }

  .mr-lg-n7 {
    margin-right: -35px;
  }

  .mr-lg-n8 {
    margin-right: -40px;
  }

  .mr-lg-n9 {
    margin-right: -45px;
  }

  .mr-lg-n10 {
    margin-right: -50px;
  }

  .mb-lg-n1 {
    margin-bottom: -5px;
  }

  .mb-lg-n2 {
    margin-bottom: -10px;
  }

  .mb-lg-n3 {
    margin-bottom: -15px;
  }

  .mb-lg-n4 {
    margin-bottom: -20px;
  }

  .mb-lg-n5 {
    margin-bottom: -25px;
  }

  .mb-lg-n6 {
    margin-bottom: -30px;
  }

  .mb-lg-n7 {
    margin-bottom: -35px;
  }

  .mb-lg-n8 {
    margin-bottom: -40px;
  }

  .mb-lg-n9 {
    margin-bottom: -45px;
  }

  .mb-lg-n10 {
    margin-bottom: -50px;
  }

  .ml-lg-n1 {
    margin-left: -5px;
  }

  .ml-lg-n2 {
    margin-left: -10px;
  }

  .ml-lg-n3 {
    margin-left: -15px;
  }

  .ml-lg-n4 {
    margin-left: -20px;
  }

  .ml-lg-n5 {
    margin-left: -25px;
  }

  .ml-lg-n6 {
    margin-left: -30px;
  }

  .ml-lg-n7 {
    margin-left: -35px;
  }

  .ml-lg-n8 {
    margin-left: -40px;
  }

  .ml-lg-n9 {
    margin-left: -45px;
  }

  .ml-lg-n10 {
    margin-left: -50px;
  }

  .p-lg-0 {
    padding: 0;
  }

  .p-lg-1 {
    padding: 5px;
  }

  .p-lg-2 {
    padding: 10px;
  }

  .p-lg-3 {
    padding: 15px;
  }

  .p-lg-4 {
    padding: 20px;
  }

  .p-lg-5 {
    padding: 25px;
  }

  .p-lg-6 {
    padding: 30px;
  }

  .p-lg-7 {
    padding: 35px;
  }

  .p-lg-8 {
    padding: 40px;
  }

  .p-lg-9 {
    padding: 45px;
  }

  .p-lg-10 {
    padding: 50px;
  }

  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-lg-1 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .px-lg-2 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .px-lg-3 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .px-lg-4 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .px-lg-5 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .px-lg-6 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .px-lg-7 {
    padding-right: 35px;
    padding-left: 35px;
  }

  .px-lg-8 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .px-lg-9 {
    padding-right: 45px;
    padding-left: 45px;
  }

  .px-lg-10 {
    padding-right: 50px;
    padding-left: 50px;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-lg-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .py-lg-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .py-lg-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .py-lg-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .py-lg-5 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .py-lg-6 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .py-lg-7 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .py-lg-8 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .py-lg-9 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .py-lg-10 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pt-lg-0 {
    padding-top: 0;
  }

  .pt-lg-1 {
    padding-top: 5px;
  }

  .pt-lg-2 {
    padding-top: 10px;
  }

  .pt-lg-3 {
    padding-top: 15px;
  }

  .pt-lg-4 {
    padding-top: 20px;
  }

  .pt-lg-5 {
    padding-top: 25px;
  }

  .pt-lg-6 {
    padding-top: 30px;
  }

  .pt-lg-7 {
    padding-top: 35px;
  }

  .pt-lg-8 {
    padding-top: 40px;
  }

  .pt-lg-9 {
    padding-top: 45px;
  }

  .pt-lg-10 {
    padding-top: 50px;
  }

  .pr-lg-0 {
    padding-right: 0;
  }

  .pr-lg-1 {
    padding-right: 5px;
  }

  .pr-lg-2 {
    padding-right: 10px;
  }

  .pr-lg-3 {
    padding-right: 15px;
  }

  .pr-lg-4 {
    padding-right: 20px;
  }

  .pr-lg-5 {
    padding-right: 25px;
  }

  .pr-lg-6 {
    padding-right: 30px;
  }

  .pr-lg-7 {
    padding-right: 35px;
  }

  .pr-lg-8 {
    padding-right: 40px;
  }

  .pr-lg-9 {
    padding-right: 45px;
  }

  .pr-lg-10 {
    padding-right: 50px;
  }

  .pb-lg-0 {
    padding-bottom: 0;
  }

  .pb-lg-1 {
    padding-bottom: 5px;
  }

  .pb-lg-2 {
    padding-bottom: 10px;
  }

  .pb-lg-3 {
    padding-bottom: 15px;
  }

  .pb-lg-4 {
    padding-bottom: 20px;
  }

  .pb-lg-5 {
    padding-bottom: 25px;
  }

  .pb-lg-6 {
    padding-bottom: 30px;
  }

  .pb-lg-7 {
    padding-bottom: 35px;
  }

  .pb-lg-8 {
    padding-bottom: 40px;
  }

  .pb-lg-9 {
    padding-bottom: 45px;
  }

  .pb-lg-10 {
    padding-bottom: 50px;
  }

  .pl-lg-0 {
    padding-left: 0;
  }

  .pl-lg-1 {
    padding-left: 5px;
  }

  .pl-lg-2 {
    padding-left: 10px;
  }

  .pl-lg-3 {
    padding-left: 15px;
  }

  .pl-lg-4 {
    padding-left: 20px;
  }

  .pl-lg-5 {
    padding-left: 25px;
  }

  .pl-lg-6 {
    padding-left: 30px;
  }

  .pl-lg-7 {
    padding-left: 35px;
  }

  .pl-lg-8 {
    padding-left: 40px;
  }

  .pl-lg-9 {
    padding-left: 45px;
  }

  .pl-lg-10 {
    padding-left: 50px;
  }

  .text-lg-left {
    text-align: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }

  .h1 {
    font-size: 2.5rem;
  }

  h2,
  .h2 {
    font-size: 2rem;
  }

  .h2 {
    font-size: 2rem;
  }

  h3,
  .h3 {
    font-size: 1.75rem;
  }

  .h3 {
    font-size: 1.75rem;
  }

  h4,
  .h4 {
    font-size: 1.5rem;
  }

  .h4 {
    font-size: 1.5rem;
  }

  legend {
    font-size: 1.5rem;
  }

  .display-1 {
    font-size: 5rem;
  }

  .display-2 {
    font-size: 4.5rem;
  }

  .display-3 {
    font-size: 4rem;
  }

  .display-4 {
    font-size: 3.5rem;
  }

  .display-5 {
    font-size: 3rem;
  }

  .display-6 {
    font-size: 2.5rem;
  }

  .container-xl {
    max-width: 1170px;
  }

  .container-lg {
    max-width: 1170px;
  }

  .container-md {
    max-width: 1170px;
  }

  .container-sm {
    max-width: 1170px;
  }

  .container {
    max-width: 1170px;
  }

  .col-xl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1 {
    --bs-gutter-x: 5px;
    --bs-gutter-y: 5px;
  }

  .gx-xl-1 {
    --bs-gutter-x: 5px;
  }

  .gy-xl-1 {
    --bs-gutter-y: 5px;
  }

  .g-xl-2 {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 10px;
  }

  .gx-xl-2 {
    --bs-gutter-x: 10px;
  }

  .gy-xl-2 {
    --bs-gutter-y: 10px;
  }

  .g-xl-3 {
    --bs-gutter-x: 15px;
    --bs-gutter-y: 15px;
  }

  .gx-xl-3 {
    --bs-gutter-x: 15px;
  }

  .gy-xl-3 {
    --bs-gutter-y: 15px;
  }

  .g-xl-4 {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
  }

  .gx-xl-4 {
    --bs-gutter-x: 20px;
  }

  .gy-xl-4 {
    --bs-gutter-y: 20px;
  }

  .g-xl-5 {
    --bs-gutter-x: 25px;
    --bs-gutter-y: 25px;
  }

  .gx-xl-5 {
    --bs-gutter-x: 25px;
  }

  .gy-xl-5 {
    --bs-gutter-y: 25px;
  }

  .g-xl-6 {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 30px;
  }

  .gx-xl-6 {
    --bs-gutter-x: 30px;
  }

  .gy-xl-6 {
    --bs-gutter-y: 30px;
  }

  .g-xl-7 {
    --bs-gutter-x: 35px;
    --bs-gutter-y: 35px;
  }

  .gx-xl-7 {
    --bs-gutter-x: 35px;
  }

  .gy-xl-7 {
    --bs-gutter-y: 35px;
  }

  .g-xl-8 {
    --bs-gutter-x: 40px;
    --bs-gutter-y: 40px;
  }

  .gx-xl-8 {
    --bs-gutter-x: 40px;
  }

  .gy-xl-8 {
    --bs-gutter-y: 40px;
  }

  .g-xl-9 {
    --bs-gutter-x: 45px;
    --bs-gutter-y: 45px;
  }

  .gx-xl-9 {
    --bs-gutter-x: 45px;
  }

  .gy-xl-9 {
    --bs-gutter-y: 45px;
  }

  .g-xl-10 {
    --bs-gutter-x: 50px;
    --bs-gutter-y: 50px;
  }

  .gx-xl-10 {
    --bs-gutter-x: 50px;
  }

  .gy-xl-10 {
    --bs-gutter-y: 50px;
  }

  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .modal-xl {
    max-width: 1140px;
  }

  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .float-xl-left {
    float: left;
  }

  .float-xl-right {
    float: right;
  }

  .float-xl-none {
    float: none;
  }

  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-table {
    display: table;
  }

  .d-xl-table-row {
    display: table-row;
  }

  .d-xl-table-cell {
    display: table-cell;
  }

  .d-xl-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-xl-none {
    display: none;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .flex-xl-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  .align-items-xl-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .align-items-xl-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .align-items-xl-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .m-xl-0 {
    margin: 0;
  }

  .m-xl-1 {
    margin: 5px;
  }

  .m-xl-2 {
    margin: 10px;
  }

  .m-xl-3 {
    margin: 15px;
  }

  .m-xl-4 {
    margin: 20px;
  }

  .m-xl-5 {
    margin: 25px;
  }

  .m-xl-6 {
    margin: 30px;
  }

  .m-xl-7 {
    margin: 35px;
  }

  .m-xl-8 {
    margin: 40px;
  }

  .m-xl-9 {
    margin: 45px;
  }

  .m-xl-10 {
    margin: 50px;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .mx-xl-1 {
    margin-right: 5px;
    margin-left: 5px;
  }

  .mx-xl-2 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .mx-xl-3 {
    margin-right: 15px;
    margin-left: 15px;
  }

  .mx-xl-4 {
    margin-right: 20px;
    margin-left: 20px;
  }

  .mx-xl-5 {
    margin-right: 25px;
    margin-left: 25px;
  }

  .mx-xl-6 {
    margin-right: 30px;
    margin-left: 30px;
  }

  .mx-xl-7 {
    margin-right: 35px;
    margin-left: 35px;
  }

  .mx-xl-8 {
    margin-right: 40px;
    margin-left: 40px;
  }

  .mx-xl-9 {
    margin-right: 45px;
    margin-left: 45px;
  }

  .mx-xl-10 {
    margin-right: 50px;
    margin-left: 50px;
  }

  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xl-1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .my-xl-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .my-xl-3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .my-xl-4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .my-xl-5 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .my-xl-6 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .my-xl-7 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .my-xl-8 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-xl-9 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .my-xl-10 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xl-0 {
    margin-top: 0;
  }

  .mt-xl-1 {
    margin-top: 5px;
  }

  .mt-xl-2 {
    margin-top: 10px;
  }

  .mt-xl-3 {
    margin-top: 15px;
  }

  .mt-xl-4 {
    margin-top: 20px;
  }

  .mt-xl-5 {
    margin-top: 25px;
  }

  .mt-xl-6 {
    margin-top: 30px;
  }

  .mt-xl-7 {
    margin-top: 35px;
  }

  .mt-xl-8 {
    margin-top: 40px;
  }

  .mt-xl-9 {
    margin-top: 45px;
  }

  .mt-xl-10 {
    margin-top: 50px;
  }

  .mt-xl-auto {
    margin-top: auto;
  }

  .mr-xl-0 {
    margin-right: 0;
  }

  .mr-xl-1 {
    margin-right: 5px;
  }

  .mr-xl-2 {
    margin-right: 10px;
  }

  .mr-xl-3 {
    margin-right: 15px;
  }

  .mr-xl-4 {
    margin-right: 20px;
  }

  .mr-xl-5 {
    margin-right: 25px;
  }

  .mr-xl-6 {
    margin-right: 30px;
  }

  .mr-xl-7 {
    margin-right: 35px;
  }

  .mr-xl-8 {
    margin-right: 40px;
  }

  .mr-xl-9 {
    margin-right: 45px;
  }

  .mr-xl-10 {
    margin-right: 50px;
  }

  .mr-xl-auto {
    margin-right: auto;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .mb-xl-1 {
    margin-bottom: 5px;
  }

  .mb-xl-2 {
    margin-bottom: 10px;
  }

  .mb-xl-3 {
    margin-bottom: 15px;
  }

  .mb-xl-4 {
    margin-bottom: 20px;
  }

  .mb-xl-5 {
    margin-bottom: 25px;
  }

  .mb-xl-6 {
    margin-bottom: 30px;
  }

  .mb-xl-7 {
    margin-bottom: 35px;
  }

  .mb-xl-8 {
    margin-bottom: 40px;
  }

  .mb-xl-9 {
    margin-bottom: 45px;
  }

  .mb-xl-10 {
    margin-bottom: 50px;
  }

  .mb-xl-auto {
    margin-bottom: auto;
  }

  .ml-xl-0 {
    margin-left: 0;
  }

  .ml-xl-1 {
    margin-left: 5px;
  }

  .ml-xl-2 {
    margin-left: 10px;
  }

  .ml-xl-3 {
    margin-left: 15px;
  }

  .ml-xl-4 {
    margin-left: 20px;
  }

  .ml-xl-5 {
    margin-left: 25px;
  }

  .ml-xl-6 {
    margin-left: 30px;
  }

  .ml-xl-7 {
    margin-left: 35px;
  }

  .ml-xl-8 {
    margin-left: 40px;
  }

  .ml-xl-9 {
    margin-left: 45px;
  }

  .ml-xl-10 {
    margin-left: 50px;
  }

  .ml-xl-auto {
    margin-left: auto;
  }

  .m-xl-n1 {
    margin: -5px;
  }

  .m-xl-n2 {
    margin: -10px;
  }

  .m-xl-n3 {
    margin: -15px;
  }

  .m-xl-n4 {
    margin: -20px;
  }

  .m-xl-n5 {
    margin: -25px;
  }

  .m-xl-n6 {
    margin: -30px;
  }

  .m-xl-n7 {
    margin: -35px;
  }

  .m-xl-n8 {
    margin: -40px;
  }

  .m-xl-n9 {
    margin: -45px;
  }

  .m-xl-n10 {
    margin: -50px;
  }

  .mx-xl-n1 {
    margin-right: -5px;
    margin-left: -5px;
  }

  .mx-xl-n2 {
    margin-right: -10px;
    margin-left: -10px;
  }

  .mx-xl-n3 {
    margin-right: -15px;
    margin-left: -15px;
  }

  .mx-xl-n4 {
    margin-right: -20px;
    margin-left: -20px;
  }

  .mx-xl-n5 {
    margin-right: -25px;
    margin-left: -25px;
  }

  .mx-xl-n6 {
    margin-right: -30px;
    margin-left: -30px;
  }

  .mx-xl-n7 {
    margin-right: -35px;
    margin-left: -35px;
  }

  .mx-xl-n8 {
    margin-right: -40px;
    margin-left: -40px;
  }

  .mx-xl-n9 {
    margin-right: -45px;
    margin-left: -45px;
  }

  .mx-xl-n10 {
    margin-right: -50px;
    margin-left: -50px;
  }

  .my-xl-n1 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .my-xl-n2 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .my-xl-n3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .my-xl-n4 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .my-xl-n5 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .my-xl-n6 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .my-xl-n7 {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .my-xl-n8 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .my-xl-n9 {
    margin-top: -45px;
    margin-bottom: -45px;
  }

  .my-xl-n10 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .mt-xl-n1 {
    margin-top: -5px;
  }

  .mt-xl-n2 {
    margin-top: -10px;
  }

  .mt-xl-n3 {
    margin-top: -15px;
  }

  .mt-xl-n4 {
    margin-top: -20px;
  }

  .mt-xl-n5 {
    margin-top: -25px;
  }

  .mt-xl-n6 {
    margin-top: -30px;
  }

  .mt-xl-n7 {
    margin-top: -35px;
  }

  .mt-xl-n8 {
    margin-top: -40px;
  }

  .mt-xl-n9 {
    margin-top: -45px;
  }

  .mt-xl-n10 {
    margin-top: -50px;
  }

  .mr-xl-n1 {
    margin-right: -5px;
  }

  .mr-xl-n2 {
    margin-right: -10px;
  }

  .mr-xl-n3 {
    margin-right: -15px;
  }

  .mr-xl-n4 {
    margin-right: -20px;
  }

  .mr-xl-n5 {
    margin-right: -25px;
  }

  .mr-xl-n6 {
    margin-right: -30px;
  }

  .mr-xl-n7 {
    margin-right: -35px;
  }

  .mr-xl-n8 {
    margin-right: -40px;
  }

  .mr-xl-n9 {
    margin-right: -45px;
  }

  .mr-xl-n10 {
    margin-right: -50px;
  }

  .mb-xl-n1 {
    margin-bottom: -5px;
  }

  .mb-xl-n2 {
    margin-bottom: -10px;
  }

  .mb-xl-n3 {
    margin-bottom: -15px;
  }

  .mb-xl-n4 {
    margin-bottom: -20px;
  }

  .mb-xl-n5 {
    margin-bottom: -25px;
  }

  .mb-xl-n6 {
    margin-bottom: -30px;
  }

  .mb-xl-n7 {
    margin-bottom: -35px;
  }

  .mb-xl-n8 {
    margin-bottom: -40px;
  }

  .mb-xl-n9 {
    margin-bottom: -45px;
  }

  .mb-xl-n10 {
    margin-bottom: -50px;
  }

  .ml-xl-n1 {
    margin-left: -5px;
  }

  .ml-xl-n2 {
    margin-left: -10px;
  }

  .ml-xl-n3 {
    margin-left: -15px;
  }

  .ml-xl-n4 {
    margin-left: -20px;
  }

  .ml-xl-n5 {
    margin-left: -25px;
  }

  .ml-xl-n6 {
    margin-left: -30px;
  }

  .ml-xl-n7 {
    margin-left: -35px;
  }

  .ml-xl-n8 {
    margin-left: -40px;
  }

  .ml-xl-n9 {
    margin-left: -45px;
  }

  .ml-xl-n10 {
    margin-left: -50px;
  }

  .p-xl-0 {
    padding: 0;
  }

  .p-xl-1 {
    padding: 5px;
  }

  .p-xl-2 {
    padding: 10px;
  }

  .p-xl-3 {
    padding: 15px;
  }

  .p-xl-4 {
    padding: 20px;
  }

  .p-xl-5 {
    padding: 25px;
  }

  .p-xl-6 {
    padding: 30px;
  }

  .p-xl-7 {
    padding: 35px;
  }

  .p-xl-8 {
    padding: 40px;
  }

  .p-xl-9 {
    padding: 45px;
  }

  .p-xl-10 {
    padding: 50px;
  }

  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .px-xl-1 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .px-xl-2 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .px-xl-3 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .px-xl-4 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .px-xl-5 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .px-xl-6 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .px-xl-7 {
    padding-right: 35px;
    padding-left: 35px;
  }

  .px-xl-8 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .px-xl-9 {
    padding-right: 45px;
    padding-left: 45px;
  }

  .px-xl-10 {
    padding-right: 50px;
    padding-left: 50px;
  }

  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xl-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .py-xl-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .py-xl-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .py-xl-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .py-xl-5 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .py-xl-6 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .py-xl-7 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .py-xl-8 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .py-xl-9 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .py-xl-10 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pt-xl-0 {
    padding-top: 0;
  }

  .pt-xl-1 {
    padding-top: 5px;
  }

  .pt-xl-2 {
    padding-top: 10px;
  }

  .pt-xl-3 {
    padding-top: 15px;
  }

  .pt-xl-4 {
    padding-top: 20px;
  }

  .pt-xl-5 {
    padding-top: 25px;
  }

  .pt-xl-6 {
    padding-top: 30px;
  }

  .pt-xl-7 {
    padding-top: 35px;
  }

  .pt-xl-8 {
    padding-top: 40px;
  }

  .pt-xl-9 {
    padding-top: 45px;
  }

  .pt-xl-10 {
    padding-top: 50px;
  }

  .pr-xl-0 {
    padding-right: 0;
  }

  .pr-xl-1 {
    padding-right: 5px;
  }

  .pr-xl-2 {
    padding-right: 10px;
  }

  .pr-xl-3 {
    padding-right: 15px;
  }

  .pr-xl-4 {
    padding-right: 20px;
  }

  .pr-xl-5 {
    padding-right: 25px;
  }

  .pr-xl-6 {
    padding-right: 30px;
  }

  .pr-xl-7 {
    padding-right: 35px;
  }

  .pr-xl-8 {
    padding-right: 40px;
  }

  .pr-xl-9 {
    padding-right: 45px;
  }

  .pr-xl-10 {
    padding-right: 50px;
  }

  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pb-xl-1 {
    padding-bottom: 5px;
  }

  .pb-xl-2 {
    padding-bottom: 10px;
  }

  .pb-xl-3 {
    padding-bottom: 15px;
  }

  .pb-xl-4 {
    padding-bottom: 20px;
  }

  .pb-xl-5 {
    padding-bottom: 25px;
  }

  .pb-xl-6 {
    padding-bottom: 30px;
  }

  .pb-xl-7 {
    padding-bottom: 35px;
  }

  .pb-xl-8 {
    padding-bottom: 40px;
  }

  .pb-xl-9 {
    padding-bottom: 45px;
  }

  .pb-xl-10 {
    padding-bottom: 50px;
  }

  .pl-xl-0 {
    padding-left: 0;
  }

  .pl-xl-1 {
    padding-left: 5px;
  }

  .pl-xl-2 {
    padding-left: 10px;
  }

  .pl-xl-3 {
    padding-left: 15px;
  }

  .pl-xl-4 {
    padding-left: 20px;
  }

  .pl-xl-5 {
    padding-left: 25px;
  }

  .pl-xl-6 {
    padding-left: 30px;
  }

  .pl-xl-7 {
    padding-left: 35px;
  }

  .pl-xl-8 {
    padding-left: 40px;
  }

  .pl-xl-9 {
    padding-left: 45px;
  }

  .pl-xl-10 {
    padding-left: 50px;
  }

  .text-xl-left {
    text-align: left;
  }

  .text-xl-right {
    text-align: right;
  }

  .text-xl-center {
    text-align: center;
  }
}

@media (min-width: 1450px) {
  .container-xl {
    max-width: 1400px;
  }

  .container-lg {
    max-width: 1400px;
  }

  .container-md {
    max-width: 1400px;
  }

  .container-sm {
    max-width: 1400px;
  }

  .container {
    max-width: 1400px;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }

  .form-check-input {
    -webkit-transition: none;
    transition: none;
  }

  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }

  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }

  .form-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }

  /* .btn {
    -webkit-transition: none;
    transition: none;
  } */

  .fade {
    -webkit-transition: none;
    transition: none;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
  }

  .nav-link {
    -webkit-transition: none;
    transition: none;
  }

  .navbar-toggler {
    -webkit-transition: none;
    transition: none;
  }

  .page-link {
    -webkit-transition: none;
    transition: none;
  }

  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }

  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }

  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }

  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }

  .carousel-fade .active.carousel-item-left {
    -webkit-transition: none;
    transition: none;
  }

  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }

  .carousel-control-prev {
    -webkit-transition: none;
    transition: none;
  }

  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }

  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}

@media print {
  .d-print-inline {
    display: inline;
  }

  .d-print-inline-block {
    display: inline-block;
  }

  .d-print-block {
    display: block;
  }

  .d-print-table {
    display: table;
  }

  .d-print-table-row {
    display: table-row;
  }

  .d-print-table-cell {
    display: table-cell;
  }

  .d-print-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .d-print-none {
    display: none;
  }
}

.btn {
  padding: 0;
  font-size: 14px;
  border-radius: 0;
  height: 45px;
  border: 0;
  font-weight: 500;
  line-height: 20px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
}

.btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* 
.btn-primary {
    color: #ffffff;
    background-color: #FD9801;
    border-color: #FD9801;
    border: 0;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
} */

/* .btn-primary:hover {
    color: #ffffff;
    background-color: #555;
    border-color: #555;
}

.btn-primary:focus {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
}

.btn-primary:active {
    color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
} */

.btn-color-dark {
  background-color: #4d4d4d;
  border-color: #4d4d4d;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  width: 130px;
  height: 45px;
  line-height: 20px;
}

.btn-hover-color-primary:hover {
  color: #ffffff;
  background-color: #555;
  border-color: #555;
}

.btn-check:focus + .btn {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-check:focus + .btn-primary {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

.btn-check:checked + .btn-primary {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

.btn-check:active + .btn-primary {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

/* .btn-primary.active { */
/* color: #ffffff;
    background-color: #3474d4;
    border-color: #3474d4;
} */

.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

/*-------------------------------------------------
  Common Style CSS
---------------------------------------------------*/
body {
  font-size: 14px;
  color: #777;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: "Poppins";
  font-weight: 300;
  top: 0 !important;
}

h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Poppins";
}

/*----  Section  ----*/
.section-space {
  padding: 50px 0;
}

.section-space-p {
  padding: 50px 0;
}

.section-space-pb {
  padding-bottom: 50px;
}

.section-space-pt {
  padding-top: 50px;
}

.section-space-ptb-100 {
  padding: 100px 0;
}

.section-space-m {
  margin: 50px 0;
}

.section-space-mt {
  margin-top: 50px;
}

.section-space-mb {
  margin-bottom: 50px;
}

.section-space-footer-p {
  padding: 50px 0;
}

/*----  Margin  ----*/
.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-minus-t-15 {
  margin-top: -15px;
}

.margin-minus-tb-15 {
  margin: -15px 0;
}

.margin-minus-b-30 {
  margin-bottom: -30px;
}

.margin-minus-b-15 {
  margin-bottom: -15px;
}

.skiptranslate {
  height: 0px !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#goog-gt-tt {
  display: none !important;
  visibility: hidden !important;
}

.goog-text-highlight {
  background: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#scrollUp {
  width: 40px;
  height: 40px;
  background-color: #555;
  color: #ffffff;
  right: 15px;
  bottom: 70px;
  text-align: center;
  overflow: hidden;
  z-index: 11 !important;
  font-size: 18px;
  line-height: 40px;
}

#scrollUp:hover {
  background: #3474d4;
  color: #ffffff;
}

.slider-animation > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

.swiper-slide-active .slider-animation > * {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.swiper-slide-active .slider-animation > *:nth-child(1) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.swiper-slide-active .slider-animation > *:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.swiper-slide-active .slider-animation > *:nth-child(3) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.swiper-slide-active .slider-animation > *:nth-child(4) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.swiper-slide-active .slider-animation > *:nth-child(5) {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.swiper-slide-active .slider-animation > *:nth-child(6) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

/*----  Section Title ----*/
.section-title {
  margin-bottom: 30px;
  position: relative;
}

.section-title .ec-title:after {
  content: "";
  position: absolute;
  top: 50%;
  height: 3px;
  width: 50px;
  margin: 0 auto;
  background: #3474d4;
  right: 0;
  left: auto;
  display: none;
}

.section-title .ec-title:before {
  content: "";
  position: absolute;
  top: 50%;
  height: 3px;
  width: 50px;
  margin: 0 auto;
  background: #3474d4;
  left: 0;
  right: auto;
  display: none;
}

.section-title .ec-bg-title {
  font-family: "Poppins";
  font-size: 63px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: #444444;
  opacity: 0.1;
  margin-bottom: 0;
  letter-spacing: 0;
  display: none;
}

.section-title .sub-title {
  margin: 10px 0 0 0 !important;
  color: #fff;
  /* padding-left: 34px; */
  /* padding-right: 20%; */
  margin-top: 20px;
  font-size: 27px;
  letter-spacing: 0.03rem;
  font-weight: 300;
}

.text-upper {
  text-transform: uppercase;
}

/*---- Side Color,Dark,Rtl Fixed Button Style ----*/
#ec-fixedbutton * {
  direction: ltr;
}

#ec-fixedbutton > * {
  display: block;
  position: relative;
}

#ec-fixedbutton .ec-color-btn {
  -webkit-animation: rotation-icon 5s linear infinite;
  animation: rotation-icon 5s linear infinite;
}

@-webkit-keyframes rotation-icon {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotation-icon {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*---- Recent purchase popup  ----*/
.recent-purchase {
  width: 300px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  left: 15px;
  bottom: 15px;
  background-color: #ffffff;
  z-index: 12;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.recent-purchase img {
  width: 70px;
  height: 75px;
  margin-right: 15px;
  display: inline-block;
  border: 1px solid #eeeeee;
}

.recent-purchase .detail {
  width: 65%;
  display: inline-block;
}

.recent-purchase .detail h6,
.recent-purchase .detail .h6 {
  color: #444;
  font-size: 14px;
}

.recent-purchase .detail p {
  margin-bottom: 10px;
  font-size: 11px;
  color: #777;
}

.recent-purchase a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 20px;
  color: #777;
}

.recent-purchase a:hover {
  color: #555;
}

/*---- Newsletter Page On load Style ----*/
#ec-popnews-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 9998;
}

#ec-popnews-box-content {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#ec-popnews-box {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #444444;
  width: 100%;
  max-width: 700px;
  padding: 30px;
  border: 0;
  display: none;
  z-index: 9999;
  text-align: center;
  border-radius: 30px;
  background-image: url("assets/images/email-template/newsletter-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

#ec-popnews-box h2,
#ec-popnews-box .h2 {
  text-decoration: none;
  color: #444;
  display: block;
  font-size: 22px;
  line-height: 33px;
  font-weight: 600;
  margin: 0 auto 10px;
  letter-spacing: 0;
  text-transform: capitalize;
}

#ec-popnews-box p {
  margin-bottom: 20px;
  color: #777;
}

#ec-popnews-box form {
  margin-bottom: 0px;
}

#ec-popnews-box input {
  height: 45px;
  background-color: transparent;
  border: 1px solid #ededed;
  color: #444444;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 15px;
  width: 100%;
  outline: none;
  background-color: #fff;
  border-radius: 30px;
}

#ec-popnews-box button {
  height: 40px;
  line-height: 42px;
  font-size: 1.143rem;
  text-transform: uppercase;
  border-radius: 30px;
}

#ec-popnews-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #777;
  border-color: #3474d4;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.143rem;
  line-height: 1;
}

/*---- Site Loader Style ----*/
#ec-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 999999999999;
}

#ec-overlay .loader_img {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999999999;
  background: #ffffff url("assets/images/common/loader.svg") no-repeat scroll
    50% 49%;
  background-size: 150px;
  pointer-events: none;
  overflow: hidden;
}

/*----  Add To Cart toast ----*/
#addtocart_toast,
#wishlist_toast {
  visibility: hidden;
  max-width: 290px;
  height: auto;
  margin: auto;
  background-color: green;
  color: #ffffff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 9999;
  left: 15px;
  right: auto;
  bottom: 30px;
  font-size: 1.143rem;
  white-space: nowrap;
}

#addtocart_toast #desc,
#wishlist_toast #desc {
  color: #ffffff;
  padding: 14px 10px;
  overflow: hidden;
  white-space: nowrap;
}

#wishlist_toast.show,
#addtocart_toast.show {
  visibility: visible;
}

.slick-arrow {
  position: absolute;
  top: 45%;
  left: auto;
  right: auto;
  margin: 0 auto;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  z-index: 1;
}

.slick-arrow:before {
  content: "";
  color: #777777;
  position: relative;
  font-size: 30px;
  font-family: "EcIcons";
  width: 100%;
  line-height: 30px;
  height: 30px;
}

.slick-arrow:hover:before {
  color: #3474d4;
}

.slick-arrow.slick-prev {
  left: 0;
  right: auto;
}

.slick-arrow.slick-prev:before {
  content: "";
}

.slick-arrow.slick-next {
  right: 0;
  left: auto;
}

.slick-arrow.slick-next:before {
  content: "";
}

.ec-nav-toolbar {
  display: none;
  background-color: #ffffff;
}

/*----  Layout switcher  ----*/
.bg-switcher {
  position: fixed;
  right: -163px;
  top: 28%;
  width: 158px;
  z-index: 12;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bg-switcher .bg-option-box img {
  width: 25px;
  height: 25px;
}

.bg-switcher .option-box-title {
  padding: 3px 15px;
  background-color: #222;
}

.bg-switcher .option-box-title h3,
.bg-switcher .option-box-title .h3 {
  color: #fff;
  margin: 0;
  font-size: 18px;
  text-align: center;
  padding: 6px 5px;
}

.bg-switcher ul {
  margin: 0;
  background: #fff;
  padding: 18px;
  overflow: hidden;
  border: 2px solid #222;
}

.bg-switcher ul li {
  width: 25px;
  height: 25px;
  margin: 7px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}

.bg-switcher ul li span {
  cursor: pointer;
}

.bg-switcher ul .bg {
  width: 100%;
  margin: 0 5px;
}

.bg-switcher ul .bg a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #222;
  font-size: 0.928rem;
}

.bg-switcher ul .bg a:hover {
  margin-left: 5px;
}

.bg-switcher .section i {
  font-size: 1.143rem;
  margin-right: 10px;
}

.bg-switcher a.bg-option-box {
  position: absolute;
  right: 164px;
  width: 40px;
  height: 41px;
  color: #000;
  background-color: #222;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 25px;
  z-index: -1;
  text-decoration: none;
  top: 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.bg-switcher .skin-color {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bg-switcher .skin a {
  display: block;
  width: 30px;
  height: 30px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 5px;
  text-align: center;
  line-height: 30px;
  border: 2px transparent solid;
}

.bg-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg-panel .bg {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 3px;
  padding: 2px;
  border-radius: 30px;
}

.bg-panel .bg:hover {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.bg-panel .bg a {
  -webkit-transition: transform 0.2s;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  margin: 0 auto;
  font-size: 0;
  width: 30px;
  display: block;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #eeeeee;
  border-radius: 30px;
}

.bg-panel .bg .back-bg-4 {
  background-image: url("assets/images/bg/display-bg-0.png");
}

.bg-panel .bg .back-bg-1 {
  background-image: url("assets/images/bg/display-bg-1.png");
}

.bg-panel .bg .back-bg-2 {
  background-image: url("assets/images/bg/display-bg-2.png");
}

.bg-panel .bg .back-bg-3 {
  background-image: url("assets/images/bg/display-bg-3.png");
}

/*----  Language switcher  ----*/
.lang-switcher {
  position: fixed;
  right: -163px;
  top: 28%;
  width: 158px;
  z-index: 12;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.lang-switcher .lang-option-box img {
  width: 25px;
  height: 25px;
}

.lang-switcher .option-box-title {
  padding: 5px 7px;
  margin-bottom: 10px;
  background-color: #222;
  height: 40px;
  overflow: hidden;
}

.lang-switcher .option-box-title h3,
.lang-switcher .option-box-title .h3 {
  color: #fff;
  margin: 0;
  font-size: 18px;
  text-align: center;
  padding: 6px 5px;
}

.lang-switcher ul {
  margin: 0;
  background: #fff;
  padding: 18px;
  overflow: hidden;
  border: 2px solid #222;
}

.lang-switcher ul li {
  width: 25px;
  height: 25px;
  margin: 7px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}

.lang-switcher ul li span {
  cursor: pointer;
}

.lang-switcher ul .lang {
  width: 100%;
  margin: 0 5px;
}

.lang-switcher ul .lang a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #555;
  font-size: 0.928rem;
}

.lang-switcher ul .lang a:hover {
  margin-left: 5px;
}

.lang-switcher .lang-panel {
  padding: 12px;
}

.lang-switcher .section i {
  font-size: 1.143rem;
  margin-right: 10px;
}

.lang-switcher a.lang-option-box {
  position: absolute;
  right: 164px;
  width: 40px;
  height: 41px;
  color: #ffffff;
  font-size: 0.857rem;
  background-color: #222;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: -1;
  text-decoration: none;
  top: 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.lang-switcher .skin-color {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.lang-switcher .skin a {
  display: block;
  width: 30px;
  height: 30px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 5px;
  text-align: center;
  line-height: 30px;
  border: 2px transparent solid;
}

/*----  Whatsapp chat start  ----*/
.ec-style {
  display: inline-block;
  position: fixed;
  letter-spacing: 0.1px;
  z-index: 13;
}

.ec-style .ec-button {
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-style .ec-button .whatsapp {
  width: 23px;
  height: 23px;
  background-image: url(assets/images/common/whatsapp.png);
  background-repeat: no-repeat;
  background-size: 100%;
  display: table;
  margin-right: 0;
  font-size: 25px;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  color: #2ab674;
}

.ec-style .ec-panel {
  display: none;
  margin-bottom: 5px;
  width: 275px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.479);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.479);
  background-color: #fff;
}

.ec-style .ec-panel .ec-header {
  padding: 12px 15px 15px;
  text-align: center;
  color: #fff;
  background: #2ab674;
}

.ec-style .ec-panel .ec-body {
  min-width: 250px;
  white-space: nowrap;
  list-style: none;
  overflow-y: auto;
}

.ec-style .ec-header strong {
  font-size: 15px;
  line-height: 20px;
}

.ec-style .ec-header p {
  margin-bottom: 0;
  color: #fff;
  font-size: 0.857rem;
  line-height: 20px;
}

.ec-style .ec-list .d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-style .ec-list .ec-img-cont {
  position: relative;
}

.ec-style .ec-list .ec-user-img {
  height: 40px;
  width: 40px;
  vertical-align: middle;
  border-radius: 50%;
}

.ec-style .ec-list .ec-status-icon {
  position: absolute;
  bottom: 5px;
  right: 1px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #a0a0a0;
}

.ec-style .ec-list .ec-online {
  background-color: #2ab674;
}

.ec-style .ec-list .ec-offline {
  background-color: #a0a0a0;
}

.ec-style .ec-list .ec-user-info {
  margin-top: 0;
  margin-bottom: auto;
  margin-left: 15px;
}

.ec-style .ec-list .ec-user-info span {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #555;
}

.ec-style .ec-list .ec-user-info p {
  margin-bottom: 0;
  color: #888;
  font-size: 0.857rem;
  line-height: 15px;
  font-weight: 300;
}

.ec-style .ec-list .ec-chat-icon {
  margin-top: -46px;
  right: -10px;
  position: absolute;
  color: #d4d4d4;
  font-size: 60px;
  opacity: 0.5;
  -webkit-transition: all 0.05s ease-in-out;
  transition: all 0.05s ease-in-out;
  overflow: hidden;
}

.ec-style .ec-body:hover {
  text-decoration: none;
  cursor: pointer;
}

.ec-style .ec-body:hover .ec-user-img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-style .ec-body li {
  display: block;
  padding: 10px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  border-bottom: 1px solid #eee;
  list-style-type: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-style .ec-body li:hover {
  background-color: rgba(42, 182, 116, 0.07);
}

.ec-style .ec-body li:hover .ec-chat-icon {
  color: #555;
  -webkit-transition: all 0.05s ease-in-out;
  transition: all 0.05s ease-in-out;
}

.ec-style .ec-body li:hover .ec-chat-icon i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-style .ec-body .ec-chat-icon i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-right-bottom {
  bottom: 15px;
  right: 15px;
  float: right;
}

.ec-right-bottom .ec-box {
  background-color: #ffffff;
  border: 2px solid #2ab674;
}

.ec-right-bottom .ec-box:hover {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

@keyframes ec-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes ec-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rotateForward {
  -webkit-animation-name: rotateF;
  animation-name: rotateF;
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@-webkit-keyframes rotateF {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateF {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotateBackward {
  -webkit-animation-name: rotateB;
  animation-name: rotateB;
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@-webkit-keyframes rotateB {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotateB {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.ec-panel .ec-body ul {
  margin: 0;
  padding: 0;
}

.ec-cart-float {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 70px;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  z-index: 15;
  background: #ffffff;
}

.ec-cart-float .ec-header-btn {
  position: relative;
  display: block;
}

.ec-cart-float .ec-header-btn .svg_img {
  width: 45px;
  height: 45px;
}

.ec-cart-float .ec-header-btn .ec-cart-count {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  bottom: 0;
  background: #3474d4;
  color: #ffffff;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  font-size: 0.857rem;
  line-height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-tools-sidebar-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 16;
}

.ec-tools-sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  right: -200px;
  top: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 16;
}

.ec-tools-sidebar ::-webkit-scrollbar {
  width: 5px;
}

.ec-tools-sidebar ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ec-tools-sidebar ::-webkit-scrollbar-thumb {
  background: #888;
}

.ec-tools-sidebar ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ec-tools-sidebar .ec-tools-detail {
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.ec-tools-sidebar .tool-title {
  padding: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.ec-tools-sidebar .tool-title h3,
.ec-tools-sidebar .tool-title .h3 {
  line-height: 28px;
  margin: 0;
  font-size: 1.143rem;
  color: #777;
  font-weight: 700;
  text-transform: uppercase;
}

.ec-tools-sidebar .ec-tools-sidebar-toggle {
  position: absolute;
  top: 30%;
  right: 202px;
  width: 40px;
  height: 40px;
  color: #000;
  background-color: #373840;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 25px;
  z-index: -1;
  text-decoration: none;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.ec-tools-sidebar .ec-tools-sidebar-toggle img {
  width: 27px;
  -webkit-animation: rotation 3s infinite linear;
  animation: rotation 3s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.ec-tools-sidebar .ec-tools-sidebar-content {
  padding: 15px;
  border-bottom: 1px solid #f3f3f3;
}

.ec-tools-sidebar .ec-tools-sidebar-content h3,
.ec-tools-sidebar .ec-tools-sidebar-content .h3 {
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 14px;
  color: #777;
  font-weight: 600;
}

.ec-tools-sidebar .ec-tools-sidebar-content li {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-tools-sidebar .ec-tools-sidebar-content .ec-change-lang {
  width: 100%;
  height: 28px;
  padding-bottom: 29px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
}

.ec-tools-sidebar
  .ec-tools-sidebar-content
  .ec-change-lang
  #google_translate_element
  span {
  display: none;
}

.ec-tools-sidebar
  .ec-tools-sidebar-content
  .ec-change-lang
  #google_translate_element
  select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 10px;
  background: url(assets/images/common/arrow-down-light.png);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-repeat: no-repeat;
  background-position: 96% 42%;
  color: #777;
}

.ec-tools-sidebar
  .ec-tools-sidebar-content
  .ec-change-lang
  #google_translate_element
  select
  option {
  color: #777;
  background: #ffffff;
}

.ec-tools-sidebar
  .ec-tools-sidebar-content
  .ec-change-lang
  #google_translate_element
  .goog-te-gadget
  .goog-te-combo {
  margin: 0;
  border: 0;
  padding: 4px 5px;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-tools-sidebar
  .ec-tools-sidebar-content
  .ec-change-lang
  #google_translate_element
  .goog-te-gadget
  .goog-te-combo:focus-visible,
.ec-tools-sidebar
  .ec-tools-sidebar-content
  .ec-change-lang
  #google_translate_element
  .goog-te-gadget
  .goog-te-combo:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 0 !important;
  outline: none !important;
}

.ec-tools-sidebar .ec-color-desc .ec-color-title {
  font-weight: 600;
  color: #ffffff;
  padding-right: 7px;
}

.ec-tools-sidebar .ec-color-desc ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.ec-tools-sidebar .ec-color-desc ul li {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.ec-tools-sidebar .ec-color-desc ul li a {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-align: center;
}

.ec-tools-sidebar .ec-color-desc ul li:hover {
  margin-left: 0 !important;
}

.ec-tools-sidebar .ec-color-desc ul li:hover a {
  margin-left: 0 !important;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.ec-tools-sidebar .ec-color-desc ul li a.colorcode1 {
  background-color: #3474d4;
}

.ec-tools-sidebar .ec-color-desc ul li a.colorcode2 {
  background-color: #7f77fd;
}

.ec-tools-sidebar .ec-color-desc ul li a.colorcode3 {
  background-color: #ff764b;
}

.ec-tools-sidebar .ec-color-desc ul li a.colorcode4 {
  background-color: #009688;
}

.ec-tools-sidebar .ec-color-desc ul li a.colorcode5 {
  background-color: #db3a5d;
}

.ec-tools-sidebar .ec-color-desc ul li.active {
  pointer-events: none;
}

.ec-tools-sidebar .ec-color-desc ul li.active a:before {
  content: "";
  color: #ffffff;
  font-size: 0.857rem;
  line-height: 1;
  position: relative;
  top: 0;
  font-family: "EcIcons";
  font-style: normal;
  font-weight: 400;
}

.ec-tools-sidebar .ec-change-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 0;
  white-space: nowrap;
  height: 30px;
  border-radius: 5px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  width: 90px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.ec-tools-sidebar .ec-change-mode > * {
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.857rem;
  border-radius: 30px;
  overflow: hidden;
}

.ec-tools-sidebar .ec-change-mode .ec-mode-btn {
  width: 45px;
  pointer-events: none;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#737373),
    to(#000)
  );
  background: linear-gradient(#737373, #000);
  padding: 0 10px 0;
  font-size: 10px;
  cursor: pointer;
}

.ec-tools-sidebar .ec-change-mode .ec-mode-switch > * {
  width: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 10px 0;
}

.ec-tools-sidebar .ec-change-mode .ec-mode-off {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ff9a9a),
    to(red)
  );
  background: linear-gradient(#ff9a9a, red);
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-tools-sidebar .ec-change-mode .ec-mode-on {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a1ff9e),
    to(#0b8600)
  );
  background: linear-gradient(#a1ff9e, #0b8600);
  color: #ffffff;
  display: none;
}

.ec-tools-sidebar .ec-change-mode:hover {
  width: 90px;
}

.ec-tools-sidebar .ec-change-mode.active {
  width: 90px;
}

.ec-tools-sidebar .ec-change-mode.active .ec-mode-on {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-tools-sidebar .ec-change-mode.active .ec-mode-off {
  display: none;
}

.ec-tools-sidebar .ec-change-rtl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 0;
  white-space: nowrap;
  height: 30px;
  border-radius: 5px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  width: 90px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.ec-tools-sidebar .ec-change-rtl > * {
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.857rem;
  border-radius: 30px;
  overflow: hidden;
}

.ec-tools-sidebar .ec-change-rtl .ec-rtl-btn {
  pointer-events: none;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#737373),
    to(#000)
  );
  background: linear-gradient(#737373, #000);
  padding: 0 10px 0;
}

.ec-tools-sidebar .ec-change-rtl .ec-rtl-switch > * {
  width: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 10px 0;
}

.ec-tools-sidebar .ec-change-rtl .ec-rtl-off {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ff9a9a),
    to(red)
  );
  background: linear-gradient(#ff9a9a, red);
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-tools-sidebar .ec-change-rtl .ec-rtl-on {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a1ff9e),
    to(#0b8600)
  );
  background: linear-gradient(#a1ff9e, #0b8600);
  color: #ffffff;
  display: none;
}

.ec-tools-sidebar .ec-change-rtl:hover {
  width: 90px;
}

.ec-tools-sidebar .ec-change-rtl.active {
  width: 90px;
}

.ec-tools-sidebar .ec-change-rtl.active .ec-rtl-on {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-tools-sidebar .ec-change-rtl.active .ec-rtl-off {
  display: none;
}

.ec-tools-sidebar .ec-fullscreen-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 0;
  white-space: nowrap;
  height: 30px;
  border-radius: 5px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  width: 90px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.ec-tools-sidebar .ec-fullscreen-mode > * {
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.857rem;
  border-radius: 30px;
  overflow: hidden;
}

.ec-tools-sidebar .ec-fullscreen-mode .ec-fullscreen-btn {
  width: 45px;
  pointer-events: none;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#737373),
    to(#000)
  );
  background: linear-gradient(#737373, #000);
  padding: 0 10px 0;
  font-size: 10px;
  cursor: pointer;
}

.ec-tools-sidebar .ec-fullscreen-mode .ec-fullscreen-switch > * {
  width: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 10px 0;
}

.ec-tools-sidebar .ec-fullscreen-mode .ec-fullscreen-off {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ff9a9a),
    to(red)
  );
  background: linear-gradient(#ff9a9a, red);
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-tools-sidebar .ec-fullscreen-mode .ec-fullscreen-on {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a1ff9e),
    to(#0b8600)
  );
  background: linear-gradient(#a1ff9e, #0b8600);
  color: #ffffff;
  display: none;
}

.ec-tools-sidebar .ec-fullscreen-mode:hover {
  width: 90px;
}

.ec-tools-sidebar .ec-fullscreen-mode.active {
  width: 90px;
}

.ec-tools-sidebar .ec-fullscreen-mode.active .ec-fullscreen-on {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-tools-sidebar .ec-fullscreen-mode.active .ec-fullscreen-off {
  display: none;
}

.emp-wishlist-msg {
  padding: 30px 0;
  text-align: center;
  font-size: 50px;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #777;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
}

/*----  Particles  ----*/
.particles {
  overflow: hidden;
}

.particles canvas {
  display: block;
  vertical-align: bottom;
}

.particles .count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 0 0 3px 3px;
}

.particles .js-count-particles {
  font-size: 1.1em;
}

.particles #stats {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}

.particles #particles-js {
  width: 100%;
  height: 100vh;
  background-image: url("");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

/*----  Maintenance page  ----*/
.ec-under-maintenance {
  height: 100vh;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-under-maintenance .under-maintenance {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-under-maintenance .under-maintenance .maintenance-img {
  width: 80%;
  padding: 15px;
}

.ec-under-maintenance .under-maintenance .logo img {
  width: 150px;
  padding: 0;
}

.ec-under-maintenance .under-maintenance p {
  max-width: 500px;
  margin-bottom: 15px;
  color: #777;
  text-align: center;
}

.ec-under-maintenance .under-maintenance h1,
.ec-under-maintenance .under-maintenance .h1 {
  margin-bottom: 15px;
  color: #444;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 28px;
}

.ec-under-maintenance .under-maintenance h4,
.ec-under-maintenance .under-maintenance .h4 {
  margin-bottom: 15px;
  color: #666;
  text-align: center;
  text-transform: capitalize;
  font-size: 20px;
}

.ec-under-maintenance .under-maintenance .mainte-counter {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-under-maintenance .under-maintenance .mainte-counter #timer .date-box {
  color: #3474d4;
  border-color: #3474d4;
}

.ec-under-maintenance
  .under-maintenance
  .mainte-counter
  #timer
  .date-box
  .text {
  color: #777;
}

/*----  Main page Contant without server or localhost popup  ----*/
.ec-direct-run {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 16;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ec-direct-run .ec-direct-body {
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 500px;
}

.ec-direct-run .ec-direct-body h4,
.ec-direct-run .ec-direct-body .h4 {
  padding: 0 0 10px 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  border-bottom: 1px solid;
}

.ec-direct-run .ec-direct-body p {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

/*----  Main page Contant with lazy load  ----*/
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideInleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes zoomReverseIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes zoomReverseIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

[data-animation] {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.animations-disabled,
.animations-disabled [data-animation] {
  -webkit-animation: none !important;
  animation: none !important;
  opacity: 1 !important;
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

.slideInLeft {
  -webkit-animation-name: slideInleft;
  animation-name: slideInleft;
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-direction: reverse;
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.zoomOut {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  animation-direction: reverse;
}

.zoomReverseIn {
  -webkit-animation-name: zoomReverseIn;
  animation-name: zoomReverseIn;
}

.zoomReverseOut {
  -webkit-animation-name: zoomReverseIn;
  animation-name: zoomReverseIn;
  animation-direction: reverse;
}

.flipInY {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

.flipOutY {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  animation-direction: reverse;
}

/*-------------------------------------------------
  Popup Model Dialog CSS
---------------------------------------------------*/
.modal-dialog {
  height: 100%;
  margin: 0% auto;
  max-width: 960px;
  width: 800px;
  padding: 35px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal-dialog .modal-header {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 10px 10px;
  border-bottom: 1px solid #eeeeee;
}

.modal-dialog .modal-header span {
  font-size: 24px;
  color: #444444;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal-dialog .modal-header span:hover {
  color: #555;
}

.modal-dialog .qty-nav-thumb {
  padding: 0 30px;
  margin-top: 20px;
}

.modal-dialog .qty-nav-thumb .slick-slide img {
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal-dialog .qty-nav-thumb .slick-slide:hover img {
  border-color: #555;
}

.modal-dialog .qty-nav-thumb .slick-slide.slick-current img {
  border-color: #555;
}

.modal-dialog .qty-nav-thumb .slick-arrow {
  height: 100%;
  top: 0;
}

.modal-dialog .qty-slide {
  display: block !important;
  padding: 2px;
}

.modal.fade {
  display: block !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal.fade.show {
  display: block !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal-dialog {
  padding: 30px;
}

/*-------------------------------------------------
  Breadcrumb Style CSS
---------------------------------------------------*/
.ec-breadcrumb {
  padding: 15px 0;
  background-color: #f7f7f7;
}

.ec-breadcrumb .ec-breadcrumb-title {
  text-decoration: none;
  color: #444444;
  display: block;
  font-size: 15px;
  font-family: "Poppins";
  line-height: 22px;
  font-weight: 700;
  margin: 0 auto;
  text-transform: capitalize;
}

.ec-breadcrumb-list {
  text-align: right;
}

.ec-breadcrumb-list li {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.02rem;
  line-height: 1.2;
  text-transform: capitalize;
}

.ec-breadcrumb-list li a {
  position: relative;
  color: #444444;
}

.ec-breadcrumb-list li.active {
  color: #3474d4;
}

.ec-breadcrumb-list .ec-breadcrumb-item.active::before {
  color: #3474d4;
}

.ec-breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-breadcrumb-item + .ec-breadcrumb-item {
  padding-left: 7px;
}

.ec-breadcrumb-item + .ec-breadcrumb-item::before {
  display: inline-block;
  padding-right: 7px;
  color: #444444;
  content: "";
  font-family: "EcIcons";
  font-size: 15px;
}

/*-------------------------------------------------
  Site Header Section CSS
---------------------------------------------------*/
.ec-header {
  background: #ffffff;
  margin-top: -1px;
}

/*----  Fixed Header  ----*/
.sticky-nav.menu_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 16;
  -webkit-box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.2);
  background: #ffffff;
  -webkit-animation: smoothScroll 1s forwards;
  animation: smoothScroll 1s forwards;
}

@-webkit-keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

/*----  Social Style  ----*/
.header-top {
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
}

.header-top-social ul {
  display: initial;
}

.header-top-social ul li a {
  width: 25px;
  height: 25px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 100%;
  color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
  background: #f7f7f7;
  border: 1px solid #eeeeee;
  color: #777;
}

.header-top-social ul li a:hover {
  color: #fff;
  border: 0;
}

.header-top-social ul li .hdr-facebook:hover {
  background: #3b5998;
}

.header-top-social ul li .hdr-twitter:hover {
  background: #1da1f2;
}

.header-top-social ul li .hdr-instagram:hover {
  background: #e44a73;
}

.header-top-social ul li .hdr-linkedin:hover {
  background: #0073b2;
}

.header-top-social ul li.list-inline-item:not(:last-child) {
  margin-right: 4px;
}

.social-text {
  padding-right: 3px;
  font-size: 0.928rem;
  display: none;
}

/*----  Nav Offer Style  ----*/
.header-top-message {
  font-size: 0.928rem;
}

.header-top-message span {
  color: #777;
  padding-right: 5px;
}

/*----  Language & Currency  ----*/
.header-top-lan-curr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.header-top-lan-curr .dropdown .dropdown-toggle {
  font-size: 0.928rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 18px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #777;
  border: none;
  padding-right: 0;
  letter-spacing: 0.5px;
}

.header-top-lan-curr .dropdown .dropdown-toggle i {
  font-size: 18px;
  margin-left: 7px;
  color: #777;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-bottom: 1px;
}

.header-top-lan-curr .dropdown .dropdown-toggle::after {
  display: none;
}

.header-top-lan-curr .dropdown .dropdown-toggle:hover {
  color: #555;
}

.header-top-lan-curr .dropdown .dropdown-toggle:hover i {
  color: #555;
}

.header-top-lan-curr .dropdown .dropdown-menu {
  margin: 0;
  top: 34px !important;
  left: auto !important;
  right: 0 !important;
  min-width: 130px;
  overflow: hidden;
  padding: 0 10px;
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

.header-top-lan-curr .dropdown .dropdown-menu li {
  border-bottom: 1px solid #ebebeb;
}

.header-top-lan-curr .dropdown .dropdown-menu li:last-child {
  border: none;
}

.header-top-lan-curr .dropdown .dropdown-menu .dropdown-item {
  padding: 7px;
  color: #777;
  font-size: 0.928rem;
  font-weight: 300;
  background: transparent;
  text-transform: uppercase;
}

.header-top-lan-curr .dropdown .dropdown-menu .dropdown-item:hover {
  color: #555;
}

.header-top-lan-curr .dropdown .dropdown-menu li.active .dropdown-item {
  color: #555;
}

.ec-header-bottom {
  background-color: transparent;
  padding: 25px 0;
  border-bottom: 1px solid #eeeeee;
}

.ec-header-login {
  padding: 13px 0;
}

.ec-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/*----  Site Logo  ----*/
.header-logo {
  text-align: left;
}

.header-logo img {
  width: 181px;
}

/*----  Header Button Actions  ----*/
.header_svg {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 36px;
  height: 36px;
  fill: #444444;
}

.ec-header-wishlist .header_svg {
  width: 33px;
  height: 33px;
}

.ec-header-bottons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.ec-header-bottons > * + * {
  padding-right: 9px;
  margin-left: 27px;
}

.ec-header-bottons .ec-header-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  color: #444444;
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 6px;
}

.ec-header-bottons .ec-header-btn:hover {
  color: #555;
}

.ec-header-bottons .ec-header-btn:hover .ec-header-count {
  color: #ffffff;
  background-color: #555;
}

.ec-header-bottons .ec-header-btn:hover .header_svg {
  fill: #3474d4;
}

.ec-header-bottons .ec-header-btn:hover .cart-amount {
  color: #555;
}

.ec-header-bottons .ec-header-btn i {
  font-size: 24px;
  line-height: 1;
}

.ec-header-bottons .ec-header-btn .ec-header-count {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 0.857rem;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  z-index: 2;
  top: auto;
  right: 0;
  height: 20px;
  min-width: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  background-color: #777777;
  bottom: 0;
  padding: 0 4px;
}

.ec-header-bottons .ec-header-btn .cart-amount {
  display: block;
  font-size: 1.143rem;
  font-weight: 700;
  text-align: center;
  padding-left: 15px;
  color: #0f0f0f;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-header-bottons .ec-header-btn.ec-header-wishlist {
  padding-bottom: 0;
  padding-right: 8px;
  margin-left: 34px;
}

.ec-header-bottons .ec-header-btn.ec-header-wishlist .header-icon {
  margin-top: 3px;
}

.ec-header-bottons .ec-header-btn.dropdown-toggle::after {
  display: none;
}

.ec-header-bottons .ec-header-user:hover .header_svg {
  fill: #3474d4;
}

.ec-header-user .dropdown-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-top: 5px;
}

.ec-header-user .dropdown-toggle::after {
  display: none;
}

.ec-header-user .dropdown-menu {
  margin: 0;
  top: 50px !important;
  left: auto !important;
  right: 0 !important;
  min-width: 130px;
  overflow: hidden;
  padding: 0 10px;
  background: #ffffff;
  border-radius: 0;
  -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
  border: 1px solid #d9d9d9;
}

.ec-header-user .dropdown-menu li {
  border-bottom: 1px solid #ebebeb;
}

.ec-header-user .dropdown-menu li:last-child {
  border: none;
}

.ec-header-user .dropdown-menu .dropdown-item {
  padding: 7px;
  color: #777;
  font-size: 0.928rem;
  font-weight: 300;
  background: transparent;
  text-transform: uppercase;
}

.ec-header-user .dropdown-menu .dropdown-item:hover {
  color: #555;
}

/*----  Main Menu  ----*/
.ec-main-menu-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-main-menu-login ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.ec-main-menu-login ul li {
  margin-left: 22px;
  margin-right: 22px;
}

.ec-main-menu-login ul li a {
  font-size: 14px;
  line-height: 50px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
  display: block;
  position: relative;
  font-family: "Poppins";
}

.ec-main-menu-login ul li .main-label-note-new {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 10px;
  height: 10px;
  margin: auto;
  background-color: #ec716d;
  border-radius: 50%;
  cursor: default;
  display: block;
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  z-index: 3;
  -webkit-animation: blink-lbl-back 2.3s infinite;
  animation: blink-lbl-back 2.3s infinite;
}

@-webkit-keyframes blink-lbl-back {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  80% {
    opacity: 0.4;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes blink-lbl-back {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  80% {
    opacity: 0.4;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes blink-lbl {
  0% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes blink-lbl {
  0% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.ec-main-menu-login ul li:hover > a {
  color: #3474d4 !important;
}

.ec-main-menu-login ul li .mega-menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-top: 15px;
  padding: 20px 0px 30px 30px;
  position: absolute;
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  left: 0;
  z-index: 15;
}

.ec-main-menu-login ul li .mega-menu li ul {
  width: 25%;
  margin-right: 30px;
}

.ec-main-menu-login ul li .mega-menu li ul li.menu_title a {
  color: #444444;
  font-size: 14px;
  font-weight: 600;
  display: block;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding-bottom: 5px;
  height: auto;
}

.ec-main-menu-login ul li .mega-menu li ul li.menu_title a:hover {
  color: #555;
}

.ec-main-menu-login ul li .mega-menu li ul li a {
  color: #777;
  text-transform: capitalize;
  line-height: 30px;
  font-weight: 400;
  font-size: 14px;
  display: block;
  padding: 3px 0;
  border: 0;
  height: auto;
}

.ec-main-menu-login ul li .mega-menu li ul li a:hover {
  color: #555;
}

.ec-main-menu-login ul li .mega-menu ul.ec-main-banner {
  margin-top: 20px;
}

.ec-main-menu-login ul li .mega-menu ul.ec-main-banner li {
  width: 25%;
  padding-right: 30px;
}

.ec-main-menu-login ul li .mega-menu ul.ec-main-banner li a {
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  height: auto;
}

.ec-main-menu-login ul li.active > a {
  color: #3474d4 !important;
}

.ec-main-menu-login ul li.dropdown {
  position: relative;
}

.ec-main-menu-login ul li.dropdown ul.sub-menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  min-width: 215px;
  margin-top: 15px;
  padding: 15px 0;
  position: absolute;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  left: auto !important;
  background: #ffffff;
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  display: block;
  z-index: 9;
}

.ec-main-menu-login ul li.dropdown ul li {
  padding: 0;
  margin: 0;
  display: block;
}

.ec-main-menu-login ul li.dropdown ul li a {
  display: block;
  line-height: 20px;
  padding: 8px 20px;
  font-weight: 400;
  font-size: 14px;
  color: #777;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-main-menu-login ul li.dropdown ul li .label-note-new {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 5px;
  height: 20px;
  background: #faa4a4;
  cursor: default;
  display: none;
  position: absolute;
  top: 8px;
  left: 0px;
  z-index: 16;
  border-radius: 0 4px 4px 0;
}

.ec-main-menu-login ul li.dropdown ul li .label-note-hot {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 5px;
  height: 20px;
  background: #8ab386;
  cursor: default;
  display: none;
  position: absolute;
  top: 8px;
  left: 0px;
  z-index: 16;
  border-radius: 0 4px 4px 0;
}

.ec-main-menu-login ul li.dropdown ul li .label-note-trending {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 5px;
  height: 20px;
  background: #1980af;
  cursor: default;
  display: none;
  position: absolute;
  top: 8px;
  left: 0px;
  z-index: 16;
  border-radius: 0 4px 4px 0;
}

.ec-main-menu-login ul li.dropdown li.position-static {
  position: relative !important;
}

.ec-main-menu-login
  ul
  li.dropdown
  li.position-static:hover
  .sub-menu.sub-menu-child {
  margin: 0;
  opacity: 1;
  visibility: visible;
}

.ec-main-menu-login ul li.dropdown ul.sub-menu.sub-menu-child {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 15px 0 0 0;
  left: 100% !important;
  opacity: 0;
  visibility: hidden;
  top: 0;
}

.ec-main-menu-login ul li.dropdown:hover .sub-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.ec-main-menu-login ul li.dropdown:hover .mega-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.ec-main-menu-login > ul > li > a:before {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  height: 2px;
  width: 0;
  margin: 0 auto;
  background: transparent;
  left: 0;
  right: auto;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-main-menu-login > ul > li:hover > a:before {
  background: #3474d4;
  width: 100%;
}

.ec-main-menu-login > ul > li.active > a:before {
  background: #3474d4;
  width: 100%;
}

/*----  Desktop search  ----*/
.header-search {
  width: 500px;
  position: relative;
}

.header-search .form-control {
  display: block;
  width: 100%;
  min-height: 45px;
  height: 45px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #777777;
  border-radius: 0;
  background-color: #f7f7f7;
  border: none;
  letter-spacing: 0.5px;
}

.action-form {
  position: relative;
}

.submit {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 100%;
  background: transparent;
  color: #555;
  font-size: 1.143rem;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  padding: 0;
}

.submit .header_svg {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 14px;
  height: 14px;
  fill: #555;
  margin-bottom: 1px;
}

.submit .header_svg:hover {
  fill: #3474d4;
}

.submit:hover {
  color: #3474d4;
}

.submit:hover .header_svg {
  fill: #3474d4;
}

/*----  Side Cart & Side Menu Toggle  ----*/
body.ec-open {
  overflow: hidden;
}

body.ec-open:before {
  height: 100%;
  width: 100%;
  background: #000000;
  opacity: 0.7;
  position: absolute;
  z-index: 998;
  content: "";
}

.ec-side-cart-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 16;
}

.ec-side-cart {
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  width: 350px;
  height: 100%;
  padding: 15px 20px 20px 20px;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  background-color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: auto;
  opacity: 0;
}

.ec-side-cart .text-right {
  font-weight: 700;
}

.ec-side-cart .ec-cart-inner {
  position: relative;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ec-side-cart .ec-cart-inner .ec-cart-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 10px 0;
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 20px;
}

.ec-side-cart .ec-cart-inner .ec-cart-title .cart_title {
  font-size: 17px;
  color: #555;
  font-family: "Poppins,sans-serif";
  font-weight: 600;
}

.ec-side-cart .ec-cart-inner .ec-cart-title .ec-close {
  position: relative;
  border: 0;
  font-size: 30px;
  line-height: 1;
  color: #555;
}

.ec-side-cart .ec-cart-inner .ec-cart-bottom .cart_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ec-side-cart .ec-cart-inner .ec-cart-bottom .cart_btn a {
  width: 48%;
  height: 40px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 8px 15px;
  line-height: 22px;
}

.ec-side-cart .ec-cart-inner .ec-cart-bottom .cart-sub-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 8px;
  border-top: 1px solid #eeeeee;
  margin: 20px 0 0 0px;
}

.ec-side-cart .ec-cart-inner .ec-cart-bottom .cart-sub-total table {
  margin: 10px 0 0 0;
}

.ec-side-cart .ec-cart-inner .ec-cart-bottom .cart-sub-total table tr td {
  border: none;
}

.ec-side-cart .ec-menu-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 10px 0;
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 30px;
  margin-bottom: 10px;
}

.ec-side-cart .ec-menu-title .menu_title {
  font-size: 18px;
  color: #3474d4;
  font-family: "Poppins";
  font-weight: 600;
}

.ec-side-cart .ec-menu-title .ec-close {
  position: relative;
  border: 0;
  font-size: 30px;
  line-height: 1;
  color: #555;
}

.ec-side-cart .ec-menu-inner .ec-menu-content ul li {
  position: relative;
  display: block;
  line-height: 28px;
}

.ec-side-cart .ec-menu-inner .ec-menu-content ul li a {
  display: block;
  padding: 10px 0px;
  text-transform: capitalize;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-size: 15px;
  font-weight: 500;
}

.ec-side-cart .ec-menu-inner .ec-menu-content ul li .sub-menu {
  position: static;
  top: auto;
  display: none;
  visibility: visible;
  width: 100%;
  min-width: auto;
  padding: 0;
  -webkit-transition: none;
  transition: none;
  opacity: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-side-cart .ec-menu-inner .ec-menu-content ul li .sub-menu li {
  line-height: inherit;
  position: relative;
}

.ec-side-cart .ec-menu-inner .ec-menu-content ul li .sub-menu li a {
  text-transform: capitalize;
  padding-left: 15px;
  padding-right: 0px;
  display: block;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  font-weight: 500;
  color: #777;
}

.ec-side-cart
  .ec-menu-inner
  .ec-menu-content
  ul
  li
  .sub-menu
  li
  .sub-menu
  li
  a {
  font-weight: 300;
  padding-left: 30px;
  font-size: 14px;
}

.ec-side-cart .ec-menu-inner .ec-menu-content ul li:hover > a {
  color: #555;
}

.ec-side-cart
  .ec-menu-inner
  .ec-menu-content
  ul
  li:hover
  > span.menu-toggle:before {
  color: #555;
}

.ec-side-cart .ec-menu-inner .ec-social li {
  display: inline-block;
  margin: 0 10px 0px 0;
  line-height: 40px;
  padding: 0;
}

.ec-side-cart .ec-menu-inner .ec-social li a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #ffffff;
  background: #555;
  font-size: 1.143rem;
  padding: 0;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  border: none;
  z-index: 1;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.ec-side-cart .ec-menu-inner .ec-social li a:hover {
  background: #212121;
  color: #ffffff;
}

.ec-menu-content > ul > li .menu-toggle {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 24px;
  height: 44px;
  cursor: pointer;
  background-color: transparent;
}

.ec-menu-content > ul > li .menu-toggle::before {
  width: 100%;
  content: "";
  font-family: "EcIcons";
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  color: #444444;
  font-size: 0.857rem;
}

.ec-menu-content > ul > li.active > .menu-toggle::before {
  content: "";
}

.ec-menu-content > ul > li.active > ul > li.active > .menu-toggle::before {
  content: "";
}

.ec-side-cart.ec-mobile-menu {
  right: auto;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  width: 340px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ec-side-cart.ec-mobile-menu.ec-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.ec-side-cart.ec-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.ec-side-cart.ec-cart .ec-cart-inner .ec-cart-title {
  margin-bottom: 30px;
  padding: 0;
}

.ec-side-cart.ec-cart .ec-cart-inner .ec-cart-title .cart_title {
  font-size: 20px;
}

.ec-side-cart.ec-wishlist .ec-cart-inner .ec-cart-title {
  margin-bottom: 30px;
  padding: 0;
}

.ec-side-cart.ec-wishlist .ec-cart-inner .ec-cart-title .cart_title {
  font-size: 20px;
}

.eccart-pro-items {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.eccart-pro-items li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.eccart-pro-items li .sidekka_pro_img {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 20%;
  flex: 1 0 20%;
}

.eccart-pro-items li .sidekka_pro_img img {
  max-width: 100%;
}

.eccart-pro-items li .ec-pro-content {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 70%;
  flex: 1 0 70%;
  padding-left: 15px;
  overflow: hidden;
}

.eccart-pro-items li .ec-pro-content .cart_pro_title {
  width: 100%;
  padding-right: 30px;
  text-decoration: none;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
}

.eccart-pro-items li .ec-pro-content .cart_pro_title:hover {
  color: #3474d4;
}

.eccart-pro-items li .ec-pro-content .cart-price {
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

.eccart-pro-items li .ec-pro-content .cart-price span {
  color: #777;
  font-weight: 700;
  font-size: 1.143rem;
}

.eccart-pro-items li .ec-pro-content .remove {
  line-height: 15px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 9px;
  color: red;
  font-size: 1.143rem;
  background: #ffffff;
}

.eccart-pro-items li .ec-pro-content .remove:hover {
  color: #eb2606;
}

.eccart-pro-items li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.eccart-pro-items li .ec-pro-content .qty-plus-minus {
  border: 1px solid #eeeeee;
  display: inline-block;
  height: 35px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 7px 0;
  margin-top: 7px;
}

.eccart-pro-items li .ec-pro-content .qty-plus-minus .ec_qtybtn {
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  color: #777;
}

.eccart-pro-items li .ec-pro-content .qty-plus-minus input.qty-input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #777;
  float: left;
  font-size: 14px;
  height: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 32px;
  outline: none;
  font-weight: 400;
  line-height: 35px;
}

.eccart-pro-items li .ec-pro-content .qty-plus-minus .dec.ec_qtybtn {
  border-right: 1px solid #eeeeee;
}

.eccart-pro-items li .ec-pro-content .qty-plus-minus .inc.ec_qtybtn {
  border-left: 1px solid #eeeeee;
}

/*-------------------------------------------------
  Product Tab Section CSS
---------------------------------------------------*/
/*----  Product nav tab  ----*/
.ec-pro-tab-nav {
  margin: -12px 0 30px;
}

.ec-pro-tab-nav .nav-item {
  margin-right: 50px;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
  line-height: 36px;
  letter-spacing: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-pro-tab-nav .nav-item:last-child {
  margin-right: 0;
}

.ec-pro-tab-nav .nav-item .nav-link {
  color: #444444;
  padding: 0;
  position: relative;
  font-weight: 600;
}

.ec-pro-tab-nav .nav-item .nav-link:before {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  height: 2px;
  width: 0;
  margin: 0 auto;
  background: transparent;
  left: 0;
  right: auto;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-pro-tab-nav .nav-item .nav-link:hover {
  color: #3474d4;
}

.ec-pro-tab-nav .nav-item .nav-link:hover:before {
  background: #3474d4;
  width: 100%;
}

.ec-pro-tab-nav .nav-item .nav-link.active {
  color: #3474d4;
}

.ec-pro-tab-nav .nav-item .nav-link.active:before {
  background: #3474d4;
  width: 100%;
}

.shop-all-btn {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-all-btn a {
  color: #444444;
  border-bottom: 1px solid #444444;
  line-height: 26px;
  font-size: 1.143rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
}

/*----  Product Style  ----*/
.ec-pro-variation {
  margin: 5px 0 0px;
}

.ec-pro-variation .ec-pro-variation-inner {
  font-size: 1.143rem;
  font-weight: 600;
  color: #212121;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-pro-variation .ec-pro-variation-inner > span {
  display: block;
  margin: 0 0 5px;
}

.ec-pro-variation .ec-pro-variation-inner .ec-pro-variation-content li {
  display: inline-block;
  border: 1px solid transparent;
  padding: 1px;
  float: left;
  cursor: pointer;
  margin-right: 5px;
}

.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-color
  .ec-pro-variation-content
  li {
  border-radius: 100%;
}

.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-color
  .ec-pro-variation-content
  li
  span {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 100%;
}

.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-color
  .ec-pro-variation-content
  li:hover {
  border: 1px solid #444444;
}

.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-size
  .ec-pro-variation-content
  li {
  height: 100%;
  cursor: pointer;
  background-color: #eeeeee;
  color: #777777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  border-radius: 5px;
}

.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-size
  .ec-pro-variation-content
  li:hover {
  background-color: #e2e2e2;
}

.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-size
  .ec-pro-variation-content
  .active {
  background-color: #e2e2e2;
}

button.btn-close.qty_close {
  position: absolute;
  left: auto;
  right: 10px;
  top: 10px;
  z-index: 9999;
}

.ec-product-inner .ec-pro-image {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  border: 1px solid #eeeeee;
}

.ec-product-inner .ec-pro-image .image {
  position: relative;
  display: block;
  overflow: hidden;
  pointer-events: none;
}

.ec-product-inner .ec-pro-image .image img {
  z-index: 1;
  max-width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-inner .ec-pro-image .image img.hover-image {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0;
}

.ec-product-inner .ec-pro-image .flags {
  position: absolute;
  z-index: 8;
  top: 24px;
  left: auto;
  right: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: #555;
}

.ec-product-inner .ec-pro-image .flags .sale {
  padding: 0 5px;
  background-color: #81dca8;
}

.ec-product-inner .ec-pro-image .flags .new {
  padding: 0 5px;
  background-color: #8196dc;
}

.ec-product-inner .ec-pro-image .flags span {
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff;
  letter-spacing: 1px;
}

.ec-product-inner .ec-pro-image .flags + .flags {
  right: 20px;
}

.ec-product-inner .ec-pro-image .percentage {
  position: absolute;
  z-index: 8;
  top: 13px;
  left: 0;
  font-size: 0.857rem;
  font-weight: 400;
  line-height: 24px;
  padding: 0 8px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ff6285;
  border-radius: 0 15px 15px 0;
  font-weight: 700;
}

.ec-product-inner .ec-pro-image a.quickview {
  visibility: hidden;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 auto;
  top: auto !important;
  bottom: 15px;
  right: 15px;
  left: auto;
  width: 35px;
  height: 35px;
  opacity: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  z-index: 9;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  border: 1px solid #eeeeee;
}

.ec-product-inner .ec-pro-image a.quickview:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-inner .ec-pro-image .ec-pro-actions {
  position: absolute;
  top: auto;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  visibility: hidden;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  bottom: 0;
  z-index: 9;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .ec-btn-group {
  position: absolute;
  right: 15px;
  bottom: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  cursor: pointer;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .ec-btn-group i {
  font-size: 14px;
  line-height: 1;
}

.ec-product-inner
  .ec-pro-image
  .ec-pro-actions
  .ec-btn-group:hover:not(.active),
.ec-product-inner
  .ec-pro-image
  .ec-pro-actions
  .ec-btn-group
  .active:not(.active) {
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .compare {
  position: absolute;
  right: 15px;
  bottom: 26px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .compare:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .wishlist {
  position: absolute;
  right: 15px;
  bottom: 62px;
  border: 1px solid #eeeeee;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .wishlist:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .active {
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .ec-btn-group.active {
  color: rgba(68, 68, 68, 0.7);
  background-color: #3474d4;
}

.ec-product-inner
  .ec-pro-image
  .ec-pro-actions
  .ec-btn-group.active
  .svg_img.pro_svg {
  fill: #fff;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .add-to-cart {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  width: 35px;
  right: 15px;
  bottom: 98px;
  margin: 0;
  padding: 0;
  font-size: 0;
  border-radius: 10px 10px 0 0;
  line-height: 1;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.ec-product-inner .ec-pro-image .ec-pro-actions .add-to-cart:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-inner .ec-pro-image .shop-cart-icon {
  padding: 0;
  width: 35px !important;
  font-size: 0 !important;
}

.ec-product-inner .ec-pro-image .shop-cart-icon .svg_img.pro_svg {
  margin-right: 0;
}

.ec-product-inner .ec-pro-image-outer {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 8;
  position: relative;
}

.ec-product-inner:hover a.quickview {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.ec-product-inner:hover .ec-pro-image .ec-pro-actions {
  visibility: visible;
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
  opacity: 1;
}

.ec-product-inner:hover .ec-pro-image a.quickview {
  visibility: visible;
  opacity: 1;
  top: 44%;
}

.ec-product-inner:hover .ec-pro-image .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.ec-product-inner:hover .ec-pro-image .image img:not(:last-child) {
  opacity: 0;
}

.ec-product-inner:hover .ec-pro-image .image img.hover-image {
  opacity: 1;
}

.ec-product-inner .ec-pro-content {
  position: relative;
  z-index: 7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 11px 0 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: left;
  background-color: #ffffff;
  letter-spacing: 0.6px;
}

.ec-product-inner .ec-pro-content .ec-pro-title {
  font-size: 20px;
  margin: 0 0 7px;
}

.ec-product-inner .ec-pro-content .ec-pro-title a {
  text-decoration: none;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 1.143rem;
  line-height: 1.5;
  font-weight: 400;
  font-family: "Poppins";
}

.ec-product-inner .ec-pro-content .ec-pro-title a:hover {
  color: #3474d4;
}

.ec-product-inner .ec-pro-content .ec-price {
  font-size: 1.143rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  color: #777777;
}

.ec-product-inner .ec-pro-content .ec-price:not(:last-child) {
  margin-bottom: 5px;
}

.ec-product-inner .ec-pro-content .ec-price span.new-price {
  color: #555;
  font-weight: 700;
  font-size: 15px;
  font-family: "Poppins";
}

.ec-product-inner .ec-pro-content .ec-price span.old-price {
  font-size: 15px;
  margin-right: 15px;
  text-decoration: line-through;
  color: #777777;
}

.ec-product-inner .ec-pro-content .btn {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.ec-product-inner .ec-pro-content .ec-pro-rating {
  margin-bottom: 5px;
}

.ec-product-inner .ec-pro-content .ec-pro-rating i {
  opacity: 0.7;
}

.ec-product-inner .ec-pro-image.pro-loading:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  opacity: 0.5;
  z-index: 98;
}

.ec-shop-product-inner .ec-pro-image .add-to-cart {
  width: 35px;
  padding: 0;
}

.ec-shop-product-inner .ec-pro-image .add-to-cart .svg_img.pro_svg {
  margin-right: 0;
}

.svg_img.pro_svg {
  height: 22px;
  fill: #686868;
  width: auto;
}

a.quickview .svg_img.pro_svg {
  height: 22px;
}

a.compare .svg_img.pro_svg {
  height: 22px;
}

a.wishlist .svg_img.pro_svg {
  height: 22px;
}

/*----  Product Image Loader  ----*/
.ec-pro-loader {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  display: none;
  background: url(assets/images/common/pro-loader.gif) no-repeat scroll 50% 50%;
  background-color: rgba(255, 255, 255, 0.9);
  background-size: 75px;
}

.pro-loading .ec-pro-loader {
  opacity: 1;
  display: block;
}

/*----  Product Ratting Star  ----*/
i.ecicon.eci-star,
i.ecicon.eci-star-o {
  margin-right: 3px;
  color: #b2b2b2;
  float: left;
}

i.ecicon.eci-star.fill {
  color: #eec317;
}

.ec-pro-option {
  width: 100%;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-pro-option ul {
  margin-top: -1px;
}

.ec-pro-option .ec-pro-opt-label {
  display: none;
}

/*----  Product Color And Size Style  ----*/
.ec-pro-color li {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border-radius: 30px;
  float: left;
  cursor: pointer;
  margin-right: 5px;
  opacity: 0.8;
  padding: 2px;
}

.ec-pro-color li span {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 30px;
}

.ec-pro-color li a {
  pointer-events: none;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

.ec-pro-color li:last-child {
  margin-right: 0;
}

.ec-pro-color li:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #bbb;
  box-shadow: 0px 0px 5px 0px #bbb;
}

.ec-pro-color li.active {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-pro-size li {
  display: inline-block;
  float: left;
  cursor: pointer;
  margin-right: 5px;
}

.ec-pro-size li:last-child {
  margin-right: 0;
}

.ec-pro-size li a {
  padding: 3px;
  pointer-events: none;
  height: 18px;
  min-width: 20px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.857rem;
  line-height: 24px;
  background-color: #f7f7f7;
  color: #777777;
  font-weight: 400;
}

.ec-pro-size li.active a {
  background-color: #e2e2e2;
}

.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
  position: relative;
}

.tab-content .tab-pane .panel-overlay {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
}

.tab-content .tab-pane:hover .panel-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*-------------------------------------------------
  Services Section CSS
---------------------------------------------------*/
.ec_ser_inner {
  padding: 30px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border: 1px solid #f4f4f4;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}

.ec_ser_inner .ec-service-image {
  margin-bottom: 15px;
}

.ec_ser_inner .ec-service-image svg {
  width: 50px;
  height: 50px;
  fill: #444444;
  stroke: #444444;
  stroke-dashoffset: 1500;
  stroke-dasharray: 1500;
}

.ec_ser_inner:hover {
  box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
}

.ec_ser_inner:hover .ec-service-image svg {
  stroke-dashoffset: 0;
  -webkit-transition: fill 0.5s, stroke-dashoffset 6s, -webkit-transform 0.3s;
  transition: fill 0.5s, stroke-dashoffset 6s, -webkit-transform 0.3s;
  transition: transform 0.3s, fill 0.5s, stroke-dashoffset 6s;
  transition: transform 0.3s, fill 0.5s, stroke-dashoffset 6s,
    -webkit-transform 0.3s;
}

.ec-service-desc h2,
.ec-service-desc .h2 {
  font-size: 18px;
  font-weight: 700;
  color: #444444;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
}

.ec-service-desc p {
  font-size: 14px;
  color: #777777;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

/*-------------------------------------------------
  New Product CSS
---------------------------------------------------*/
section.ec-new-product .section-title .sub-title {
  margin-top: 7px;
}

/*-------------------------------------------------
  Testimonial Section CSS
---------------------------------------------------*/
section.ec-test-section {
  background: #f9f9f9;
}

.ec-test-section .ec-test-inner {
  max-width: 730px;
  margin: 0 auto;
  cursor: pointer;
}

.ec-test-section .ec-test-item {
  max-width: 1140px;
  margin: 0 auto;
  background: #ffffff;
  padding: 29px 25px;
  position: relative;
}

.ec-test-section .slick-slide {
  padding: 27px 0;
}

.ec-test-section .ec-test-desc {
  font-size: 15px;
  color: #777777;
  line-height: 26px;
  letter-spacing: 0;
  margin-bottom: 15px;
}

.ec-test-section .ec-test-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ec-test-section .ec-test-name {
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 6px;
  color: #3474d4;
  letter-spacing: 0.6px;
  line-height: 1.2;
}

.ec-test-section .ec-test-designation {
  font-size: 1.143rem;
  color: #444444;
  line-height: 1.2;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.ec-test-section .ec-test-rating {
  margin: 0 auto;
}

.ec-test-section .ec-test-rating i {
  font-size: 11px;
  margin: 0 0.5px;
}

.ec-test-section .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-test-section .slick-dots li {
  width: 100px;
  background-color: #ffffff;
  border: 3px solid #555;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  display: inline-block;
  height: 100px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0.5;
}

.ec-test-section .slick-dots li:hover {
  margin: 0 15px;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.ec-test-section .slick-dots li img {
  max-width: 100%;
}

.ec-test-section .slick-dots li.slick-active {
  margin: 0 15px;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

#ec-testimonial-slider {
  text-align: center;
}

.ec-test-inner .ec-test-img {
  display: none;
}

.svg_img.test_svg {
  position: absolute;
  width: 45px;
  height: auto;
  fill: #555;
}

.svg_img.test_svg.top {
  top: -24px;
  left: 63px;
  right: auto;
}

.svg_img.test_svg.bottom {
  bottom: -27px;
  right: 88px;
  left: auto;
}

/*-------------------------------------------------
  Instagram Section CSS
---------------------------------------------------*/
section.ec-instagram-section {
  overflow: hidden;
}

.ec-instagram-section .section-title .sub-title {
  margin-top: 7px;
}

.ec-insta-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ec-insta-wrapper {
  margin: 0 -15px;
}

.ec-insta-item {
  width: 250px;
  padding: 0 15px;
}

.ec-insta-inner a {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: block;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  overflow: hidden;
}

.ec-insta-inner a:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #3474d4;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-insta-inner a:before {
  font-family: "EcIcons";
  content: "";
  font-size: 45px;
  top: 52%;
  left: 50%;
  color: #ffffff;
  z-index: 3;
  position: absolute;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  line-height: 1;
  margin-left: -20px;
  margin-top: -28px;
}

.ec-insta-inner a:hover:after {
  opacity: 0.8;
}

.ec-insta-inner a:hover:before {
  opacity: 1;
  visibility: visible;
}

.ec-insta-inner img {
  max-width: 100%;
}

/*-------------------------------------------------
  Site Footer Section CSS
---------------------------------------------------*/
.ec-footer {
  color: #777777;
  background: #ffffff;
}

.ec-footer .footer-top {
  border-top: 1px solid #eeeeee;
}

.ec-footer .footer-top .ec-footer-widget .ec-footer-heading {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 11px;
  color: #777;
  letter-spacing: 0.7px;
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}

.ec-footer .footer-top .ec-footer-widget .ec-footer-links .ec-footer-link {
  display: block;
  margin: 0;
  font-size: 0.928rem;
  line-height: 26px;
  border: 0;
  padding: 0;
  font-weight: 400;
  color: #777;
}

.ec-footer .footer-top .ec-footer-widget .ec-footer-links .ec-footer-link span {
  color: #777;
  font-weight: 700;
  padding-right: 6px;
}

.ec-footer
  .footer-top
  .ec-footer-widget
  .ec-footer-links
  .ec-footer-link:not(:last-child) {
  margin-bottom: 8px;
}

.ec-footer .footer-top .ec-footer-widget .ec-footer-links .ec-footer-link a {
  font-size: 0.928rem;
  line-height: 22px;
  padding: 0;
  color: #777777;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  word-break: break-all;
}

.ec-footer
  .footer-top
  .ec-footer-widget
  .ec-footer-links
  .ec-footer-link
  a:hover {
  color: #3474d4;
}

.ec-footer .footer-top .ec-footer-contact .ec-footer-widget .ec-footer-heading {
  margin-bottom: 11px;
  padding-bottom: 10px;
  border-bottom: 1px solid transparent;
  display: none;
}

.ec-footer .footer-top .ec-footer-contact ul {
  width: 245px;
}

.footer-offer {
  background: #555;
}

.footer-off-msg {
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer-off-msg span {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 0.7px;
  line-height: 1.2;
}

.footer-off-msg a {
  font-size: 1.143rem;
  line-height: 1.5;
  text-decoration: underline;
  padding-left: 11px;
  color: #fff;
}

.footer-off-msg a:hover {
  color: #ddd;
}

.ec-footer-logo {
  margin-bottom: 20px;
}

.ec-footer-logo img {
  width: 130px;
}

.ec-subscribe-form {
  position: relative;
  margin-top: 23px;
}

.ec-subscribe-form .ec-email {
  display: inline-block;
  vertical-align: top;
  line-height: 35px;
  height: 35px;
  color: #777777;
  font-size: 0.928rem;
  width: calc(100% - 35px);
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.5px;
}

.ec-subscribe-form .ec-email::-webkit-input-placeholder {
  color: #777777;
}

.ec-subscribe-form .ec-email::-moz-placeholder {
  color: #777777;
}

.ec-subscribe-form .ec-email:-ms-input-placeholder {
  color: #777777;
}

.ec-subscribe-form .ec-email::-ms-input-placeholder {
  color: #777777;
}

.ec-subscribe-form .ec-email::placeholder {
  color: #777777;
}

.ec-subscribe-form .ec-email:focus {
  border-color: #555;
  border-radius: 0;
}

.ec-subscribe-form .button {
  font-size: 20px;
  border: 0;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  min-width: 35px;
}

.ec-subscribe-form .button i {
  margin-right: 5px;
}

div#ec_news_signup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #eeeeee;
  padding: 5px;
}

/*----  Footer Bottom  ----*/
.footer-bottom {
  border-top: 1px solid #eeeeee;
  padding: 18px 0 20px 0;
}

.footer-bottom .ec-copy {
  color: #777777;
  font-size: 1.143rem;
  line-height: 1.2;
}

.footer-bottom .ec-copy .site-name {
  padding-left: 5px;
  color: #3474d4;
}

.footer-bottom .ec-copy .site-name:hover {
  color: #555;
}

.footer-bottom .ec-copy .site-name:hover span {
  color: #3474d4;
}

.footer-bottom .ec-copy .site-name span {
  display: none;
  color: #555;
}

.footer-bottom-social {
  color: #444444;
}

.footer-bottom-social ul {
  display: initial;
}

.footer-bottom-social ul li a {
  width: 25px;
  height: 25px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #909090;
  border-radius: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
  background: #f7f7f7;
  border: 1px solid #eeeeee;
  color: #777;
}

.footer-bottom-social ul li a:hover {
  color: #fff;
}

.footer-bottom-social ul li .hdr-facebook:hover {
  background: #3b5998;
}

.footer-bottom-social ul li .hdr-twitter:hover {
  background: #1da1f2;
}

.footer-bottom-social ul li .hdr-instagram:hover {
  background: #e44a73;
}

.footer-bottom-social ul li .hdr-linkedin:hover {
  background: #0073b2;
}

.footer-bottom-social ul li.list-inline-item:not(:last-child) {
  margin-right: 4px;
}

.ec-heading-res {
  display: none;
}

/*----  Footer nav toolbar responsive display  ----*/
.nav-toolbar {
  display: none;
}

/*===  Quick View ===*/
.quickview-pro-content .ec-quick-title a {
  color: #444444;
  font-size: 20px;
  text-transform: capitalize;
  margin: 0 0 15px 0;
  text-decoration: none;
  display: block;
  line-height: 1.5;
  font-weight: 700;
}

.quickview-pro-content p {
  font-size: 14px;
  color: #212121;
  margin: 0 0 12px;
  font-weight: 600;
}

.quickview-pro-content p span {
  font-weight: 400;
}

.quickview-pro-content .quickview-para {
  font-weight: 400;
  color: #444444;
  line-height: 26px;
}

.quickview-pro-content .ec-quickview-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.quickview-pro-content .ec-quickview-desc {
  margin-bottom: 10px;
}

.quickview-pro-content .ec-quickview-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.quickview-pro-content .ec-quickview-price span.old-price {
  font-size: 18px;
  margin-right: 10px;
  text-decoration: line-through;
  color: #444444;
}

.quickview-pro-content .ec-quickview-price span.new-price {
  color: #555;
  font-weight: 700;
  font-size: 22px;
}

.quickview-pro-content .pro-details-list {
  padding: 0 0 30px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #ebebeb;
  display: inline-block;
  width: 100%;
  color: #444444;
}

.quickview-pro-content .pro-details-list .ec-quickview-desc {
  font-weight: 400;
  color: #444444;
  line-height: 24px;
  margin: 0;
}

.quickview-pro-content .pro-details-list li {
  display: block;
  width: 100%;
  line-height: 24px;
}

.quickview-pro-content .ec-quickview-qty {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 15px 0 0 0;
  width: 100%;
}

.quickview-pro-content .ec-quickview-qty .qty-plus-minus {
  border: 1px solid #eeeeee;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.quickview-pro-content .ec-quickview-qty .qty-plus-minus .ec_qtybtn {
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.quickview-pro-content .ec-quickview-qty .qty-plus-minus input.qty-input {
  height: 40px;
  margin: 2px 0 0 0;
  padding: 0;
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #444444;
  float: left;
  font-size: 14px;
  text-align: center;
  width: 40px;
  outline: none;
}

.quickview-pro-content .ec-quickview-qty .ec-quickview-cart .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  line-height: 1.5;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0 15px;
  text-transform: uppercase;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.quickview-pro-content .ec-quickview-qty .ec-quickview-cart .btn svg {
  margin-right: 8px;
}

.quickview-pro-content
  .ec-quickview-qty
  .ec-quickview-cart
  .btn
  .svg_img.pro_svg {
  display: none;
}

/*-------------------------------------------------
  Category Section CSS
---------------------------------------------------*/
.ec-category-section .section-title .sub-title {
  margin-top: 7px;
}

.ec-category-section .ec-cat-tab-nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ec-category-section .ec-cat-tab-nav li.cat-item:not(:last-child) {
  margin-bottom: 5px;
}

.ec-category-section .ec-cat-tab-nav .cat-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  background-color: #f5f5f5;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-category-section .ec-cat-tab-nav .cat-link:hover {
  background-color: #3474d4;
}

.ec-category-section .ec-cat-tab-nav .cat-link:hover .cat-icon-hover {
  display: block;
}

.ec-category-section .ec-cat-tab-nav .cat-link:hover .cat-icon {
  display: none;
}

.ec-category-section .ec-cat-tab-nav .cat-link:hover .cat-desc span {
  color: #ffffff;
}

.ec-category-section .ec-cat-tab-nav .cat-link:hover .cat-desc span + span {
  color: #ffffff;
}

.ec-category-section .ec-cat-tab-nav .cat-link .cat-icons {
  display: block;
  margin-right: 15px;
}

.ec-category-section .ec-cat-tab-nav .cat-link .cat-icon-hover {
  display: none;
}

.ec-category-section .ec-cat-tab-nav .cat-link .cat-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ec-category-section .ec-cat-tab-nav .cat-link .cat-desc span {
  margin-top: 5px;
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 700;
  color: #444444;
  letter-spacing: 0;
  line-height: 1.2;
}

.ec-category-section .ec-cat-tab-nav .cat-link .cat-desc span + span {
  font-size: 0.928rem;
  color: #777777;
  letter-spacing: 0;
  line-height: 1.2;
  font-family: "Poppins, sans-serif", sans-serif;
  text-transform: capitalize;
  font-weight: 500;
}

.ec-category-section .ec-cat-tab-nav .cat-link.active {
  background-color: #3474d4;
}

.ec-category-section .ec-cat-tab-nav .cat-link.active .cat-icon-hover {
  display: block;
}

.ec-category-section .ec-cat-tab-nav .cat-link.active .cat-icon {
  display: none;
}

.ec-category-section .ec-cat-tab-nav .cat-link.active .cat-desc span {
  color: #ffffff;
}

.ec-category-section .ec-cat-tab-nav .cat-link.active .cat-desc span + span {
  color: #ffffff;
}

/*-------------------------------------------------
  Shop Page CSS
---------------------------------------------------*/
.width-100 {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.width-50 {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  width: 50% !important;
}

.col-5 {
  width: 20%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}

.ec-shop-rightside .ec-pro-list-top {
  margin-bottom: 30px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  background: #f7f7f7;
  padding: 7px;
  border: none;
}

.ec-gl-btn .btn {
  width: 34px;
  height: 34px;
  padding: 0;
  background: #ffffff;
  border: 1px solid #eeeeee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 34px;
  float: left;
  margin-right: 10px;
}

.ec-gl-btn .btn:hover {
  background: #3474d4;
  border: 1px solid #3474d4;
}

.ec-gl-btn .btn:hover .svg_img.gl_svg {
  fill: #ffffff;
}

.ec-gl-btn .btn .svg_img.gl_svg {
  height: 20px;
  fill: #444444;
  width: 20px;
}

.ec-gl-btn .btn.active {
  background: #3474d4;
  border: 1px solid #3474d4;
}

.ec-gl-btn .btn.active .svg_img.gl_svg {
  fill: #ffffff;
}

.ec-sort-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-sort-select .sort-by {
  font-size: 14px;
  color: #777777;
  padding-right: 22px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0 !important;
  background: #ffffff;
  background-image: none;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
  color: #777777;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.ec-select-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 172px;
  height: 34px;
  line-height: 1.5;
  background: #ffffff;
  overflow: hidden;
  border-radius: 0;
}

.ec-select-inner::after {
  content: "";
  position: absolute;
  font-family: "Ecicons";
  top: 0;
  right: 0;
  padding: 0 10px;
  cursor: pointer;
  pointer-events: none;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 34px;
  line-height: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777777;
}

.ec-page-detail .ec-cat-bnr a span {
  width: 100%;
  height: 250px;
  display: block;
  background-image: url(assets/images/banner/5.png);
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

.ec-page-detail .ec-page-description {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px;
  background-color: #f7f7f7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #777;
  font-size: 15px;
  line-height: 28px;
}

.ec-page-detail .ec-page-description h6,
.ec-page-detail .ec-page-description .h6 {
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #555;
}

.ec-page-detail .ec-page-description p {
  color: #777;
  font-size: 14px;
  line-height: 24px;
}

.col-5 .add-to-cart {
  width: 50px !important;
  font-size: 0 !important;
}

.col-5 .add-to-cart .svg_img.pro_svg {
  margin: 0;
}

.container-fluid .col-lg-2 .add-to-cart {
  width: 50px;
  font-size: 0 !important;
}

.container-fluid .col-lg-2 .add-to-cart .svg_img.pro_svg {
  margin: 0;
}

/*-----  List View Section  -----*/
.list-view .ec-pro-content {
  padding-left: 30px;
  padding-top: 0;
}

.list-view .ec-pro-content .ec-pro-size {
  margin-top: 15px;
}

.list-view .ec-product-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-view .ec-product-inner .ec-pro-image-outer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.list-view .ec-product-inner .ec-pro-image-outer {
  max-width: 270px;
  float: left;
}

.list-view .ec-pro-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.list-view .ec-pro-list-desc {
  display: block;
  margin: 5px auto 10px;
  font-size: 0.928rem;
}

.list-view-50 .ec-pro-content {
  padding-left: 30px;
  padding-top: 0;
}

.list-view-50 .ec-pro-content .ec-pro-size {
  margin-top: 10px;
}

.list-view-50 .add-to-cart {
  font-size: 0 !important;
}

.list-view-50 .add-to-cart .svg_img.pro_svg {
  margin: 0;
}

.list-view-50 .ec-product-inner .ec-pro-image-outer {
  max-width: 220px;
  float: left;
}

.list-view-50 .ec-pro-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.list-view-50 .ec-pro-list-desc {
  display: block;
  margin: 5px auto 10px;
  font-size: 15px;
}

/*-----  Pro List View Section  -----*/
.ec-pro-list-desc {
  display: none;
}

.ec-pro-pagination {
  padding-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #eeeeee;
}

.ec-pro-pagination span {
  font-size: 1.143rem;
  color: #777;
}

.ec-pro-pagination li {
  display: inline-block;
  float: left;
  margin-left: 1px;
}

.ec-pro-pagination a {
  font-weight: 300;
  color: #777;
  padding: 0;
  height: 32px;
  line-height: 32px;
  background: #ffffff;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  text-align: center;
  vertical-align: top;
  font-size: 1.143rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-pro-pagination a:hover {
  color: #ffffff;
  background-color: #3474d4;
}

.ec-pro-pagination a.next {
  width: auto;
  padding: 0 13px;
  border: 1px solid #eeeeee;
  line-height: 30px;
}

.ec-pro-pagination a.next i:before {
  padding-left: 7px;
  font-size: 22px;
}

.ec-pro-pagination a.active {
  color: #ffffff;
  background-color: #3474d4;
}

/*-----  Filter Sidebar Back Overlay  -----*/
.filter-sidebar-overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;
  display: none;
}

.filter-sidebar {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 300px;
  height: 100vh;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;
  background-color: #ffffff;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.filter-sidebar ::-webkit-scrollbar {
  width: 5px;
}

.filter-sidebar ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.filter-sidebar ::-webkit-scrollbar-thumb {
  background: #888;
}

.filter-sidebar ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-sidebar .ec-sidebar-heading {
  position: relative;
}

.filter-sidebar .ec-sidebar-heading h1,
.filter-sidebar .ec-sidebar-heading .h1 {
  margin-bottom: 0;
}

.filter-sidebar .ec-sidebar-heading a {
  position: absolute;
  top: 0px;
  right: 13px;
  font-size: 31px;
  color: #777;
}

.filter-sidebar .ec-sidebar-wrap {
  height: calc(100vh - 50px);
  border: none;
  overflow-y: auto;
}

/*-----  Togal Sidebar  -----*/
.toggle-sidebar-swipe {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.ec-sidebar-wrap {
  padding: 15px;
  border: 1px solid #eeeeee;
  background-color: #fff;
}

.ec-sidebar-wrap h3,
.ec-sidebar-wrap .h3 {
  font-size: 1.143rem;
  font-weight: 600;
  margin-bottom: 15px;
  position: relative;
  display: block;
  color: #555;
  font-family: "Poppins";
}

.ec-sidebar-wrap .h3 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.2px;
  margin-bottom: 10px;
  position: relative;
  display: block;
  color: #3474d4;
  font-family: "Poppins";
  letter-spacing: 0.4px;
}

.ec-sidebar-wrap .ec-sb-block-content {
  margin-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.ec-sidebar-wrap .ec-sb-block-content li a span {
  float: right;
}

.ec-sidebar-wrap .ec-sb-block-content li a:hover {
  color: #555;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 0 0 14px;
  display: block;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item {
  position: relative;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item
  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item
  .checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item
  .checked:after {
  left: -1px;
  top: -1px;
  width: 18px;
  height: 18px;
  border: 1px solid #3474d4;
  content: "";
  position: absolute;
  display: none;
  background: #3474d4;
  font-family: "Ecicons";
  font-size: 14px;
  color: #ffffff;
  line-height: 16px;
  text-align: center;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item
  .checked.grey {
  background: #aab2bd;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item
  .checked.white {
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item
  .checked.black {
  background: #434a54;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item
  .checked.camel {
  background: #c19a6b;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item.ec-more-toggle {
  cursor: pointer;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content li #ec-more-toggle {
  margin-left: 25px;
  display: block;
  color: #444444;
  font-size: 15px;
  margin-top: 0;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  cursor: pointer;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content li a {
  margin-left: 30px;
  display: block;
  color: #777;
  font-size: 14px;
  margin-top: 0;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content li a:hover {
  color: #3474d4;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-more-toggle
  .checked:after {
  left: -1px;
  top: -1px;
  width: 18px;
  height: 18px;
  border: 1px solid #3474d4;
  content: "";
  position: absolute;
  display: block;
  background-color: #3474d4;
  font-family: "Ecicons";
  font-size: 0.857rem;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-more-toggle.active
  .checked:after {
  content: "";
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content li:hover .checked {
  color: #3474d4;
}

.ec-sidebar-wrap
  .ec-sidebar-block.ec-sidebar-block-clr
  .ec-sb-block-content
  li {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 2px;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 5px;
  width: 26px;
  height: 26px;
}

.ec-sidebar-wrap
  .ec-sidebar-block.ec-sidebar-block-clr
  .ec-sb-block-content
  li:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-sidebar-wrap
  .ec-sidebar-block.ec-sidebar-block-clr
  .ec-sb-block-content
  li
  span {
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 30px;
}

.ec-sidebar-wrap
  .ec-sidebar-block.ec-sidebar-block-clr
  .ec-sb-block-content
  li.active {
  padding: 2px;
  border-color: #3474d4;
}

.ec-sidebar-wrap .ec-sb-block-content.es-price-slider {
  margin-bottom: 0;
  border-bottom: none;
  background: #f5f5f5;
  padding: 21px 15px 15px;
  font-family: "Poppins";
}

.ec-sidebar-heading h1,
.ec-sidebar-heading .h1 {
  text-decoration: none;
  color: #777;
  display: block;
  font-size: 1.143rem;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 20px;
  background: #f7f7f7;
  padding: 14px 15px 14px;
  letter-spacing: 0.2px;
  margin-bottom: 30px;
}

/*-----  No UI Target  -----*/
.noUi-target {
  cursor: pointer;
}

.noUi-target:focus {
  outline: none;
}

.noUi-horizontal {
  height: 4px;
  margin-bottom: 20px;
}

.noUi-horizontal .noUi-handle {
  top: -6px;
  right: -15px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 2px solid #3474d4;
  border-radius: 50%;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
}

.noUi-connect {
  background: #444444;
}

.noUi-base {
  background: #777777;
}

.noUi-handle.noUi-handle-upper {
  right: 0;
}

/*-----  Price Filter  -----*/
.ec-price-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ec-price-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-price-input .ec-price-divider {
  position: relative;
  border-bottom: 1px solid #777;
  width: 10px;
  height: 1px;
  margin: 0 10px;
}

/*-----  Filter Label  -----*/
.filter__label {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin-bottom: 0;
  font-size: 1.143rem;
  color: #777;
}

.filter__label:before {
  color: #777;
  font-size: 15px;
  line-height: 1;
  margin-right: 4px;
}

.filter__label:nth-child(1):before {
  content: "$";
}

.filter__label:nth-child(3):before {
  content: "$";
}

.filter__input {
  height: 30px;
  border: none;
  border-radius: 0;
  padding: 0;
  max-width: 48px;
  line-height: 30px;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  color: #777;
}

/*-----  Sidebar Block  -----*/
.ec-sidebar-block
  .ec-sb-block-content
  ul
  li
  .ec-sidebar-block-item
  input:checked
  ~ .checked::after {
  display: block;
}

.ec-sidebar-block.no-cba
  .ec-sb-block-content
  ul
  li
  .ec-sidebar-block-item
  input:checked
  ~ .checked::after {
  display: none;
}

.ec-sidebar-block.no-cba
  .ec-sb-block-content
  ul
  li
  .ec-sidebar-block-item
  .checked {
  border: 0;
}

.ec-sidebar-res {
  display: none;
}

/*-------------------------------------------------
  Login Page CSS
---------------------------------------------------*/
.ec-login-wrapper {
  max-width: 530px;
  margin: 0 auto 0;
}

.ec-login-wrapper .ec-login-container {
  /* border: 1px solid #ededed; */
  padding: 40px 30px 0 0px;
  text-align: left;
  /* background-color: #fff; */
}

.ec-login-wrapper .ec-login-container .ec-login-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ec-login-wrapper .ec-login-container .ec-login-form label {
  margin-bottom: 9px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.ec-login-wrapper .ec-login-container .ec-login-form input {
  background-color: #fff;
  border: 1px solid #787885;
  box-sizing: border-box;
  border-radius: 5px;
  color: #555;
  font-size: 14px;
  margin-bottom: 27px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.ec-login-wrapper
  .ec-login-container
  .ec-login-form
  .ec-login-wrap
  + .ec-login-wrap
  input {
  margin-bottom: 15px;
}

.ec-login-wrapper .ec-login-container .ec-login-form .ec-login-fp label {
  margin-bottom: 0;
}

.ec-login-wrapper .ec-login-container .ec-login-form .ec-login-fp label a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}

.ec-login-wrapper .ec-login-container .ec-login-form .ec-login-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-login-wrapper .ec-login-container .ec-login-form .btn {
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  padding: 0 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 5px;
  width: 100%;
  margin: 15px auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*-------------------------------------------------
  Register Page CSS
---------------------------------------------------*/
.ec-register-wrapper {
  max-width: 934px;
  margin: 0 auto 0;
}

.ec-register-wrapper .ec-register-container {
  border: 1px solid #ededed;
  padding: 30px;
  text-align: left;
  background-color: #fff;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-register-wrap {
  width: 100%;
  padding: 0 15px;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-register-wrap.ec-register-half {
  width: 50%;
}

.ec-register-wrapper .ec-register-container .ec-register-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -15px;
}

.ec-register-wrapper .ec-register-container .ec-register-form label {
  margin-bottom: 9px;
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.ec-register-wrapper .ec-register-container .ec-register-form input {
  background-color: transparent;
  border: 1px solid #ededed;
  color: #777;
  font-size: 14px;
  margin-bottom: 26px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  input::-webkit-input-placeholder {
  color: #777 !important;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  input::-moz-placeholder {
  color: #777 !important;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  input:-ms-input-placeholder {
  color: #777 !important;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  input::-ms-input-placeholder {
  color: #777 !important;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  input::placeholder {
  color: #777 !important;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-register-fp
  label {
  margin-bottom: 0;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-register-fp
  label
  a {
  color: #444444;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-rg-select-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: 1px solid #ededed;
  font-size: 14px;
  padding: 0;
  margin-bottom: 27px;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-rg-select-inner
  select {
  padding: 0 15px;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-rg-select-inner::after {
  content: "";
  position: absolute;
  font-family: "EcIcons";
  top: 0;
  right: 0;
  padding: 0 10px;
  cursor: pointer;
  pointer-events: none;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777777;
}

.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-register-wrap.ec-recaptcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-register-wrapper .ec-register-container .ec-register-form .ec-register-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-register-wrapper .ec-register-container .ec-register-form .btn {
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  padding: 0 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 0px;
  width: 150px;
  margin: 26px auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*-------------------------------------------------
  About Us Page CSS
---------------------------------------------------*/
.ec-cms-block {
  background-color: #fff;
}

.ec-cms-block .ec-cms-block-title {
  margin-bottom: 14px;
  color: #777;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
  font-family: "Poppins";
}

.ec-cms-block p {
  font-size: 14px;
  color: #777777;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: 0;
  text-align: left;
}

.ec-cms-block .a-img {
  border-radius: 30px;
}

/*-------------------------------------------------
  Terms-Condition Page CSS
---------------------------------------------------*/
.terms_condition_page .ec-common-wrapper {
  padding: 30px;
  border: 1px solid #ededed;
  max-width: 1140px;
  margin: 0 auto;
  background-color: #fff;
}

.terms_condition_page .ec-cms-block p {
  margin-bottom: 29px;
}

.terms_condition_page .ec-cms-block p b {
  font-weight: 500;
}

.terms_condition_page .ec-cms-block:last-child p {
  margin-bottom: 0;
}

/*-------------------------------------------------
  Contact Page CSS
---------------------------------------------------*/
.ec-contact-leftside {
  max-width: 568px;
  padding-right: 15px;
  float: left;
  margin: 0 auto;
}

.ec-contact-rightside {
  max-width: calc(100% - 568px);
  padding-left: 15px;
  float: left;
  margin: 0 auto;
  width: 100%;
}

.ec-contact-container {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ededed;
  text-align: left;
}

.ec-contact-container .ec-contact-form .ec-contact-wrap {
  width: 100%;
  padding: 0 15px;
}

.ec-contact-container .ec-contact-form .ec-contact-wrap.ec-contact-half {
  width: 50%;
}

.ec-contact-container .ec-contact-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -15px;
}

.ec-contact-container .ec-contact-form label {
  margin-bottom: 9px;
  color: #444444;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.ec-contact-container .ec-contact-form input {
  background-color: transparent;
  border: 1px solid #ededed;
  color: #444444;
  font-size: 14px;
  margin-bottom: 27px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.ec-contact-container .ec-contact-form input::-webkit-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form input::-moz-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form input:-ms-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form input::-ms-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form input::placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form input::-ms-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form textarea {
  background-color: transparent;
  border: 1px solid #ededed;
  color: #444444;
  font-size: 14px;
  margin-bottom: 27px;
  padding: 0 15px;
  width: 100%;
  outline: none;
  padding-top: 14px;
  min-height: 150px;
}

.ec-contact-container .ec-contact-form textarea::-webkit-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form textarea::-moz-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form textarea:-ms-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form textarea::-ms-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form textarea::placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form textarea::-ms-input-placeholder {
  color: #777;
}

.ec-contact-container .ec-contact-form .ec-contact-fp label {
  margin-bottom: 0;
}

.ec-contact-container .ec-contact-form .ec-contact-fp label a {
  color: #444444;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
}

.ec-contact-container .ec-contact-form .ec-rg-select-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: 1px solid #ededed !important;
  font-size: 14px;
  padding: 0;
  margin-bottom: 27px;
}

.ec-contact-container .ec-contact-form .ec-rg-select-inner select {
  padding: 0 15px;
}

.ec-contact-container .ec-contact-form .ec-rg-select-inner::after {
  content: "";
  position: absolute;
  font-family: "EcIcons";
  top: 0;
  right: 0;
  padding: 0 10px;
  cursor: pointer;
  pointer-events: none;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777777;
}

.ec-contact-container .ec-contact-form .ec-contact-wrap.ec-recaptcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-contact-container .ec-contact-form .ec-contact-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-contact-container .ec-contact-form .btn {
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  padding: 0 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 0px;
  width: 190px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec_contact_info {
  max-width: 375px;
  margin-top: 30px;
  background-color: #fff;
}

.ec_contact_info .ec_contact_info_head {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 19px;
  color: #202b3a;
  letter-spacing: 0.6px;
  position: relative;
}

li.ec-contact-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  line-height: 26px;
  border: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: #777777;
  margin-bottom: 9px;
}

li.ec-contact-item + .ec-contact-item {
  margin-bottom: 18px;
}

li.ec-contact-item:last-child {
  margin-bottom: 0;
}

li.ec-contact-item a {
  color: #777777;
}

li.ec-contact-item span {
  font-size: 15px;
  color: #444444;
  font-weight: 500;
  min-width: 74px;
}

.ec-contact-item i {
  min-width: 20px;
  font-size: 20px;
  color: #3474d4;
  margin-right: 11px;
}

.ec_map_canvas iframe {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}

/*-------------------------------------------------
  FAQ Page Wrapper CSS
---------------------------------------------------*/
/*-----  FAQ Wrapper  -----*/
.ec-faq-wrapper {
  max-width: 934px;
  margin: 0 auto;
}

.ec-faq-wrapper .ec-faq-container {
  border: 1px solid #ededed;
  padding: 30px;
  background-color: #fff;
}

.ec-faq-container-1 {
  margin-bottom: -15px;
}

.ec-faq-container-1 .ec-faq-wrapper {
  margin: 0 auto;
}

.ec-faq-container-1 .ec-faq-block {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  background-color: #fff;
}

.ec-faq-container-2 {
  margin-bottom: -15px;
}

.ec-faq-container-2 .ec-faq-wrapper {
  margin: 0 auto;
}

.ec-faq-container-3 {
  margin-bottom: -15px;
}

.ec-faq-container-3 h4.ec-faq-title,
.ec-faq-container-3 .ec-faq-title.h4 {
  background-color: #3474d4;
  color: #ffffff;
}

.ec-faq-container-3 h4.ec-faq-title:after,
.ec-faq-container-3 .ec-faq-title.h4:after {
  color: #ffffff;
}

/*-----  FAQ Section  -----*/
h2.ec-faq-heading,
.ec-faq-heading.h2 {
  font-size: 18px;
  font-weight: 700;
  color: #444444;
  letter-spacing: 0;
  line-height: 1;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 2px;
  font-family: "Poppins";
}

.ec-faq-block {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 100%;
  background-color: #fff;
}

.ec-faq-block:first-child .ec-faq-content {
  display: block;
  background-color: #fff;
}

.ec-faq-block:last-child {
  margin-bottom: 0;
}

h4.ec-faq-title,
.ec-faq-title.h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  color: #777777;
  letter-spacing: 0;
  position: relative;
  line-height: 17px;
  padding: 15px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Poppins";
}

h4.ec-faq-title::after,
.ec-faq-title.h4::after {
  content: "";
  font-family: "EcIcons";
  cursor: pointer;
  font-size: 24px;
  color: #777777;
  font-weight: 400;
  margin-left: 15px;
}

.ec-faq-content {
  border-top: 2px solid #ededed;
  display: none;
  padding: 7px 15px 8px;
}

.ec-faq-content p {
  font-size: 14px;
  font-weight: 300;
  color: #777777;
  letter-spacing: 0.3px;
  position: relative;
  line-height: 26px;
}

/*-------------------------------------------------
  Single product Page CSS
---------------------------------------------------*/
.product_page .ec-sidebar-wrap {
  padding-top: 12px;
  margin-bottom: 30px;
}

.product_page .ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content li {
  padding: 0;
}

.product_page
  .ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-sub-item
  a {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #777777;
  font-size: 14px;
  margin-top: 0;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  cursor: pointer;
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product_page
  .ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item {
  padding: 10px 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777;
  font-size: 1.143rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1;
  cursor: pointer;
}

.product_page
  .ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  li
  .ec-sidebar-block-item::after {
  content: "";
  font-family: "EcIcons";
  cursor: pointer;
  font-size: 10px;
  color: #777777;
  font-weight: 400;
  margin-left: 7px;
}

.product_page .ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content li li {
  padding: 0 0 6px;
}

.product_page .ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content ul ul {
  border-top: 1px solid #ededed;
  display: none;
  padding-top: 13px;
  padding-bottom: 3px;
}

.product_page
  .ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content:last-child
  li
  .ec-sidebar-block-item {
  padding-bottom: 3px;
}

.product_page .ec-sidebar-wrap .ec-sb-block-content {
  margin-bottom: 0;
  padding: 0;
  border-bottom: none;
}

.product_page .ec-sidebar-slider .ec-sb-pro-sl .ec-sb-pro-sl-item {
  background: #f7f7f7;
  padding: 15px;
  border: none;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .ec-pro-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 4px;
  overflow: hidden;
  padding-left: 12px;
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .ec-pro-content
  .ec-pro-title
  a {
  text-decoration: none;
  color: #444444;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-family: "Poppins";
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .ec-pro-content
  .ec-pro-rating {
  margin: 4px 0 6px;
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .ec-pro-content
  .ec-price {
  font-size: 1.143rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  color: #777777;
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .ec-pro-content
  .ec-price
  span.new-price {
  color: #555;
  font-weight: 600;
  font-size: 1.143rem;
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .ec-pro-content
  .ec-price
  span.old-price {
  font-size: 0.928rem;
  margin-right: 15px;
  text-decoration: line-through;
  color: #777777;
  line-height: 14px;
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .sidekka_pro_img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 36%;
  flex: 0 0 36%;
}

.product_page
  .ec-sidebar-slider
  .ec-sb-pro-sl
  .ec-sb-pro-sl-item
  .sidekka_pro_img
  img {
  max-width: 100%;
}

.product_page .ec-sb-slider-title {
  font-family: "Poppins";
  text-decoration: none;
  color: #212121;
  display: block;
  font-size: 1.143rem;
  line-height: 22px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 15px;
}

.product_page .ec-page-content {
  margin-bottom: 0;
}

/*-----  Sidebar Slider  -----*/
.ec-sidebar-slider .slick-arrow {
  top: -36px;
  border: 1px solid #dedede;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.ec-sidebar-slider .slick-arrow:hover {
  border-color: #3474d4;
}

.ec-sidebar-slider .slick-arrow:before {
  height: 20px;
  font-size: 1.143rem;
  line-height: 19px;
}

.ec-sidebar-slider .slick-arrow.slick-next {
  right: 0;
  left: auto;
}

.ec-sidebar-slider .slick-arrow.slick-prev {
  right: 28px;
  left: auto;
}

/*-----  Single Product  -----*/
.single-pro-img {
  position: relative;
  width: 47.3%;
}

.single-pro-img .single-product-scroll {
  position: sticky;
  top: 30px;
}

.single-pro-img .ec-360-lbl {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  text-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
  display: -ms-grid;
  display: grid;
  opacity: 0.7;
}

.single-pro-img .ec-360-lbl:hover {
  opacity: 1;
}

.single-pro-img .ec-video-icon {
  position: absolute;
  display: -ms-grid;
  display: grid;
  top: 15px;
  right: 15px;
  height: 40px;
  width: 40px;
  text-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
  cursor: pointer;
  z-index: 1;
  opacity: 0.7;
}

.single-pro-img .ec-video-icon:hover {
  opacity: 1;
}

.single-pro-desc {
  width: 52.7%;
  background-color: #fff;
}

.single-pro-img-no-sidebar {
  width: 40%;
}

.single-pro-desc-no-sidebar {
  width: 60%;
}

.space-6 > div {
  margin-top: 30px;
}

.space-6 > div:nth-child(-n + 2) {
  margin-top: 0;
}

.single-product-cover {
  overflow: hidden;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.single-nav-thumb {
  width: 100%;
  overflow: hidden;
}

.single-nav-thumb .slick-list {
  margin: 0 -8px;
}

.single-nav-thumb .single-slide {
  padding: 11px 11px 0 11px;
  display: block !important;
}

.single-nav-thumb .slick-slide img {
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.single-nav-thumb .slick-slide:hover img {
  border-color: #3474d4;
}

.single-nav-thumb .slick-slide.slick-current.slick-active img {
  border-color: #3474d4;
}

.single-nav-thumb .slick-arrow {
  top: 42%;
}

.single-pro-content .ec-single-title {
  color: #444444;
  font-size: 22px;
  text-transform: capitalize;
  margin: 0 0 20px 0;
  text-decoration: none;
  display: block;
  font-family: "Poppins";
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0.02rem;
}

.single-pro-content p {
  font-size: 14px;
  color: #212121;
  margin: 0 0 12px;
  font-weight: 600;
}

.single-pro-content p span {
  font-weight: 400;
}

.single-pro-content .single-para {
  font-weight: 400;
  color: #444444;
  line-height: 26px;
}

.single-pro-content .ec-single-rating-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-pro-content .ec-single-rating-wrap i.ecicon {
  margin-right: 2px;
  line-height: 20px;
  font-size: 1.143rem;
  float: unset;
}

.single-pro-content .ec-single-rating-wrap .ec-read-review a {
  color: #444444;
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
}

.single-pro-content .ec-single-rating-wrap .ec-read-review a:hover {
  color: #3474d4;
}

.single-pro-content .ec-single-rating {
  padding-right: 15px;
  border-right: 1px solid #eeeeee;
  margin-right: 15px;
  line-height: 17px;
}

.single-pro-content .ec-read-review {
  line-height: 17px;
}

.single-pro-content .ec-single-desc {
  margin-bottom: 12px;
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  word-break: break-all;
  line-height: 1.7;
  font-family: "Poppins";
}

.single-pro-content .ec-single-sales {
  margin: 25px 0 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  border: 1px solid #eeeeee;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.single-pro-content .ec-single-sales .ec-single-sales-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 18px;
  position: relative;
  display: block;
  color: #444444;
  font-family: "Poppins";
  letter-spacing: 0.5px;
  text-transform: capitalize;
  line-height: 1.2;
  display: none;
}

.single-pro-content .ec-single-sales .ec-single-sales-visitor {
  color: #444444;
  font-size: 1.143rem;
  line-height: 27px;
  text-transform: capitalize;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.1px;
  margin-bottom: 16px;
}

.single-pro-content .ec-single-sales .ec-single-sales-visitor span {
  color: #ffffff;
  font-size: 1.143rem;
  line-height: 27px;
  font-weight: 600;
  background-color: #3474d4;
  padding: 0 3px;
  margin: 0 6px;
}

.single-pro-content .ec-single-sales .ec-single-sales-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
}

.single-pro-content
  .ec-single-sales
  .ec-single-sales-progress
  .ec-single-progressbar {
  position: relative;
  border: 3px solid #edecff;
  width: 100%;
  border-radius: 6px;
}

.single-pro-content
  .ec-single-sales
  .ec-single-sales-progress
  .ec-single-progressbar:before {
  position: absolute;
  border: 3px solid #555;
  width: 50%;
  border-radius: 6px;
  top: -3px;
  left: -3px;
  right: auto;
  content: "";
}

.single-pro-content .ec-single-sales .ec-single-progress-desc {
  font-size: 0.857rem;
  color: #444444;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 4px;
}

.single-pro-content .ec-single-sales .ec-single-sales-countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-pro-content
  .ec-single-sales
  .ec-single-sales-countdown
  .ec-single-countdown {
  margin-right: 32px;
}

.single-pro-content .ec-single-sales .ec-single-count-desc {
  font-size: 14px;
  font-weight: 700;
  position: relative;
  display: block;
  color: #777;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.single-pro-content .ec-single-price-stoke {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
}

.single-pro-content .ec-single-price-stoke > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.single-pro-content .ec-single-price-stoke .ec-single-ps-title {
  line-height: 1;
  font-size: 1.143rem;
  color: #202020;
  margin-bottom: 14px;
  letter-spacing: 0;
}

.single-pro-content .ec-single-price-stoke .ec-single-sku {
  line-height: 1.4;
  font-size: 18px;
  color: #444444;
  font-weight: 600;
}

.single-pro-content .ec-single-price span.new-price {
  color: #202020;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
  font-family: "Poppins";
  letter-spacing: 0;
}

.single-pro-content .ec-pro-variation {
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eeeeee;
}

.single-pro-content .ec-pro-variation .ec-pro-variation-inner {
  font-size: 1.143rem;
  font-weight: 600;
  color: #212121;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-pro-content .ec-pro-variation .ec-pro-variation-inner > span {
  margin: 0 0 10px;
  color: #202020;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.1;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  font-family: "Poppins";
}

.single-pro-content
  .ec-pro-variation
  .ec-pro-variation-inner
  .ec-pro-variation-content
  li {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #777777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.857rem;
  line-height: 22px;
  padding: 0;
  border: 1px solid #eeeeee;
  float: left;
  margin-right: 10px;
  border-radius: 0;
}

.single-pro-content
  .ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-color
  .ec-pro-variation-content
  li {
  padding: 2px;
  border-radius: 30px;
  opacity: 0.7;
  background-color: transparent;
}

.single-pro-content
  .ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-color
  .ec-pro-variation-content
  li
  span {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 30px;
}

.single-pro-content
  .ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-color
  .ec-pro-variation-content
  .active {
  border: 1px solid #444444;
}

.single-pro-content .ec-single-qty {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.single-pro-content .ec-single-qty .qty-plus-minus {
  border: 1px solid #eeeeee;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
}

.single-pro-content .ec-single-qty .qty-plus-minus .ec_qtybtn {
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 22px;
  color: #777777;
}

.single-pro-content .ec-single-qty .qty-plus-minus input.qty-input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #444444;
  float: left;
  font-size: 15px;
  height: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 40px;
  outline: none;
  font-weight: 700;
}

.single-pro-content .ec-single-qty .qty-plus-minus .dec.ec_qtybtn {
  border-right: 1px solid #eeeeee;
}

.single-pro-content .ec-single-qty .qty-plus-minus .inc.ec_qtybtn {
  border-left: 1px solid #eeeeee;
}

.single-pro-content .ec-single-qty .ec-single-cart .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin: 0 10px;
  text-transform: uppercase;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 160px;
  letter-spacing: 0.02rem;
}

.single-pro-content .ec-single-qty .ec-single-cart .btn svg {
  margin-right: 8px;
}

.single-pro-content .ec-single-qty .ec-btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.single-pro-content .ec-single-qty .ec-btn-group .svg_img.pro_svg {
  fill: #000000;
}

.single-pro-content .ec-single-qty .ec-btn-group:hover {
  color: #ffffff;
  background-color: #3474d4;
  border-color: #3474d4;
}

.single-pro-content .ec-single-qty .ec-btn-group:hover .svg_img.pro_svg {
  fill: #ffffff;
}

.single-pro-content .ec-single-qty .ec-single-wishlist {
  margin-right: 10px;
}

.single-pro-content .ec-single-social ul {
  display: initial;
}

.single-pro-content .ec-single-social ul li a {
  width: 30px;
  height: 30px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #efefef;
  border-radius: 100%;
  color: #777;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
}

.single-pro-content .ec-single-social ul li a:hover {
  color: #fff;
}

.single-pro-content .ec-single-social ul li.facebook a:hover {
  background: #3c5a98;
}

.single-pro-content .ec-single-social ul li.twitter a:hover {
  background: #1da1f2;
}

.single-pro-content .ec-single-social ul li.instagram a:hover {
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

.single-pro-content .ec-single-social ul li.behance a:hover {
  background: #0057ff;
}

.single-pro-content .ec-single-social ul li.whatsapp a:hover {
  background: #2db842;
}

.single-pro-content .ec-single-social ul li.plus a:hover {
  background: #000000;
}

.single-pro-content .ec-single-social ul li.youtube-play a:hover {
  background: #ff0000;
}

.single-pro-content .ec-single-social ul li.list-inline-item:not(:last-child) {
  margin-right: 6px;
}

i.ecicon.eci-star-o.fill {
  color: #fec500;
}

i.ecicon.eci-star-o {
  color: #fec500;
  float: left;
}

.ec-single-sales-countdown .displaySection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
  background-color: #f7f7f7;
  min-width: 60px;
  padding: 10px 5px;
}

.ec-single-sales-countdown .numberDisplay {
  font-size: 18px;
  min-height: unset;
  min-width: auto;
  color: #777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2;
  margin-bottom: 5px;
  background-color: transparent;
}

.ec-single-sales-countdown .periodDisplay {
  font-weight: 400;
}

.ec-single-sales-countdown .labelformat {
  width: auto;
  font-size: 0.857rem;
  color: #777;
  line-height: 1;
  letter-spacing: -1px;
  font-weight: 500;
  letter-spacing: 0.02rem;
}

.ec-single-pro-tab {
  margin-top: 40px;
}

.ec-single-pro-tab-nav {
  border-bottom: none;
  position: relative;
  display: block;
  margin: auto;
  text-align: center;
  width: 100%;
  float: left;
  margin-bottom: -20px;
}

.ec-single-pro-tab-nav .nav-tabs {
  border-bottom: none;
  display: inline-block;
  float: left;
  background: #ffffff;
}

.ec-single-pro-tab-nav .nav-tabs li:last-child {
  margin-right: 0;
}

.ec-single-pro-tab-nav .nav-tabs li {
  margin: 0 5px 0 auto;
}

.ec-single-pro-tab-nav .nav-tabs .nav-link {
  border: 1px solid #eeeeee;
  text-transform: capitalize;
  line-height: 24px;
  color: #444444;
  padding: 8px 28px;
  margin: 0;
  font-size: 1.143rem;
  font-weight: 600;
  position: relative;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  display: inline-block;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 0;
  letter-spacing: 0;
}

.ec-single-pro-tab-nav .nav-tabs .nav-link:hover {
  color: #ffffff;
  background-color: #3474d4;
  border: 1px solid #3474d4;
}

.ec-single-pro-tab-nav .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #3474d4;
  border: 1px solid #3474d4;
}

.ec-single-pro-tab-nav li {
  display: inline-block;
  float: left;
  margin-right: 2px;
}

/* .tab-pane p {
  color: #777;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
} */

.ec-single-pro-tab-content {
  overflow: hidden;
  background-color: #fff;
  text-align: left;
  padding: 35px 30px 21px 30px;
  border: 1px solid #eeeeee;
  border-radius: 0;
  color: #202020;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.6;
}

.ec-single-pro-tab-content .ec-t-review-wrapper {
  margin-top: 10px;
}

.ec-single-pro-tab-content .ec-single-pro-tab-desc p {
  margin-bottom: 15px;
  font-size: 14px;
  letter-spacing: 0;
  color: #777;
  line-height: 24px;
}

.ec-single-pro-tab-content .ec-single-pro-tab-desc ol {
  padding-left: 24px;
}

.ec-single-pro-tab-content .ec-single-pro-tab-desc ol li {
  list-style: outside;
  margin-bottom: 4px;
  color: #777;
}

.ec-single-pro-tab-content .ec-single-pro-tab-desc ul {
  padding-left: 24px;
}

.ec-single-pro-tab-content .ec-single-pro-tab-desc ul li {
  font-size: 15px;
  list-style: outside;
  margin-bottom: 4px;
  color: #777;
}

.ec-single-pro-tab-content .ec-single-pro-tab-moreinfo p {
  color: #777;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
}

.ec-single-pro-tab-content .ec-single-pro-tab-moreinfo ol {
  padding-left: 24px;
}

.ec-single-pro-tab-content .ec-single-pro-tab-moreinfo ol li {
  list-style: outside;
  margin-bottom: 4px;
  color: #777;
}

.ec-single-pro-tab-content .ec-single-pro-tab-moreinfo ul {
  padding-left: 24px;
}

.ec-single-pro-tab-content .ec-single-pro-tab-moreinfo ul li {
  margin-bottom: 4px;
  font-size: 15px;
  list-style: outside;
  color: #777;
}

.ec-single-pro-tab-content .ec-single-pro-tab-moreinfo li span {
  margin: 0 25px 0 0;
  min-width: 150px;
  font-size: 15px;
  color: #000000;
  display: inline-block;
  font-weight: 600;
  color: #777;
}

.ec-single-pro-tab-content .ec-t-review-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eeeeee;
}

.ec-single-pro-tab-content .ec-t-review-item p {
  color: #777;
}

.ec-single-pro-tab-content .ec-t-review-item .ec-t-review-avtar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

.ec-single-pro-tab-content .ec-t-review-item .ec-t-review-avtar img {
  max-width: 100%;
}

.ec-single-pro-tab-content .ec-t-review-item .ec-t-review-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 0 10px;
}

.ec-single-pro-tab-content
  .ec-t-review-item
  .ec-t-review-top
  .ec-t-review-name {
  margin-bottom: 25px;
  font-size: 1.143rem;
  margin: 0;
  line-height: 1.5;
  font-weight: 600;
  display: block;
  color: #444444;
}

.ec-single-pro-tab-content
  .ec-t-review-item
  .ec-t-review-top
  .ec-t-review-rating {
  font-size: 1.143rem;
  margin-top: 5px;
}

.ec-single-pro-tab-content .ec-t-review-item .ec-t-review-top a {
  color: #444444;
}

.ec-single-pro-tab-content .ec-t-review-item .ec-t-review-top a:hover {
  color: #555;
}

.ec-single-pro-tab-content .ec-t-review-item .ec-t-review-bottom p {
  width: 100%;
  font-size: 14px;
  color: #777;
  line-height: 24px;
}

.ec-single-pro-tab-content .ec-ratting-content h3,
.ec-single-pro-tab-content .ec-ratting-content .h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
  font-family: "Poppins";
}

.ec-single-pro-tab-content .ec-ratting-content .ec-ratting-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 5px 0 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-single-pro-tab-content .ec-ratting-content .ec-ratting-star span {
  margin: 0 18px 0 0;
  font-size: 1.143rem;
  color: #202020;
}

.ec-single-pro-tab-content .ec-ratting-content .ec-ratting-star i {
  font-size: 18px;
}

.ec-single-pro-tab-content .ec-ratting-content .ec-ratting-input {
  margin-bottom: 10px;
}

.ec-single-pro-tab-content .ec-ratting-content .ec-ratting-input input {
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #eeeeee;
  color: #444444;
  padding: 5px 20px;
}

.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  input::-webkit-input-placeholder,
.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  textarea::-webkit-input-placeholder {
  color: #777;
  opacity: 1;
}

.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  input::-moz-placeholder,
.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  textarea::-moz-placeholder {
  color: #777;
  opacity: 1;
}

.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  input:-ms-input-placeholder,
.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  textarea:-ms-input-placeholder {
  color: #777;
  opacity: 1;
}

.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  input::-ms-input-placeholder,
.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  textarea::-ms-input-placeholder {
  color: #777;
  opacity: 1;
}

.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  input::placeholder,
.ec-single-pro-tab-content
  .ec-ratting-content
  .ec-ratting-input
  textarea::placeholder {
  color: #777;
  opacity: 1;
}

.ec-single-pro-tab-content .ec-ratting-content .ec-ratting-input textarea {
  background: transparent;
  border: 1px solid #eeeeee;
  color: #444444;
  height: 150px;
  padding: 20px;
  margin-bottom: 15px;
  width: 100%;
  outline: none;
  font-size: 14px;
}

.ec-single-pro-tab-content .ec-ratting-content .ec-ratting-input button {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1;
}

/*-----  Video Player on Product page  -----*/
#ec_product_player_modal .ec-product-player {
  padding: 15px 15px 7px 15px !important;
}

#ec_product_player_modal .ec-player-wrapper video {
  width: 100%;
}

#ec_product_player_modal .qty_close {
  height: 35px;
  margin-bottom: 15px;
  line-height: 35px;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 15px;
  opacity: 0.5;
  z-index: 2;
}

#ec_product_player_modal .qty_close:hover {
  opacity: 1;
}

/*-----  Product 360 Modal  -----*/
#ec_360_view_modal .modal-dialog {
  width: auto;
  max-width: 800px;
  margin: auto;
}

.ec-360-panel {
  position: relative;
}

.ec-360-panel .qty_close {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  margin-left: 5px;
  padding: 0;
  line-height: 30px;
  background-color: #555;
  opacity: 0.6;
  z-index: 1;
  font-size: 23px;
  color: #fff;
  border-radius: 30px;
}

.ec-360-panel .qty_close:hover {
  opacity: 1;
}

.ec-360-panel .ec-tool-play {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.ec-360-panel .ec-tool-play a {
  height: 30px;
  width: 30px;
  margin-left: 5px;
  padding: 0;
  line-height: 29px;
  background-color: #555;
  opacity: 0.6;
  border-radius: 30px;
}

.ec-360-panel .ec-tool-play a:hover {
  opacity: 1;
}

.ec-360-panel .ec-tool-play a .svg_img {
  width: 13px;
  height: 13px;
  fill: #ffffff;
}

.ec-product-360 .ec-circlr {
  max-width: 760px;
  cursor: move;
  margin: 0 auto 0 auto;
  min-height: 100px;
  position: relative;
  border: 1px solid #f1f1f1;
}

.ec-product-360 .ec-circlr .ec-img-loader {
  background: url(assets/images/common/loader.gif) center center no-repeat;
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ec-product-360 .space-b-15 {
  margin-bottom: 30px;
}

.ec-product-360 .ec-tool-box-sec {
  max-width: 740px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.ec-product-360 .ec-tool-box-sec .ec-tool-box {
  width: 165px;
  text-align: center;
}

.ec-product-360 .ec-tool-box-sec .ec-tool-box h4,
.ec-product-360 .ec-tool-box-sec .ec-tool-box .h4 {
  font-size: 14px;
  color: #777;
  font-weight: 700;
  text-transform: uppercase;
}

.ec-product-360 .ec-tool-box-sec .ec-tool-box-2 {
  max-width: 740px;
  text-align: center;
}

.ec-product-360 .ec-tool-box-sec .ec-tool-box-2 h4,
.ec-product-360 .ec-tool-box-sec .ec-tool-box-2 .h4 {
  font-size: 14px;
  color: #777;
  font-weight: 700;
  text-transform: uppercase;
}

.ec-product-360 .ec-tool-box-sec .ec-tool-box-2 a {
  height: 30px;
  width: 30px;
  margin-left: 5px;
  margin-top: 8px;
  padding: 0;
  line-height: 29px;
  background-color: #555;
  opacity: 0.6;
  color: #fff;
}

.ec-product-360 .ec-tool-box-sec .ec-tool-box-2 a:hover {
  opacity: 1;
}

.ec-product-360 .ec-switch {
  width: 40px;
  height: 20px;
  margin: 0;
  position: relative;
  display: inline-block;
}

.ec-product-360 .ec-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ec-product-360 .ec-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.ec-product-360 .ec-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.ec-product-360 input:checked + .ec-slider {
  background-color: #3474d4;
}

.ec-product-360 input:checked + .ec-slider:before {
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
}

.ec-product-360 input:focus + .ec-slider {
  -webkit-box-shadow: 0 0 1px #2196f3;
  box-shadow: 0 0 1px #2196f3;
}

.ec-product-360 .ec-slider.round {
  border-radius: 34px;
}

.ec-product-360 .ec-slider.round:before {
  border-radius: 50%;
}

/*-------------------------------------------------
  Blog Page CSS
---------------------------------------------------*/
.blog_page .ec-page-content .section-title {
  margin-bottom: 23px;
}

.blog_page .ec-sidebar-wrap .ec-sb-block-content {
  margin-bottom: 0;
  border-bottom: none;
}

.blog_page .ec-sidebar-wrap .ec-sidebar-recent-blog .ec-sb-block-content {
  margin-bottom: 30px;
  padding-bottom: 0;
}

.blog_page .ec-sidebar-wrap .ec-sidebar-recent-blog h3,
.blog_page .ec-sidebar-wrap .ec-sidebar-recent-blog .h3 {
  margin-bottom: 4px;
  margin-top: 2px;
}

.blog_page .ec-sidebar-wrap h3,
.blog_page .ec-sidebar-wrap .h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  letter-spacing: 0;
}

.blog_page
  .ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  > li:last-child {
  padding: 0;
}

.ec-blog-inner {
  background-color: #fff;
}

.ec-blog-inner .ec-blog-image {
  margin-bottom: 15px;
}

.ec-blog-inner .ec-blog-title {
  margin: 0 0 3px;
}

.ec-blog-inner .ec-blog-title a {
  color: #555;
  font-size: 1.143rem;
  margin: 0 auto;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: capitalize;
}

.ec-blog-inner .ec-blog-title a:hover {
  color: #3474d4;
}

.ec-blog-inner .ec-blog-date {
  font-size: 14px;
  color: #777777;
  line-height: 1.4;
  letter-spacing: 0;
  margin-bottom: 10px;
}

.ec-blog-inner .ec-blog-date span {
  padding: 0 5px;
  color: #555;
}

.ec-blog-inner .ec-blog-desc {
  margin-bottom: 13px;
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  word-break: break-all;
  line-height: 24px;
  font-family: "Poppins";
}

.ec-blog-inner .ec-blog-btn a.btn {
  min-width: 130px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}

.ec-sidebar-recent-blog .ec-sidebar-block-item {
  margin-bottom: 13px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 18px;
}

.ec-sidebar-recent-blog .ec-sidebar-block-item .ec-blog-title {
  margin-bottom: 2px;
}

.ec-sidebar-recent-blog .ec-sidebar-block-item .ec-blog-title a {
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1;
  font-weight: 600;
  font-family: "Poppins";
}

.ec-sidebar-recent-blog .ec-sidebar-block-item .ec-blog-date {
  font-size: 1.143rem;
  color: #777777;
  line-height: 1;
  letter-spacing: 0;
}

.ec-blog-search {
  height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  margin-bottom: 30px;
  outline: none;
  border: 1px solid #eeeeee;
  color: #444444;
  padding: 0;
  position: relative;
}

.ec-blog-search .ec-blog-search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
}

.ec-blog-search .form-control {
  display: block;
  width: 100%;
  min-height: 48px;
  height: 48px;
  padding: 0 20px;
  font-size: 1.143rem;
  font-weight: 400;
  line-height: 1;
  color: #777777;
  border-radius: 0;
  background-color: transparent;
  border: none;
  letter-spacing: 0;
}

.ec-blog-search .submit {
  position: relative;
  height: 48px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 60px;
  color: #444444;
}

.ec-blog-search .submit i {
  font-weight: 700;
}

.ec-blogs-inner {
  background: #fff;
}

.ec-blog-date {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 5px 0 0px 0;
}

.ec-blog-date a {
  margin-left: 5px;
  margin-top: 15px;
}

.ec-blog-date .date {
  margin-top: 15px;
}

.ec-blog-detail .ec-blog-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0;
  color: #555;
}

.ec-blog-detail p {
  font-size: 14px;
  line-height: 26px;
}

.ec-blog-detail .blockquote {
  font-size: 1.143rem;
  line-height: 28px;
  font-weight: 500;
  font-style: italic;
}

.ec-blog-detail .ec-blog-sub-imgs img {
  margin-bottom: 30px;
}

.ec-blog-tags {
  margin: 30px 0;
}

.ec-blog-tags a {
  margin-right: 10px;
  font-size: 17px;
  text-transform: capitalize;
}

.ec-blog-arrows {
  margin-bottom: 30px;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.ec-blog-arrows a {
  font-size: 20px;
  text-transform: capitalize;
  color: #777;
}

.ec-blog-comment-wrapper h4,
.ec-blog-comment-wrapper .h4 {
  color: #777;
}

.ec-blog-comment-wrapper .ec-single-comment-wrapper {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-blog-comment-wrapper .ec-single-comment-wrapper h5,
.ec-blog-comment-wrapper .ec-single-comment-wrapper .h5 {
  color: #777;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.ec-blog-comment-wrapper .ec-single-comment-wrapper .ec-blog-user-img {
  margin-right: 30px;
  max-width: 4rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 4rem;
  flex: 0 0 4rem;
  height: 4rem;
  overflow: hidden;
}

.ec-blog-comment-wrapper .ec-single-comment-wrapper .ec-blog-user-img img {
  width: 100%;
}

.ec-blog-comment-wrapper .ec-single-comment-wrapper .ec-blog-comment-content p {
  margin: 15px 0;
}

.ec-blog-comment-wrapper .ml-150 {
  margin-left: 4rem;
}

.ec-blog-cmt-form {
  margin-top: 30px;
}

.ec-blog-cmt-form .ec-blog-dec-title {
  font-weight: 600;
  color: #777;
  margin-bottom: 15px;
}

.ec-blog-cmt-form .ec-blog-reply-wrapper .ec-blog-form .ec-leave-form input,
.ec-blog-cmt-form .ec-blog-reply-wrapper .ec-blog-form .ec-text-leave input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #777;
  height: 45px;
  margin-bottom: 30px;
  padding-left: 20px;
  font-size: 14px;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  input::-webkit-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  input::-webkit-input-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  input::-moz-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  input::-moz-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  input:-ms-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  input:-ms-input-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  input::-ms-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  input::-ms-input-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  input::placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  input::placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  input::-ms-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  input::-ms-input-placeholder {
  color: #777;
}

.ec-blog-cmt-form .ec-blog-reply-wrapper .ec-blog-form .ec-leave-form textarea,
.ec-blog-cmt-form .ec-blog-reply-wrapper .ec-blog-form .ec-text-leave textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #777;
  height: 250px;
  margin-bottom: 20px;
  padding: 20px 20px 0 20px;
  font-size: 14px;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  textarea::-webkit-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  textarea::-webkit-input-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  textarea::-moz-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  textarea::-moz-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  textarea:-ms-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  textarea:-ms-input-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  textarea::-ms-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  textarea::-ms-input-placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  textarea::placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  textarea::placeholder {
  color: #777;
}

.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-leave-form
  textarea::-ms-input-placeholder,
.ec-blog-cmt-form
  .ec-blog-reply-wrapper
  .ec-blog-form
  .ec-text-leave
  textarea::-ms-input-placeholder {
  color: #777;
}

/*-------------------------------------------------
  Cart Page CSS
---------------------------------------------------*/
.cart_page .shop-all-btn {
  margin-bottom: 0;
}

.cart_page .ec-sidebar-wrap {
  border: 1px solid #ededed;
}

.cart_page .ec-sidebar-wrap h3,
.cart_page .ec-sidebar-wrap .h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 21px;
}

.cart_page .ec-sidebar-wrap .ec-sb-block-content {
  margin-bottom: 0;
  border-bottom: none;
}

.cart_page .ec-sidebar-wrap .ec-ship-title {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0;
  border-bottom: 1px solid #ededed;
  color: #444444;
  padding-bottom: 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart_page .ec-sidebar-wrap .ec-ship-title::after {
  content: "";
  position: relative;
  font-family: "EcIcons";
  font-size: 18px;
  color: #777777;
  font-weight: 400;
}

.ec-cart-content .table-content table {
  border: 1px solid #ebebeb;
  width: 100%;
  background-color: #fff;
}

.ec-cart-content .table-content table thead > tr {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
}

.ec-cart-content .table-content table thead > tr > th {
  border-top: medium none;
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 14px 12px;
  text-align: left;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1;
  letter-spacing: 0;
}

.ec-cart-content .table-content table tbody > tr {
  border-bottom: 1px solid #ebebeb;
}

.ec-cart-content .table-content table tbody > tr td {
  color: #444444;
  font-size: 1.143rem;
  padding: 15px 14px;
  text-align: left;
}

.ec-cart-content .table-content table tbody > tr td .ec-cart-pro-img {
  width: 61px;
}

.ec-cart-content .table-content table tbody > tr td .cart-qty-plus-minus {
  border: 1px solid #ededed;
  display: inline-block;
  height: 35px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 84px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
}

.ec-cart-content .table-content table tbody > tr td .cart-qty-plus-minus input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #444444;
  float: left;
  font-size: 14px;
  height: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 40px;
  outline: none;
  font-weight: 600;
  line-height: 38px;
}

.ec-cart-content
  .table-content
  table
  tbody
  > tr
  td
  .cart-qty-plus-minus
  .ec_cart_qtybtn {
  color: #444444;
  float: left;
  font-size: 20px;
  height: auto;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-cart-content
  .table-content
  table
  tbody
  > tr
  td
  .cart-qty-plus-minus
  .ec_qtybtn {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0;
  color: #444444;
  height: 19px;
  position: relative;
}

.ec-cart-content
  .table-content
  table
  tbody
  > tr
  td
  .cart-qty-plus-minus
  .ec_qtybtn:before {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  color: #444444;
  height: 19px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: "";
  font-family: "EcIcons";
  overflow: hidden;
}

.ec-cart-content
  .table-content
  table
  tbody
  > tr
  td
  .cart-qty-plus-minus
  .dec.ec_qtybtn:before {
  padding-bottom: 4px;
  content: "";
}

.ec-cart-content
  .table-content
  table
  tbody
  > tr
  td
  .cart-qty-plus-minus
  .inc.ec_qtybtn:before {
  padding-top: 4px;
  content: "";
}

.ec-cart-content .table-content table tbody > tr td.ec-cart-pro-name {
  width: 40%;
}

.ec-cart-content .table-content table tbody > tr td.ec-cart-pro-name a {
  color: #444444;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0.6px;
}

.ec-cart-content .table-content table tbody > tr td.ec-cart-pro-subtotal {
  color: #555;
  font-weight: 500;
  font-size: 15px;
}

.ec-cart-content .table-content table tbody > tr td.ec-cart-pro-price {
  color: #555;
  font-weight: 500;
  font-size: 15px;
}

.ec-cart-content
  .table-content
  table
  tbody
  > tr
  td.ec-cart-pro-qty
  .qty-plus-minus {
  display: inline-block;
  height: 40px;
  padding: 0;
  position: relative;
  width: 110px;
}

.ec-cart-content .table-content table tbody > tr td.ec-cart-pro-remove {
  width: 90px;
  text-align: right;
}

.ec-cart-content .table-content table tbody > tr td.ec-cart-pro-remove a {
  font-size: 22px;
  margin: 0 auto;
  color: #555;
}

.ec-cart-content .ec-cart-update-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 0 0;
}

.ec-cart-update-bottom > a {
  color: #444444;
  display: inline-block;
  text-decoration: underline;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.ec-cart-update-bottom .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 1.143rem;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 150px;
}

.ec-cart-form {
  padding-top: 19px;
}

.ec-cart-form label {
  margin-bottom: 9px;
  color: #444444;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.ec-cart-form p {
  color: #777;
  font-size: 14px;
  margin-bottom: 10px;
  letter-spacing: 0;
}

.ec-cart-form input {
  background-color: transparent;
  border: 1px solid #ededed;
  color: #444444;
  font-size: 14px;
  margin-bottom: 26px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.ec-cart-form select {
  padding: 0 15px;
}

.ec-cart-form .ec-cart-select-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: 1px solid #ededed;
  font-size: 14px;
  padding: 0;
  margin-bottom: 28px;
}

.ec-cart-form .ec-cart-select-inner::after {
  content: "";
  position: absolute;
  font-family: "EcIcons";
  top: 0;
  right: 0;
  padding: 0 10px;
  cursor: pointer;
  pointer-events: none;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777777;
}

.ec-cart-summary-bottom .ec-cart-summary > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.ec-cart-summary-bottom .ec-cart-summary .text-left {
  color: #444444;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
}

.ec-cart-summary-bottom .ec-cart-summary .text-right {
  color: #555;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.ec-cart-summary-bottom .ec-cart-summary .text-right a {
  color: #eb2629;
  font-weight: 500;
}

.ec-cart-summary-bottom .ec-cart-summary .ec-cart-summary-total {
  border-top: 1px solid #ededed;
  padding-top: 19px;
  margin-bottom: 0;
  margin-top: 16px;
}

.ec-cart-summary-bottom .ec-cart-summary .ec-cart-summary-total .text-left {
  font-size: 1.143rem;
  font-weight: 500;
}

.ec-cart-summary-bottom .ec-cart-summary .ec-cart-summary-total .text-right {
  font-size: 1.143rem;
  font-weight: 500;
}

.ec-cart-summary-bottom .ec-cart-summary .ec-cart-coupan-content {
  display: none;
  margin-bottom: 0;
}

.ec-cart-coupan-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #ededed;
  padding: 5px;
}

.ec-cart-coupan-form .ec-coupan {
  display: inline-block;
  vertical-align: top;
  line-height: 35px;
  height: 35px;
  color: #777777;
  font-size: 14px;
  width: 100%;
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.5px;
}

.ec-cart-coupan-form .button {
  font-size: 1.143rem;
  text-align: center;
  padding: 0 15px;
}

/*-------------------------------------------------
  Compare Page CSS
---------------------------------------------------*/
.ec-compare-leftside .ec-sidebar-wrap {
  min-height: 355px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-compare-leftside .ec-compare-title {
  line-height: 23px;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  display: block;
  color: #555;
  font-family: "Poppins";
  letter-spacing: 0;
  margin: 0 auto;
  text-align: center;
}

.ec-compare-inner .ec-com-remove {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 11px;
  left: auto;
  right: 11px;
  border-radius: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #555;
  z-index: 8;
}

.ec-compare-inner .ec-com-remove:hover {
  background: #3474d4;
}

.ec-compare-inner .ec-com-remove a {
  font-size: 1.143rem;
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
  padding-bottom: 1px;
  letter-spacing: 0;
}

.ec-compare-table {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.ec-compare-table form {
  border: 1px solid #eeeeee;
}

.ec-compare-table .table tbody {
  border: none;
}

.ec-compare-table .table tbody tr:nth-child(even) {
  background: #eeeeee;
}

.ec-compare-table .table tbody tr:nth-child(odd) {
  background: #fff;
}

.ec-compare-table .table tbody tr td {
  text-align: left;
  border: none;
  padding: 19px 30px;
  vertical-align: middle;
  font-size: 14px;
  color: #444444;
  letter-spacing: 0;
  line-height: 1.1;
}

.ec-compare-table .table tbody tr td.first-column {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}

.ec-com-tab-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 28px;
  text-align: center;
  font-size: 17px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  font-family: "Poppins";
  background-color: #3474d4;
  letter-spacing: 0;
  padding: 15px;
  font-weight: 600;
}

/*-------------------------------------------------
  Track Order Page CSS
---------------------------------------------------*/
.track_order_page .ec-page-content .section-title {
  margin-bottom: 22px;
}

.track_order_page .ec-page-content .section-title .ec-title {
  padding: 0 64px;
}

.track_order_page .ec-page-content .section-title .ec-title:after {
  width: 50px;
}

.track_order_page .ec-page-content .section-title .ec-title:before {
  width: 50px;
}

.ec-trackorder-inner {
  background: #f9f9f9;
  padding: 50px;
  overflow: hidden;
}

.ec-trackorder-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ec-trackorder-top .ec-order-id {
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 0;
  color: #555;
  letter-spacing: 0;
  position: relative;
  text-transform: capitalize;
}

.ec-trackorder-top .ec-order-detail {
  font-size: 15px;
  color: #444444;
  letter-spacing: 0;
  line-height: 1.1;
}

.ec-trackorder-top .ec-order-detail div:first-child {
  margin-bottom: 7px;
}

.ec-trackorder-top .ec-order-detail span {
  color: #3474d4;
  text-transform: uppercase;
  font-weight: 600;
}

#ec-progressbar {
  overflow: hidden;
  margin: 58px -9% 5px;
}

#ec-progressbar li {
  list-style-type: none;
  font-size: 14px;
  width: 20%;
  float: left;
  position: relative;
  font-weight: 400;
  text-align: center;
}

#ec-progressbar li .ec-progressbar-track {
  position: relative;
  display: block;
}

#ec-progressbar li .ec-progressbar-track:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  font-size: 26px;
  background: #deddff;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff;
  z-index: 9;
  position: relative;
}

#ec-progressbar li .ec-progressbar-track:after {
  content: "";
  width: 100%;
  height: 12px;
  background: #deddff;
  position: absolute;
  left: 0;
  top: 19px;
}

#ec-progressbar li .ec-track-icon {
  display: block;
  margin-bottom: 11px;
}

#ec-progressbar li .ec-track-title {
  position: relative;
  color: #444444;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 24px;
  max-width: 50%;
  display: block;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 0;
  margin-top: 16px;
  word-break: break-all;
}

#ec-progressbar li:first-child .ec-progressbar-track:after {
  content: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#ec-progressbar li:last-child .ec-progressbar-track:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#ec-progressbar li:nth-child(2) .ec-progressbar-track:after {
  left: -50%;
}

#ec-progressbar li:nth-child(3) .ec-progressbar-track:after {
  left: -50%;
}

#ec-progressbar li:nth-child(4) .ec-progressbar-track:after {
  left: -50%;
}

#ec-progressbar li.active .ec-progressbar-track:before {
  background: #deddff;
  background: #3474d4;
  font-family: "EcIcons";
  content: "";
  z-index: 2;
}

#ec-progressbar li.active .ec-progressbar-track:after {
  background: #3474d4;
}

#ec-progressbar .step0 .ec-progressbar-track:before {
  font-family: "EcIcons";
  content: "";
}

/*-------------------------------------------------
  Checkout Page CSS
---------------------------------------------------*/
.checkout_page .ec-checkout-wrap {
  padding: 30px;
  border: 1px solid #ededed;
  background-color: #fff;
}

.checkout_page .ec-checkout-wrap h3,
.checkout_page .ec-checkout-wrap .h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 25px;
  position: relative;
  display: block;
  color: #444444;
  font-family: "Poppins";
  line-height: 1;
}

.checkout_page .ec-checkout-wrap .ec-check-login h3,
.checkout_page .ec-checkout-wrap .ec-check-login .h3 {
  margin-bottom: 22px;
}

.checkout_page .ec-checkout-wrap .ec-check-bill h3,
.checkout_page .ec-checkout-wrap .ec-check-bill .h3 {
  margin-bottom: 26px;
  line-height: 1.2;
}

.checkout_page .ec-checkout-wrap .ec-check-bill .ec-check-subtitle {
  margin-bottom: 10px;
}

.checkout_page [type="radio"]:checked {
  position: absolute;
  left: -9999px;
}

.checkout_page [type="radio"]:checked + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #777777;
  letter-spacing: 0;
}

.checkout_page [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #3474d4;
}

.checkout_page [type="radio"]:checked + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #3474d4;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.checkout_page [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.checkout_page [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #777777;
  letter-spacing: 0;
}

.checkout_page [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #ffffff;
}

.checkout_page [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #3474d4;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.checkout_page .ec-sidebar-wrap {
  border: 1px solid #ededed;
  margin-bottom: 30px;
}

.checkout_page .ec-sidebar-wrap:last-child {
  margin-bottom: 0;
}

.checkout_page .ec-sidebar-wrap .ec-sb-block-content {
  margin-bottom: 0;
  border-bottom: none;
}

.checkout_page .ec-checkout-pay-wrap [type="radio"]:checked + label {
  margin-bottom: 30px;
}

.checkout_page .ec-checkout-pay-wrap [type="radio"]:not(:checked) + label {
  margin-bottom: 6px;
}

.checkout_page .ec-new-product {
  margin-top: 2px;
}

.ec-check-subtitle {
  margin-bottom: 14px;
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.ec-checkout-block.ec-check-new {
  margin-bottom: 30px;
}

.ec-new-option span {
  margin-right: 45px;
}

.ec-new-desc {
  position: relative;
  line-height: 24px;
  color: #777;
  letter-spacing: 0;
  max-width: 64%;
}

.ec-new-btn {
  margin-top: 26px;
}

.ec-new-btn a {
  height: 40px;
  text-transform: uppercase;
  font-size: 1.143rem;
  min-width: 150px;
  line-height: 40px;
  letter-spacing: 0;
}

.ec-check-login-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ec-check-login-form label {
  margin-bottom: 11px;
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.ec-check-login-form input {
  background-color: transparent;
  border: 1px solid #ededed;
  color: #444444;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.ec-check-login-form .ec-check-login-fp {
  color: #444444;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-decoration: underline;
  font-size: 15px;
  line-height: 24px;
}

.ec-check-login-form .ec-check-login-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 14px;
}

.ec-check-login-form .ec-check-login-btn .btn {
  height: 40px;
  text-transform: uppercase;
  font-size: 1.143rem;
  min-width: 150px;
  line-height: 40px;
  letter-spacing: 0;
  cursor: pointer;
  padding: 0 15px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
}

.ec-check-bill-form {
  margin-bottom: 2px;
}

.ec-bill-option {
  margin-bottom: 32px;
  display: block;
}

.ec-bill-option span {
  margin-right: 45px;
}

.padding-bottom-3 {
  padding-bottom: 3px !important;
}

/*-----  Checkout Page CSS  ------*/
.ec-checkout-wrap .ec-check-bill-form .ec-bill-wrap {
  width: 100%;
  padding: 0 15px;
}

.ec-checkout-wrap .ec-check-bill-form .ec-bill-wrap.ec-bill-half {
  width: 50%;
}

.ec-checkout-wrap .ec-check-bill-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -15px;
}

.ec-checkout-wrap .ec-check-bill-form label {
  margin-bottom: 7px;
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

.ec-checkout-wrap .ec-check-bill-form input {
  background-color: transparent;
  border: 1px solid #ededed;
  color: #444444;
  font-size: 14px;
  margin-bottom: 26px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.ec-checkout-wrap .ec-check-bill-form .ec-bill-fp label {
  margin-bottom: 0;
}

.ec-checkout-wrap .ec-check-bill-form .ec-bill-fp label a {
  color: #444444;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
}

.ec-checkout-wrap .ec-check-bill-form .ec-bl-select-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: 1px solid #ededed;
  font-size: 14px;
  padding: 0;
  margin-bottom: 27px;
}

.ec-checkout-wrap .ec-check-bill-form .ec-bl-select-inner select {
  padding: 0 15px;
}

.ec-checkout-wrap .ec-check-bill-form .ec-bl-select-inner::after {
  content: "";
  position: absolute;
  font-family: "EcIcons";
  top: 0;
  right: 0;
  padding: 0 10px;
  cursor: pointer;
  pointer-events: none;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #777777;
}

.ec-checkout-content .ec-check-order-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0;
}

.ec-checkout-content .ec-check-order-btn .btn {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 1.143rem;
  line-height: 1;
  padding: 0 5px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 0px;
  width: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0;
}

.ec-checkout-summary > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.ec-checkout-summary .text-left {
  color: #444444;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
}

.ec-checkout-summary .text-right {
  color: #555;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
}

.ec-checkout-summary .text-right a {
  color: #eb2629;
  font-weight: 500;
  font-size: 14px;
}

.ec-checkout-summary .ec-checkout-summary-total {
  border-top: 1px solid #ededed;
  padding-top: 19px;
  margin-bottom: 0;
  margin-top: 16px;
}

.ec-checkout-summary .ec-checkout-summary-total .text-left {
  font-size: 1.143rem;
  font-weight: 700;
  font-family: "Poppins";
}

.ec-checkout-summary .ec-checkout-summary-total .text-right {
  font-size: 1.143rem;
  font-weight: 700;
  font-family: "Poppins";
}

/*-----  Sidebar Page CSS  ------*/
.ec-sidebar-wrap .ec-sidebar-block .ec-checkout-pro {
  margin-top: 34px;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-checkout-pro li {
  padding: 1px;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-checkout-pro li a {
  margin: 0 auto;
  display: block;
  color: #777777;
  font-size: 0.857rem;
  margin-top: 0;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  cursor: pointer;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  .ec-checkout-pro
  .ec-pro-size
  li
  a {
  pointer-events: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-color: #dddddd;
  color: #777777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.857rem;
  line-height: 18px;
  padding-top: 2px;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  .ec-checkout-pro
  .ec-pro-size
  li.active
  a {
  background-color: #555;
  color: #ffffff;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  .ec-checkout-pro
  .ec-pro-option {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  .ec-checkout-pro
  .ec-product-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  .ec-checkout-pro
  .ec-product-inner
  .ec-pro-content {
  width: calc(100% - 143px);
  padding: 0;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  .ec-checkout-pro
  .ec-product-inner
  .ec-pro-image-outer {
  width: 128px;
  margin-right: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-sidebar-wrap
  .ec-sidebar-block
  .ec-sb-block-content
  .ec-checkout-pro
  .ec-product-inner
  .ec-pro-color {
  margin-bottom: 8px;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-del-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-del-option > * {
  width: 50%;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-del-opt-head {
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-del-desc {
  color: #777;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 15px;
  display: block;
  letter-spacing: 0;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-pay-opt-head {
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 6px;
  display: block;
}

.ec-sidebar-wrap .ec-sidebar-block .ec-sb-block-content .ec-pay-desc {
  color: #777;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 15px;
  display: block;
  letter-spacing: 0;
}

.ec-product-inner .ec-pro-content .ec-price:not(:last-child) {
  margin-bottom: 4px;
}

.ec-sidebar-wrap.ec-checkout-del-wrap h3,
.ec-sidebar-wrap.ec-checkout-del-wrap .h3 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 13px;
}

.ec-sidebar-wrap.ec-checkout-del-wrap .ec-del-commemt {
  margin-top: 12px;
  display: block;
}

.ec-sidebar-wrap.ec-checkout-del-wrap .ec-del-commemt .ec-del-opt-head {
  margin-bottom: 8px;
}

.ec-sidebar-wrap.ec-checkout-del-wrap textarea {
  background: transparent;
  border: 1px solid #ededed;
  color: #444444;
  height: 100px;
  padding: 10px 15px;
  margin-bottom: 0;
  width: 100%;
  outline: none;
  font-size: 14px;
  display: block;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap h3,
.ec-sidebar-wrap.ec-checkout-pay-wrap .h3 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 8px;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap textarea {
  background: transparent;
  border: 1px solid #ededed;
  color: #444444;
  height: 110px;
  padding: 10px 15px;
  margin-bottom: 0;
  width: 100%;
  outline: none;
  font-size: 14px;
  display: block;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap .ec-pay-commemt .ec-pay-opt-head {
  margin-bottom: 8px;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap .ec-pay-agree {
  position: relative;
  display: block;
  margin-top: 20px;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap .ec-pay-agree input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap
  .ec-pay-agree
  input:checked
  ~ .checked::after {
  display: block;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap .ec-pay-agree a {
  margin-left: 27px;
  display: block;
  color: #777777;
  font-size: 14px;
  margin-top: 0;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 0;
  cursor: pointer;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap .ec-pay-agree a span {
  font-weight: 500;
  color: #444444;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap .ec-pay-agree .checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.ec-sidebar-wrap.ec-checkout-pay-wrap .ec-pay-agree .checked:after {
  left: -1px;
  top: -1px;
  width: 15px;
  height: 15px;
  border: 1px solid #3474d4;
  content: "";
  position: absolute;
  display: none;
  font-family: "EcIcons";
  font-size: 9px;
  color: #3474d4;
  line-height: 14px;
  text-align: center;
  border-radius: 4px;
}

.ec-sidebar-wrap.ec-check-pay-img-wrap h3,
.ec-sidebar-wrap.ec-check-pay-img-wrap .h3 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 24px;
}

.ec-check-pay-img-wrap .ec-check-pay-img-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 55%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ec-check-pay-img-wrap .ec-check-pay-img {
  margin-bottom: 18px;
  margin-right: 5px;
}

.ec-check-pay-img-wrap .ec-check-pay-img:last-child {
  margin-bottom: 0;
  margin-top: 12px;
}

/*-----  Checkout Summary CSS  ------*/
.ec-checkout-summary .ec-checkout-coupan-content {
  display: none;
  margin-bottom: 0;
}

.ec-checkout-coupan-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #ededed;
  padding: 5px;
}

.ec-checkout-coupan-form .ec-coupan {
  display: inline-block;
  vertical-align: top;
  line-height: 35px;
  height: 35px;
  color: #777777;
  font-size: 14px;
  width: 100%;
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.5px;
}

.ec-checkout-coupan-form .ec-coupan-btn {
  font-size: 1.143rem;
  text-align: center;
  padding: 0 15px;
}

/*-------------------------------------------------
  Element Tab Wrapper CSS
---------------------------------------------------*/
.ec-tab-wrapper-1 .ec-single-pro-tab-content .ec-single-pro-tab-desc p,
.ec-tab-wrapper-2 .ec-single-pro-tab-content .ec-single-pro-tab-desc p,
.ec-tab-wrapper-3 .ec-single-pro-tab-content .ec-single-pro-tab-desc p,
.ec-tab-wrapper-4 .ec-single-pro-tab-content .ec-single-pro-tab-desc p {
  margin: 0;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
}

.ec-tab-wrapper-2 .ec-single-pro-tab-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-tab-wrapper-3 .ec-single-pro-tab-content {
  padding: 30px;
}

.ec-tab-wrapper-3 .ec-single-pro-tab-nav {
  margin-bottom: -1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-tab-wrapper-3 .ec-single-pro-tab-nav .nav-tabs .nav-link.active {
  background-color: transparent;
  color: #777;
  border-color: #ebebeb;
  border-bottom: none;
}

.ec-tab-wrapper-4 .ec-single-pro-tab-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
}

.ec-tab-wrapper-4 .ec-single-pro-tab-content {
  padding: 30px 0px 0px 1px;
  border: none;
}

/*-------------------------------------------------
  Element Category Wrapper CSS
---------------------------------------------------*/
.ec-category-wrapper-1 .slick-arrow:before {
  content: "";
  color: #ffffff;
  position: relative;
  font-size: 38px;
  font-family: "EcIcons";
  width: 100%;
  line-height: 50px;
  height: 50px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-category-wrapper-1 .slick-arrow:after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  width: 50px;
  top: 0;
  height: 50px;
  background: #2d313e;
  left: 0;
  right: 0;
  border-radius: 100%;
  opacity: 0.5;
  border: 1px solid #fff;
}

.ec-category-wrapper-1 .slick-arrow:hover:after {
  opacity: 1;
}

.ec-category-wrapper-1 .slick-arrow.slick-prev {
  left: 5px;
}

.ec-category-wrapper-1 .slick-arrow.slick-next {
  right: 5px;
}

.ec-category-wrapper-1 .slick-arrow.slick-next:before {
  content: "";
}

.ec-category-wrapper-1 .slick-arrow.slick-prev:before {
  content: "";
}

.ec-category-wrapper-1 .slick-arrow {
  position: absolute;
  top: 45%;
  margin: 0 auto;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 0;
  z-index: 1;
}

.ec-category-wrapper-1 .ec_cat_content {
  padding: 15px;
}

.ec-category-wrapper-1 .ec_cat_content .ec_cat_inner {
  position: relative;
}

.ec-category-wrapper-1 .ec_cat_content .ec-cat-image {
  box-shadow: 5px 7px 13px 0px rgba(35, 31, 32, 0.1);
  -webkit-box-shadow: 5px 7px 13px 0px rgba(35, 31, 32, 0.1);
  -moz-box-shadow: 5px 7px 13px 0px rgbaa(35, 31, 32, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.ec-category-wrapper-1 .ec_cat_content .ec-cat-desc {
  position: absolute;
  top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
}

.ec-category-wrapper-1 .ec_cat_content .ec-section-btn a {
  font-size: 1.143rem;
  padding: 10px 15px;
  min-width: 160px;
}

.ec-category-wrapper-3 .cat-card {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.ec-category-wrapper-3 .cat-card img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-category-wrapper-3 .cat-card:hover img {
  -webkit-transform: scale(1.2) rotate(-5deg);
  transform: scale(1.2) rotate(-5deg);
}

.ec-category-wrapper-3 .cat-card .cat-detail {
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.ec-category-wrapper-3 .cat-card .cat-detail h4,
.ec-category-wrapper-3 .cat-card .cat-detail .h4 {
  font-weight: 700;
  font-size: 18px;
  color: #777;
}

.ec-category-wrapper-3 .cat-card .cat-detail h5,
.ec-category-wrapper-3 .cat-card .cat-detail .h5 {
  line-height: 1.4;
  margin-bottom: 20px;
  font-size: 15px;
  color: #777;
}

.ec-category-wrapper-3 .cat-card .cat-detail .btn-primary {
  padding: 8px 15px;
  text-transform: uppercase;
  line-height: 22px;
}

.ec-category-wrapper-4 .cat-card {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.ec-category-wrapper-4 .cat-card .card-img {
  position: relative;
  overflow: hidden;
}

.ec-category-wrapper-4 .cat-card img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-category-wrapper-4 .cat-card:hover img {
  -webkit-transform: scale(1.2) rotate(-5deg);
  transform: scale(1.2) rotate(-5deg);
}

.ec-category-wrapper-4 .cat-card .cat-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-category-wrapper-4 .cat-card .cat-detail h4,
.ec-category-wrapper-4 .cat-card .cat-detail .h4 {
  margin-top: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  color: #777;
}

.ec-category-wrapper-4 .cat-card .cat-detail h5,
.ec-category-wrapper-4 .cat-card .cat-detail .h5 {
  margin-bottom: 15px;
  text-align: center;
  line-height: 1.4;
  font-size: 15px;
  color: #777;
}

.ec-category-wrapper-4 .cat-card .cat-detail .btn-primary {
  text-align: center;
  width: 120px;
  padding: 8px 15px;
  text-transform: uppercase;
  line-height: 22px;
}

.ec-category-wrapper-5 .cat-card {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.ec-category-wrapper-5 .cat-card img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-category-wrapper-5 .cat-card:hover .cat-detail {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.ec-category-wrapper-5 .cat-card:hover .btn-primary-1 {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.ec-category-wrapper-5 .cat-card:hover img {
  -webkit-transform: scale(1.2) rotate(-5deg);
  transform: scale(1.2) rotate(-5deg);
}

.ec-category-wrapper-5 .cat-card .btn-primary-1 {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 120px;
  margin: 0 auto;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 10px 15px 8px 15px;
  text-transform: uppercase;
  border-radius: 30px;
  z-index: 1;
  -webkit-transform: scale(0);
  transform: scale(0);
  line-height: 22px;
  text-align: center;
}

.ec-category-wrapper-5 .cat-card .cat-detail {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px;
  opacity: 1;
}

.ec-category-wrapper-5 .cat-card .cat-detail .cat-detail-block {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
}

.ec-category-wrapper-5 .cat-card .cat-detail h4,
.ec-category-wrapper-5 .cat-card .cat-detail .h4 {
  font-weight: 700;
  font-size: 17px;
}

.ec-category-wrapper-5 .cat-card .cat-detail h5,
.ec-category-wrapper-5 .cat-card .cat-detail .h5 {
  line-height: 1.4;
  margin-bottom: 15px;
  text-align: center;
  font-size: 15px;
}

.ec-category-wrapper-5 .cat-card .cat-detail .btn-primary {
  padding: 8px 15px;
  text-transform: uppercase;
  border-radius: 30px;
  line-height: 22px;
}

.cat-space-2 > div {
  margin: 15px 0;
}

.cat-space-3 > div {
  margin: 15px 0;
}

/*-------------------------------------------------
  Element Button Page CSS
---------------------------------------------------*/
/*------  Button Set  ------*/
.btn-sets {
  margin: -15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-btn-ds {
  margin: -10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ec-btn-ds .btn {
  margin: 10px;
}

.ec-btn-ds .btn-jittery {
  animation: jittery 4s infinite;
  -webkit-animation: jittery 4s infinite;
}

.ec-btn-ds .btn-icon {
  position: relative;
  margin-left: 8px;
  overflow: hidden;
}

.ec-btn-ds .btn-icon i {
  position: absolute;
  top: 41%;
  left: 48%;
  -webkit-transform: scale(0.75) translate(-16%, 400%);
  transform: scale(0.75) translate(-16%, 400%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.ec-btn-ds .btn-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.ec-btn-ds .btn-icon:hover i {
  -webkit-transform: scale(0.75) translate(-16%, 0);
  transform: scale(0.75) translate(-16%, 0);
}

.ec-btn-ds .btn-icon:hover span {
  -webkit-transform: translateY(-400%);
  transform: translateY(-400%);
}

.ec-btn-ds .btn-jelly:hover {
  animation: jelly 0.5s;
  -webkit-animation: jelly 0.5s;
}

.ec-btn-ds .btn-pulse:hover {
  -webkit-box-shadow: 0 0 0 18px transparent;
  box-shadow: 0 0 0 18px transparent;
  animation: pulse 1s;
  -webkit-animation: pulse 1s;
}

@-webkit-keyframes jittery {
  5%,
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  15% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }

  20% {
    -webkit-transform: scale(1.15) rotate(-5deg);
    transform: scale(1.15) rotate(-5deg);
  }

  25% {
    -webkit-transform: scale(1.15) rotate(5deg);
    transform: scale(1.15) rotate(5deg);
  }

  30% {
    -webkit-transform: scale(1.15) rotate(-3deg);
    transform: scale(1.15) rotate(-3deg);
  }

  35% {
    -webkit-transform: scale(1.15) rotate(2deg);
    transform: scale(1.15) rotate(2deg);
  }

  40% {
    -webkit-transform: scale(1.15) rotate(0);
    transform: scale(1.15) rotate(0);
  }
}

@keyframes jittery {
  5%,
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  15% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }

  20% {
    -webkit-transform: scale(1.15) rotate(-5deg);
    transform: scale(1.15) rotate(-5deg);
  }

  25% {
    -webkit-transform: scale(1.15) rotate(5deg);
    transform: scale(1.15) rotate(5deg);
  }

  30% {
    -webkit-transform: scale(1.15) rotate(-3deg);
    transform: scale(1.15) rotate(-3deg);
  }

  35% {
    -webkit-transform: scale(1.15) rotate(2deg);
    transform: scale(1.15) rotate(2deg);
  }

  40% {
    -webkit-transform: scale(1.15) rotate(0);
    transform: scale(1.15) rotate(0);
  }
}

@-webkit-keyframes jelly {
  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}

@keyframes jelly {
  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}

@-webkit-keyframes pulse {
  from {
    -webkit-box-shadow: 0 0 0 0 #3474d4;
    box-shadow: 0 0 0 0 #3474d4;
  }
}

@keyframes pulse {
  from {
    -webkit-box-shadow: 0 0 0 0 #3474d4;
    box-shadow: 0 0 0 0 #3474d4;
  }
}

.ec-btn-bw {
  margin: -10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  /* 2 */
  /* 3 */
  /* 4 */
  /* 5 */
  /* 6 */
  /* 7 */
  /* 8 */
  /* 9 */
  /* 10 */
  /* 11 */
  /* 12 */
  /* 13 */
  /* 14 */
  /* 15 */
  /* 16 */
}

.ec-btn-bw .custom-btn {
  margin: 10px;
  outline: none;
  width: 130px;
  height: 39px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: "Poppins, sans-serif", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  line-height: 17px;
}

.ec-btn-bw .btn-1 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-1:hover {
  -webkit-box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
}

.ec-btn-bw .btn-2:after {
  position: absolute;
  content: "";
  top: 4px;
  left: 5px;
  right: 5px;
  height: 28px;
  border: 1px solid #000;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-2:hover:after {
  opacity: 1;
}

.ec-btn-bw .btn-3 {
  line-height: 39px;
  padding: 0;
}

.ec-btn-bw .btn-3:hover {
  background: transparent;
  color: #000;
}

.ec-btn-bw .btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.ec-btn-bw .btn-3:before,
.ec-btn-bw .btn-3:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-3:before {
  height: 0%;
  width: 2px;
}

.ec-btn-bw .btn-3:after {
  width: 0%;
  height: 2px;
}

.ec-btn-bw .btn-3:hover:before {
  height: 100%;
}

.ec-btn-bw .btn-3:hover:after {
  width: 100%;
}

.ec-btn-bw .btn-3 span:before,
.ec-btn-bw .btn-3 span:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-3 span:before {
  width: 2px;
  height: 0%;
}

.ec-btn-bw .btn-3 span:after {
  width: 0%;
  height: 2px;
}

.ec-btn-bw .btn-3 span:hover:before {
  height: 100%;
}

.ec-btn-bw .btn-3 span:hover:after {
  width: 100%;
}

.ec-btn-bw .btn-4 {
  position: relative;
  color: #000;
  z-index: 2;
  padding: 0;
}

.ec-btn-bw .btn-4:hover {
  border: none;
}

.ec-btn-bw .btn-4:before,
.ec-btn-bw .btn-4:after {
  position: absolute;
  content: "";
  width: 0%;
  height: 0%;
  border: 2px solid;
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-4:before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #000;
  border-left-color: #000;
}

.ec-btn-bw .btn-4:after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #000;
  border-right-color: #000;
}

.ec-btn-bw .btn-4:hover:before,
.ec-btn-bw .btn-4:hover:after {
  border-color: #000;
  height: 100%;
  width: 100%;
}

.ec-btn-bw .btn-5 {
  background: #000;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
}

.ec-btn-bw .btn-5:hover {
  background: transparent;
  color: #000;
  -webkit-box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
}

.ec-btn-bw .btn-5:before,
.ec-btn-bw .btn-5:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #000;
  -webkit-transition: 400ms ease all;
  transition: 400ms ease all;
}

.ec-btn-bw .btn-5:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.ec-btn-bw .btn-5:hover:before,
.ec-btn-bw .btn-5:hover:after {
  width: 100%;
  -webkit-transition: 800ms ease all;
  transition: 800ms ease all;
}

.ec-btn-bw .btn-6 {
  background: #000;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
}

.ec-btn-bw .btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.ec-btn-bw .btn-6:before,
.ec-btn-bw .btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: #000;
}

.ec-btn-bw .btn-6:before {
  right: 0;
  top: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ec-btn-bw .btn-6:after {
  left: 0;
  bottom: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ec-btn-bw .btn-6:hover {
  color: #000;
  background: transparent;
}

.ec-btn-bw .btn-6:hover:before {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 100%;
}

.ec-btn-bw .btn-6:hover:after {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 100%;
}

.ec-btn-bw .btn-6 span:before,
.ec-btn-bw .btn-6 span:after {
  position: absolute;
  content: "";
  background: #000;
}

.ec-btn-bw .btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ec-btn-bw .btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ec-btn-bw .btn-6 span:hover:before {
  width: 100%;
}

.ec-btn-bw .btn-6 span:hover:after {
  width: 100%;
}

.ec-btn-bw .btn-7 {
  background: #000;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.ec-btn-bw .btn-7:hover {
  background: transparent;
  color: #000;
}

.ec-btn-bw .btn-7:before,
.ec-btn-bw .btn-7:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 50%;
  right: 0;
  z-index: -1;
  background: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-7:before {
  top: 0;
}

.ec-btn-bw .btn-7:after {
  bottom: 0;
}

.ec-btn-bw .btn-7:hover:before,
.ec-btn-bw .btn-7:hover:after {
  height: 0;
  background-color: #000;
}

.ec-btn-bw .btn-8 {
  line-height: 40px;
  padding: 0;
  background: transparent;
  position: relative;
  z-index: 2;
  color: #fff;
  -webkit-perspective: 300px;
  perspective: 300px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.ec-btn-bw .btn-8:hover {
  color: #000;
}

.ec-btn-bw .btn-8:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: -1;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-8:hover:after {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.ec-btn-bw .btn-9 {
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}

.ec-btn-bw .btn-9:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-9:hover {
  -webkit-box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  color: #fff;
}

.ec-btn-bw .btn-9:hover:after {
  -webkit-transform: scale(2) rotate(180deg);
  transform: scale(2) rotate(180deg);
  background: #000;
  -webkit-box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}

.ec-btn-bw .btn-10 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}

.ec-btn-bw .btn-10:after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
}

.ec-btn-bw .btn-10:hover {
  color: #fff;
}

.ec-btn-bw .btn-10:hover:after {
  background: #000;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.ec-btn-bw .btn-11 {
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-11:hover {
  background: #000;
  color: #fff;
}

.ec-btn-bw .btn-11:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  -webkit-animation: shiny-btn1 3s ease-in-out infinite;
  animation: shiny-btn1 3s ease-in-out infinite;
}

.ec-btn-bw .btn-11:active {
  -webkit-box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

.ec-btn-bw .btn-12 {
  position: relative;
  border: none;
  width: 130px;
  height: 40px;
  line-height: 40px;
  -webkit-perspective: 230px;
  perspective: 230px;
  overflow: hidden;
}

.ec-btn-bw .btn-12 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 40px;
  border: 2px solid #000;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ec-btn-bw .btn-12 span:nth-child(1) {
  -webkit-box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -24px;
  transform-origin: 50% 50% -24px;
}

.ec-btn-bw .btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.ec-btn-bw .btn-12:hover span:nth-child(1) {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.ec-btn-bw .btn-12:hover span:nth-child(2) {
  background: #e0e5ec;
  color: #e0e5ec;
  -webkit-transform: rotateX(-100deg);
  transform: rotateX(-100deg);
}

.ec-btn-bw .btn-13 {
  background: #000;
  color: #fff;
  z-index: 1;
}

.ec-btn-bw .btn-13:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #e0e5ec;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-13:hover {
  color: #000;
}

.ec-btn-bw .btn-13:hover:after {
  top: 0;
  height: 100%;
}

.ec-btn-bw .btn-13:active {
  top: 2px;
}

.ec-btn-bw .btn-14 {
  background: #000;
  color: #fff;
  z-index: 1;
}

.ec-btn-bw .btn-14:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: #e0e5ec;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-14:hover {
  color: #000;
}

.ec-btn-bw .btn-14:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

.ec-btn-bw .btn-14:active {
  top: 2px;
}

.ec-btn-bw .btn-15 {
  background: #000;
  color: #fff;
  z-index: 1;
}

.ec-btn-bw .btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: #e0e5ec;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-15:hover {
  color: #000;
}

.ec-btn-bw .btn-15:hover:after {
  left: 0;
  width: 100%;
}

.ec-btn-bw .btn-15:active {
  top: 2px;
}

.ec-btn-bw .btn-16 {
  background: #000;
  color: #fff;
  z-index: 1;
}

.ec-btn-bw .btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  background: #e0e5ec;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ec-btn-bw .btn-16:hover {
  color: #000;
}

.ec-btn-bw .btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.ec-btn-bw .btn-16:active {
  top: 2px;
}

.ec-btn-anim .noselect {
  width: 150px;
  height: 50px;
  margin: 15px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: red;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background: #e62222;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  direction: ltr !important;
}

.ec-btn-anim .noselect,
.ec-btn-anim .noselect span {
  -webkit-transition: 200ms;
  transition: 200ms;
}

.ec-btn-anim .noselect .text {
  -webkit-transform: translateX(35px);
  transform: translateX(35px);
  color: white;
  font-weight: bold;
}

.ec-btn-anim .noselect .icon {
  position: absolute;
  border-left: 1px solid #ffffff;
  -webkit-transform: translateX(110px);
  transform: translateX(110px);
  height: 50px;
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ec-btn-anim .noselect svg {
  width: 15px;
  fill: #eee;
}

.ec-btn-anim .noselect:hover {
  background: #ff3636;
}

.ec-btn-anim .noselect:hover .text {
  color: transparent;
}

.ec-btn-anim .noselect:hover .icon {
  width: 150px;
  border-left: none;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
}

.ec-btn-anim .noselect:focus {
  outline: none;
}

.ec-btn-anim .btn-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ec-btn-anim .btn-swip-right {
  margin: 15px;
  border: 0;
  position: relative;
  min-width: 180px;
  min-height: 50px;
  border-radius: 60px;
  color: #fff;
  font-family: "Poppins";
  font-weight: bold;
  background: #222;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  direction: ltr !important;
}

.ec-btn-anim .btn-swip-right .text,
.ec-btn-anim .btn-swip-right .icon-container {
  position: relative;
  z-index: 2;
}

.ec-btn-anim .btn-swip-right .icon-container {
  position: relative;
  width: 14px;
  height: 25px;
  margin-left: 15px;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.ec-btn-anim .btn-swip-right .icon-container .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  -webkit-transition: opacity 250ms ease, -webkit-transform 500ms ease;
  transition: opacity 250ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 250ms ease;
  transition: transform 500ms ease, opacity 250ms ease,
    -webkit-transform 500ms ease;
}

.ec-btn-anim .btn-swip-right .icon-container .icon--left {
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
  opacity: 0;
}

.ec-btn-anim .btn-swip-right .icon-container .icon svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

.ec-btn-anim .btn-swip-right::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #da0000;
  border-radius: 60px;
  z-index: 1;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.ec-btn-anim .btn-swip-right:hover::after {
  -webkit-transform: translateX(73%);
  transform: translateX(73%);
}

.ec-btn-anim .btn-swip-right:hover .icon-container {
  -webkit-transform: translateX(260%);
  transform: translateX(260%);
}

.ec-btn-anim .btn-swip-right:hover .icon-container .icon--left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.ec-btn-anim .btn-swip-right:hover .icon-container .icon--right {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0;
}

.ec-bg-anim .horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 180px;
  height: 50px;
  margin: 15px;
  border: 5px solid #3474d4;
  font-size: 20px;
  cursor: pointer;
}

.ec-bg-anim .horizontal > .text {
  position: relative;
  color: #3474d4;
  background-repeat: repeat;
  background-size: 200%;
  background-position: 100% 0;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-transition: background-position 300ms;
  transition: background-position 300ms;
  font-weight: 700;
}

.ec-bg-anim .horizontal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3474d4;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.ec-bg-anim .horizontal:hover .text {
  background-position: 0 0;
  color: #fff;
}

.ec-bg-anim .horizontal:hover::before {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.ec-bg-anim .vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 180px;
  height: 50px;
  margin: 15px;
  border: 5px solid #3474d4;
  font-size: 20px;
  cursor: pointer;
}

.ec-bg-anim .vertical > .text {
  position: relative;
  color: #3474d4;
  background-repeat: repeat;
  background-size: 100% 200%;
  background-position: 0 0;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-transition: background-position 300ms;
  transition: background-position 300ms;
  font-weight: 700;
}

.ec-bg-anim .vertical::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3474d4;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.ec-bg-anim .vertical:hover .text {
  background-position: 0 100%;
  color: #fff;
}

.ec-bg-anim .vertical:hover::before {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.ec-border-anim .corner-button {
  margin: 15px;
  letter-spacing: 0.02rem;
  cursor: pointer;
  background: transparent;
  border: 4px solid #3474d4;
  padding: 5px 30px;
  font-size: 20px;
  color: #3474d4;
  position: relative;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-weight: 700;
}

.ec-border-anim .corner-button:hover {
  color: #3474d4;
}

.ec-border-anim .corner-button:hover::before {
  width: 0;
}

.ec-border-anim .corner-button:hover::after {
  height: 0;
}

.ec-border-anim .corner-button:active {
  border-width: 0.25rem;
}

.ec-border-anim .corner-button span {
  position: relative;
  z-index: 2;
}

.ec-border-anim .corner-button::before,
.ec-border-anim .corner-button::after {
  content: "";
  position: absolute;
  background: #ffffff;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ec-border-anim .corner-button::before {
  width: calc(100% - 1rem);
  height: calc(100% + 1rem);
  top: -0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ec-border-anim .corner-button::after {
  height: calc(100% - 1rem);
  width: calc(101% + 1rem);
  left: -0.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ec-swipe-left {
  height: 50px;
  width: 170px;
  margin: 15px;
  padding: 0;
}

.ec-swipe-left:hover .cta {
  -webkit-transform: translateX(-90%);
  transform: translateX(-90%);
  -webkit-transition: 1.2s;
  transition: 1.2s;
}

.ec-swipe-left .phone {
  background-color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 36px;
  position: absolute;
  cursor: pointer;
}

.ec-swipe-left .cta {
  height: 50px;
  width: 170px;
  color: white;
  z-index: 1;
  -webkit-transition: 1.2s;
  transition: 1.2s;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#4776e6),
    to(#8e54e9)
  );
  background: linear-gradient(to right bottom, #4776e6, #8e54e9);
}

.ec-swipe-left .number {
  height: 50px;
  width: 170px;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.5)),
      to(rgba(0, 0, 0, 0.5))
    ),
    url(https://images.unsplash.com/photo-1529044728228-9917df974897?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://images.unsplash.com/photo-1529044728228-9917df974897?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80);
  background-size: cover;
  color: white;
  -webkit-box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.5);
}

.ec-swipe-arrow {
  height: 50px;
  width: 159px;
  margin: 15px;
  padding: 0;
}

.ec-swipe-arrow .ec-swipe-btn {
  border: 4px solid #d9d9d9;
  color: #777;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin: auto;
  padding: 10px 20px 10px 50px;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}

.ec-swipe-arrow .ec-swipe-btn .label,
.ec-swipe-arrow .ec-swipe-btn .icon-arrow {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-perspective: 1000;
  perspective: 1000;
}

.ec-swipe-arrow .ec-swipe-btn .label {
  display: inline-block;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}

.ec-swipe-arrow .ec-swipe-btn .icon-arrow {
  fill: #777;
  height: 15px;
  top: 16px;
  -webkit-transition: opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1),
    opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1),
    opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  width: 35px;
}

.ec-swipe-arrow .ec-swipe-btn .icon-arrow.before {
  left: 10px;
  margin-right: 15px;
  position: absolute;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.ec-swipe-arrow .ec-swipe-btn .icon-arrow.after {
  margin-left: 15px;
  opacity: 0;
  position: absolute;
  right: 10px;
  -webkit-transform: translateX(75%) scaleX(0.1);
  transform: translateX(75%) scaleX(0.1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.ec-swipe-arrow .ec-swipe-btn:hover .label {
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
}

.ec-swipe-arrow .ec-swipe-btn:hover .icon-arrow.before {
  opacity: 0;
  -webkit-transform: translateX(-75%) scaleX(0.1);
  transform: translateX(-75%) scaleX(0.1);
}

.ec-swipe-arrow .ec-swipe-btn:hover .icon-arrow.after {
  opacity: 1;
  -webkit-transform: translateX(0) scaleX(1);
  transform: translateX(0) scaleX(1);
}

.ec-swipe-arrow .ec-swipe-btn:active {
  border-color: #fd6154;
  color: #fd6154;
}

.ec-swipe-arrow .ec-swipe-btn:active .icon-arrow {
  fill: #fd6154;
}

.ec-bg-swipe {
  height: 50px;
  width: 200px;
  padding: 0;
  margin: 15px;
}

.ec-bg-swipe .ec-btn-bg-swipe {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  direction: ltr !important;
}

.ec-bg-swipe .ec-btn-bg-swipe {
  width: 12rem;
  height: auto;
  width: 12rem;
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}

.ec-bg-swipe .ec-btn-bg-swipe .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #d9d9d9;
  border-radius: 1.625rem;
}

.ec-bg-swipe .ec-btn-bg-swipe .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.ec-bg-swipe .ec-btn-bg-swipe .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.ec-bg-swipe .ec-btn-bg-swipe .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ec-bg-swipe .ec-btn-bg-swipe .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #777;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}

.ec-bg-swipe .ec-btn-bg-swipe:hover .circle {
  width: 100%;
}

.ec-bg-swipe .ec-btn-bg-swipe:hover .circle .icon.arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
  transform: translate(1rem, 0);
}

.ec-bg-swipe .ec-btn-bg-swipe:hover .button-text {
  color: #fff;
}

.ec-btn-classic {
  margin: -10px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ec-btn-classic .ec-buttons {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 10px;
  padding: 15px 20px;
  border: 2px solid #d9d9d9;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-weight: 700;
}

.ec-btn-classic .ec-buttons:after {
  position: absolute;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  content: "";
  width: 0;
  left: 50%;
  bottom: 0;
  height: 3px;
  background: #3474d4;
}

.ec-btn-classic .ec-buttons:nth-of-type(2):after {
  left: 0;
}

.ec-btn-classic .ec-buttons:nth-of-type(3):after {
  right: 0;
  left: auto;
}

.ec-btn-classic .ec-buttons:nth-of-type(4):after {
  left: 0;
  bottom: auto;
  top: -3px;
  width: 100%;
}

.ec-btn-classic .ec-buttons:nth-of-type(5):after {
  height: 120%;
  left: -10%;
  -webkit-transform: skewX(15deg);
  transform: skewX(15deg);
  z-index: -1;
}

.ec-btn-classic .ec-buttons:hover {
  cursor: pointer;
}

.ec-btn-classic .ec-buttons:hover:after {
  width: 100%;
  left: 0;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(4):after {
  top: calc(100% - 3px);
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(5) {
  color: #fff;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(5):after {
  left: -10%;
  width: 120%;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(6) {
  border-radius: 30px;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(6):after {
  width: 0%;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(7) {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(7):after {
  width: 0%;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(8) {
  -webkit-box-shadow: inset 0px 0px 0px 3px #3474d4;
  box-shadow: inset 0px 0px 0px 3px #3474d4;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(8):after {
  width: 0%;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(9) {
  -webkit-box-shadow: 0px 0px 0px 3px #3474d4;
  box-shadow: 0px 0px 0px 3px #3474d4;
}

.ec-btn-classic .ec-buttons:hover:nth-of-type(9):after {
  width: 0%;
}

/*-------------------------------------------------
  Element Product Card Page CSS
---------------------------------------------------*/
/* ----------- Start Product-DS -----------*/
.ec-product-ds {
  font-family: "Poppins, sans-serif", sans-serif;
  text-align: center;
  border: 1px solid #eeeeee;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.ec-product-ds:hover {
  -webkit-box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
}

.ec-product-ds:hover .links {
  opacity: 1;
  top: 50%;
}

.ec-product-ds:hover .ec-price {
  opacity: 0;
}

.ec-product-ds:hover .ec-add-to-cart {
  opacity: 1;
  bottom: 13px;
  text-decoration: none;
}

.ec-product-ds .ec-product-image {
  position: relative;
}

.ec-product-ds .ec-product-image a.image {
  display: block;
}

.ec-product-ds .ec-product-image img {
  width: 100%;
  height: auto;
}

.ec-product-ds .ec-product-discount-label {
  color: #fff;
  background: #3474d4;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 2px 8px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.ec-product-ds .links {
  width: 145px;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 65%;
  left: 50%;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.ec-product-ds .links li {
  display: inline-block;
  margin: 0 2px;
}

.ec-product-ds .links li a {
  position: relative;
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 10px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.ec-product-ds .links li a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-ds .links li a:hover img {
  fill: #fff;
}

.ec-product-ds .links li a:hover:before {
  display: block;
}

.ec-product-ds .links li a:hover:after {
  display: block;
}

.ec-product-ds .links li a:before {
  content: attr(data-tip);
  color: #fff;
  background-color: #555;
  font-size: 0.857rem;
  line-height: 18px;
  padding: 5px 10px;
  white-space: nowrap;
  display: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -40px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-ds .links li a:after {
  content: attr(data-tip);
  color: #fff;
  background-color: #555;
  font-size: 0.857rem;
  line-height: 18px;
  padding: 5px 10px;
  white-space: nowrap;
  display: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -40px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  content: "";
  height: 10px;
  width: 10px;
  padding: 0;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  top: -18px;
  z-index: -1;
}

.ec-product-ds .ec-product-body {
  padding: 12px 12px 15px;
  position: relative;
  background-color: #fff;
}

.ec-product-ds .ec-rating {
  padding: 0;
  margin: 10px 0 8px;
  list-style: none;
}

.ec-product-ds .ec-rating li {
  font-size: 14px;
}

.ec-product-ds .ec-title {
  margin: 0 0 10px;
}

.ec-product-ds .ec-title a {
  display: block;
  font-size: 0.928rem;
  text-transform: uppercase;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-ds .ec-title a:hover {
  color: #3474d4;
  text-decoration: none;
}

.ec-product-ds .ec-price {
  margin-bottom: 5px;
  color: #3474d4;
  font-size: 1.143rem;
  font-weight: 600;
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-ds .ec-price span {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.ec-product-ds .ec-add-to-cart {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: 130px;
  height: 35px;
  font-size: 0.857rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 33px;
  opacity: 0;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 2px solid #eeeeee;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.ec-product-ds .ec-add-to-cart:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

/* -----------/ END Product-DS -----------*/
/* ----------- START Product-csc -----------*/
.ec-product-csc {
  font-family: "Poppins, sans-serif", sans-serif;
  text-align: center;
  border: 1px solid #eeeeee;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.ec-product-csc .ec-product-image {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
}

.ec-product-csc .ec-product-image img {
  max-width: 100%;
}

.ec-product-csc .ec-product-image .ec-product-ribbon {
  width: 45px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase !important;
  color: #fff;
  background-color: #373840;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  z-index: 10;
}

.ec-product-csc:hover .ec-product-image .ec-product-ribbon {
  -webkit-box-shadow: 0px 20px 20px -16px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 20px 20px -16px rgba(0, 0, 0, 0.5);
}

.ec-product-csc .ec-product-body {
  padding: 15px;
  position: relative;
  background-color: #fff;
}

.ec-product-csc .ec-product-body .ec-title {
  margin: 0 0 5px;
  font-size: 0.857rem;
  font-weight: 500;
  color: #777;
  text-transform: uppercase;
}

.ec-product-csc .ec-product-body .ec-title a:hover {
  text-decoration: none;
}

.ec-product-csc .ec-product-body .ec-description {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 5px;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ec-product-csc .ec-product-body .ec-rating {
  padding: 0;
  margin: 10px 0 8px;
  list-style: none;
}

.ec-product-csc .ec-product-body .ec-rating li {
  font-size: 14px;
}

.ec-product-csc .ec-product-body .ec-price {
  display: block;
  margin-bottom: 5px;
  font-size: 1.143rem;
  font-weight: 600;
  text-align: center;
  color: #3474d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-csc .ec-product-body .ec-price span {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.ec-product-csc .ec-product-body .ec-link-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ec-product-csc .ec-product-body .ec-link-icon a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 10px 5px 0 5px;
  padding: 6px;
  line-height: 10px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.ec-product-csc .ec-product-body .ec-link-icon a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-csc .ec-product-body .ec-link-btn {
  padding: 10px 20px 0 0;
}

.ec-product-csc .ec-product-body .ec-link-btn .ec-add-to-cart {
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  font-size: 0.857rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.ec-product-csc .ec-product-body .ec-link-btn .ec-add-to-cart:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

/* -----------/ END Product-csc -----------*/
/* ----------- START Product-fw -----------*/
.ec-product-fw {
  font-family: "Poppins, sans-serif", sans-serif;
  text-align: center;
  background: #fff;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: 1px solid #eeeeee;
}

.ec-product-fw .ec-product-image {
  padding: 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.ec-product-fw .ec-product-image img {
  max-width: 100%;
}

.ec-product-fw .ec-product-image .ec-link-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  right: auto;
  left: auto;
  bottom: 20px;
  opacity: 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.ec-product-fw .ec-product-image .ec-link-icon a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 9px 5px;
  padding: 7px;
  line-height: 10px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.ec-product-fw .ec-product-image .ec-link-icon a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-fw .ec-product-image .ec-product-ribbon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase !important;
  color: #fff;
  background-color: #3474d4 !important;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  z-index: 10;
  padding: 5px 15px;
}

.ec-product-fw:hover .ec-product-image .ec-link-icon {
  opacity: 1;
}

.ec-product-fw:hover .ec-product-image .ec-product-ribbon {
  -webkit-box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
}

.ec-product-fw .ec-product-body {
  padding: 0 15px 15px 15px;
  position: relative;
}

.ec-product-fw .ec-product-body .ec-title {
  font-size: 1.143rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 5px;
}

.ec-product-fw .ec-product-body .ec-title a:hover {
  text-decoration: none;
}

.ec-product-fw .ec-product-body .ec-description {
  font-size: 0.928rem;
  font-weight: 500;
  margin: 0 0 5px;
  color: #777;
  font-family: "Poppins";
}

.ec-product-fw .ec-product-body .ec-rating {
  padding: 0;
  margin: 10px 0 8px;
  list-style: none;
}

.ec-product-fw .ec-product-body .ec-rating li {
  font-size: 14px;
}

.ec-product-fw .ec-product-body .ec-price {
  display: block;
  margin-bottom: 5px;
  font-size: 1.143rem;
  font-weight: 600;
  text-align: center;
  color: #3474d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-fw .ec-product-body .ec-price span {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.ec-product-fw .ec-link-btn .ec-add-to-cart {
  display: block;
  padding: 5px 10px;
  width: 100%;
  font-size: 0.857rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.ec-product-fw .ec-link-btn .ec-add-to-cart:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

/* -----------/ END Product-fw -----------*/
/* ----------- START Product-tp -----------*/
.ec-product-tp {
  font-family: "Poppins, sans-serif", sans-serif;
  text-align: center;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.ec-product-tp .ec-product-image {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 15px 15px 15px;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  z-index: 1;
}

.ec-product-tp .ec-product-image img {
  max-width: 100%;
  border-radius: 15px;
}

.ec-product-tp .ec-product-image .ec-link-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  right: auto;
  left: auto;
  bottom: 30px;
  opacity: 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.ec-product-tp .ec-product-image .ec-link-icon a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 9px 5px;
  padding: 7px;
  line-height: 10px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  border-radius: 30px;
}

.ec-product-tp .ec-product-image .ec-link-icon a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-tp .ec-product-image .ec-product-ribbon {
  position: absolute;
  padding: 5px 15px;
  top: 15px;
  right: 30px;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase !important;
  border-radius: 0 10px 0 0;
  color: #fff;
  background-color: #3474d4 !important;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  z-index: 10;
  border-radius: 15px;
}

.ec-product-tp:hover .ec-product-image .ec-link-icon {
  opacity: 1;
}

.ec-product-tp .ec-product-body {
  position: relative;
  margin-top: -130px;
  padding: 15px;
  border: 1px solid;
  border-color: #eeeeee;
  border-radius: 15px;
  background-color: #fff;
}

.ec-product-tp .ec-product-body .ec-title {
  margin: 110px 0 5px;
  font-size: 1.143rem;
  font-weight: 600;
  text-transform: uppercase;
}

.ec-product-tp .ec-product-body .ec-title a:hover {
  text-decoration: none;
}

.ec-product-tp .ec-product-body .ec-description {
  font-size: 0.928rem;
  font-weight: 300;
  margin: 0 0 5px;
  color: #777;
}

.ec-product-tp .ec-product-body .ec-rating {
  padding: 0;
  margin: 10px 0 8px;
  list-style: none;
}

.ec-product-tp .ec-product-body .ec-rating li {
  font-size: 14px;
}

.ec-product-tp .ec-product-body .ec-price {
  display: block;
  margin-bottom: 5px;
  font-size: 1.143rem;
  font-weight: 600;
  text-align: center;
  color: #3474d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-tp .ec-product-body .ec-price span {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.ec-product-tp .ec-link-btn .ec-add-to-cart {
  display: block;
  padding: 5px 10px;
  width: 100%;
  font-size: 0.857rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
  color: #fff;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 30px;
}

.ec-product-tp .ec-link-btn .ec-add-to-cart:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

/* -----------/ END Product-tp -----------*/
/* ----------- START Product-lsc -----------*/
.ec-product-lsc {
  padding: 15px;
  border: 1px solid;
  border-color: #eeeeee;
  font-family: "Poppins, sans-serif", sans-serif;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.ec-product-lsc .ec-product-image {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.ec-product-lsc .ec-product-image img {
  max-width: 100%;
}

.ec-product-lsc .ec-product-image .ec-product-ribbon {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase !important;
  color: #fff;
  background-color: #3474d4 !important;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  z-index: 10;
  padding: 3px 10px;
  line-height: 15px;
}

.ec-product-lsc:hover .ec-product-image .ec-product-ribbon {
  -webkit-box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
}

.ec-product-lsc .ec-product-body {
  padding: 12px 0 0;
  position: relative;
  text-align: left;
}

.ec-product-lsc .ec-product-body .ec-title {
  font-size: 1.143rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 5px;
}

.ec-product-lsc .ec-product-body .ec-title a:hover {
  text-decoration: none;
}

.ec-product-lsc .ec-product-body .ec-description {
  font-size: 0.928rem;
  font-weight: 500;
  margin: 0 0 8px;
  color: #777;
}

.ec-product-lsc .ec-product-body .ec-description span {
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 0.857rem;
  color: #555;
}

.ec-product-lsc .ec-product-body .ec-rating {
  padding: 0;
  margin: 10px 0 12px;
  list-style: none;
}

.ec-product-lsc .ec-product-body .ec-rating li {
  font-size: 14px;
}

.ec-product-lsc .ec-product-body .ec-el-price {
  position: relative;
}

.ec-product-lsc .ec-product-body .ec-el-price .ec-o-price {
  position: relative;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #3474d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-lsc .ec-product-body .ec-el-price .ec-d-price {
  margin: -5px 0 8px;
  position: absolute;
  top: 9px;
  left: 60px;
  color: #999;
  font-size: 14px;
  font-weight: 600;
  text-decoration: line-through;
}

.ec-product-lsc .ec-product-body .ec-size a {
  display: inline-block;
  padding: 1px;
  border: 1px solid;
  border-color: #eeeeee;
  color: #777;
  margin: 5px 0 20px;
  font-size: 11px;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 20px;
  height: 20px;
  text-align: center;
}

.ec-product-lsc .ec-product-body .ec-size a:hover {
  border: 1px solid #eeeeee;
  text-decoration: none;
  color: #fff;
  background-color: #3474d4;
}

.ec-product-lsc .ec-product-body .ec-link-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ec-product-lsc .ec-product-body .ec-link-btn .ec-add-to-cart {
  height: 35px;
  display: block;
  padding: 0 10px;
  font-size: 0.857rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  margin-right: 2px;
}

.ec-product-lsc .ec-product-body .ec-link-btn .ec-add-to-cart:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-lsc .ec-product-body .ec-link-icon {
  display: contents;
  position: absolute;
  right: auto;
  left: auto;
  bottom: 20px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.ec-product-lsc .ec-product-body .ec-link-icon a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 10px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.ec-product-lsc .ec-product-body .ec-link-icon a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

/* -----------/ END Product-lsc -----------*/
/* ----------- START Product-swd -----------*/
.ec-product-swd {
  background-color: #fff;
  font-family: "Lato", sans-serif;
  text-align: center;
}

.ec-product-swd .ec-product-image {
  position: relative;
}

.ec-product-swd .ec-product-image a.image {
  display: block;
}

.ec-product-swd .ec-product-image img {
  width: 100%;
  height: auto;
}

.ec-product-swd .ec-product-image .pic-2 {
  width: 100%;
  height: 100%;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.ec-product-swd:hover .ec-product-image .pic-1 {
  opacity: 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.ec-product-swd:hover .ec-product-image .pic-2 {
  -webkit-filter: blur(0);
  filter: blur(0);
  opacity: 1;
}

.ec-product-swd:hover .ec-product-links {
  opacity: 1;
  -webkit-transform: translateX(-50%) scale(1);
  transform: translateX(-50%) scale(1);
}

.ec-product-swd:hover .ec-product-body {
  -webkit-transform: translateY(47px);
  transform: translateY(47px);
}

.ec-product-swd .ec-product-like-icon {
  color: #999;
  font-size: 1.143rem;
  position: absolute;
  right: 13px;
  top: 10px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-swd .ec-product-like-icon:hover {
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
}

.ec-product-swd .ec-product-links {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  -webkit-transform: translateX(-50%) scale(0);
  transform: translateX(-50%) scale(0);
  position: absolute;
  top: calc(100% + 18px);
  left: 50%;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #fff;
}

.ec-product-swd .ec-product-links li {
  display: inline-block;
}

.ec-product-swd .ec-product-links li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  font-size: 1.143rem;
  line-height: 10px;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-swd .ec-product-links li a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-swd .ec-product-body {
  background-color: #fff;
  padding: 10px 10px 10px;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-swd .ec-product-body .ec-title {
  font-size: 1.143rem;
  font-weight: 700;
  text-transform: capitalize;
  margin: 10px 0 7px;
}

.ec-product-swd .ec-product-body .ec-title a {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-swd .ec-product-body .ec-title a:hover {
  text-decoration: none;
}

.ec-product-swd .ec-product-body .ec-price {
  display: block;
  margin-bottom: 5px;
  font-size: 1.143rem;
  font-weight: 600;
  text-align: center;
  color: #3474d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-swd .ec-product-body .ec-price span {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.ec-product-swd .ec-product-body .ec-size a {
  display: inline-block;
  padding: 2px;
  border: 1px solid;
  border-color: #eeeeee;
  color: #777;
  margin: 5px 0 15px;
  font-size: 0.928rem;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 25px;
  height: 25px;
  text-align: center;
}

.ec-product-swd .ec-product-body .ec-size a:hover {
  border: 1px solid #eeeeee;
  text-decoration: none;
  background-color: #eeeeee;
}

.ec-product-swd .ec-product-body .ec-color {
  opacity: 0.7;
}

.ec-product-swd .ec-product-body .ec-color a {
  border: none;
  border: 2px solid transparent;
  padding: 1px;
  line-height: 10px;
  text-align: center;
  display: inline-block;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-swd .ec-product-body .ec-color a:hover {
  border: 2px solid;
  border-color: #eeeeee;
}

.ec-product-swd .ec-product-body .ec-color span {
  width: 17px;
  height: 17px;
  display: inline-block;
  border-radius: 30px;
}

.ec-product-swd .ec-product-body .ec-color .red {
  background-color: #f56464;
}

.ec-product-swd .ec-product-body .ec-color .blue {
  background-color: #5d5dfc;
}

.ec-product-swd .ec-product-body .ec-color .pink {
  background-color: #ff9faf;
}

.ec-product-swd .ec-product-body .ec-color .green {
  background-color: #539653;
}

/* -----------/ END Product-swd -----------*/
/* ----------- START Product-cbb -----------*/
.ec-product-cbb {
  background-color: #fff;
  font-family: "Work Sans", sans-serif;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-cbb:hover {
  -webkit-box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
}

.ec-product-cbb:hover .ec-product-image .pic-1 {
  opacity: 0;
}

.ec-product-cbb:hover .ec-product-image .pic-2 {
  -webkit-filter: blur(0);
  filter: blur(0);
  opacity: 1;
}

.ec-product-cbb:hover .ec-product-links {
  opacity: 1;
}

.ec-product-cbb:hover .ec-product-links li {
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}

.ec-product-cbb:hover .ec-product-links li:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.ec-product-cbb:hover .ec-product-links li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.ec-product-cbb:hover .ec-product-links li:nth-child(1) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.ec-product-cbb .ec-product-image {
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-cbb .ec-product-image a.image {
  display: block;
}

.ec-product-cbb .ec-product-image img {
  width: 100%;
  height: auto;
}

.ec-product-cbb .ec-product-image .pic-1 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.ec-product-cbb .ec-product-image .pic-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.ec-product-cbb .ec-product-links {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 10px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-cbb .ec-product-links li {
  margin: 0 0 10px 0;
  -webkit-transform: rotate(360deg) scale(0);
  transform: rotate(360deg) scale(0);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-cbb .ec-product-links li a {
  display: block;
  padding: 6px;
  width: 35px;
  height: 35px;
  line-height: 10px;
  border-radius: 50%;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-cbb .ec-product-links li a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-cbb .ec-product-body {
  text-align: center;
  padding: 15px 10px;
}

.ec-product-cbb .ec-product-body .ec-rating {
  padding: 0;
  margin: 0 0 5px;
  list-style: none;
}

.ec-product-cbb .ec-product-body .ec-rating li {
  font-size: 14px;
}

.ec-product-cbb .ec-product-body .ec-title {
  font-size: 1.143rem;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 7px;
}

.ec-product-cbb .ec-product-body .ec-title a {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-cbb .ec-product-body .ec-title a:hover {
  text-decoration: none;
}

.ec-product-cbb .ec-product-body .ec-price {
  display: block;
  margin-bottom: 5px;
  font-size: 1.143rem;
  font-weight: 600;
  color: #3474d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-cbb .ec-product-body .ec-price span {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.ec-product-cbb .ec-product-body .ec-size a {
  display: inline-block;
  padding: 1px;
  border: 1px solid;
  border-color: #eeeeee;
  color: #777;
  margin: 5px 0;
  font-size: 0.857rem;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 20px;
  height: 20px;
  text-align: center;
}

.ec-product-cbb .ec-product-body .ec-size a:hover {
  border: 1px solid #3474d4;
  text-decoration: none;
  color: #fff;
  background-color: #3474d4;
}

.ec-product-cbb .ec-product-body .ec-color a {
  border: none;
  border: 2px solid transparent;
  padding: 1px;
  line-height: 10px;
  text-align: center;
  display: inline-block;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 30px;
}

.ec-product-cbb .ec-product-body .ec-color a:hover {
  border: 2px solid;
  border-color: #eeeeee;
}

.ec-product-cbb .ec-product-body .ec-color span {
  width: 17px;
  height: 17px;
  display: inline-block;
  opacity: 0.7;
  border-radius: 30px;
}

.ec-product-cbb .ec-product-body .ec-color .red {
  background-color: #ff8f8f;
}

.ec-product-cbb .ec-product-body .ec-color .blue {
  background-color: #6c6cff;
}

.ec-product-cbb .ec-product-body .ec-color .pink {
  background-color: #ff8fa1;
}

.ec-product-cbb .ec-product-body .ec-color .green {
  background-color: #74b474;
}

/* -----------/ END Product-cbb -----------*/
/* ----------- START Product-sup -----------*/
.ec-product-sup {
  font-family: "Roboto", sans-serif;
  text-align: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.ec-product-sup:hover {
  -webkit-box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3);
}

.ec-product-sup:hover .ec-product-image .pic-1 {
  opacity: 0;
}

.ec-product-sup:hover .ec-product-image .pic-2 {
  opacity: 1;
}

.ec-product-sup:hover .ec-social li {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.ec-product-sup:hover .ec-product-rating {
  opacity: 1;
  bottom: 0;
}

.ec-product-sup .ec-product-image {
  position: relative;
  overflow: hidden;
}

.ec-product-sup .ec-product-image a.image {
  display: block;
}

.ec-product-sup .ec-product-image img {
  width: 100%;
  height: auto;
}

.ec-product-sup .ec-product-image .pic-1 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.ec-product-sup .ec-product-image .pic-2 {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.ec-product-sup .ec-product-sale-label {
  color: #fff;
  background: #3474d4;
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.ec-product-sup .ec-social {
  padding: 0;
  margin: 0;
  top: 0;
  right: 10px;
  list-style: none;
  position: absolute;
}

.ec-product-sup .ec-social li {
  -webkit-transform: translateX(60px);
  transform: translateX(60px);
  -webkit-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;
}

.ec-product-sup .ec-social li:nth-child(2) {
  -webkit-transition: all 0.3s ease 0.4s;
  transition: all 0.3s ease 0.4s;
}

.ec-product-sup .ec-social li a {
  display: block;
  padding: 6px;
  margin: 10px 0;
  width: 35px;
  height: 35px;
  line-height: 10px;
  border-radius: 50%;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-sup .ec-social li a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-sup .ec-product-rating {
  background: #eee;
  width: 100%;
  padding: 10px;
  opacity: 0;
  position: absolute;
  bottom: -60px;
  left: 0;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.ec-product-sup .ec-rating {
  padding: 0;
  margin: 0;
  list-style: none;
  float: left;
}

.ec-product-sup .ec-rating li {
  font-size: 0.928rem;
}

.ec-product-sup .ec-add-to-cart {
  color: #fff;
  font-weight: 500;
  float: right;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  border: 1px solid;
  padding: 2px 10px;
  font-size: 0.857rem;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.ec-product-sup .ec-add-to-cart:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-sup .ec-product-body {
  background: #fff;
  padding: 15px;
}

.ec-product-sup .ec-product-body .ec-title {
  font-size: 1.143rem;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.ec-product-sup .ec-product-body .ec-title a {
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.ec-product-sup .ec-product-body .ec-title a:hover {
  text-decoration: none;
  color: #3474d4;
}

.ec-product-sup .ec-product-body .ec-price {
  display: block;
  margin-bottom: 5px;
  font-size: 1.143rem;
  font-weight: 600;
  color: #3474d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ec-product-sup .ec-product-body .ec-price span {
  color: #999;
  font-weight: 500;
  text-decoration: line-through;
}

.ec-product-sup .ec-product-body .ec-size a {
  display: inline-block;
  padding: 2px;
  border: 1px solid;
  border-color: #3474d4;
  color: #3474d4;
  margin: 5px 0;
  font-size: 0.928rem;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 25px;
  height: 25px;
  text-align: center;
}

.ec-product-sup .ec-product-body .ec-size a:hover {
  border: 1px solid #3474d4;
  text-decoration: none;
  color: #fff;
  background-color: #3474d4;
}

.ec-product-sup .ec-product-body .ec-color a {
  width: 20px;
  height: 20px;
  border: none;
  border: 1px solid transparent;
  display: inline-block;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-sup .ec-product-body .ec-color a:hover {
  border: 1px solid #eeeeee;
}

.ec-product-sup .ec-product-body .ec-color span {
  width: 100%;
  height: 100%;
  border: 1px solid #fff !important;
  display: inline-block;
}

.ec-product-sup .ec-product-body .ec-color .red {
  background-color: red;
}

.ec-product-sup .ec-product-body .ec-color .blue {
  background-color: blue;
}

.ec-product-sup .ec-product-body .ec-color .pink {
  background-color: pink;
}

.ec-product-sup .ec-product-body .ec-color .green {
  background-color: green;
}

/* -----------/ END Product-sup -----------*/
/* ----------- START Product-sbtn -----------*/
.ec-product-sbtn {
  font-family: "Poppins";
  text-align: center;
}

.ec-product-sbtn:hover {
  -webkit-box-shadow: 0 0 20px -10px rgba(63, 63, 63, 0.3);
  box-shadow: 0 0 20px -10px rgba(63, 63, 63, 0.3);
}

.ec-product-sbtn:hover .ec-product-image .pic-1 {
  opacity: 0;
}

.ec-product-sbtn:hover .ec-product-image .pic-2 {
  opacity: 1;
}

.ec-product-sbtn:hover .ec-add-to-cart {
  text-decoration: none;
  color: #fff;
  background: #3474d4;
}

.ec-product-sbtn:hover .ec-add-to-cart i {
  display: inline-block;
  visibility: visible;
}

.ec-product-sbtn:hover .ec-product-like-icon {
  opacity: 1;
  left: 0;
}

.ec-product-sbtn:hover .ec-product-compare-icon {
  opacity: 1;
  right: 0;
}

.ec-product-sbtn .ec-product-image {
  position: relative;
}

.ec-product-sbtn .ec-product-image a.ec-image {
  display: block;
}

.ec-product-sbtn .ec-product-image img {
  width: 100%;
  height: auto;
}

.ec-product-sbtn .ec-product-image .pic-1 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-product-sbtn .ec-product-image .pic-2 {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-product-sbtn .ec-product-sale-label {
  color: #fff;
  background: #da5555;
  font-size: 0.857rem;
  letter-spacing: 1px;
  line-height: 14px;
  padding: 5px 11px;
  border-radius: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #81dca8;
  text-transform: uppercase;
  left: 11px;
  right: auto;
  font-weight: 600;
  line-height: 12px;
}

.ec-product-sbtn .ec-product-discount-label {
  color: #fff;
  background: #ff6285;
  font-size: 0.857rem;
  letter-spacing: 1px;
  line-height: 14px;
  padding: 5px 11px;
  border-radius: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ec-product-sbtn .ec-product-body {
  padding: 15px;
}

.ec-product-sbtn .ec-product-body .ec-size a {
  display: inline-block;
  border: 1px solid;
  border-color: #eeeeee;
  color: #3474d4;
  margin: 10px 0 16px;
  font-size: 0.928rem;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 25px;
  height: 25px;
  text-align: center;
}

.ec-product-sbtn .ec-product-body .ec-size a:hover {
  border: 1px solid #3474d4;
  text-decoration: none;
  color: #fff;
  background-color: #3474d4;
}

.ec-product-sbtn .ec-product-body .ec-color a {
  width: 20px;
  height: 20px;
  border: none;
  border: 1px solid transparent;
  text-align: center;
  display: inline-block;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-sbtn .ec-product-body .ec-color a:hover {
  border: 1px solid #eeeeee;
}

.ec-product-sbtn .ec-product-body .ec-color span {
  width: 100%;
  height: 100%;
  border: 1px solid #fff !important;
  display: inline-block;
}

.ec-product-sbtn .ec-product-body .ec-color .red {
  background-color: red;
}

.ec-product-sbtn .ec-product-body .ec-color .blue {
  background-color: blue;
}

.ec-product-sbtn .ec-product-body .ec-color .pink {
  background-color: pink;
}

.ec-product-sbtn .ec-product-body .ec-color .green {
  background-color: green;
}

.ec-product-sbtn .ec-rating {
  padding: 0;
  margin: 0 0 7px;
  list-style: none;
}

.ec-product-sbtn .ec-rating li {
  font-size: 0.857rem;
  display: inline-block;
}

.ec-product-sbtn .ec-title {
  font-size: 1.143rem;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.ec-product-sbtn .ec-title a {
  -webkit-transition: all 500ms;
  transition: all 500ms;
  font-family: "Poppins, sans-serif", sans-serif;
}

.ec-product-sbtn .ec-title a:hover {
  text-decoration: none;
  color: #3474d4;
}

.ec-product-sbtn .ec-price {
  color: #3474d4;
  font-size: 1.143rem;
  font-weight: 600;
  letter-spacing: 0.005em;
  margin-bottom: 10px;
}

.ec-product-sbtn .ec-price span {
  color: #a7a7a7;
  font-size: 1.143rem;
  font-weight: 400;
  text-decoration: line-through;
}

.ec-product-sbtn .ec-product-button-group {
  position: relative;
  z-index: 1;
}

.ec-product-sbtn .ec-add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  width: calc(100% - 95px);
  height: 40px;
  font-size: 0.857rem;
  font-weight: 700;
  color: #fff;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
  background-color: #3474d4;
}

.ec-product-sbtn .ec-add-to-cart:hover {
  background-color: #555;
  text-decoration: none;
}

.ec-product-sbtn .ec-add-to-cart i {
  margin-right: 8px;
  display: none;
  visibility: hidden;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}

.ec-product-sbtn .ec-product-like-icon {
  left: 50px;
  right: auto;
}

.ec-product-sbtn .ec-product-compare-icon {
  left: auto;
  right: 50px;
}

.ec-product-sbtn .ec-product-like-icon,
.ec-product-sbtn .ec-product-compare-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  line-height: 10px;
  width: 40px;
  height: 40px;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  z-index: -1;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}

.ec-product-sbtn .ec-product-like-icon:hover,
.ec-product-sbtn .ec-product-compare-icon:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

/* -----------/ END Product-sbtn -----------*/
/* ----------- START Product-sdwn -----------*/
.ec-product-sdwn {
  -webkit-box-shadow: 0px 15px 35px -5px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 15px 35px -5px rgba(0, 0, 0, 0.32);
  border-radius: 15px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  position: relative;
}

.ec-product-sdwn:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.ec-product-sdwn:hover .ec-product-image .pic-2 {
  opacity: 1;
}

.ec-product-sdwn:hover .ec-social li {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.ec-product-sdwn:hover .ec-product-body {
  margin-top: 0px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.ec-product-sdwn .ec-product-image {
  position: relative;
  overflow: hidden;
}

.ec-product-sdwn .ec-product-image a.ec-image {
  display: block;
}

.ec-product-sdwn .ec-product-image img {
  width: 100%;
  height: auto;
}

.ec-product-sdwn .ec-product-image .pic-1 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-product-sdwn .ec-product-sale-label {
  color: #fff;
  background: #3474d4;
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50px;
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: center;
}

.ec-product-sdwn .ec-social {
  padding: 0;
  margin: 0;
  top: 0;
  right: 10px;
  list-style: none;
  position: absolute;
}

.ec-product-sdwn .ec-social li {
  -webkit-transform: translateX(60px);
  transform: translateX(60px);
  -webkit-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;
}

.ec-product-sdwn .ec-social li:nth-child(2) {
  -webkit-transition: all 0.3s ease 0.4s;
  transition: all 0.3s ease 0.4s;
}

.ec-product-sdwn .ec-social li a {
  display: block;
  padding: 7px;
  margin: 10px 0;
  width: 35px;
  height: 35px;
  line-height: 10px;
  border-radius: 50%;
  color: rgba(68, 68, 68, 0.7);
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-sdwn .ec-social li a:hover {
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.ec-product-sdwn .ec-product-body {
  width: 100%;
  margin-top: -145px;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.ec-product-sdwn .ec-product-body .ec-slide {
  padding: 10px;
}

.ec-product-sdwn .ec-product-body .ec-rating {
  padding: 0;
  margin: 0 0 7px;
  list-style: none;
}

.ec-product-sdwn .ec-product-body .ec-rating li {
  font-size: 0.857rem;
  display: inline-block;
}

.ec-product-sdwn .ec-product-body .ec-title {
  font-size: 1.143rem;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.ec-product-sdwn .ec-product-body .ec-title a {
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.ec-product-sdwn .ec-product-body .ec-title a:hover {
  text-decoration: none;
  color: #3474d4;
}

.ec-product-sdwn .ec-product-body .ec-price {
  color: #3474d4;
  font-size: 1.143rem;
  font-weight: 600;
  letter-spacing: 0.005em;
  margin-bottom: 10px;
}

.ec-product-sdwn .ec-product-body .ec-price span {
  color: #a7a7a7;
  font-size: 1.143rem;
  font-weight: 400;
  text-decoration: line-through;
}

.ec-product-sdwn .ec-product-body .ec-size a {
  display: inline-block;
  margin: 10px 0;
  width: 20px;
  height: 20px;
  font-size: 0.857rem;
  line-height: 20px;
  text-align: center;
  border: 1px solid;
  border-color: #eeeeee;
  color: #777;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.ec-product-sdwn .ec-product-body .ec-size a:hover {
  border: 1px solid #3474d4;
  text-decoration: none;
  color: #fff;
  background-color: #3474d4;
}

.ec-product-sdwn .ec-product-body .ec-color a {
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  text-align: center;
  display: inline-block;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ec-product-sdwn .ec-product-body .ec-color a:hover {
  border: 1px solid #eeeeee;
}

.ec-product-sdwn .ec-product-body .ec-color span {
  width: 100%;
  height: 100%;
  border: 1px solid #fff !important;
  display: inline-block;
  border-radius: 30px;
}

.ec-product-sdwn .ec-product-body .ec-color .red {
  background-color: #ff8383;
}

.ec-product-sdwn .ec-product-body .ec-color .blue {
  background-color: #8383ff;
}

.ec-product-sdwn .ec-product-body .ec-color .pink {
  background-color: #ffa3b3;
}

.ec-product-sdwn .ec-product-body .ec-color .green {
  background-color: #76aa76;
}

.ec-product-sdwn .ec-product-body .ec-link-btn .ec-add-to-cart {
  display: block;
  padding: 5px;
  width: 100%;
  font-size: 0.857rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
  color: #fff;
  background-color: #3474d4;
}

.ec-product-sdwn .ec-product-body .ec-link-btn .ec-add-to-cart:hover {
  background-color: #555;
  text-decoration: none;
}

/* -----------/ END Product-sdwn -----------*/
/* ----------- START Common CSS -----------*/
.ec-product-image .pic-2 {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ec-title a {
  color: #777;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.ec-title a:hover {
  color: #3474d4 !important;
  text-decoration: none;
}

.ec-rating li {
  color: #999;
}

.ec-rating .fill {
  color: #eec317;
}

/* -----------/ END Common CSS -----------*/
@media screen and (max-width: 1200px) {
  .ec-product-ds .ec-title a {
    font-size: 14px;
  }

  .ec-product-ds .ec-price {
    font-size: 14px;
  }

  .ec-product-csc .ec-product-body .ec-link-icon a {
    margin: 9px 2px;
  }

  .ec-product-csc .ec-product-body .ec-link-btn .ec-add-to-cart {
    font-size: 0.857rem;
    line-height: 33px;
  }

  .ec-product-fw .ec-product-body .ec-link-icon a {
    margin: 9px 2px;
  }

  .ec-product-fw .ec-product-body .ec-link-btn .ec-add-to-cart {
    font-size: 0.857rem;
    line-height: 33px;
  }

  .ec-product-tp .ec-product-body .ec-link-icon a {
    margin: 9px 2px;
  }

  .ec-product-tp .ec-product-body .ec-link-btn .ec-add-to-cart {
    font-size: 0.857rem;
    line-height: 33px;
  }

  .ec-product-lsc .ec-product-body .ec-link-icon a {
    margin: 0 2px;
  }

  .ec-product-lsc .ec-product-body .ec-link-btn .ec-add-to-cart {
    font-size: 0.857rem;
    line-height: 35px;
    height: 35px;
    margin: 0 2px 0 0;
  }
}

@media screen and (max-width: 991px) {
  .ec-product-ds {
    margin: 20px 0;
    overflow: inherit;
  }

  .ec-product-ds .links {
    opacity: unset;
    bottom: 0;
    top: auto !important;
  }

  .ec-product-ds .links li a {
    width: 35px;
    height: 35px;
    font-size: 1.143rem;
  }

  .ec-product-ds .ec-price {
    margin-bottom: 15px;
    opacity: inherit;
  }

  .ec-product-ds:hover .ec-price {
    opacity: inherit;
  }

  .ec-product-ds .ec-add-to-cart {
    opacity: unset;
    bottom: auto !important;
    line-height: 35px;
    font-size: 0.928rem;
  }

  .ec-product-csc {
    margin: 20px 0;
  }

  .ec-product-csc .ec-product-body .ec-title a {
    font-size: 14px;
  }

  .ec-product-csc .ec-product-body .ec-link-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .ec-product-fw {
    margin: 20px 0;
  }

  .ec-product-fw .ec-product-body .ec-title a {
    font-size: 14px;
  }

  .ec-product-fw .ec-product-image .ec-link-icon {
    opacity: 1;
  }

  .ec-product-tp {
    margin: 20px 0;
  }

  .ec-product-tp .ec-product-body .ec-title a {
    font-size: 14px;
  }

  .ec-product-tp .ec-product-image .ec-link-icon {
    opacity: 1;
  }

  .ec-product-sdwn {
    margin: 15px 0;
  }

  .ec-product-lsc {
    margin: 15px 0;
  }

  .ec-product-lsc .ec-product-body .ec-title a {
    font-size: 14px;
  }

  .ec-product-lsc .ec-product-image .ec-link-icon {
    opacity: 1;
  }

  .ec-product-swd {
    margin: 0 0 30px;
  }

  .ec-product-cbb {
    margin: 0 0 30px;
  }

  .ec-product-sup {
    margin-bottom: 40px;
  }

  .ec-product-sbtn {
    margin-bottom: 30px;
  }
}

/*-------------------------------------------------
  Element Title Page CSS
---------------------------------------------------*/
/*-----  Common CSS for all section  -----*/
.sec-1 h2,
.sec-1 .h2,
.sec-2 h2,
.sec-2 .h2,
.sec-3 h2,
.sec-3 .h2,
.sec-4 h2,
.sec-4 .h2,
.sec-5 h2,
.sec-5 .h2,
.sec-6 h2,
.sec-6 .h2,
.sec-7 h2,
.sec-7 .h2,
.sec-8 h2,
.sec-8 .h2,
.sec-9 h2,
.sec-9 .h2,
.sec-10 h2,
.sec-10 .h2,
.sec-11 h2,
.sec-11 .h2,
.sec-12 h2,
.sec-12 .h2 {
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 20px;
  position: relative;
}

.sec-1 p,
.sec-2 p,
.sec-3 p,
.sec-4 p,
.sec-5 p,
.sec-6 p,
.sec-7 p,
.sec-8 p,
.sec-9 p,
.sec-10 p,
.sec-11 p,
.sec-12 p {
  margin: 0;
  color: #777;
  font-size: 1.143rem;
  font-family: "Poppins";
}

/*-----  Title Section-1 CSS  -----*/
.sec-1 h2,
.sec-1 .h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sec-1 h2 span,
.sec-1 .h2 span {
  position: relative;
}

.sec-1 h2 span:after,
.sec-1 .h2 span:after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  top: -37px;
  right: -12px;
  background-color: #3474d4;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
}

.sec-1 h2 span:before,
.sec-1 .h2 span:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 9px;
  right: -25px;
  background-color: #555;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
}

/*-----  Title Section-2 CSS  -----*/
.sec-2 h2,
.sec-2 .h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sec-2 h2 span,
.sec-2 .h2 span {
  position: relative;
}

.sec-2 h2 span:after,
.sec-2 .h2 span:after {
  background: transparent -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(#3474d4),
      color-stop(#555),
      to(transparent)
    ) repeat scroll 0 0;
  background: transparent
    linear-gradient(to right, transparent, #3474d4, #555, transparent) repeat
    scroll 0 0;
  content: "";
  position: absolute;
  height: 120%;
  width: 170%;
  top: -15%;
  left: -33%;
  opacity: 0.25;
  z-index: -1;
}

/*-----  Title Section-2 CSS  -----*/
.sec-3 h2,
.sec-3 .h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sec-3 h2 span,
.sec-3 .h2 span {
  position: relative;
}

.sec-3 h2 span:after,
.sec-3 .h2 span:after {
  content: "";
  width: 50px;
  height: 5px;
  position: absolute;
  top: 13px;
  left: -70px;
  background-color: #3474d4;
  z-index: -1;
  opacity: 0.3;
}

.sec-3 h2 span:before,
.sec-3 .h2 span:before {
  content: "";
  width: 50px;
  height: 5px;
  position: absolute;
  top: 13px;
  right: -70px;
  background-color: #3474d4;
  z-index: -1;
  opacity: 0.3;
}

/*-----  Title Section-3 CSS  -----*/
.sec-4 h2,
.sec-4 .h2 {
  margin-bottom: 25px;
  padding-bottom: 5px;
  border-bottom: 5px solid #555;
}

.sec-4 h2 span,
.sec-4 .h2 span {
  position: relative;
}

.sec-4 h2 span:after,
.sec-4 .h2 span:after {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: -10px;
  left: 0;
  background-color: #3474d4;
  z-index: 0;
}

/*-----  Title Section-4 CSS  -----*/
.sec-5 h2,
.sec-5 .h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*-------------------------------------------------
  General CSS
---------------------------------------------------*/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-black {
  margin: 0 11px 0 0;
  padding: 10px 20px;
  color: #fff !important;
  font-size: 15px;
  background-color: rgba(11, 13, 19, 0.8);
  border-radius: 0;
  border: 1px solid #222;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
}

.btn-black:hover {
  cursor: pointer;
  color: #111 !important;
  background-color: #fff;
  outline: none;
  text-decoration: none;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
}

.btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input::-moz-selection {
  color: #fff;
  background: #3474d4;
}

/* input::selection {
    color: #fff;
    background: #3474d4;
} */

input:-webkit-autofill {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

select:-webkit-autofill {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

select:-webkit-autofill:hover {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

select:-webkit-autofill:focus {
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.auto-height {
  margin: 0;
  min-height: 100vh;
}

.main-container {
  padding: 0;
}

.main-block {
  min-height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
  text-align: center;
  background: url(assets/images/offer-image/1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.5);
}

#canvas {
  width: 100%;
  height: 100vh;
  margin: auto;
  position: fixed;
  left: 0;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 2;
  text-align: center;
}

.main-info {
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  z-index: 3;
}

.validation {
  display: none;
  position: absolute;
  top: 45px;
}

.about_sec {
  padding: 100px 0;
}

.portfolio_sec {
  padding: 100px 0;
}

.contact_sec {
  padding: 100px 0;
}

.global_title {
  margin: -7px 0 20px 0;
  font-size: 42px;
  text-align: center;
  font-weight: 300;
}

.global_title span {
  font-weight: 600;
}

.hero-section {
  position: relative;
  z-index: 1;
}

.hero-section-position {
  position: absolute;
}

.logo {
  margin: 0 auto 30px auto;
  text-align: center;
}

.logo img {
  width: 150px;
}

.hero-detail {
  margin: 0;
  padding: 30px;
  background-color: rgba(11, 13, 19, 0.8);
}

.title {
  margin-bottom: 15px;
  color: #fff;
  font-size: 50px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
}

.title span {
  color: #3474d4;
  font-weight: 800;
}

.sub-title {
  color: #555;
  font-weight: 600;
  margin-bottom: 5px;
}

.description {
  margin: auto auto 5px auto;
  color: #777;
  margin-bottom: 5px;
}

.hero-container {
  position: relative;
  z-index: 1;
  max-width: 600px;
}

.hero-counter {
  max-width: 600px;
}

.counter {
  margin: 10px -8px -10px -8px;
  display: block;
}

#timer {
  color: #111;
  text-align: center;
  text-transform: uppercase;
}

#timer .date-box {
  margin: 10px 8px;
  padding: 10px;
  width: 96px;
  height: 96px;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  font-size: 32px;
  font-weight: 600;
  z-index: 2;
  color: #fff;
}

#timer .text {
  font-size: 0.857rem;
  font-weight: 300;
  color: #fff;
}

.hero-counter-desc {
  color: #f1f1f1;
  font-size: 14px;
  font-weight: 300;
}

.subscribe_form {
  padding: 20px 20px 0 20px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.subscribe_form:focus {
  outline: none;
  color: #fff;
}

.subscribe_form .form-control {
  min-height: 59px;
  padding: 0;
}

.subscribe_form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #888;
  background-color: transparent;
  color: #fff;
}

.subscribe_form input {
  height: 44px;
  color: #fff;
  background-color: transparent;
  border-radius: 0;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #d9d9d9;
}

.subscribe_form .button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  box-shadow: none;
}

.subscribe_form .button:hover {
  color: #111;
  background-color: #fff;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
}

.subscribe_form .button .btn-icon {
  display: none;
}

.subscribe_form button {
  margin-top: 15px;
  padding: 0 20px;
  border: none;
  height: 44px;
  color: #fff;
  font-size: 15px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  text-transform: uppercase;
  position: relative;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 15px auto auto auto;
}

input[type="email"]::-webkit-input-placeholder {
  color: #ddd;
}

input[type="email"]::-moz-placeholder {
  color: #ddd;
}

input[type="email"]:-ms-input-placeholder {
  color: #ddd;
}

input[type="email"]::-ms-input-placeholder {
  color: #ddd;
}

input[type="email"]::placeholder {
  color: #ddd;
}

input[type="email"]::-ms-input-placeholder {
  color: #ddd;
}

input[type="email"]:focus:-ms-input-placeholder {
  color: #ddd;
}

input[type="email"]:focus::-moz-placeholder {
  color: #ddd;
}

input[type="email"]:focus:-moz-placeholder {
  color: #ddd;
}

input[type="email"]:focus::-webkit-input-placeholder {
  color: #ddd;
}

#particles-js {
  width: 100%;
  height: 100vh;
  margin: auto;
  position: fixed;
  left: 0;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 2;
  text-align: center;
}

/** Comingsoon 2 page **/
.ec-coming-soon-02 {
  background: url("assets/images/common/1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 0px);
  position: relative;
  z-index: 0;
  display: -ms-grid;
  display: grid;
  padding: 10px 0;
}

.ec-coming-soon-02 .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ec-coming-soon-02:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.ec-coming-soon-02 .coming-back {
  margin: 80px auto 60px;
  max-width: 600px;
  text-align: center;
  padding: 1em 0;
}

.ec-coming-soon-02 h1,
.ec-coming-soon-02 .h1 {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  color: #ff9800;
  line-height: 50px;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.ec-coming-soon-02 .countdown {
  margin: auto;
  display: table;
  font-size: 18px;
  font-weight: 500;
}

.ec-coming-soon-02 .number {
  font-family: Poppins;
}

.ec-coming-soon-02 ::-webkit-input-placeholder {
  font-family: Poppins;
}

.ec-coming-soon-02 .countdown > div {
  float: left;
  min-width: 80px;
  margin: 20px 0;
  font-size: 30px;
  line-height: 70px;
  font-weight: 700;
  color: #ffffff;
  padding: 0;
}

.ec-coming-soon-02 .countdown > div:last-child {
  border-right: none;
}

.ec-coming-soon-02 p {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.ec-coming-soon-02 p.t-text {
  line-height: 26px;
  opacity: 0.9;
  font-family: Poppins;
}

.ec-coming-soon-02 .countdown span {
  position: relative;
  display: block;
  font-size: 0.857rem;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #ffffff;
}

.ec-coming-soon-02 input[type="text"] {
  padding: 1em 1.5em;
  border: solid 1px #ffffff;
  width: 88%;
  margin-right: 10px;
  font-size: 17px;
  color: #999999;
  outline: none;
  display: inline-block;
  background: #ffffff;
  border-radius: 30px;
}

.ec-coming-soon-02 form {
  max-width: 500px;
  margin: 0 50px;
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ec-coming-soon-02 button {
  color: #ffffff;
  padding: 7px 15px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  background: #ff9800;
  border: 1px solid #ff9800;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  border-radius: 30px;
}

.ec-coming-soon-02 button:hover {
  background: #e98c03;
}

@media only screen and (max-width: 767px) {
  .ec-coming-soon-02 h1,
  .ec-coming-soon-02 .h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .ec-coming-soon-02 input[type="text"] {
    font-size: 1.143rem;
  }
}

@media only screen and (max-width: 575px) {
  .ec-coming-soon-02 h1,
  .ec-coming-soon-02 .h1 {
    font-size: 35px;
  }

  .ec-coming-soon-02 input[type="text"] {
    font-size: 14px;
  }

  .ec-coming-soon-02 .countdown > div {
    line-height: 50px;
  }

  .ec-coming-soon-02 .number {
    font-size: 25px;
  }

  .ec-coming-soon-02 .countdown > div {
    width: 50%;
  }

  .ec-coming-soon-02 form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ec-coming-soon-02 button {
    margin-top: 15px;
  }

  .ec-coming-soon-02 input[type="text"] {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .ec-coming-soon-02 h1,
  .ec-coming-soon-02 .h1 {
    font-size: 30px;
  }
}
 
.labels h2,
.labels .h2 {
  margin: 0;
}

.labels h3,
.labels .h3 {
  margin: 0;
  font-size: 35px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.labels h4,
.labels .h4 {
  margin: 0;
}

.labels .coupon_box {
  background: #ff416c;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff4b2b),
    to(#ff416c)
  );
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  width: 200px;
  border-radius: 7px;
  padding: 1rem;
  text-align: center;
  color: #fff;
  font-family: "Tahoma", sans-serif;
  position: relative;
  margin: 5rem auto;
}

.labels .coupon_box::before {
  content: "";
  position: absolute;
  top: 65%;
  background: #fff;
  width: 20px;
  height: 30px;
  z-index: 1;
}

.labels .coupon_box::after {
  content: "";
  position: absolute;
  top: 65%;
  background: #fff;
  width: 20px;
  height: 30px;
  z-index: 1;
}

.labels .title {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 20px;
}

.labels .how_much {
  font-size: 55px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.labels .ec-offer-coupon {
  padding: 30px;
  border: 2px solid #f1f1f1;
  text-align: center;
  background: #ffffff;
}

.labels .ec-offer-coupon .ec-cpn-brand .ec-brand-img {
  display: block;
  margin: 0 auto 30px auto;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -9000px;
}

.labels .ec-offer-coupon .ec-cpn-title {
  margin: 0 0 1rem 0;
}

.labels .ec-offer-coupon .ec-cpn-title .coupon-title {
  display: block;
  font-size: 1.143rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 24px;
  color: #777;
}

.labels .ec-offer-coupon .ec-cpn-desc {
  margin-bottom: 0;
}

.labels .ec-offer-coupon .ec-cpn-desc p {
  color: #777;
  font-size: 14px;
  line-height: 24px;
}

.labels .ec-offer-coupon .ec-cpn-desc .coupon-text {
  line-height: 24px;
}

.labels .ec-offer-coupon .ec-cpn-code {
  margin-bottom: 0;
}

.labels .ec-offer-coupon .ec-cpn-code .coupon-code {
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 0px 10px;
  border: 2px dotted currentColor;
  background: #f5f5f5;
  font-weight: bold;
  font-style: normal;
  font-size: 1.4em;
  font-family: "Courier New", Courier, monospace;
  line-height: 1.5;
}

.labels .ec-offer-coupon .ec-cpn-code a {
  margin-top: 15px;
  height: 40px;
  line-height: 40px;
}

.labels .ec-flate-offer {
  background: #2b2b2b;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.labels .ec-flate-offer .ec-flate-offer-info {
  padding: 50px;
  width: 50%;
  display: -ms-grid;
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
}

.labels .ec-flate-offer .ec-flate-offer-info .offer-title {
  color: #fff;
  margin-bottom: 1rem;
}

.labels .ec-flate-offer .ec-flate-offer-info .offer-desc {
  color: #fff;
  margin-bottom: 3rem;
}

.labels .ec-flate-offer .ec-flate-offer-info .offer-btn .btn-shop-now {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  border: 1px dashed #fff;
  padding: 8px 15px;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
}

.labels .ec-flate-offer .ec-flate-offer-info .offer-btn .btn-shop-now:hover {
  border: 1px solid #fff;
}

.labels .ec-flate-offer .ec-flate-offer-image {
  width: 50%;
}

.labels .ec-flate-offer .ec-flate-offer-image .flate-banner {
  width: 100%;
}

.labels .ec-in-offer {
  height: 500px;
  background-size: cover;
  opacity: 1;
}

.labels .ec-in-offer .ec-in-offer-info {
  padding: 30px;
  display: -ms-grid;
  display: grid;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
  background: #000;
  height: 100%;
  width: 100%;
  opacity: 0.8;
}

.labels .ec-in-offer .ec-in-offer-info .offer-upto {
  color: #fff;
  margin-bottom: 6rem;
  font-weight: 500;
  font-size: 20px;
}

.labels .ec-in-offer .ec-in-offer-info .offer-upto span {
  font-size: 35px;
  font-weight: 700;
}

.labels .ec-in-offer .ec-in-offer-info .offer-title {
  color: #fff;
  margin-bottom: 1rem;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
}

.labels .ec-in-offer .ec-in-offer-info .offer-desc {
  color: #fff;
  margin-bottom: 3rem;
}

.labels .ec-in-offer .ec-in-offer-info .offer-btn .btn-shop-now {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  border: 1px dashed #fff;
  padding: 8px 15px;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
}

.labels .ec-in-offer .ec-in-offer-info .offer-btn .btn-shop-now:hover {
  border: 1px solid #fff;
}

.labels .ec-line-offer {
  background-size: cover;
  opacity: 1;
}

.labels .ec-line-offer .ec-line-offer-info {
  width: 100%;
  height: 65vh;
  padding: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.labels .ec-line-offer .ec-line-offer-info h6,
.labels .ec-line-offer .ec-line-offer-info .h6 {
  margin-bottom: 5px;
  font-weight: 800;
  color: #777;
}

.labels .ec-line-offer .ec-line-offer-info .offer-upto {
  margin-bottom: 15px;
  color: #111;
  font-weight: 600;
  font-size: 20px;
}

.labels .ec-line-offer .ec-line-offer-info .offer-upto span {
  font-size: 35px;
  font-weight: 700;
}

.labels .ec-line-offer .ec-line-offer-info .offer-desc {
  margin-bottom: 30px;
  color: #777;
}

.labels .ec-line-offer .ec-line-offer-info .offer-btn .btn-shop-now {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #777;
  border: 1px dashed #777;
  background-color: #f9f9f9;
  padding: 8px 15px;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.labels .ec-line-offer .ec-line-offer-info .offer-btn .btn-shop-now:hover {
  border: 1px solid #777;
}

/*-------------------------------------------------
  General CSS
---------------------------------------------------*/
.ec-404-error-page-02 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 0;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
}

.ec-404-error-page-02 .main-title {
  margin-top: 20px;
  font-size: 50px;
  font-weight: 700;
}

.ec-404-error-page-02 .sub-title {
  font-size: 18px;
}

.ec-404-error-page-02 .desc-content {
  font-size: 15px;
}

.ec-404-error-page-02 .link-404 {
  color: #ffffff !important;
  padding: 10px 20px;
  background: #3474d4;
  margin: 20px 0;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .ec-404-error-page-02 .main-title {
    font-size: 30px;
  }
}

/*-------------------------------------------------
  General CSS
---------------------------------------------------*/
.ec-under-maintenance-02 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  color: #ffffff;
  font-family: sans-serif;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ec-under-maintenance-02 div {
  width: 100%;
  text-align: center;
  vertical-align: middle;
}

.ec-under-maintenance-02 img {
  max-width: 100%;
}

.ec-under-maintenance-02 .wrap {
  padding: 0 15px;
  text-align: center;
}

.ec-under-maintenance-02 .wrap p {
  color: #777;
}

.ec-under-maintenance-02 .wrap h1,
.ec-under-maintenance-02 .wrap .h1 {
  font-weight: 700;
  margin: 30px 0 20px;
  font-size: 45px;
  color: #30ad94;
}

.ec-under-maintenance-02 .wrap h2,
.ec-under-maintenance-02 .wrap .h2 {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 1.6;
  color: #2e4654;
  font-weight: 600;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 575px) {
  .ec-under-maintenance-02 img {
    max-width: 50%;
  }

  .ec-under-maintenance-02 .wrap h1,
  .ec-under-maintenance-02 .wrap .h1 {
    font-size: 30px;
  }

  .ec-under-maintenance-02 .wrap h2,
  .ec-under-maintenance-02 .wrap .h2 {
    font-size: 15px;
  }
}

/*-------------------------------------------------
   Wishlist Page CSS
---------------------------------------------------*/
.wishlist-2 .ec-table tbody tr td .avl {
  color: #8bd38b;
}

.wishlist-2 .ec-table tbody tr td .out {
  color: #e9abab;
}

.wishlist-2 .ec-table tbody tr td .dis {
  color: #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wishlist-2 .ec-table tbody tr td .btn {
  width: 30px;
  height: 30px;
  margin: 0 3px;
  padding: 0;
}

.wishlist-2 .ec-table tbody tr td .btn .svg_img.pro_svg {
  width: 16px;
  fill: #fff;
}

.wishlist-2 .ec-table tbody tr td .ec-remove-wish {
  font-size: 20px;
  background-color: #e9abab;
}

.wishlist-2 .ec-table tbody tr td .ec-remove-wish:hover {
  background-color: #555;
}

.modal-content {
  border-radius: 15px !important;
}

/*Styles for the mobile screen size for Supplier Login Page,
 Customer Login Page*/

.mobile-text {
  display: none;
}

.full-text-layout {
  margin-right: 20%;
  margin-left: 20%;
}
@media screen and (max-width: 1000px) {
  .mobile-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .mobile-width {
    width: 100% !important;
  }
  .mobile-height {
    min-height: 100%;
  }

  .mobile-hide {
    display: none;
  }
  .mobile-text {
    display: block;
    text-align: justify;
    line-height: normal;
    padding-right: 5px;
    padding-left: 5px;
  }
  .full-text-layout {
    margin-right: 5px;
    margin-left: 5px;
  }
}

/*Registration page styles*/
.reg-text {
  line-height: normal;
}
.reg-padding-top {
  padding-top: 0 !important;
}
.reg-password-width {
  width: 50%;
}

.form_button {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
.form_button button {
  width: 100%;
  height: 50px;
  background: #fd9801;
  border-radius: 3px;
  color: #f2fffd;
  font-weight: 600;
  font-size: 1.143rem;
}

.form_button button:active {
  box-shadow: 5px 5px 5px grey;
}

.reg-vew-height {
  min-height: 100vh;
  height: 100vh;
}
