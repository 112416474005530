.progressBarContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progressBarContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
}
.progressBar {
  width: 0;
  height: 3px;
  background: blue;
  padding: 0;
  position: absolute;
  left: 0;
  transition: width 0.5s;
}
.progressItems {
  width: 100%;
  height: 3px;
  background: #e9e9e9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

/*Test bar styles*/
.item {
  width: 190px;
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  font-size: 12px;
}
.item span {
  width: 20px;
  height: 20px;
  border: 1px solid #e9e9e9;
  background: #e9e9e9;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  padding: 0;
  margin: 0;
}
.item p {
  position: absolute;
  top: -35px;
  text-align: center;
  padding: 0;
}
.item:first-child {
  justify-content: flex-start;
}
.item:last-child {
  justify-content: flex-end;
}
.item:first-child p {
  left: -40px;
}
.item:last-child p {
  right: -26px;
}
.item:first-child span {
  left: -3px;
}
.item:last-child span {
  right: -3px;
}
.item:nth-child(2) {
  padding-right: 30px;
}
.item:nth-child(3) {
  padding-left: 30px;
}

.testComplete {
  border: 2px solid #51d877 !important;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testComplete:after {
  /* content: "✔" !important; */
  content: url(assets/img/icons/check-green.svg) !important;
  color: #51d877 !important;
}
.testActive {
  background-color: blue !important;
}
