.mobile-header {
    height: 100px;
    background: #3051CC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 100;

    & .mobile-logo {
        margin-right: 30px;
        img {
            height: 45px;
        }
    }

    & .hamburger {
        position: relative;
        width: 3rem;
        cursor: pointer; 
        appearance: none;
        background: none;
        outline: none;
        border: none;
        margin: 0 1rem;
     }
     
     & .hamburger .bar {
         width: 100%;
         height: 0.4rem;
         background-color: white;
         margin: 0.4rem 0;
         -webkit-transition: all 0.3s ease-in-out;
         transition: all 0.3s ease-in-out;
       }

    & .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    & .hamburger.active .bar:nth-child(1) {
       transform: translateY(15px) rotate(45deg);
   }
   & .hamburger.active .bar:nth-child(3) {
       transform: translateY(-11px) rotate(-45deg);
   }

   & .main-nav_hamburger {
      position: absolute;
      left: -101%;
      top: 97px;
      width: 100%;
      background-color: #3051CC;
      transition: 1s;
      z-index: 100;
      &.set-open {
        left: 0;
    }
   }

   & .main-nav_links_ul_hamburger{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    gap: 1rem;
    padding-bottom: 20px;

     & .nav-link-hamburger {
        display: inline-block;
        text-decoration: none;
        color: white;
        font-weight: 600;
        font-size: 1.5rem;
        transition: all 0.3s;
        margin: 1rem 0;
    }
    }
}

  

