.stared {
  color: orange !important;
}
.unstarred {
  color: #777;
}
.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
}

.notification-window {
  position: absolute;
  width: 580px;
  right: 1px;
  top: 60px;
  background: #ffffff;
  opacity: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 2px;
  border-radius: 0px 0px 10px 10px;
}

.bell-notification-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  border-bottom: 1px solid #dfe0eb;
}

.bell-notification-header h6 {
  font-size: 1.143rem;
  font-weight: 600;
  color: #393e46;
  margin-left: 10px;
  margin-bottom: 10px;
}

.bell-notification-header div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 9px;
}
.bell-notification-header div span {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.857rem;
}
.bell-notification-header div span:hover {
  cursor: pointer;
}

.notification-content {
  display: grid;
  grid-template-columns: 50px 1fr 60px;
  margin-bottom: 10px;
  padding: 15px 10px;
  border-bottom: 1px solid #dfe0eb;
}
.notification-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
}
.notification-info h6 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
.notification-info p {
  margin-bottom: 0px;
  font-size: 0.857rem;
}

.notification-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notification-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
