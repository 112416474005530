.mobile-header-main {
  display: none;
}

@media screen and (max-width: 1000px) {
  .website-header-main {
    display: none;
  }
  .mobile-header-main {
    display: block;
  }
}
