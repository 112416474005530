.login-image {
  position: absolute;
  right: 0;
  bottom: 0px;
  z-index: -1;
}

@media screen and (max-width: 945px) {
  .login-image {
    display: none;
  }

  .mobile-layout {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 3rem;
  }
  .mobile-padding {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

