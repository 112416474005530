// READ ME: You can add nested css here, to make sure not overriding other people's work.
// see example: https://blog.logrocket.com/native-css-nesting/
body {
  overflow-x: hidden;
}
.orange-color {
  background: #fd9801;
  color: white;
}

.orange-color:hover {
  color: #ffffff;
  background-color: #3051cc;
  border-color: #3051cc;
}

.orange-color-mini {
  height: 40px;
  background: #fd9801;
  color: white;
}

.orange-color-mini:hover {
  height: 40px;
  color: #ffffff;
  background-color: #3051cc;
  border-color: #3051cc;
}

.orange-text {
  color: #fd9801;
  font-weight: 700;
  // font-size: 0.857rem;
}
.button-size {
  width: 170px;
  height: 35px;
}
.grey-button {
  background: #c2cfe0 !important;
  border-radius: 5px;
  color: #ffffff !important;
}

.white-button-text-gray {
  background: #ffffff !important;
  border: 0.5px solid rgba(179, 181, 178, 0.23) !important;
  border-radius: 5px;
  color: #353535 !important;
}

.text-gray {
  color: #d3d3d3;
}
.border-black {
  border: 1px solid #000 !important;
}
/*add-file-button class is used in LeasingDetail component*/
.add-file-button {
  background: #e5e5e5;
  border: 0.7px solid #787885;
  border-radius: 5px;
  color: black;
  font-size: 0.857rem;
  width: 90px;
  padding: 1px;
}

.customCard {
  border: 0.5px #787885 solid;
  border-radius: 4.3px;
  width: 621.63px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  background-color: #ffffff;
}
@media only screen and (max-width: 1200px) {
  .customCard {
    display: block;
    width: 100%;
  }
}
.list {
  text-align: left;
  padding: 5px;
}

.list li {
  margin: 6px;
  margin-left: 20px;
  list-style-type: disc;
  font-family: inherit;
  font-size: 11px;
  font-weight: 400;
}

.machineImage img {
  padding: 2px;
  height: 95px;
  width: 95px;
  border-radius: 50%;
}
.machineImage {
  display: block;
  width: 95px;
  height: 85px;
}
.customHeading {
  font-family: inherit;
  font-weight: 600;
  font-size: 20px;
  margin-top: 18px;
  margin-left: 22px;
}

.machineName h2 {
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  margin-left: 13px;
}
.machineName p {
  font-family: inherit;
  font-size: 11px;
  font-weight: 500;
  color: #90a0b7;
  margin-left: 13px;
}

.machineBlock {
  display: flex;
  flex-direction: column;
}
.machineBadge {
  display: flex;
  flex-direction: row;
}

.detailList {
  width: 300px;
}

.detailList ul li button {
  width: 296px;
  height: 34px;
  background: #ffffff;
  border-radius: 4.5px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 16px;
  font-family: inherit;
  font-size: 0.857rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkMark {
  display: block;
  height: 14px;
  width: 14px;
  margin-right: 10px;
  margin-top: -5px;
  padding: 0;
}
.detailList ul li .active {
  background: #fd9801;
  color: #ffffff;
}

.visibleWebsite {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.visibleWebsite p {
  font-family: inherit;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 6px;
}

.visibleWebsite div input {
  margin: 7px;
  color: #353535;
  outline: 1px solid #353535;
}
.visibleWebsite div label {
  font-family: inherit;
  font-size: 11.22px;
  font-weight: 400;
  color: #000000;
}

.machine-row {
  .position-relative {
    padding-bottom: 10px;
  }
}

.incrementbutton {
  // background: red;
  font-weight: 700;
  border: 1px solid;
  border-radius: 50%;
  width: 26px;
  // margin: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.test {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 10px;
  align-items: center;
}

.rounded-5 {
  border-radius: 5px !important;
}

.form-sheet {
  // margin-top: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.DraftEditor-editorContainer {
  min-height: 150px;
}

.nav-link.rounded-5 {
  background-color: white !important;
  margin-bottom: 10px !important;
}
.image-upload-large {
  width: 100px;
  height: 80px;
}

.modal-footer {
  // display: flex;
  // justify-content: center;
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-content {
  border-radius: 10px !important;
}

.modal-dialog .modal-header {
  border-bottom: unset;
}

.modal-footer {
  border-top: unset;
}

.image-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.modal-content-title {
  height: 45px;
  text-align: center;
  size: 24px;
  font-weight: 600;
}

.modal-content-text {
  text-align: center;
}

.reviewCard {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  width: 70%;
  background: #ffffff;
  box-shadow: 3px 5px 14.26px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 3px;
}

/*bell-dot is used for styling a dot on the bell icon inside DashboardHeader.jsx*/
.bell-dot {
  width: 20px;
  height: 19px;
  background: rgb(255, 153, 0);
  right: 1px;
  top: 3px;
  border: 0.5px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    font-size: 0.7rem;
    color: white;
    font-weight: 600;
    margin-top: 1px;
  }
}

// override react-calendar

// #date-assign > .react-datepicker {
//   width: 66%;
// }
.react-datepicker {
  font-family: "Poppins";
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: unset !important;
  border-radius: 0.35rem;
  display: inline-block;
  position: relative;
  padding: 30px;
  top: 20px;
  box-shadow: 3px 5px 14.26px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: unset !important;
  // border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__navigation-icon::before {
  border-color: unset !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 35px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  border-bottom: 1px solid #aeaeae;
  padding-bottom: 15px;
}

.react-datepicker__day-names {
  font-weight: 400;
  // padding-left: 15px;
  // padding-right: 15px;
  // padding: 15px;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  font-weight: 400;
  // padding-left: 15px;
  // padding-right: 15px;
  // padding: 15px;
}

.react-datepicker__navigation-icon {
  top: 10px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.59rem !important;
  color: #000000;
  font-size: 14px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem !important;
  background-color: #3051cc !important;
  color: #fff !important;
  font-weight: 700;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border-radius: 0.3rem !important;
  background-color: #3051cc !important;
  color: #fff !important;
  font-weight: 700;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  border-radius: 0.3rem !important;
  background-color: #3051cc !important;
  color: #fff !important;
  font-weight: 500;
}

.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem !important;
  background-color: #3051cc !important;
  color: #fff !important;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: rgba(48, 81, 204, 0.6);
}

// time picker
.card-time {
  padding: 20px;
  padding-right: 40px;
  padding-left: 40px;
  overflow-y: scroll;
  height: 350px;
  // width: 300px;
}
.time-button {
  margin-bottom: 10px;
}

.action-needed::after {
}
.border-list {
  box-shadow: 3px 5px 14.26px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.border-list:hover {
  border: 2px #fd9801 solid;
  // background-color: rgba(253, 152, 1, 0.26);
}

.vertically-centered {
  margin: auto;
  // width: 50%;
  // border: 3px solid green;
  // padding: 10px;
}
.vertical-center {
  vertical-align: middle;
}

.success-card {
  margin: auto;
  width: 658px;
  padding: 10px;
  height: 478px;
  width: 50%;
  justify-content: center;
  background-color: #f5f6f8;
  box-shadow: 3px 5px 14.26px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 50%;
  width: 35%;
}
@media screen and (max-width: 1024px) {
  .success-card {
    width: 60%;
  }
}

.success-title {
  font-family: "Roboto";
  font-size: 24px;
  text-align: center;
  color: #000;
  font-weight: 400;
}

.choose-plan-modal .modal-dialog {
  // padding: 0px;
  // margin: 0px;
  min-width: 100vh;
  // max-height: 100vh;
}

.choose-plan-modal .modal-footer {
  padding: 0px;
  margin: 0px;
  // min-width: 100vh;
  // max-height: 100vh;
}

td > input {
  border: 0;
  outline: 0;
  height: 20px;
}
td > input:focus {
  outline: none !important;
}
td > textarea {
  border: 0;
  outline: 0;
}
td > textarea:focus {
  outline: none !important;
}
// override accordion
.accordion-item {
  background-color: #f5f6f8 !important;
}

.accordion-button:not(.collapsed),
.accordion-button {
  color: #000;
  font-size: 1.143rem;
  font-weight: 500;
}

//override table
.table-bordered {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
}

.nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  .nav {
    flex-grow: 2;
  }
  h4 {
    margin: 0;
    flex-grow: 1;
  }
}
.fixed-height-43 {
  height: 43vh !important;
  overflow: auto;
}

.fixed-height-48 {
  height: 48vh !important;
  overflow: auto;
}

.fixed-height-56 {
  height: 56vh !important;
  overflow: auto;
}

.fixed-height-58 {
  height: 58vh !important;
  overflow: auto;
}

.fixed-height-59 {
  height: 59vh !important;
  overflow: auto;
}

.fixed-height-60 {
  height: 60vh !important;
  overflow: auto;
}

.fixed-height-61 {
  height: 61vh !important;
  overflow: auto;
}
.fixed-height-64 {
  height: 64vh !important;
  overflow: auto;
}

.fixed-height-65 {
  height: 65vh !important;
  overflow: auto;
}
.fixed-height-67 {
  height: 67vh !important;
  overflow: auto;
}

.fixed-height-68 {
  height: 68vh !important;
  overflow: auto;
}

.fixed-height-69 {
  height: 69vh !important;
  overflow: auto;
}

.fixed-height-70 {
  height: 70vh !important;
  overflow: auto;
}

.fixed-height-71 {
  height: 71vh !important;
  overflow: auto;
}
.fixed-height-73 {
  height: 73vh !important;
  overflow: auto;
}

.fixed-height-74 {
  height: 74vh !important;
  overflow: auto;
}

.fixed-height-75 {
  height: 75vh !important;
  overflow: auto;
}

.fixed-height-74 {
  height: 74vh !important;
  overflow: auto;
}

.fixed-height-76 {
  height: 76vh !important;
  overflow: auto;
}

.fixed-height-79 {
  height: 79vh !important;
  overflow: auto;
}

.fixed-height-80 {
  height: 80vh !important;
  overflow: auto;
}
.fixed-height-82 {
  height: 82vh !important;
  overflow: auto;
}

.fixed-height-83 {
  height: 83vh !important;
  overflow: auto;
}

.input-text-white {
  background-color: #fff;
  border-radius: 5px;
  // border-color: #787885 !important;
  border: 1px solid #ced4da !important;
}
.highlight-red {
  border: 1px solid #ff2222 !important;
}
.modal-content-custom-text {
  width: 50%;
  margin: 0 auto;
}

// THIS IS FOR PAGINATION
.paginate-container {
  position: fixed;
  bottom: 30px;
  background-color: inherit;
  left: 60%;
  // width: calc(100vh + 45%);
  ul {
    position: absolute;
    width: 100%;
    bottom: -10px;
    text-align: center;
    li {
      padding-right: 5px;
      padding-left: 5px;
      padding-top: 5px;
      //border:1px solid red;
      font-weight: bold;
    }
  }
}

.side-menu-icon {
  background-size: 18px 18px;
  display: inline-block;
  height: 18px;
  width: 18px;
}

// side bar icons
.dashboard {
  color: #c2cfe0;
}
.task-icon-s {
  background-image: url("assets/images/sidebar/task_s.svg");
}
.enquiries-icon-s {
  background-image: url("assets/images/sidebar/enquiries_s.svg");
}
.notifications-icon-s {
  background-image: url("assets/images/sidebar/notification_s.svg");
}
.settings-icon-s {
  background-image: url("assets/images/sidebar/settings_s.svg");
}
.finance-icon-s {
  background-image: url("assets/images/sidebar/finance_s.svg");
}
.logout-icon-s {
  background-image: url("assets/images/sidebar/logout_s.svg");
}
.task-icon {
  background-image: url("assets/images/sidebar/task.svg");
}
.enquiries-icon {
  background-image: url("assets/images/sidebar/enquiries.svg");
}
.notifications-icon {
  background-image: url("assets/images/sidebar/notification.svg");
}
.settings-icon {
  background-image: url("assets/images/sidebar/settings.svg");
}

.finance-icon {
  background-image: url("assets/images/sidebar/finance.svg");
}

.users-icon {
  background-image: url("assets/images/sidebar/users.svg");
}
.organizations-icon {
  background-image: url("assets/images/sidebar/roles.svg");
}

.security-icon {
  background-image: url("assets/images/sidebar/security.svg");
}
.website-icon {
  background-image: url("assets/images/sidebar/website.svg");
}
.roles-icon {
  background-image: url("assets/images/sidebar/roles.svg");
}
.permissions-icon {
  background-image: url("assets/images/sidebar/permissions.svg");
}
.modules-icon {
  background-image: url("assets/images/sidebar/modules.svg");
}
.forms-icon {
  background-image: url("assets/images/sidebar/forms.svg");
}

.users-icon-s {
  background-image: url("assets/images/sidebar/users_s.svg");
}
.security-icon-s {
  background-image: url("assets/images/sidebar/security_s.svg");
}
.organizations-icon-s {
  background-image: url("assets/images/sidebar/roles_s.svg");
}
.website-icon-s {
  background-image: url("assets/images/sidebar/website_s.svg");
}
.roles-icon-s {
  background-image: url("assets/images/sidebar/roles_s.svg");
}
.permissions-icon-s {
  background-image: url("assets/images/sidebar/permissions_s.svg");
}
.modules-icon-s {
  background-image: url("assets/images/sidebar/modules_s.svg");
}
.forms-icon-s {
  background-image: url("assets/images/sidebar/forms_s.svg");
}

.customer-icon {
  background-image: url("assets/images/common/customer-icon.svg");
}

.supplier-icon {
  background-image: url("assets/images/common/supplier-icon.svg");
}

.btn.btn-gray {
  color: #ffffff;
  background-color: #d3d3d3;
  border-color: #d3d3d3;
}
.btn.btn-gray:hover {
  color: #ffffff;
  background-color: #3051cc;
  border-color: #3051cc;
}

.cursor-pointer {
  cursor: pointer;
}

.line-height-21 {
  line-height: 1.313rem;
}

.modal-plan-footer {
  border-top: 1px solid;
  margin-top: 0px;
  margin-bottom: 0px;
}

.edit-box {
  border: 1px solid;
  margin: 4px;
  padding: 2px;
}

.contact-color {
  color: #9b51e0 !important;
}

.dashboard-search-btn {
  background-color: white;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
