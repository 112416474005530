.shoppingCart {
  position: relative;
}
.shoppingCart:hover {
  cursor: pointer;
}
.shoppingCart p {
  position: absolute;
  top: -6px;
  right: -6px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  width: 21px;
  height: 21px;
  background: #fd9801;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding-top: 2px;
}
