.header_operator {
  background-color: #5880d0; //Operator - Blue
}

.header_line_manager {
  background-color: #e54c4c; //Line Manager - Red
}

.header_evaluator {
  background-color: #ffc745; //Evaluator - Yellow
}

.header_refurbisher {
  background-color: #ff9801; //Refurbisher - Orange
}

.header_commissioner {
  background-color: #078d25; //Commissioner - Green
}

.header_customer {
  background-color: #c5c5c5; //Customer - Gray
}

.header_superadmin {
  background-color: #000000; //Customer - Black
}

.header_admin {
  background-color: #000000; //Customer - Black
}

#btn-nofification-bell {
  transform: translateY(3px);
}
