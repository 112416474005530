.supplier-landing-image {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 945px) {
  .supplier-landing-image {
    display: none;
  }
}


@media screen and (max-width: 1000px) {
  .mobile-hide-image {
    display: none !important;
  }
  .font-mobile-size h1 {
    font-size: 1rem;
  } 
}
